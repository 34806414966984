
import { Component } from "vue-property-decorator";
import LoginForm from "@/components/auth/LoginForm.vue";
import { Action } from "vuex-class";
import Alert from "@/components/Alert.vue";
import Loading from "@/components/Loading.vue";
import NotLoggedInLayout from "@/layouts/NotLoggedInLayout.vue";
import { AuthMixin } from "@/mixins/AuthMixin";

const namespace: string = "auth";

@Component({
  components: {
    LoginForm,
    NotLoggedInLayout,
    Alert,
    Loading
  }
})
export default class Login extends AuthMixin {
  public name: string = "Login";

  @Action("login", { namespace })
  private login: any;

  public async onSubmit(credentials: object) {
    this.login(credentials);
  }

  public beforeDestroy() {
    this.resetMessage();
  }
}
