import axios from "axios";
import AppConfig from "@/config/AppConfig";
import CryptService from "@/service/CryptService";

export default class AuthService {
  public isAuthenticated(): boolean {
    const token: string = this.getToken();
    return !!token;
  }

  public checkIsAuthenticated(): Promise<any> | null {
    if (this.isAuthenticated()) {
      const promise: Promise<any> = axios.get(
        AppConfig.ENTRYPOINT + "/api/user",
        {
          headers: {
            Authorization: "Bearer " + this.getToken()
          }
        }
      );

      promise.catch((error: any) => {
        this.clear();
      });

      return promise;
    }

    return null;
  }

  public clear(): void {
    localStorage.clear();
    sessionStorage.clear();

    window.dispatchEvent(
      new StorageEvent("storage", {
        key: "access_token",
        newValue: ""
      })
    );
  }

  public getToken(): string {
    const token: string = this.getUserDataByKey("access_token");
    return token;
  }

  public setToken(token: string): void {
    this.setUserDataByKey("access_token", token);
  }

  public setRole(value: string) {
    this.setUserDataByKey("role", value);
  }

  public getRole(): string {
    const role: string = this.getUserDataByKey("role");

    return role;
  }

  // @ts-ignore
  private getUserDataByKey(key: string): string {
    const value: string | null = localStorage.getItem(key);

    if (value === null) {
      return "";
    }
    try {
      return CryptService.decode(value.toString());
    } catch (e) {
      this.clear();
    }
  }

  private setUserDataByKey(key: string, value: string): void {
    const ciphertext: string = CryptService.encode(value);

    localStorage.setItem(key, ciphertext);

    window.dispatchEvent(
      new StorageEvent("storage", {
        key: key,
        newValue: value
      })
    );
  }

  public setUser(data: any) {
    this.setLocalStore("user", data);
  }

  public getUser(): any {
    return this.getLocalStore("user");
  }

  public getUserId(): number | null {
    if (this.getUser()) {
      return Number(this.getUser().id);
    }
    return null;
  }

  private setLocalStore(key: string, data: any): void {
    if (data instanceof Object || data instanceof Array) {
      data = JSON.stringify(data);
    }
    const encryptedText: string = CryptService.encode(data);
    localStorage.setItem(key, encryptedText);

    window.dispatchEvent(
      new StorageEvent("storage", {
        key: key,
        newValue: data
      })
    );
  }

  private getLocalStore(key: string): any {
    let data: any | null = localStorage.getItem(key);

    if (data === null) {
      return "";
    }
    try {
      return JSON.parse(CryptService.decode(data));
    } catch (e) {
      this.clear();
    }
  }
}
