
import { Component, Prop, Vue } from "vue-property-decorator";
import { BaseMixin } from "@/mixins/baseMixin";
import ActionButton from "@/components/buttons/ActionButton.vue";
import { Action } from "vuex-class";

const namespace = "auth";

@Component({
  components: { ActionButton },
  mixins: [BaseMixin]
})
export default class LoginAsExhibitorButton extends Vue {
  public name: string = "LoginAsExhibitorButton";

  @Action("loginAsExhibitor", { namespace }) loginAsExhibitorAction: any;

  @Prop({ default: {} })
  public item: any;

  private clickAction(): void {
    this.loginAsExhibitorAction({
      id: this.item.id,
      email: this.item.email
    });
  }
}
