// CUSTOM VALIDATORS

import moment from "moment";
import AppConfig from "@/config/AppConfig";

export const dateGreater: any = (value: any, vm: any) => {
  const toDate = moment(value, AppConfig.DATE_FORMAT_FRONTEND);
  const fromDate = moment(vm.dateFrom, AppConfig.DATE_FORMAT_FRONTEND);
  return toDate >= fromDate;
};

/**
 * for i18n working, this validation must have passed "lang" (object) as prop on Validaiton Component
 * placeholders:
 * attribute: (this field name (already passed by label prop) )
 * other:     (name of related field)
 * value:     (the "if"-value of the related field
 * i.e.
 * :lang="{other: $t('general.catalog'), value: $t('general.expovina')}">
 */
export const requiredIfValue: any = (related: any, relatedValue: any) => {
  return (value: any, vm: any) => {
    if (vm.$data[related] == relatedValue) {
      return value !== "" && value !== null;
    }
    return true;
  };
};

export const pdfRequired: any = (value: File) => {
  if (!value) {
    return true;
  }
  return value.type === "application/pdf";
};

export const isMedia: any = (file: File) => {
  if (!file) {
    return true;
  }
  switch (file.type) {
    case "image/png":
      return true;
    case "application/pdf":
      return true;
    case "image/jpeg":
      return true;
    default:
      return false;
  }
};

export const countStand: any = (value: Array<number>, vm: any) => {
  return value.length == Number(vm.countStands);
};

export const linkValidator: any = (value: any, vm: any) => {
  console.log(value);
  if (value) {
    const urlPattern = new RegExp(
      "^(https?:\\/\\/)?[^\\s$.?#].[^\\s]*\\..[^\\s]+$" //Regular expression for URL with or without http/https
    );
    return urlPattern.test(value);
  }
  return true;
};
