import { MutationTree } from "vuex";
import { State } from "./state";

export function TEXT_NOTIFICATION(state: State, message: string) {
  state.text = message;
}

export function ACTIVE_NOTIFICATION(state: State, active: boolean) {
  state.active = active;
}

export function THEME_NOTIFICATION(state: State, theme: string) {
  state.theme = theme;
}

export default <MutationTree<State>>{
  TEXT_NOTIFICATION,
  ACTIVE_NOTIFICATION,
  THEME_NOTIFICATION
};
