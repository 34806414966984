import { MutationTree } from "vuex";
import { State } from "./state";
import CryptService from "@/service/CryptService";

export function CHANGE_STATUS(state: State, status: any) {
  let userData = JSON.parse(
    CryptService.decode(localStorage.getItem("user") || "")
  );
  userData["aside-menu"] = status;
  localStorage.setItem("user", CryptService.encode(JSON.stringify(userData)));

  state.isOpen = status;
}

export function SET_MOBILE_MENU_STATUS(state: State, status: boolean) {
  state.isMobileMenuOpen = status;
}

export default <MutationTree<State>>{
  CHANGE_STATUS,
  SET_MOBILE_MENU_STATUS
};
