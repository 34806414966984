
import { Component, Vue, Watch } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import CrudLayout from "@/layouts/CrudLayout.vue";
import Validation from "@/components/Validation.vue";
import CatalogForm from "@/views/admin/catalog/CatalogForm.vue";
import FormCreate from "@/views/form/create/FormCreate.vue";

const namespace: string = "catalog";

@Component({
  components: {
    CrudLayout,
    Validation,
    CatalogForm
  },
  extends: FormCreate
})
export default class CatalogCreate extends Vue {
  protected name: string = "CatalogCreate";
  protected resourceName = "catalog";

  @Getter("getError", { namespace }) error: any;
  @Getter("isLoading", { namespace }) loading: any;
  @Getter("isCreated", { namespace }) created: any;
  @Getter("getItem", { namespace }) createdItem: any;

  @Action("create", { namespace }) createItemAction: any;
  @Action("resetCreate", { namespace }) resetAction: any;

  beforeDestroy() {
    this.resetAction();
  }
}
