
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class ExpovinaButton extends Vue {
  public name: string = "ExpovinaButton";

  @Prop({ default: "button-title" })
  public title!: string;

  @Prop({ default: false })
  public isLinkOnly!: boolean;
}
