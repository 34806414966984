
import { Component, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import CrudLayout from "@/layouts/CrudLayout.vue";
import Validation from "@/components/Validation.vue";
import PageTitleForm from "@/views/admin/page_title/PageTitleForm.vue";

const namespace: string = "pagetitle";

@Component({
  components: {
    CrudLayout,
    Validation,
    PageTitleForm
  }
})
export default class PageTitleUpdate extends Vue {
  public name: string = "PageTitleUpdate";
  private resourceName = "page-title";

  @Getter("getError", { namespace }) error: any;
  @Getter("isLoading", { namespace }) loading: any;
  @Getter("isUpdated", { namespace }) updated: any;
  @Getter("getItem", { namespace }) item: any;

  @Action("update", { namespace }) updateItemAction: any;
  @Action("findOne", { namespace }) getItemAction: any;
  @Action("resetUpdate", { namespace }) resetAction: any;

  protected back() {
    // TODO remember last table state (session storage)
    this.$router.push({ path: "/" + this.resourceName });
  }

  mounted() {
    this.getItemAction({
      resource: this.resourceName,
      id: Number.parseInt(this.$route.params.id)
    });
  }
  beforeDestroy() {
    this.resetAction();
  }
}
