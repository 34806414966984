export default class SortService {
  public static arrayAsc(array: any[], field: string): any[] {
    return JSON.parse(JSON.stringify(array)).sort(function(
      first: any,
      next: any
    ) {
      if (first[field] < next[field]) {
        return -1;
      }
      if (first[field] > next[field]) {
        return 1;
      }
      return 0;
    });
  }
}
