import auth from "@/middlewares/AuthMiddleware";
import { ROLES } from "@/config/Roles";
import i18n from "@/locales";
import ExhibitorList from "@/views/admin/exhibitor/ExhibitorList.vue";
import ExhibitorView from "@/views/admin/exhibitor/ExhibitorView.vue";
import ExhibitorSendMails from "@/views/admin/exhibitor/ExhibitorSendMails.vue";
import ExhibitorUpdate from "@/views/admin/exhibitor/ExhibitorUpdate.vue";
import ExhibitorCreate from "@/views/admin/exhibitor/ExhibitorCreate.vue";
import ExhibitorResetPassword from "@/views/admin/exhibitor/ExhibitorResetPassword.vue";

export default [
  {
    path: "/exhibitor",
    component: ExhibitorView,
    meta: {
      visibleInAside: true,
      roles: [ROLES.ADMIN, ROLES.SUPER_ADMIN],
      title: (route: any, data: any) => {
        if (data && data.hasOwnProperty("byCatalog")) {
          return String(i18n.t("menu.catalog"));
        }
        return String(i18n.t("menu.exhibitor"));
      },
      middleware: [auth]
    },
    children: [
      {
        path: "",
        name: "exhibitor_list",
        component: ExhibitorList,
        meta: {
          title: "",
          roles: [ROLES.ADMIN, ROLES.SUPER_ADMIN],
          middleware: [auth]
        }
      },
      {
        path: "edit/:id",
        name: "exhibitor_edit",
        component: ExhibitorUpdate,
        meta: {
          title: String(i18n.t("general.edit_exhibitor")),
          roles: [ROLES.ADMIN, ROLES.SUPER_ADMIN],
          middleware: [auth]
        }
      },
      {
        path: "send-mail",
        name: "exhibitor_send_mail",
        component: ExhibitorSendMails,
        meta: {
          title: String(i18n.t("general.send_email_to_all_users")),
          roles: [ROLES.ADMIN, ROLES.SUPER_ADMIN],
          middleware: [auth]
        }
      },
      {
        path: "create",
        name: "exhibitor_create",
        component: ExhibitorCreate,
        meta: {
          title: String(i18n.t("general.exhibitor_create")),
          visibleInAside: true,
          roles: [ROLES.SUPER_ADMIN],
          middleware: [auth],
          avoidBack: true
        }
      },
      {
        path: "password-reset/:id",
        name: "exhibitor_password_reset",
        component: ExhibitorResetPassword,
        meta: {
          roles: [ROLES.SUPER_ADMIN],
          title: (route: any, data: any) => {
            if (data && data.hasOwnProperty("email")) {
              return String(
                i18n.t("general.exhibitor_password_reset", { mail: data.email })
              );
            }
            return "";
          },
          middleware: [auth]
        }
      }
    ]
  }
];
