import AwardCreate from "@/views/admin/award/AwardCreate.vue";
import AwardUpdate from "@/views/admin/award/AwardUpdate.vue";
import { ROLES } from "@/config/Roles";
import auth from "@/middlewares/AuthMiddleware";
import i18n from "@/locales";
import AwardView from "@/views/admin/award/AwardView.vue";
import AwardList from "@/views/admin/award/AwardList.vue";

export default [
  {
    path: "/award",
    component: AwardView,
    meta: {
      visibleInAside: true,
      roles: [ROLES.NOBODY],
      title: String(i18n.t("menu.award")),
      middleware: [auth]
    },
    children: [
      {
        path: "",
        name: "award",
        component: AwardList,
        meta: {
          visibleInAside: true,
          title: "",
          roles: [ROLES.NOBODY],
          middleware: [auth]
        }
      },
      {
        path: "create",
        name: "award_create",
        component: AwardCreate,
        meta: {
          visibleInAside: true,
          roles: [ROLES.NOBODY],
          title: String(i18n.t("general.award_add")),
          middleware: [auth],
          avoidBack: true
        }
      },
      {
        path: "edit/:id",
        name: "award_edit",
        component: AwardUpdate,
        meta: {
          roles: [ROLES.NOBODY],
          title: String(i18n.t("general.award_edit")),
          middleware: [auth]
        }
      }
    ]
  }
];
