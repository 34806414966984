import { ActionTree, ActionContext } from "vuex";
import { State } from "./state";

export function setText(store: ActionContext<State, any>, data: any) {
  store.commit("TEXT_NOTIFICATION", data.text);
  store.commit("ACTIVE_NOTIFICATION", true);
  store.commit("THEME_NOTIFICATION", data.theme);
}

export function setActive(store: ActionContext<State, any>, active: boolean) {
  store.commit("ACTIVE_NOTIFICATION", active);
}

export default <ActionTree<State, any>>{
  setText,
  setActive
};
