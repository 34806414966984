
import { Component, Prop, Vue } from "vue-property-decorator";
import { BaseMixin } from "@/mixins/baseMixin";
import ActionButton from "@/components/buttons/ActionButton.vue";
import { Action } from "vuex-class";

const namespace = "logo";

@Component({
  components: { ActionButton },
  mixins: [BaseMixin]
})
export default class ApproveLogoButton extends Vue {
  public name: string = "ApproveLogoButton";

  @Prop({ default: {} })
  public item: any;

  @Action("selectForApproval", { namespace })
  protected selectForApprovalAction: any;

  public approve() {
    this.selectForApprovalAction(this.item);
  }

  @Prop({ default: false, type: Boolean, required: false })
  protected disabled: any;
}
