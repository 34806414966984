import { GetterTree } from "vuex";
import { State } from "./state";

export function getData(state: State): State {
  return state;
}

export default <GetterTree<State, any>>{
  getData
};
