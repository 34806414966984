
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import CrudLayout from "@/layouts/CrudLayout.vue";
import { Validate } from "vuelidate-property-decorators";
import {
  maxLength,
  //maxValue,
  minLength,
  //minValue,
  numeric,
  required
} from "vuelidate/lib/validators";
import Validation from "@/components/Validation.vue";
import IWine from "@/models/IWine";
import Dropdown from "@/components/Dropdown.vue";
import FormBackButton from "@/components/buttons/FormBackButton.vue";
import IRegion from "@/models/IRegion";
import IAward from "@/models/IAward";
import { Action, Getter } from "vuex-class";
import AppConfig from "@/config/AppConfig";
import MultiSelect from "@/components/multiselect/MultiSelect.vue";
import AuthService from "@/service/AuthService";
import IUser from "@/models/IUser";
import AuthImage from "@/directives/AuthImage";
import { isMedia, requiredIfValue } from "@/validators/validators";
import axios, { AxiosResponse } from "axios";
import FileSaver from "file-saver";
import WineFormUpload from "@/views/admin/wine/WineFormUpload.vue";
import Permission from "@/service/Permission";
import { ROLES } from "@/config/Roles";
import SortService from "@/service/SortService";
import BottleSizeTable from "@/components/tables/BottleSizeTable.vue";
import { namespace as vuexNamespace } from "vuex-class";
import IWineType from "@/models/IWineType";
import IBottleSize from "@/models/IBottleSize";
import XButton from "@/components/buttons/XButton.vue";
import IWineCategory from "@/models/IWineCategory";

//const namespace: string = "wine";

const WineTypeModule = vuexNamespace("winetype");
const SUPER_ADMIN: string = "SUPER_ADMIN";
const ADMIN: string = "ADMIN";
const EXHIBITOR: string = "EXHIBITOR";
const CREATE: string = "CREATE";
const UPDATE: string = "UPDATE";

@Component({
  components: {
    XButton,
    BottleSizeTable,
    MultiSelect,
    FormBackButton,
    Dropdown,
    CrudLayout,
    Validation,
    WineFormUpload
  },
  directives: {
    AuthImage: new AuthImage()
  }
})
export default class WineForm extends Vue {
  // Component initial values
  public name: string = "WineForm";
  private resourceName = "wine";
  private authService = new AuthService();
  public logo: string = "";
  public uploadPercentage: number = 0;
  public showProgressBar: boolean = true;
  public imageUrl: string = "";
  public id?: number;
  public isPublic: number = 1;

  // Regions Dropdown
  @Action("region/search")
  public fetchAllRegions: any;

  @Getter("region/getList")
  public getAllRegions: any;

  // Awards Dropdown
  @Action("award/search")
  public fetchAllAwards: any;

  @Getter("award/getList")
  public getAllAwards: any;

  // Exhibitors Dropdown
  @Action("wine/getExhibitors")
  public fetchAllExhibitors: any;

  @Getter("wine/getExhibitorsList")
  public getAllExhibitors: any;

  @WineTypeModule.Action("findAll")
  public getWineTypesAction: any;

  @WineTypeModule.Getter("getList")
  public getWineTypes: any;

  // props
  @Prop({ default: "add" })
  public type: any;

  @Prop({ default: false })
  public saved: any;

  @Prop({ default: false })
  public resetAction: any;

  @Prop({ default: false })
  public loading: any;

  @Prop({ default: "" })
  public error: any;

  @Prop({
    default: () => {}
  })
  public saveAction: any;

  @Prop({
    default: () => {}
  })
  public back: any;

  @Prop()
  public item: any;

  // model
  @Validate({ required, minLength: minLength(3), maxLength: maxLength(64) })
  public wine: string = "";

  public regionId: number = -1;
  @Validate({ required })
  public region: IRegion | null = null;

  @Validate({})
  public year: number | null = null;

  @Validate({ required })
  public wineType: any = null;

  @Validate({})
  public wineCategory: any = null;

  @Validate({ numeric })
  public awardId: number | null = null;
  public award: IAward | undefined = undefined;

  @Validate({ maxValue: maxLength(20) })
  public sku: string = "";

  @Validate({
    requireIfValue: requiredIfValue("logo", null),
    incorrectMediaType: isMedia
  })
  public file: any = "";

  @Validate({ required })
  public exhibitor: any = null;

  protected selectedGrapeVarieties: Array<{ id: number; name: string }> = [];

  public userId: number | null = null;
  public createdBy: number | null = null;
  public creator: IUser | null = null;
  public bottleSize: IBottleSize | null = null;

  protected allGrapeVarieties = [];

  protected allWineCategories: Array<IWineCategory> = [];

  @Watch("item", { immediate: true, deep: true })
  protected onItemChange(item: any) {
    if (item) {
      this.init(item);
    }
  }

  @Watch("region", { immediate: true, deep: true })
  protected onRegionChange(region: any) {
    if (region) {
      this.regionId = region.id;
    }
  }

  @Watch("exhibitor", { immediate: true, deep: true })
  protected onExhibitorChange(exhibitor: any) {
    if (exhibitor) {
      this.userId = exhibitor.id;
    }
  }

  public get fileExtension(): string {
    if (this.file) {
      return this.file.name.split(".").pop();
    }

    return "";
  }

  public get mode() {
    return this.$route.name === "wine_edit" ? UPDATE : CREATE;
  }

  private fetchDropdownData() {
    this.fetchAllRegions({
      resource: "region",
      params: {
        currentPerPage: 10000,
        currentPage: 1,
        sort: [
          { field: "country.name", type: "asc" },
          { field: "name", type: "asc" }
        ]
      }
    });

    this.fetchAllAwards({
      resource: "award",
      params: {
        currentPerPage: 10000,
        currentPage: 1,
        sort: [{ field: "award", type: "asc" }]
      }
    });
    const role: string = this.authService.getRole();
    if (role === SUPER_ADMIN || role === ADMIN) {
      this.fetchAllExhibitors({
        resource: "wine",
        params: {
          sort: [{ field: "email", type: "asc" }]
        }
      });
    }

    this.$crudService
      .findAll({
        resource: "grape-variety",
        descriptionField: "bezeichnung_d_cod"
      })
      .then((response: AxiosResponse) => {
        if (response.data) {
          this.allGrapeVarieties = response.data;
        }
      });

    this.$crudService
      .findAll({
        resource: "wine/wine-category",
        descriptionField: "wine_category"
      })
      .then((response: AxiosResponse) => {
        if (response.data) {
          this.allWineCategories = response.data;
        }
      });
  }

  public get exhibitorMode() {
    let item = this.item ? this.item : null;
    let formMode = this.mode;
    const role: string = this.authService.getRole();

    if (formMode === "UPDATE") {
      if (
        (role === SUPER_ADMIN || role === ADMIN) &&
        item &&
        item.isCreatedByAdmin
      ) {
        return "EDIT";
      } else if (role === SUPER_ADMIN || role === ADMIN) {
        return "SHOW";
      }
    } else {
      if (role === SUPER_ADMIN || role === ADMIN) {
        return "EDIT";
      }
    }

    return "HIDE";
  }

  public get exhibitorText() {
    return this.exhibitor && this.exhibitor.email ? this.exhibitor.email : "";
  }

  protected get dropdownRegions() {
    /*this.getAllRegions.sort((region1: any, region2: any) => {
      if (region1.country.name > region2.country.name) return 1;
      if (region1.country.name < region2.country.name) return -1;
      if (region1.name > region2.name) return 1;
      if (region1.name < region2.name) return -1;
    });*/

    return this.getAllRegions.map((region: IRegion) => {
      return {
        name: region.country.name + " - " + region.name,
        id: region.id
      };
    });
  }

  public get dropdownAwards() {
    let dropdownAwards: Array<Object> = [];

    dropdownAwards.push({
      name: String(this.$t("general.no_award")),
      value: null
    });

    this.getAllAwards
      .filter((award: IAward) => award.is_active)
      .forEach((award: any) => {
        console.debug("WineForm.drodownAwards", award);
        dropdownAwards.push({
          name: this.getAwardSymbolByValue(award.symbol) + " " + award.award,
          value: award.id
        });
      });

    return dropdownAwards;
  }

  public get dropdownWineTypes() {
    return this.getWineTypes.map((wineType: IWineType) => {
      return {
        wine_type: wineType.wine_type,
        id: wineType.id
      };
    });
  }

  public get dropdownExhibitors() {
    let dropdownExhibitors: Array<Object> = [];

    this.getAllExhibitors.forEach((exhibitor: any) => {
      dropdownExhibitors.push({
        email: exhibitor.email,
        id: exhibitor.id
      });
    });

    return SortService.arrayAsc(dropdownExhibitors, "email");
  }

  private init(wine: IWine): void {
    this.$nextTick(() => {
      this.id = wine.id;
      this.wine = wine.wine;
      this.region = {
        name: wine.region.country.name + " - " + wine.region.name,
        id: wine.region.id,
        country: wine.region.country
      };
      this.year = wine.year;

      if (wine.logo != null) {
        this.logo = wine.logo;
      }

      this.sku = wine.sku;
      this.regionId = wine.region_id;
      this.awardId = wine.award_id;
      this.wineType = wine.wine_type;
      this.wineCategory = wine.wine_category;
      //this.grapeVariety = wine.grape_variety;
      this.selectedGrapeVarieties = wine.grape_varieties.map((gv: any) => {
        return { id: gv.id, name: gv.bezeichnung_d_cod };
      });
      this.userId = wine.user_id;
      this.exhibitor = wine.user;
      this.createdBy = wine.created_by;
      this.creator = wine.creator;
      this.imageUrl =
        process.env.VUE_APP_BACKEND_HOST +
        "/api/wine/thumbnail/" +
        wine.id +
        "?t=" +
        Date.now();
      this.isPublic = Number(wine.public_wine);
    });
  }

  private fileHandler(value: any) {
    this.file = value;
  }

  private selectAward(value: any) {
    this.awardId = value;
  }

  public download() {
    const el: HTMLElement | null = document.getElementById("image-preview");
    if (
      el &&
      el.getAttribute("valid") == "true" &&
      Permission.hasPermission(
        [ROLES.EXHIBITOR, ROLES.ADMIN, ROLES.SUPER_ADMIN],
        this.authService.getRole()
      )
    ) {
      const { mimetype } = this.item;
      axios
        .get(
          process.env.VUE_APP_BACKEND_HOST + "/api/wine/download/" + this.id,
          { responseType: "arraybuffer" }
        )
        .then(response => {
          const file = new Blob([response.data], { type: mimetype });
          FileSaver.saveAs(file, this.logo);
        });
    }
  }

  private getAwardSymbolByValue(value: string): string {
    let symbol: string = "";

    AppConfig.AWARD_SYMBOLS.forEach(item => {
      if (item.value === value) {
        symbol = item.name;
      }
    });

    return symbol;
  }

  public created() {
    this.fetchDropdownData();
  }

  public get preview(): boolean {
    const authService: AuthService = new AuthService();
    if (this.type == "edit") {
      if (
        Permission.hasPermission(
          [ROLES.EXHIBITOR, ROLES.ADMIN, ROLES.SUPER_ADMIN],
          authService.getRole()
        )
      ) {
        return true;
      }

      if (this.item) {
        if (authService.getUserId() == this.item.user_id) {
          return true;
        }
      }
    }

    return false;
  }

  public mounted() {
    this.getWineTypesAction({
      descriptionField: "wine_type",
      resource: "wine-type"
    });
  }

  public onSubmit(): void {
    const authService: AuthService = new AuthService();
    if (this.type == "add" && authService.getRole() == ROLES.EXHIBITOR) {
      this.exhibitor = {
        id: authService.getUserId()
      };
    }
    this.$v.$touch();
    if (this.$v.$invalid) {
      return;
    }

    console.debug("WineForm 1");

    const formData = new FormData();
    formData.append("file", this.file);
    formData.append("wine", this.wine);
    formData.append("region_id", String(this.regionId));
    formData.append("region", String(this.region));
    formData.append("year", String(this.year));
    formData.append("wine_type_id", String(this.wineType?.id) ?? null);
    formData.append("wine_category_id", String(this.wineCategory?.id ?? null));
    //formData.append("grape_variety", String(this.grapeVariety));
    formData.append("public_wine", String(this.isPublic));
    formData.append("bottle_size", JSON.stringify(this.bottleSize));
    console.debug("WineForm 2");
    if (this.awardId == null) {
      formData.append("award_id", "");
    } else {
      formData.append("award_id", String(this.awardId));
    }

    formData.append("award", String(this.award));
    formData.append("sku", String(this.sku));
    console.debug("WineForm 3");
    formData.append(
      "grape_varieties",
      JSON.stringify(this.selectedGrapeVarieties)
    );
    console.debug("WineForm 4");
    const user_id: number | null =
      this.exhibitor && this.exhibitor.hasOwnProperty("id")
        ? this.exhibitor.id
        : null;

    formData.append("user_id", String(user_id));
    formData.append("created_by", String(this.createdBy));
    formData.append("creator", String(this.creator));

    console.debug("WineForm 5");

    if (this.$route.params.id) {
      const id: number = Number.parseInt(this.$route.params.id);

      // edit
      formData.append("id", String(id));

      this.saveAction({
        resource: "wine/update",
        data: formData,
        id: id,
        descriptionField: "wine",
        disabledLoading: true,
        config: {
          headers: {
            "Content-Type": "multipart/form-data"
          },
          onUploadProgress: (progressEvent: any) => {
            if (formData.get("file") instanceof File) {
              this.uploadPercentage = Number(
                Math.round((progressEvent.loaded / progressEvent.total) * 100)
              );
            }
          }
        }
      })
        .then(() => {
          this.uploadPercentage = 0;
        })
        .catch(() => {
          this.uploadPercentage = 0;
        });
    } else {
      // add
      this.saveAction({
        resource: this.resourceName,
        data: formData,
        disabledLoading: this.file != "",
        descriptionField: "wine",
        config: {
          headers: {
            "Content-Type": "multipart/form-data"
          },
          onUploadProgress: (progressEvent: any) => {
            if (formData.get("file") instanceof File) {
              this.uploadPercentage = Number(
                Math.round((progressEvent.loaded / progressEvent.total) * 100)
              );
            }
          }
        }
      })
        .then(() => {
          this.uploadPercentage = 0;
        })
        .catch(() => {
          this.uploadPercentage = 0;
        });
    }
    console.debug("WineForm 6");
  }

  public get bottleSizes() {
    return this.item && this.item.bottleSizes ? this.item.bottleSizes : [];
  }

  protected get dropdownGrapeVarieties(): Array<any> {
    return this.allGrapeVarieties
      .filter((grapeVariety: any) => {
        return !this.selectedGrapeVarieties
          .map((gv: any) => {
            return gv.id;
          })
          .includes(grapeVariety.id);
      })
      .map((grapeVariety: any) => {
        return { id: grapeVariety.id, name: grapeVariety.bezeichnung_d_cod };
      });
  }

  protected onGrapeVarietyDeselect(e: any, modelIndex: number): void {
    this.selectedGrapeVarieties.splice(modelIndex, 1);
  }

  public addedBottleSize(val: any) {
    this.bottleSize = val;
  }

  public bottleSizesSubmit() {
    this.onSubmit();
  }
}
