
import { Vue, Component } from "vue-property-decorator";
import { BaseMixin } from "@/mixins/baseMixin";

@Component({
  components: {},
  mixins: [BaseMixin]
})
export default class ReloadButton extends Vue {
  public name: string = "ReloadButton";

  public reload(): void {
    this.$emit("reloadData", true);
  }
}
