
import DefaultLayout from "@/layouts/DefaultLayout.vue";
import { Component, Vue } from "vue-property-decorator";
import { BaseMixin } from "@/mixins/baseMixin";
import { Action, Getter } from "vuex-class";
import MisTable from "@/components/MisTable.vue";
import { ICrudOptions } from "@/service/interfaces/ICrudOptions";
import ITableColumn from "@/interfaces/table/ITableColumn";
import CrudLayout from "@/layouts/CrudLayout.vue";
import i18n from "@/locales";
import "vue-good-table/dist/vue-good-table.css";
import IAward from "@/models/IAward";

const namespace = "award";

@Component({
  components: {
    CrudLayout,
    DefaultLayout,
    MisTable
  },
  mixins: [BaseMixin]
})
export default class AwardList extends Vue {
  public name: string = "AwardList";

  public resourceName: string = "award";

  public columns: Array<ITableColumn> = [
    {
      label: String(i18n.t("general.award")),
      field: "award",
      filterOptions: {
        enabled: true
      }
    },
    {
      label: String(i18n.t("general.symbols")),
      field: "symbol",
      filterOptions: {
        enabled: true,
        filterDropdownItems: [
          { value: "v", text: "❖" },
          { value: "n", text: "■" },
          { value: "l", text: "●" },
          { value: "m", text: "❍" },
          { value: "z", text: "❚" },
          { value: "r", text: "❒" },
          { value: "d", text: "❄" },
          { value: "k", text: "❋" },
          { value: "a", text: "❁" },
          { value: "b", text: "❂" }
        ]
      }
    },
    {
      label: String(i18n.tc("general.action", 2)),
      field: "actions",
      sortable: false
    }
  ];

  protected searchFields: Array<string> = ["award"];

  @Getter("getList", { namespace })
  protected dataList: any;

  @Getter("getTotal", { namespace })
  protected totalRows: any;

  @Getter("getError", { namespace })
  protected error: any;

  @Getter("isLoading", { namespace })
  protected loading: any;

  @Getter("isDeleted", { namespace })
  protected deleted: any;

  @Action("search", { namespace })
  protected searchItemsAction: any;

  @Action("del", { namespace })
  protected deleteItemAction: any;

  @Action("resetList", { namespace })
  protected resetAction: any;

  public searchAction(options: ICrudOptions): void {
    this.searchItemsAction(options);
  }

  public deleteAction(options: ICrudOptions): void {
    this.deleteItemAction(options);
  }

  public beforeDestroy() {
    this.resetAction();
  }

  public get parseDataList(): Array<IAward> {
    const symbols = [
      { value: "v", text: "❖" },
      { value: "n", text: "■" },
      { value: "l", text: "●" },
      { value: "m", text: "❍" },
      { value: "z", text: "❚" },
      { value: "r", text: "❒" },
      { value: "d", text: "❄" },
      { value: "k", text: "❋" },
      { value: "a", text: "❁" },
      { value: "b", text: "❂" }
    ];
    this.dataList.map((award: IAward) => {
      let symbol = symbols.filter((symbol: any) => {
        return symbol.value === award.symbol;
      });
      return (award.symbol = symbol[0].text);
    });
    return this.dataList;
  }
}
