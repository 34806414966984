import { MutationTree } from "vuex";
import { State } from "./state";
import { SET_DATA_ERROR } from "@/store/modules/crud/functions/mutations";

export function SET_DATA_GENERATED_CATALOG(state: State, data: any) {
  state.generatedCatalog = data;
}

export function SET_DATA_COUNT_CATALOG_PAGES(state: State, data: any) {
  state.countCatalogPages = data;
}

export default <MutationTree<State>>{
  SET_DATA_GENERATED_CATALOG,
  SET_DATA_ERROR,
  SET_DATA_COUNT_CATALOG_PAGES
};
