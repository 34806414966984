import { GetterTree } from "vuex";
import { State } from "./state";

export function getToken(state: State): State {
  return state.token;
}

export function getIsLoading(state: State): boolean {
  return state.isLoading;
}

export function getError(state: State): State {
  return state.error;
}

export function getSuccess(state: State): State {
  return state.success;
}

export function getExistCatalogRequest(state: State): boolean {
  return state.existCatalogRequest;
}

export default <GetterTree<State, any>>{
  getToken,
  getIsLoading,
  getError,
  getSuccess,
  getExistCatalogRequest
};
