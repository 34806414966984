
import { Component, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import CrudLayout from "@/layouts/CrudLayout.vue";
import Validation from "@/components/Validation.vue";
import FormCreate from "@/views/form/create/FormCreate.vue";
import WineTypeForm from "@/views/admin/wine_type/WineTypeForm.vue";

const namespace: string = "winetype";

@Component({
  components: {
    CrudLayout,
    Validation,
    WineTypeForm
  },
  extends: FormCreate
})
export default class WineTypeCreate extends Vue {
  public name: string = "WineTypeCreate";
  public resourceName = "wine-type";

  @Getter("getError", { namespace })
  public error: any;

  @Getter("isLoading", { namespace })
  public loading: any;

  @Getter("isCreated", { namespace })
  public created: any;

  @Getter("getItem", { namespace })
  public createdItem: any;

  @Action("create", { namespace })
  public createItemAction: any;

  @Action("resetCreate", { namespace })
  public resetAction: any;

  public beforeDestroy() {
    this.resetAction();
  }
}
