import MultiSelect from "./MultiSelect.vue";

// @ts-ignore
import MultiSelectMixin from "./MultiSelectMixin.js";

// @ts-ignore
import PointerMixin from "./PointerMixin.js";

export default MultiSelect;

export { MultiSelect, MultiSelectMixin, PointerMixin };
