import { ActionContext, ActionTree } from "vuex";
import { State } from "./state";
import { ICrudOptions } from "@/service/interfaces/ICrudOptions";
import axios from "axios";
import ErrorHelper from "@/utils/error/ErrorHelper";
import AppConfig from "@/config/AppConfig";
import {
  del,
  findAll,
  findOne,
  paginate,
  resetCreate,
  resetList,
  resetUpdate,
  search,
  update
} from "@/store/modules/crud/functions/actions";
import i18n from "@/locales";
import CrudActions from "@/store/modules/crud/classes/CrudActions";
import CrudService from "@/service/CrudService";

const crudActions: CrudActions = new CrudActions();
const BASE_URL: string = AppConfig.ENTRYPOINT + "/api/";
const crudService: CrudService = new CrudService();

export function create(
  store: ActionContext<any, any>,
  options: ICrudOptions
): Promise<any> {
  crudActions.dispatchLoading(store, !options.disabledLoading);
  store.commit("SET_DATA_LOADING", !options.disabledLoading);
  store.commit("SET_DATA_CREATED", false);

  const request: Promise<any> = crudService.create(options);

  request.then((response: any) => {
    crudActions.resolveWriteAction(
      store,
      response,
      String(
        i18n.t("general.item_created_success", {
          item: response.data.data[options.descriptionField]
        })
      ),
      response.data.data,
      options
    );
    store.commit("SET_DATA_CREATED", true);
  });

  request.catch(error => {
    crudActions.rejectWriteAction(store, error, options);
    store.commit("SET_DATA_CREATED", false);
  });

  return request;
}

export function updateWine(
  store: ActionContext<State, any>,
  options: ICrudOptions
): Promise<any> {
  const isFileUpload: boolean =
    options.data.get("file") instanceof File ? true : false;

  crudActions.dispatchLoading(store, !isFileUpload);

  store.commit("SET_DATA_UPDATED", false);
  const request = crudService.updatePost(options);
  request.then((response: any) => {
    store.commit("SET_DATA_ERROR", null);
    store.commit("SET_DATA_UPDATED", true);
    crudActions.resolveWriteAction(
      store,
      response,
      String(
        i18n.t("general.item_updated_success", {
          item: response.data.data[options.descriptionField]
        })
      ),
      response.data.data,
      options
    );
  });
  request
    .catch((error: any) => {
      store.dispatch(
        "snackbar/setText",
        {
          text: ErrorHelper.getError(error),
          type: "danger"
        },
        { root: true }
      );
      store.commit("SET_DATA_ERROR", ErrorHelper.getError(error));
      store.commit("SET_DATA_UPDATED", false);
    })
    .finally(() => {
      store.commit("SET_DATA_LOADING", false);
    });

  return request;
}

export async function getExhibitors(
  store: ActionContext<State, any>,
  options: ICrudOptions
): Promise<any> {
  let postData: any = {};

  if (options.id) {
    postData["id"] = options.id;
  }

  return axios
    .get(BASE_URL + options.resource + "/get-exhibitors")
    .then((response: any) => {
      store.commit("SET_DATA_EXHIBITORS_LIST", response.data);
      store.commit("SET_DATA_ERROR", null);
    })
    .catch((error: any) => {
      store.commit("SET_DATA_ERROR", ErrorHelper.getError(error));
    });
}

export async function addPrice(
  store: ActionContext<State, any>,
  options: ICrudOptions
): Promise<any> {
  store.commit("SET_DATA_LOADING", true);
  crudActions.dispatchLoading(store, true);

  return axios
    .post(
      BASE_URL +
        options.resource +
        "/add-price/" +
        options.params.wine_id +
        "/" +
        options.params.bottle_size_id +
        "/" +
        options.params.price
    )
    .then((response: any) => {
      store.commit("SET_DATA_LOADING", false);
      crudActions.dispatchLoading(store, false);
      crudActions.resolveWriteAction(
        store,
        response,
        String(
          i18n.t("general.item_updated_success", {
            item: options.params.wine_name
          })
        ),
        response.data.data,
        options
      );
    })
    .catch(error => {
      const errorParams = {
        attribute: String(i18n.t("general.price")),
        min: 0
      };

      store.commit("SET_DATA_LOADING", false);
      crudActions.dispatchLoading(store, false);
      crudActions.rejectWriteAction(
        store,
        String(i18n.t("validation." + error.response.data[0], errorParams)),
        options
      );
    });
}

export async function hasReleased(
  store: ActionContext<State, any>,
  options: ICrudOptions
): Promise<any> {
  return axios
    .get(
      BASE_URL + options.resource + "/" + options.additional + "/" + options.id
    )
    .then((response: any) => {
      store.commit("SET_DATA_HAS_RELEASED", response.data);
      store.commit("SET_DATA_ERROR", null);
    })
    .catch((error: any) => {
      store.commit("SET_DATA_ERROR", ErrorHelper.getError(error));
    });
}

export default <ActionTree<State, any>>{
  findOne,
  findAll,
  search,
  paginate,
  create,
  update,
  del,
  resetList,
  resetCreate,
  resetUpdate,
  getExhibitors,
  addPrice,
  hasReleased,
  updateWine
};
