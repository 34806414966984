
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import CrudLayout from "@/layouts/CrudLayout.vue";
import { Validations } from "vuelidate-property-decorators";
import { email, required, sameAs } from "vuelidate/lib/validators";
import Validation from "@/components/Validation.vue";
import IAdmin from "@/models/IAdmin";
import Loading from "@/components/Loading.vue";
import { BFormCheckbox } from "bootstrap-vue";

const namespace: string = "admin";

@Component({
  components: {
    CrudLayout,
    Validation,
    Loading,
    BFormCheckbox
  }
})
export default class AdminForm extends Vue {
  // component initial values
  public name: string = "AdminForm";
  private resourceName = "admin";

  // props
  @Prop({ default: "add" })
  public type: any;

  @Prop({ default: false })
  public saved: any;

  @Prop({ default: false })
  public loading: any;

  @Prop({ default: "" })
  public error: any;

  @Prop({ default: () => {} })
  public saveAction: any;

  @Prop({ default: () => {} })
  public back: any;

  @Prop()
  public item: any;

  public adminEmail: string = "";
  public password: string = "";
  public password_repeat: string = "";
  public adminActive: number = 0;

  @Watch("saved", { immediate: true, deep: true })
  protected onUpdated() {
    this.$v.$reset();
    this.password = "";
    this.password_repeat = "";
  }

  @Watch("item", { immediate: true, deep: true })
  protected onItemChange(item: any) {
    if (item) {
      this.init(item);
    }
  }

  @Validations()
  protected validations() {
    let fields = {
      adminEmail: {
        required,
        email
      }
    };
    if (this.type == "add") {
      fields = Object.assign({}, fields, {
        password: { required },
        password_repeat: {
          required,
          password_repeat: sameAs("password")
        }
      });
    } else {
      fields = Object.assign({}, fields, {
        password: {},
        password_repeat: {
          password_repeat: sameAs("password")
        }
      });
    }
    return fields;
  }

  private init(admin: IAdmin): void {
    this.adminEmail = admin.email;
    this.adminActive = admin.active;
  }

  public onSubmit(): void {
    this.$v.$touch();
    if (this.$v.$invalid) {
      return;
    }
    const admin: IAdmin = {
      email: this.adminEmail,
      password: this.password,
      passwordRepeat: this.password_repeat,
      active: Number(this.adminActive)
    };
    if (this.$route.params.id) {
      // edit
      admin.id = Number.parseInt(this.$route.params.id);
      this.saveAction({
        resource: this.resourceName,
        data: admin,
        id: admin.id,
        descriptionField: "email"
      });
    } else {
      // add
      this.saveAction({
        resource: this.resourceName,
        data: admin,
        descriptionField: "email"
      });
    }
  }
}
