import ICatalog from "@/models/ICatalog";
import moment from "moment";

export default class CatalogUtil {
  public static isActive(catalog: ICatalog): boolean {
    const fromDate: number = Number(
      moment(catalog.from.toString()).format("X")
    );
    const toDate: number = Number(moment(catalog.to.toString()).format("X"));
    const cDate: number = Number(moment().format("X"));

    if (cDate <= toDate && cDate >= fromDate) {
      return true;
    }

    return false;
  }

  public static isExpovina(catalog: ICatalog | null): boolean {
    return catalog !== null && 2 === catalog.catalog_type;
  }

  public static isPrimavera(catalog: ICatalog | null): boolean {
    return catalog !== null && 1 === catalog.catalog_type;
  }
}
