// TODO use/update models
import { CrudState } from "@/store/modules/crud/State";
import IWine from "@/models/IWine";

export class State extends CrudState {
  dataList: Array<IWine> = [];
  dataItem: IWine | null = null;
  exhibitors?: Array<any> = undefined;
  hasReleased: boolean = false;
}
