
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import CrudLayout from "@/layouts/CrudLayout.vue";
import Validation from "@/components/Validation.vue";
import BottleSizeForm from "@/views/admin/bottle_size/BottleSizeForm.vue";

@Component({
  components: {
    CrudLayout,
    Validation,
    BottleSizeForm
  }
})
export default class FormCreate extends Vue {
  public name: string = "FormCreate";
  protected resourceName = "";
  protected redirectTimeout: any;

  @Watch("createdItem", { immediate: true, deep: true })
  protected onCreated(createdItem: any): void {
    if (createdItem && createdItem.id) {
      this.redirectTimeout = setTimeout(
        this.redirectOnSuccess.bind(this, createdItem.id),
        1000
      );
    }
  }

  protected get redirectRouteName(): string | null {
    return null;
  }

  protected getRedirectUrl(id: any): string {
    if (this.redirectRouteName !== null) {
      const props = this.$router.resolve({
        name: this.redirectRouteName,
        params: { id: id }
      });
      return props.href;
    }
    return "/" + this.resourceName + "/edit/" + id;
  }

  protected redirectOnSuccess(id: any): void {
    clearTimeout(this.redirectTimeout);
    this.$router.push(this.getRedirectUrl(id));
  }

  protected back(): void {
    this.$router.go(-1);
  }

  protected resetAction(): void {}

  beforeDestroy() {
    if (this.redirectTimeout) {
      clearTimeout(this.redirectTimeout);
    }
    this.resetAction();
  }
}
