import { MutationTree } from "vuex";
import { State } from "./state";

export function SET_DATA_UPDATE(state: State, data: any) {
  state.data = Object.assign({}, state.data, data);
}

export default <MutationTree<State>>{
  SET_DATA_UPDATE
};
