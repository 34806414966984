import i18n from "@/locales";

export const Types: any = {
  1: i18n.t("general.logo"),
  2: i18n.t("general.ad"),
  3: i18n.t("general.content_page"),
  4: i18n.t("general.catalog")
};

export const Status: any = {
  0: i18n.t("general.processing"),
  1: i18n.t("general.ready"),
  2: i18n.t("general.failed")
};
