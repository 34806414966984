import { render, staticRenderFns } from "./SendMailButton.vue?vue&type=template&id=f958c57e&"
import script from "./SendMailButton.vue?vue&type=script&lang=ts&"
export * from "./SendMailButton.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports