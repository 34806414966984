
import "./assets/sass/master.scss";
import { Component, Vue } from "vue-property-decorator";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import AuthService from "@/service/AuthService";
import { Getter } from "vuex-class";

const authNamespace: string = "auth";

@Component
export default class App extends Vue {
  public name: string = "App";
  public watchInterval: number = 30000; // 30 seconds
  public loginRouteName: string = "Login";

  @Getter("getToken", { namespace: authNamespace })
  protected getToken: any;

  mounted() {
    const authService = new AuthService();
    setInterval(() => {
      authService.checkIsAuthenticated();
    }, this.watchInterval);

    window.onstorage = () => {
      setTimeout(() => {
        if (
          this.$router.currentRoute.name !== this.loginRouteName &&
          !this.getToken
        ) {
          this.$nextTick(() => {
            this.$router.push({ name: this.loginRouteName });
          });
        }
      }, 50);
    };
  }
}
