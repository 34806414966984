
import { Component, Vue, Watch } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import CrudLayout from "@/layouts/CrudLayout.vue";
import Validation from "@/components/Validation.vue";
import PageWineAdForm from "@/views/admin/page/winead/PageWineAdForm.vue";
import { WINE_TYPE, WINE_TYPES } from "@/config/PageTypes";

const namespace: string = "page";
const breadcrumbNamespace: string = "breadcrumb";
const catalogNamespace: string = "catalog";

@Component({
  components: {
    CrudLayout,
    Validation,
    PageWineAdForm
  }
})
export default class PageWineAdCreate extends Vue {
  protected name: string = "PageWineAdCreate";
  private title: string = "";
  private resourceName: string = namespace;

  @Getter("isLoading", { namespace }) loading: any;
  @Getter("isCreated", { namespace }) isCreated?: boolean;
  @Getter("getItem", { namespace: catalogNamespace }) catalog: any;
  @Getter("getActiveCatalog", { namespace: catalogNamespace })
  activeCatalog: any;

  @Action("mergeData", { namespace: breadcrumbNamespace })
  breadcrumbSetDataAction: any;
  @Action("findOne", { namespace: catalogNamespace }) findCatalogAction: any;
  @Action("resetCreate", { namespace }) resetAction: any;
  @Action("createPage", { namespace }) createItemAction: any;
  @Action("redirectCatalogInactive", { namespace })
  redirectCatalogInactiveAction: any;

  @Watch("isCreated", { immediate: true, deep: true })
  protected isItemCreated(status: boolean) {
    if (status) {
      this.redirect();
    }
  }

  protected redirect() {
    this.$router.go(-1);
  }

  get getCatalogId(): string {
    if (this.$router.currentRoute.params.hasOwnProperty("catalog")) {
      return this.$router.currentRoute.params.catalog;
    }
    return this.activeCatalog.id;
  }

  @Watch("catalog", { immediate: true, deep: true })
  protected onCatalog(catalog: any) {
    if (catalog) {
      const { name } = this.catalog;
      this.breadcrumbSetDataAction({
        [String(this.$router.currentRoute.name)]: {
          pageListTitle: this.$t("general.page_from", {
            catalogName: name ? name : ""
          }),
          catalog: name
        }
      });
      if (catalog.currently_active) {
        const id: number = parseInt(this.$router.currentRoute.params.id);
        const key: string =
          id == WINE_TYPE ? "general.page_wine_add" : "general.page_ad_add";
        this.title = String(this.$i18n.t(key, { catalog: catalog.name }));
      } else {
        this.redirectCatalogInactiveAction(true);
        this.redirect();
      }
    }
  }

  public created() {
    if (this.getCatalogId !== "false") {
      const id: number = parseInt(this.$router.currentRoute.params.id);

      if (WINE_TYPES.indexOf(id) == -1) {
        this.redirect();
        return;
      }
      this.findCatalogAction({
        resource: catalogNamespace,
        id: this.getCatalogId
      });
    }
  }

  beforeDestroy() {
    this.reset();
  }

  private reset() {
    this.resetAction();
    this.$store.dispatch("catalog/resetCreate");
  }
}
