import Login from "@/views/Login.vue";
import Register from "@/views/Register.vue";
import ForgotPassword from "@/views/ForgotPassword.vue";
import ResetPassword from "@/views/ResetPassword.vue";
import IfNotExistTokenMiddleware from "@/middlewares/IfNotExistTokenMiddleware";

export default [
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      title: "Login",
      middleware: [IfNotExistTokenMiddleware]
    }
  },
  {
    path: "/register",
    name: "Register",
    component: Register,
    meta: {
      title: "Register",
      middleware: [IfNotExistTokenMiddleware]
    }
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: ForgotPassword,
    meta: {
      title: "Forgot Password",
      middleware: [IfNotExistTokenMiddleware]
    }
  },
  {
    path: "/reset-password/:token",
    name: "ResetPassword",
    component: ResetPassword,
    meta: {
      title: "Reset Password",
      middleware: [IfNotExistTokenMiddleware]
    }
  }
];
