
import { Component, Vue } from "vue-property-decorator";
import CrudLayout from "@/layouts/CrudLayout.vue";

@Component({
  components: {
    CrudLayout
  }
})
export default class extends Vue {
  public name: string = "Help";
  private resourceName = "help";

  private downloadPdf(): void {
    window.location.href = process.env.VUE_APP_BACKEND_HOST + "/api/help";
  }
}
