
import ClickOutside from "@/directives/ClickOutside";
import { Component, Vue, Prop, Watch } from "vue-property-decorator";

export interface DropDownOption {
  index: number;
  text: string;
  callback?: Function | string;
}

@Component({
  directives: {
    ClickOutside: new ClickOutside()
  }
})
export default class DropDownButton extends Vue {
  public name: string = "DropDownButton";
  public expanded: Boolean = false;

  @Prop({ required: true })
  public items!: DropDownOption[];

  @Prop({ required: true })
  public label!: String;

  @Prop({ required: false, default: true })
  public isActive: any;

  @Watch("index")
  protected onChange(value: any) {
    this.$emit("change", { value: value });
  }

  protected toggle() {
    this.expanded = !this.expanded;
  }

  protected selectValue(item: any) {
    switch (typeof item.callback) {
      case "function":
        item.callback();
        break;

      case "string":
        this.$router.push(item.callback);
        break;

      default:
        if (typeof item.index != "undefined") {
          this.$emit("elementClicked", item.index);
        }
    }
  }

  protected hideDropdown() {
    this.expanded = false;
  }
}
