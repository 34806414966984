
import { Vue, Component, Prop } from "vue-property-decorator";
import { BaseMixin } from "@/mixins/baseMixin";
import router from "@/router";
import ActionButton from "@/components/buttons/ActionButton.vue";

@Component({
  components: {
    ActionButton
  },
  mixins: [BaseMixin]
})
export default class CatalogExhibitorListButton extends Vue {
  public name: string = "CatalogExhibitorListButton";

  @Prop({ default: "" })
  public to: any;

  protected queryJson: any = {
    currentPerPage: "20",
    currentPage: "1",
    columnFilters: {}
  };

  @Prop({ default: {} })
  public item: any;

  private redirect() {
    this.queryJson.columnFilters = JSON.stringify({
      catalog_name: this.item.name
    });
    router.push({
      name: "catalog_exhibitor_list",
      params: { catalog: this.item.id }
    });
  }
}
