var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"inline-block"},[_c('b-modal',{attrs:{"id":_vm.modalId,"title":_vm.$t('general.count_pages')},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('table',{staticClass:"border"},[_c('tbody',[_c('tr',{staticClass:"bg-opacity-25",class:[
              _vm.divide
                ? 'bg-green-500 text-green-500'
                : 'bg-red-500 text-red-500'
            ]},[_c('td',[_vm._v(_vm._s(_vm.$t("general.content_page")))]),_c('td',[_vm._v(_vm._s(_vm.pages)+" "+_vm._s(_vm.$t("general.pages")))])]),_c('tr',[_c('td',[_vm._v(_vm._s(_vm.$t("general.cover")))]),_c('td',[_vm._v(_vm._s(_vm.cover)+" "+_vm._s(_vm.$t("general.pages")))])]),_c('tr',{staticClass:"bg-opacity-25",class:[
              _vm.divide
                ? 'bg-green-500 text-green-500'
                : 'bg-red-500 text-red-500'
            ]},[_c('td',[_vm._v(_vm._s(_vm.$t("general.total")))]),_c('td',[_vm._v(_vm._s(_vm.total)+" "+_vm._s(_vm.$t("general.pages")))])])])]),_c('div',{staticClass:"flex px-4 py-2 font-bold items-center text-sm mt-2",class:[_vm.divide ? 'bg-green-500' : 'bg-red-500']},[_c('p',{staticClass:"text-white"},[_vm._v(" "+_vm._s(_vm.divide ? _vm.$t("general.catalog_pages_count_divided_success") : _vm.$t("general.catalog_pages_count_divided_error"))+" ")])])]},proxy:true},{key:"modal-footer",fn:function(){return [_c('button',{staticClass:"expo-btn expo-btn-white",on:{"click":function($event){return _vm.$bvModal.hide(_vm.modalId)}}},[_c('b-icon-x'),_vm._v(" "+_vm._s(_vm.$t("general.close"))+" ")],1)]},proxy:true}])}),_c('button',{staticClass:"!ml-3 expo-btn expo-btn-white",class:{ 'btn-disabled': _vm.disabled },attrs:{"disabled":_vm.disabled,"title":_vm.$t('general.count_pages')},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.countCatalogPages()}}},[_vm._v(" "+_vm._s(_vm.$t("general.count_pages"))+" ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }