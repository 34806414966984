
import { Vue, Component, Prop } from "vue-property-decorator";
import { BaseMixin } from "@/mixins/baseMixin";
import ActionButton from "@/components/buttons/ActionButton.vue";

@Component({
  components: {
    ActionButton
  },
  mixins: [BaseMixin]
})
export default class XButton extends Vue {
  public name: string = "XButton";

  @Prop({ default: "" })
  public to: any;

  @Prop({ default: false, type: Boolean, required: false })
  protected disabled: any;
}
