export class CrudState {
  dataList: Array<any> = [];
  dataItem: any | null = null;
  error: any = null;
  total: number = 0;
  loading: boolean = false;
  created: boolean = false;
  updated: boolean = false;
  deleted: boolean = false;
  savingOrder: boolean = false;
}
