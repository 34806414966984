
import { Vue, Component, Prop } from "vue-property-decorator";
import { Validations } from "vuelidate-property-decorators";
import {
  required,
  email,
  sameAs,
  minLength,
  numeric
} from "vuelidate/lib/validators";
import Validation from "@/components/Validation.vue";
import ExhibitorFormFields from "@/views/admin/exhibitor/form/ExhibitorFormFields.vue";

@Component({
  components: {
    ExhibitorFormFields,
    Validation
  }
})
export default class RegisterForm extends Vue {
  public name: string = "RegisterForm";

  @Prop({ required: false, default: false })
  public reset!: boolean;

  protected showErrors = true;

  protected email: string = "";
  protected password: string = "";
  protected password_repeat: string = "";
  protected companyName: string = "";
  protected street: string = "";
  protected number: string = "";
  protected website: string = "";
  protected zip_code: string = "";
  protected city: string = "";
  protected phone: string = "";
  protected description: string = "";

  @Validations()
  public validations() {
    let fields = {};
    fields = Object.assign({}, fields, {
      email: { required, email },
      password: { required, minLength: minLength(6) },
      password_repeat: {
        required,
        password_repeat: sameAs("password")
      },
      companyName: { required },
      street: {},
      number: { numeric },
      website: {},
      zip_code: { numeric },
      city: {},
      phone: { numeric },
      description: {}
    });
    return fields;
  }

  public onSubmit(): void {
    this.$v.$touch();
    this.showErrors = true;
    if (this.$v.$invalid) {
      return;
    }

    this.$emit("onSubmit", {
      email: this.email,
      password: this.password,
      passwordConfirmation: this.password_repeat,
      companyName: this.companyName,
      street: this.street,
      number: this.number,
      website: this.website,
      zipCode: this.zip_code,
      city: this.city,
      phone: this.phone,
      description: this.description
    });

    this.showErrors = false;

    if (this.reset) {
      this.email = "";
      this.password = "";
      this.password_repeat = "";
      this.companyName = "";
      this.street = "";
      this.number = "";
      this.website = "";
      this.zip_code = "";
      this.city = "";
      this.phone = "";
      this.description = "";
    }
  }

  private companyNameHandler(value: any) {
    this.companyName = value;
  }

  private emailHandler(value: any) {
    this.email = value;
  }

  private streetHandler(value: any) {
    this.street = value;
  }

  private numberHandler(value: any) {
    this.number = value;
  }

  private websiteHandler(value: any) {
    this.website = value;
  }

  private zipCodeHandler(value: any) {
    this.zip_code = value;
  }

  private cityHandler(value: any) {
    this.city = value;
  }

  private phoneHandler(value: any) {
    this.phone = value;
  }

  private descriptionHandler(value: any) {
    this.description = value;
  }

  private passwordHandler(value: any) {
    this.password = value;
  }

  private passwordRepeatHandler(value: any) {
    this.password_repeat = value;
  }
}
