
import { Vue, Component } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";

const namespace = "asideMenu";

@Component
export default class AsideMenuOpenButton extends Vue {
  public name: string = "AsideMenuOpenButton";

  @Getter("getStatus", { namespace: namespace })
  protected isOpen: any;

  @Action("updateStatus", { namespace: namespace })
  protected updateStatus: any;

  protected toggleAsideMenu() {
    this.updateStatus(!this.isOpen);
  }
}
