import RegionCreate from "@/views/admin/region/RegionCreate.vue";
import RegionUpdate from "@/views/admin/region/RegionUpdate.vue";
import { ROLES } from "@/config/Roles";
import auth from "@/middlewares/AuthMiddleware";
import i18n from "@/locales";
import RegionView from "@/views/admin/region/RegionView.vue";
import RegionList from "@/views/admin/region/RegionList.vue";

export default [
  {
    path: "/region",
    component: RegionView,
    meta: {
      visibleInAside: true,
      roles: [ROLES.ADMIN, ROLES.SUPER_ADMIN],
      title: String(i18n.t("menu.region")),
      middleware: [auth]
    },
    children: [
      {
        path: "",
        name: "region",
        component: RegionList,
        meta: {
          visibleInAside: true,
          title: "",
          roles: [ROLES.ADMIN, ROLES.SUPER_ADMIN],
          middleware: [auth]
        }
      },
      {
        path: "create",
        name: "region_create",
        component: RegionCreate,
        meta: {
          visibleInAside: true,
          roles: [ROLES.ADMIN, ROLES.SUPER_ADMIN],
          title: String(i18n.t("general.region_add")),
          middleware: [auth],
          avoidBack: true
        }
      },
      {
        path: "edit/:id",
        name: "region_edit",
        component: RegionUpdate,
        meta: {
          roles: [ROLES.ADMIN, ROLES.SUPER_ADMIN],
          title: String(i18n.t("general.region_edit")),
          middleware: [auth]
        }
      }
    ]
  }
];
