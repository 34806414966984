import AppConfig from "@/config/AppConfig";
// @ts-ignore
import CryptoJS from "crypto-js";

export default class CryptService {
  private static readonly secretKey: string = AppConfig.SECRET_KEY;

  public static encode(value: string | number): string {
    return CryptoJS.AES.encrypt(value, CryptService.secretKey);
  }

  public static decode(value: string | number): string {
    const bytes: any = CryptoJS.AES.decrypt(
      String(value),
      CryptService.secretKey
    );
    return bytes.toString(CryptoJS.enc.Utf8);
  }
}
