
import { Component } from "vue-property-decorator";
import RegisterForm from "@/components/auth/RegisterForm.vue";
import { Action } from "vuex-class";
import Alert from "@/components/Alert.vue";
import Loading from "@/components/Loading.vue";
import NotLoggedInLayout from "@/layouts/NotLoggedInLayout.vue";
import { AuthMixin } from "@/mixins/AuthMixin";

const namespace: string = "auth";

@Component({
  components: {
    NotLoggedInLayout,
    RegisterForm,
    Alert,
    Loading
  }
})
export default class Register extends AuthMixin {
  public name: string = "Register";

  @Action("register", { namespace })
  private register: any;

  public async onSubmit(credentials: object) {
    this.register(credentials);
  }

  public beforeDestroy() {
    this.setError(null);
  }
}
