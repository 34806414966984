
import * as CookieUtils from "@/utils/cookie";
import { Component, Vue } from "vue-property-decorator";

@Component
export default class Cookie extends Vue {
  public name: string = "Cookie";

  public isOpen: Boolean = false;
  public storageName: string = "cookie:accepted";

  public setVisited(): void {
    const now = new Date();
    now.setMonth(now.getMonth() + 4);
    CookieUtils.set(this.storageName, true, { expires: now.toUTCString() });
  }

  public getVisited(): string | null {
    return CookieUtils.get(this.storageName);
  }

  public accept(): void {
    this.isOpen = false;
    this.setVisited();
  }

  public checkVisited(): void {
    if (!this.getVisited()) {
      this.isOpen = true;
    }
  }

  public created(): void {
    this.checkVisited();
  }
}
