import { ActionContext, ActionTree } from "vuex";
import { State } from "./state";
import {
  create,
  del,
  findAll,
  findOne,
  paginate,
  resetCreate,
  resetList,
  resetUpdate,
  search
} from "@/store/modules/crud/functions/actions";

import { ICrudOptions } from "@/service/interfaces/ICrudOptions";
import i18n from "@/locales";
import CrudService from "@/service/CrudService";
import ErrorHelper from "@/utils/error/ErrorHelper";

const crudService: CrudService = new CrudService();

/**
 *
 * @param store
 * @param options
 */
export async function update(
  store: ActionContext<any, any>,
  options: ICrudOptions
): Promise<any> {
  await store.dispatch("snackbar/setLoading", true, { root: true });
  store.commit("SET_DATA_LOADING", true);
  store.commit("SET_DATA_UPDATED", false);
  const request: Promise<any> = crudService.update(options);
  request
    .then((response: any) => {
      store.dispatch(
        "snackbar/setText",
        {
          text: !options.message
            ? String(
                i18n.t("general.item_updated_success", {
                  item: response.data.data[options.descriptionField]
                })
              )
            : options.message,
          type: "success"
        },
        { root: true }
      );
      store.dispatch("snackbar/setLoading", false, { root: true });
      store.commit("SET_DATA_ITEM", response.data.data);
      store.commit("SET_DATA_ERROR", null);
      store.commit("SET_DATA_LOADING", false);
      store.commit("SET_DATA_UPDATED", true);
      store.dispatch("catalog/setIconStatus", true, { root: true });
    })
    .catch(error => {
      store.dispatch(
        "snackbar/setText",
        {
          text: ErrorHelper.getError(error),
          type: "danger"
        },
        { root: true }
      );
      store.dispatch("snackbar/setLoading", false, { root: true });
      store.commit("SET_DATA_ERROR", ErrorHelper.getError(error));
      store.commit("SET_DATA_LOADING", false);
      store.commit("SET_DATA_UPDATED", false);
    });

  return request;
}

export default <ActionTree<State, any>>{
  findOne,
  findAll,
  search,
  paginate,
  create,
  update,
  del,
  resetList,
  resetCreate,
  resetUpdate
};
