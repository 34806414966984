import { ActionContext } from "vuex";
import { PositionState } from "@/store/modules/crud/PositoinState";
import { ICrudOptions } from "@/service/interfaces/ICrudOptions";
import PositionActions from "@/store/modules/crud/classes/PostionActions";

export const positionActions: PositionActions = new PositionActions();

export function swapPositions(
  store: ActionContext<PositionState, any>,
  options: ICrudOptions
): Promise<any> {
  return positionActions.swapPositions(store, options);
}
