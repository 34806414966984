import { GetterTree } from "vuex";
import { State } from "./state";

export function getData(state: State): any {
  return state.data;
}

export default <GetterTree<State, any>>{
  getData
};
