import { Route } from "vue-router";
import CheckPageCatalogService from "@/service/CheckPageCatalogService";

export default async function CheckPageCatalogMiddleware(mainRouter: any) {
  const to: Route = mainRouter.to;
  const next: Function = mainRouter.next;
  const checkService: CheckPageCatalogService = new CheckPageCatalogService();
  checkService.isActive().then(response => {
    if (!response) {
      return next({ path: "/catalog/" + to.params.catalog + "/page" });
    }
    return next();
  });
}
