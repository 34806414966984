
import { Component, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";

const namespace: string = "page";
const catalogNamespace: string = "catalog";

@Component({
  components: {}
})
export default class PageView extends Vue {
  public name: string = "PageView";
}
