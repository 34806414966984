
import { Component, Vue } from "vue-property-decorator";
import ClickOutside from "@/directives/ClickOutside";
import BadgeItem from "@/components/badges/BadgeItem.vue";
import { namespace } from "vuex-class";
import { ICrudOptions } from "@/service/interfaces/ICrudOptions";

const StatusModule = namespace("status");
const resource = "status";

@Component({
  directives: {
    clickOutside: new ClickOutside()
  },
  components: {
    BadgeItem
  }
})
export default class Badge extends Vue {
  public name: string = "Badge";
  protected active: boolean = false;

  @StatusModule.Action("findAll")
  protected findAll: any;

  @StatusModule.Action("del")
  protected removeStatus: any;

  @StatusModule.Getter("getList")
  protected statusList: any;

  protected toggle(): void {
    this.active = !this.active;
  }

  protected onOutsideClick(): void {
    if (this.active) this.active = false;
  }

  protected get statusCount() {
    return this.statusList.length;
  }

  @StatusModule.Action("websocket")
  protected websocket: any;

  protected async created() {
    const options: ICrudOptions = {
      resource: resource,
      descriptionField: "name"
    };
    await this.findAll(options);
    await this.websocket({});
  }

  protected async remove(data: any): Promise<any> {
    const options: ICrudOptions = {
      resource: resource,
      descriptionField: String(this.$t("general.upload_status")),
      id: data.item.id
    };
    await this.removeStatus(options);
    this.statusList.splice(data.index, 1);
  }
}
