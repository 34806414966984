import { MutationTree } from "vuex";
import { State } from "./state";

import {
  SET_DATA_LIST,
  SET_DATA_ITEM,
  SET_DATA_TOTAL,
  SET_DATA_ERROR,
  SET_DATA_LOADING,
  SET_DATA_CREATED,
  SET_DATA_UPDATED,
  SET_DATA_DELETED
} from "@/store/modules/crud/functions/mutations";
import CryptService from "@/service/CryptService";

export function SET_DATA_USER_CATALOG_REQUEST(
  state: any,
  userCatalogRequest: boolean
) {
  const value = JSON.stringify(userCatalogRequest);
  const key = "userCatalogRequest";
  const ciphertext: string = CryptService.encode(value);
  sessionStorage.setItem(key, ciphertext);
  state.userCatalogRequest = userCatalogRequest;
}

export function SET_DATA_ACTIVE_CATALOG(state: any, activeCatalog: any) {
  state.activeCatalog = activeCatalog;
  const value = JSON.stringify(activeCatalog);
  const key = "activeCatalog";
  const ciphertext: string = CryptService.encode(value);
  sessionStorage.setItem(key, ciphertext);
}

export function SET_DATA_LAST_ACTIVE_CATALOG(
  state: any,
  lastActiveCatalog: any
) {
  state.lastActiveCatalog = lastActiveCatalog;
}

export function SET_DATA_CATALOG_PAGE(state: any, hasCatalogPage: any) {
  state.hasCatalogPage = hasCatalogPage;
}

export default <MutationTree<State>>{
  SET_DATA_LIST,
  SET_DATA_ITEM,
  SET_DATA_TOTAL,
  SET_DATA_ERROR,
  SET_DATA_LOADING,
  SET_DATA_CREATED,
  SET_DATA_UPDATED,
  SET_DATA_DELETED,
  SET_DATA_USER_CATALOG_REQUEST,
  SET_DATA_ACTIVE_CATALOG,
  SET_DATA_LAST_ACTIVE_CATALOG,
  SET_DATA_CATALOG_PAGE
};
