import WineTypeView from "@/views/admin/wine_type/WineTypeView.vue";
import WineTypeList from "@/views/admin/wine_type/WineTypeList.vue";
import WineTypeCreate from "@/views/admin/wine_type/WineTypeCreate.vue";
import WineTypeUpdate from "@/views/admin/wine_type/WineTypeUpdate.vue";
import auth from "@/middlewares/AuthMiddleware";
import { ROLES } from "@/config/Roles";
import i18n from "@/locales";

export default [
  {
    path: "/wine-type",
    component: WineTypeView,
    meta: {
      visibleInAside: true,
      roles: [ROLES.ADMIN, ROLES.SUPER_ADMIN],
      title: String(i18n.t("menu.wine_type")),
      middleware: [auth]
    },
    children: [
      {
        path: "",
        name: "WineTypeList",
        component: WineTypeList,
        meta: {
          visibleInAside: true,
          title: "",
          roles: [ROLES.ADMIN, ROLES.SUPER_ADMIN],
          middleware: [auth]
        }
      },
      {
        path: "create",
        name: "WineTypeCreate",
        component: WineTypeCreate,
        meta: {
          visibleInAside: true,
          roles: [ROLES.ADMIN, ROLES.SUPER_ADMIN],
          title: String(i18n.t("general.add_wine_type")),
          middleware: [auth],
          avoidBack: true
        }
      },
      {
        path: "edit/:id",
        name: "WineTypeUpdate",
        component: WineTypeUpdate,
        meta: {
          roles: [ROLES.ADMIN, ROLES.SUPER_ADMIN],
          title: String(i18n.t("general.edit_wine_type")),
          middleware: [auth]
        }
      }
    ]
  }
];
