
import { Component, Prop, Vue } from "vue-property-decorator";
import Validation from "@/components/Validation.vue";
import Upload from "@/components/Upload.vue";

@Component({
  components: {
    Validation,
    Upload
  }
})
export default class PageContentFormUpload extends Vue {
  @Prop({ required: false })
  public v?: any;

  @Prop({ default: false })
  public isExhibitor?: boolean;

  @Prop({ default: false })
  public isAd?: boolean;

  @Prop({ required: false })
  public fileExtension?: any;

  @Prop({ default: false, type: Boolean, required: false })
  public disabled!: any;

  public handleFile(file: any) {
    this.$emit("file", file);
  }

  protected get requireIfValue() {
    return this.v.file.requireIfValue == false;
  }
}
