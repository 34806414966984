import MaintenanceMode from "@/views/MaintenanceMode.vue";

export default [
  {
    path: "/maintenance-mode",
    name: "MaintenanceMode",
    component: MaintenanceMode,
    meta: {
      title: "Maintenance Mode"
    }
  }
];
