
import { Component, Vue } from "vue-property-decorator";
import { BaseMixin } from "@/mixins/baseMixin";
import { Action, Getter } from "vuex-class";
import "vue-good-table/dist/vue-good-table.css";
import MisTable from "@/components/MisTable.vue";
import { ICrudOptions } from "@/service/interfaces/ICrudOptions";
import ITableColumn from "@/interfaces/table/ITableColumn";
import CrudLayout from "@/layouts/CrudLayout.vue";
import i18n from "@/locales";
import AppConfig from "@/config/AppConfig";
import AuthService from "@/service/AuthService";
import IWine from "@/models/IWine";
import Permission from "@/service/Permission";
import { ROLES } from "@/config/Roles";
import CurrencyUtil from "@/utils/currency/CurrencyUtil";
import IBottleSize from "@/models/IBottleSize";
import ExpovinaButton from "@/components/buttons/ExpovinaButton.vue";

const namespace = "wine";

@Component({
  components: {
    ExpovinaButton,
    CrudLayout,
    MisTable
  },
  mixins: [BaseMixin]
})
export default class WineList extends Vue {
  public resourceName: string = "wine";
  protected authService: AuthService = new AuthService();
  protected role: string = this.authService.getRole();
  protected customQueryRoute: string | null = null;

  public columns: Array<ITableColumn> = [
    {
      label: String(i18n.t("general.exhibitor")),
      field: "user.company_name",
      filterOptions: {
        enabled: true
      }
    },
    {
      label: String(i18n.t("general.wine")),
      field: "wine",
      filterOptions: {
        enabled: true
      }
    },
    {
      label: String(i18n.t("general.region")),
      field: "region.name",
      filterOptions: {
        enabled: true
      }
    },
    {
      label: String(i18n.t("general.year")),
      field: "year",
      filterOptions: {
        enabled: true
      }
    },
    {
      label: String(i18n.t("general.wine_type")),
      field: "wine_type.wine_type",
      filterOptions: {
        enabled: true
      }
    },
    {
      label: String(i18n.t("general.award")),
      field: "award.award",
      filterOptions: {
        enabled: true
      }
    },
    {
      label: String(i18n.t("general.price")),
      field: "bottleSizes",
      price: true,
      sortable: false,
      printHtml: true,
      filterOptions: {
        enabled: false
      }
    },
    {
      label: String(i18n.tc("general.action", 2)),
      field: "actions",
      sortable: false
    }
  ];
  protected searchFields: Array<string> = ["name"];

  @Getter("getList", { namespace }) dataList: any;
  @Getter("getTotal", { namespace }) totalRows: any;
  @Getter("getError", { namespace }) error: any;
  @Getter("isLoading", { namespace }) loading: any;
  @Getter("isDeleted", { namespace }) deleted: any;

  @Action("search", { namespace }) searchItemsAction: any;
  @Action("del", { namespace }) deleteItemAction: any;
  @Action("resetList", { namespace }) resetAction: any;

  public name: string = "WineList";

  public searchAction(options: ICrudOptions): void {
    //options = {    resource: "resource",      descriptionField: "wine"    };
    if (this.$route?.query?.gv_count == "0") {
      options.params.custom = { gv_count: 0 };
    }

    this.searchItemsAction(options);
  }

  public deleteAction(options: ICrudOptions): void {
    this.deleteItemAction(options);
  }

  get getRouteAdd(): string {
    const authService: AuthService = new AuthService();
    if (Permission.hasPermission([ROLES.EXHIBITOR], authService.getRole())) {
      this.customQueryRoute = "exhibitor-wine";
      return "exhibitor_wine_create";
    }
    return "";
  }

  public get modifiedDataList(): Array<IWine> {
    let dataList: Array<IWine> = this.dataList;

    dataList.forEach((wine: any) => {
      // add Symbol to String, if wine has award
      if (wine.award) {
        wine.award.award =
          this.getAwardSymbolByValue(wine.award.symbol) +
          " " +
          wine.award.award;
      }

      if (!wine.region) {
        wine.region = "";
      } else {
        const countryName = wine.region.country ? wine.region.country.name : "";
        wine.region.name = countryName + " - " + wine.region.name;
      }

      // Get only the first bottle-size and format it with it's price
      if (Array.isArray(wine.bottleSizes) && wine.bottleSizes.length > 0) {
        const x = wine.bottleSizes.map((bottleSize: IBottleSize) => {
          return `<p>${CurrencyUtil.formatWinePrice(bottleSize.price)}/${
            bottleSize.size
          }</p>`;
        });
        wine.bottleSizes = x.join(" ");
      } else {
        wine.bottleSizes = null;
      }
    });
    return dataList;
  }

  private getAwardSymbolByValue(value: string): string {
    let symbol: string = "";

    AppConfig.AWARD_SYMBOLS.forEach(item => {
      if (item.value === value) {
        symbol = item.name;
      }
    });

    return symbol;
  }

  protected get winesWithoutGrapeVarietyCount(): number {
    return this.dataList.filter((wine: IWine) => {
      return wine.grape_varieties_count === 0;
    }).length;
  }

  public beforeDestroy() {
    this.resetAction();
  }

  public mounted() {
    const authService = new AuthService();
    const role = authService.getRole();
    if (role == ROLES.EXHIBITOR) {
      this.columns.splice(0, 1);
    }
  }
}
