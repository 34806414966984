
import { Component, Vue, Watch } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import CrudLayout from "@/layouts/CrudLayout.vue";
import Validation from "@/components/Validation.vue";
import PageTitleForm from "@/views/admin/page_title/PageTitleForm.vue";
import FormCreate from "@/views/form/create/FormCreate.vue";

const namespace: string = "pagetitle";

@Component({
  components: {
    CrudLayout,
    Validation,
    PageTitleForm
  },
  extends: FormCreate
})
export default class PageTitleCreate extends Vue {
  protected name: string = "PageTitleCreate";
  protected resourceName = "page-title";

  @Getter("getError", { namespace }) error: any;
  @Getter("isLoading", { namespace }) loading: any;
  @Getter("isCreated", { namespace }) created: any;
  @Getter("getItem", { namespace }) createdItem: any;

  @Action("create", { namespace }) createItemAction: any;
  @Action("resetCreate", { namespace }) resetAction: any;

  beforeDestroy() {
    this.resetAction();
  }
}
