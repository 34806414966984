
import { Component, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import CrudLayout from "@/layouts/CrudLayout.vue";
import Validation from "@/components/Validation.vue";
import ExhibitorForm from "./form/ExhibitorForm.vue";
import FormCreate from "@/views/form/create/FormCreate.vue";

const namespace: string = "exhibitor";
const catalogNamespace: string = "catalog";
const breadcrumbNamespace: string = "breadcrumb";

@Component({
  components: {
    CrudLayout,
    Validation,
    ExhibitorForm
  },
  extends: FormCreate
})
export default class ExhibitorCreate extends Vue {
  protected name: string = "ExhibitorCreate";
  protected resourceName = "exhibitor";

  @Getter("getError", { namespace }) error: any;
  @Getter("isLoading", { namespace }) loading: any;
  @Getter("isCreated", { namespace }) created: any;
  @Getter("getItem", { namespace }) createdItem: any;

  @Action("create", { namespace }) createItemAction: any;
  @Action("resetCreate", { namespace }) resetAction: any;

  @Getter("getItem", { namespace: catalogNamespace })
  protected getCatalog: any;
  @Action("findOne", { namespace: catalogNamespace })
  protected findCatalog: any;
  @Action("mergeData", { namespace: breadcrumbNamespace })
  protected setBreadcrumb: any;

  @Getter("getActiveCatalog", { namespace: catalogNamespace })
  protected getActiveCatalog: any;

  beforeDestroy() {
    this.resetAction();
  }

  public async mounted() {
    if (
      this.$router.currentRoute.name === "catalog_exhibitor_create" &&
      this.getActiveCatalog == null
    ) {
      await this.$router.push({ name: "catalog_exhibitor_list" });
    }

    const { catalog } = this.$route.params;
    if (catalog) {
      await this.findCatalog({
        resource: "catalog",
        id: catalog
      });

      const catalogObject = this.getCatalog;
      const catalogExhibitorListTitle =
        String(this.$t("general.exhibitor")) +
        " " +
        String(this.$t("general.of")) +
        " " +
        catalogObject.name;

      this.setBreadcrumb({
        [String(this.$router.currentRoute.name)]: {
          catalogExhibitorListTitle
        }
      });
    }
  }
}
