import { ActionContext, ActionTree } from "vuex";
import { State } from "./state";
import {
  create,
  del,
  findAll,
  findOne,
  paginate,
  resetCreate,
  resetList,
  resetUpdate,
  search
} from "@/store/modules/crud/functions/actions";
import { ICrudOptions } from "@/service/interfaces/ICrudOptions";
import CrudActions from "@/store/modules/crud/classes/CrudActions";
import CrudResetActions from "@/store/modules/crud/classes/CrudResetActions";
import CrudService from "@/service/CrudService";
import i18n from "@/locales";
import { getSelectedForApproval } from "@/store/modules/logo/getters";
import ILogo from "@/models/ILogo";
import axios from "axios";
import AppConfig from "@/config/AppConfig";

const crudActions: CrudActions = new CrudActions();
const crudResetActions: CrudResetActions = new CrudResetActions();
const crudService: CrudService = new CrudService();
const BASE_URL: string = AppConfig.ENTRYPOINT + "/api/";

export function update(
  store: ActionContext<State, any>,
  options: ICrudOptions
): Promise<any> {
  crudActions.dispatchLoading(store, true);
  store.commit("SET_DATA_LOADING", true);
  store.commit("SET_DATA_UPDATED", false);
  const request: Promise<any> = crudService.update(options);

  request
    .then((response: any) => {
      let message: string = "";
      if (response.data.data.approved) {
        message = String(
          i18n.t("general.logo_accept_success", {
            logo: response.data.data[options.descriptionField]
          })
        );
      } else {
        message = String(
          i18n.t("general.logo_reject_success", {
            logo: response.data.data[options.descriptionField]
          })
        );
      }

      crudActions.resolveWriteAction(
        store,
        response,
        message,
        response.data.data,
        options
      );
    })
    .catch(error => {
      crudActions.rejectWriteAction(store, error, options);
    });

  return request;
}

export function selectForApproval(
  store: ActionContext<State, any>,
  logo: ILogo | null
) {
  store.commit("SET_SELECTED_FOR_APPROVAL", logo);
}

export async function findByExhibitor(
  store: ActionContext<any, any>,
  options: ICrudOptions
): Promise<any> {
  store.commit("SET_DATA_LOADING", true);
  crudActions.dispatchLoading(store, true);
  let url: string =
    BASE_URL +
    options.resource +
    "/by-exhibitor/" +
    options.params.exhibitor_id +
    "/" +
    options.params.currentPerPage +
    "/" +
    options.params.currentPage +
    "?";
  if (options.params.search) {
    url += "&search=" + JSON.stringify(options.params.search);
  }
  if (options.params.columnFilters) {
    url += "&columnFilters=" + JSON.stringify(options.params.columnFilters);
  }
  if (options.params.sort) {
    url += "&sort=" + JSON.stringify(options.params.sort);
  }
  const request: Promise<any> = axios.get(url);
  request
    .then((response: any) => {
      crudActions.resolveReadMultipleAction(store, response, options);
    })
    .catch(error => {
      crudActions.rejectReadAction(store, error, options);
    });

  return request;
}

export default <ActionTree<State, any>>{
  findOne,
  findAll,
  search,
  paginate,
  create,
  del,
  resetList,
  resetCreate,
  resetUpdate,
  update,
  selectForApproval,
  findByExhibitor
};
