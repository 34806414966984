import { GetterTree } from "vuex";
import { State } from "./state";
import {
  getList,
  getTotal,
  getItem,
  getError,
  isLoading,
  isCreated,
  isUpdated,
  isDeleted
} from "@/store/modules/crud/functions/getters";

export function getSelectedForApproval(state: State): any {
  return state.selectedForApproval;
}

export default <GetterTree<State, any>>{
  getList,
  getTotal,
  getItem,
  getError,
  isLoading,
  isCreated,
  isUpdated,
  isDeleted,
  getSelectedForApproval
};
