import { Route } from "vue-router";
import AuthService from "@/service/AuthService";
import Permission from "@/service/Permission";
import {
  redirectUrl,
  hasMetaRoles,
  redirectToHomePage
} from "@/middlewares/helper";

const authService = new AuthService();

export default function auth(router: any) {
  const from: Route = router.from;
  const to: Route = router.to;
  const next: Function = router.next;
  const token: string = authService.getToken();

  if (!token) {
    return next({ name: "Login", query: { redirectUrl: to.fullPath } });
  }

  redirectUrl(from, next);

  if (
    hasMetaRoles(to) &&
    to &&
    to.meta &&
    !Permission.hasPermission(to.meta.roles, authService.getRole())
  ) {
    return redirectToHomePage(next, to);
  }

  return next();
}
