
import { Component, Vue } from "vue-property-decorator";

const namespace: string = "stand";

@Component({
  components: {}
})
export default class StandView extends Vue {
  public name: string = "StandView";
}
