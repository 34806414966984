
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class ReleaseRadioButton extends Vue {
  public name: string = "ReleaseRadioButton";

  @Prop({ required: true })
  public item!: any;

  public acceptedValue: any = null;

  public get accepted() {
    if (this.acceptedValue === null && this.item) {
      this.acceptedValue = this.item.release_accepted;
      return this.acceptedValue;
    }
    return this.acceptedValue;
  }

  public set accepted(a: any) {
    this.acceptedValue = a;
  }

  public onChange() {
    this.$emit("input", this.accepted);
  }
}
