
import { Component, Prop, Vue } from "vue-property-decorator";
// @ts-ignore
import DatePick from "vue-date-pick";
import i18n from "@/locales";
import AppConfig from "@/config/AppConfig";
// import { EventBus } from "@/EventBus";

@Component({
  components: {
    DatePick
  }
})
export default class Datepicker extends Vue {
  public name: string = "Datepicker";

  mounted() {
    // @ts-ignore
    this.$refs.datePickComponent.$el.childNodes[0].style.width = "100%";
  }

  protected changeValue() {
    this.$emit("input", this.givenDate);
  }

  @Prop({ default: true })
  public editable?: boolean;

  @Prop()
  public value: any;

  @Prop({ default: AppConfig.DATE_FORMAT_FRONTEND })
  public format: any;

  @Prop({ default: "" })
  public date: any;
  public currentDate: string | null = null;

  @Prop({ default: "" })
  public displayFormat: any;

  @Prop({ default: true })
  public hasInputElement: any;

  @Prop()
  public inputAttributes: any;

  @Prop({ default: 40 })
  public selectableYearRange: any;

  @Prop()
  public parseDate: any;

  @Prop()
  public formatDate: any;

  @Prop({ default: false })
  public pickTime: any;

  @Prop({ default: true })
  public pickMinutes: any;

  @Prop({ default: false })
  public pickSeconds: any;

  @Prop({
    default: () => {
      false;
    }
  })
  public isDateDisabled: any;

  @Prop({ default: i18n.t("datepicker.next_month") })
  public nextMonthCaption: any;

  @Prop({ default: i18n.t("datepicker.prev_month") })
  public prevMonthCaption: any;

  @Prop({ default: i18n.t("datepicker.set_time") })
  public setTimeCaption: any;

  @Prop({ default: 500 })
  public mobileBreakpointWidth: any;

  @Prop({
    default: () => {
      const weekdays = i18n.t("datepicker.weekdays") as string;
      return Object.keys(weekdays).map(function(i: any) {
        return weekdays[i];
      });
    }
  })
  public weekdays: any;

  @Prop({
    default: () => {
      const months: any = i18n.t("datepicker.months") as string;
      return Object.keys(months).map(function(i: any) {
        return months[i];
      });
    }
  })
  public months: any;

  @Prop({ default: false })
  public startWeekOnSunday: any;

  get givenDate(): string | null {
    if (this.currentDate == null && this.date && this.date != "null") {
      this.currentDate = this.date;
    }
    return this.currentDate;
  }

  set givenDate(d: string | null) {
    this.currentDate = d;
  }
}
