
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import PageWineAdForm from "@/views/admin/page/winead/PageWineAdForm.vue";
import CrudLayout from "@/layouts/CrudLayout.vue";
import Validation from "@/components/Validation.vue";
import { Action, Getter } from "vuex-class";
import i18n from "@/locales";

const namespace: string = "page";
const exhibitorNamespace: string = "exhibitor";

@Component({
  components: {
    CrudLayout,
    Validation,
    PageWineAdForm
  }
})
export default class PageAdUpdate extends Vue {
  public name: string = "PageAdUpdate";
  protected title: string = "";
  protected companyName: string | null = null;
  protected catalogName: string | null = null;

  @Prop()
  public page: any;

  @Prop({ default: false })
  public isExhibitor?: boolean;

  @Prop()
  public catalog: any;

  @Getter("isLoading", { namespace }) loading: any;
  @Getter("getItem", { namespace: exhibitorNamespace }) exhibitor: any;

  @Action("resetUpdate", { namespace }) resetAction: any;
  @Action("updateAd", { namespace }) updateItemAction: any;
  @Action("findOne", { namespace: exhibitorNamespace })
  findExhibitorAction: any;
  @Action("findOne", { namespace: namespace }) getPageAction: any;

  @Watch("exhibitor", { immediate: true, deep: true })
  protected onExhibitor(exhibitor: any) {
    if (exhibitor) {
      this.companyName = exhibitor.company_name;
      this.setTranslations();
    }
  }

  @Watch("page", { immediate: true, deep: true })
  protected onPage(page: any) {
    if (page) {
      this.findExhibitorAction({
        resource: exhibitorNamespace,
        id: page.exhibitor_id
      });
    }
  }

  @Watch("catalog", { immediate: true, deep: true })
  protected onCatalog(catalog: any) {
    if (catalog) {
      this.catalogName = catalog.name;
      this.setTranslations();
    }
  }

  protected setTranslations() {
    if (this.catalogName != null && this.companyName != null) {
      let title: String = "";

      if (this.isExhibitor) {
        title = String(i18n.t("general.update_page_exhibitor"));
      } else {
        title = String(
          i18n.t("general.update_page_ad", {
            catalog: this.catalogName,
            company_name: this.companyName
          })
        );
      }

      this.$emit("title", title);
    }
  }
  mounted() {
    const id: number = Number.parseInt(this.$route.params.id);

    this.getPageAction({
      resource: namespace,
      id: id,
      descriptionField: "name"
    });
  }

  beforeDestroy() {
    this.resetAction();
  }
}
