import { ActionContext, ActionTree } from "vuex";
import { State } from "./state";
import { ICrudOptions } from "@/service/interfaces/ICrudOptions";
// import CrudActions from "@/store/modules/crud/classes/CrudActions";
import ErrorHelper from "@/utils/error/ErrorHelper";
import axios from "axios";
import AppConfig from "@/config/AppConfig";
import {
  del,
  findAll,
  findOne,
  paginate,
  resetCreate,
  resetList,
  resetUpdate,
  search,
  crudActions
} from "@/store/modules/crud/functions/actions";
import CrudService from "@/service/CrudService";
import i18n from "@/locales";

// import CrudResetActions from "@/store/modules/crud/classes/CrudResetActions";

// const crudActions: CrudActions = new CrudActions();
const crudService: CrudService = new CrudService();
// const crudResetActions: CrudResetActions = new CrudResetActions();

const BASE_URL: string = AppConfig.ENTRYPOINT + "/api/";

export async function create(
  store: ActionContext<State, any>,
  options: ICrudOptions
): Promise<any> {
  const isFileUpload: boolean =
    options.data.get("web_logo") instanceof File ? true : false;

  await crudActions.dispatchLoading(store, !isFileUpload);

  store.commit("SET_DATA_CREATED", false);
  await crudService
    .create(options)
    .then((response: any) => {
      store.dispatch(
        "snackbar/setText",
        {
          text: String(
            i18n.t("general.item_created_success", {
              item: response.data.data.name
            })
          ),
          type: "success"
        },
        { root: true }
      );

      store.commit("SET_DATA_LOADING", false);
      store.dispatch("snackbar/setLoading", false, { root: true });
      store.commit("SET_DATA_ERROR", null);
      store.commit("SET_DATA_CREATED", true);
    })
    .catch(error => {
      store.dispatch("snackbar/setLoading", false, { root: true });

      store.dispatch(
        "snackbar/setText",
        {
          text: ErrorHelper.getError(error),
          type: "danger"
        },
        { root: true }
      );

      store.commit("SET_DATA_ERROR", ErrorHelper.getError(error));
      store.commit("SET_DATA_CREATED", false);
    });
}

export function update(
  store: ActionContext<State, any>,
  options: ICrudOptions
): Promise<any> {
  const request: Promise<any> = crudActions.updatePost(store, options);

  return request;
}

export async function getRegions(
  store: ActionContext<State, any>,
  options: ICrudOptions
): Promise<any> {
  let postData: any = {};

  if (options.id) {
    postData["id"] = options.id;
  }

  return axios
    .post(BASE_URL + options.resource + "/get-regions", postData)
    .then((response: any) => {
      store.commit("SET_DATA_COUNTRIES", response.data.data.countries);
      store.commit("SET_DATA_REGIONS", response.data.data.regions);
      store.commit("SET_DATA_ERROR", null);
      if (response.data.data.hasOwnProperty("usersSelectedRegions")) {
        store.commit(
          "SET_DATA_SELECTED_REGIONS",
          response.data.data.usersSelectedRegions
        );
      }
    })
    .catch((error: any) => {
      store.commit("SET_DATA_ERROR", ErrorHelper.getError(error));
    });
}

export async function getSelectedCatalogs(
  store: ActionContext<State, any>,
  options: ICrudOptions
): Promise<any> {
  store.commit("SET_DATA_LOADING", true);
  return axios
    .post(BASE_URL + options.resource + "/get-catalogs", { id: options.id })
    .then((response: any) => {
      store.commit("SET_DATA_USER_CATALOGS", response.data.data.catalogs);
      if (options.type == "edit") {
        store.commit(
          "SET_DATA_SELECTED_CATALOGS",
          response.data.data.userCatalogs
        );
      }

      store.commit("SET_DATA_ERROR", null);
      store.commit("SET_DATA_LOADING", false);
    })
    .catch((error: any) => {
      store.commit("SET_DATA_ERROR", ErrorHelper.getError(error));
      store.commit("SET_DATA_LOADING", false);
    });
}

export async function loadCatalogs(
  store: ActionContext<State, any>,
  options: ICrudOptions
): Promise<any> {
  store.commit("SET_DATA_LOADING", true);
  return axios
    .get(BASE_URL + options.resource + "/load-catalogs")
    .then((response: any) => {
      const catalogs = response.data.data.map((catalog: any) => {
        return {
          name: catalog.name,
          value: catalog.id
        };
      });
      store.commit("SET_DATA_SET_CATALOGS", true);
      store.commit("SET_DATA_CATALOGS", catalogs);
      store.commit("SET_DATA_ERROR", null);
      store.commit("SET_DATA_LOADING", false);
    })
    .catch((error: any) => {
      store.commit("SET_DATA_SET_CATALOGS", true);
      store.commit("SET_DATA_ERROR", ErrorHelper.getError(error));
      store.commit("SET_DATA_LOADING", false);
    });
}

export async function sendMail(
  store: ActionContext<State, any>,
  options: ICrudOptions
): Promise<any> {
  crudActions.dispatchLoading(store, true);
  store.commit("SET_DATA_SEND_MAIL", false);
  return axios
    .post(BASE_URL + options.resource + "/send-mail", options.data)
    .then((response: any) => {
      store.commit("SET_DATA_SEND_MAIL", true);
      store.commit("SET_DATA_ITEM", response.data.data);
      store.commit("SET_DATA_ERROR", null);
      crudActions.dispatchLoading(store, false);
    })
    .catch((error: any) => {
      store.commit("SET_DATA_ERROR", ErrorHelper.getError(error));
      crudActions.dispatchLoading(store, false);
      crudActions.rejectWriteAction(
        store,
        String(i18n.t("general.catalog_mail_send_messages_error")),
        options
      );
    });
}

export function resetPasswordReset(
  store: ActionContext<State, any>,
  options: ICrudOptions
): void {
  store.commit("SET_DATA_ITEM", null);
  store.commit("SET_DATA_IS_PASSWORD_RESET", false);
  store.commit("SET_DATA_ERROR", null);
  store.commit("SET_DATA_LOADING", false);
}

export function resetPassword(
  store: ActionContext<State, any>,
  options: ICrudOptions
): Promise<any> {
  store.commit("SET_DATA_LOADING", true);
  store.commit("SET_DATA_IS_PASSWORD_RESET", false);
  return axios
    .post(
      BASE_URL + options.resource + "/password-reset/" + options.data.id,
      options.data
    )
    .then((response: any) => {
      store.commit("SET_DATA_IS_PASSWORD_RESET", true);
      store.commit("SET_DATA_ERROR", null);
      store.commit("SET_DATA_LOADING", false);
    })
    .catch((error: any) => {
      crudActions.rejectWriteAction(store, error, options);
    });
}

export function getExhibitor(
  store: ActionContext<State, any>,
  options: ICrudOptions
): void {
  store.commit("SET_DATA_LOADING", true);
  axios
    .get(BASE_URL + options.resource + "/show/" + options.id)
    .then((response: any) => {
      store.commit("SET_DATA_SET_EXHIBITOR", null);
      store.commit("SET_DATA_ERROR", null);
      store.commit("SET_DATA_LOADING", false);
    })
    .catch((error: any) => {
      store.commit("SET_DATA_ERROR", ErrorHelper.getError(error));
      store.commit("SET_DATA_LOADING", false);
    });
}

export function remindExhibitors(
  store: ActionContext<State, any>,
  resource: string
): void {
  store.commit("SET_DATA_LOADING", true);
  crudActions.dispatchLoading(store, true);
  axios
    .post(BASE_URL + resource + "/exhibitor-remind")
    .then((response: any) => {
      store.commit("SET_DATA_ERROR", null);
      store.commit("SET_DATA_LOADING", false);
      crudActions.dispatchLoading(store, false);
      crudActions.dispatchSuccessMessage(
        store,
        String(i18n.t(response.data.message))
      );
    })
    .catch((error: any) => {
      store.commit("SET_DATA_ERROR", ErrorHelper.getError(error));
      store.commit("SET_DATA_LOADING", false);
      crudActions.dispatchLoading(store, false);
      crudActions.dispatchErrorMessage(
        store,
        String(i18n.t(error.response.data.message))
      );
    });
}

export default <ActionTree<State, any>>{
  findOne,
  findAll,
  search,
  paginate,
  create,
  update,
  del,
  resetList,
  resetCreate,
  resetUpdate,
  sendMail,
  loadCatalogs,
  resetPassword,
  resetPasswordReset,
  getSelectedCatalogs,
  getRegions,
  getExhibitor,
  remindExhibitors
};
