
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import CrudLayout from "@/layouts/CrudLayout.vue";
import { Validate } from "vuelidate-property-decorators";
import { minLength, required } from "vuelidate/lib/validators";
import { dateGreater } from "@/validators/validators";
import Validation from "@/components/Validation.vue";
import IRegion from "@/models/IRegion";
import ICountry from "@/models/ICountry";
import { Action, Getter, namespace } from "vuex-class";
import MultiSelect from "@/components/multiselect";
import FormBackButton from "@/components/buttons/FormBackButton.vue";

const country = namespace("country");
const CountryGetter = country.Getter;
const CountryAction = country.Action;
@Component({
  components: {
    FormBackButton,
    MultiSelect,
    CrudLayout,
    Validation
  }
})
export default class RegionForm extends Vue {
  // component initial values
  public name: string = "RegionForm";
  private resourceName = "region";

  @CountryGetter("getError") countriesError: any;
  @CountryGetter("isLoading") countriesLoading: any;
  @CountryGetter("getList") countries: any;

  @CountryAction("findAll") findAllCountriesAction: any;

  // props
  @Prop({ default: "add" })
  public type: any;
  @Prop({ default: false })
  public saved: any;
  @Prop({ default: false })
  public loading: any;

  @Prop({ default: false })
  public resetAction: any;

  @Prop({ default: "" })
  public error: any;
  @Prop({
    default: function() {}
  })
  public saveAction: any;
  @Prop({
    default: function() {}
  })
  public back: any;
  @Prop()
  public item: any;

  // model
  // private regionId: number = 0;
  @Validate({ required, minLength: minLength(4) })
  public regionName: string = "";
  @Validate({ required })
  public country: ICountry | null = null;

  @Watch("item", { immediate: true, deep: true })
  protected onItemChange(item: any) {
    if (item) {
      this.init(item);
    }
  }

  private get getCountries(): any[] {
    return this.countries;
  }

  private init(region: IRegion): void {
    this.$nextTick(() => {
      this.regionName = region.name;
      this.country = region.country;
    });
  }

  get isLoading(): boolean {
    return this.loading && this.countriesLoading;
  }

  get errorObject(): boolean {
    return this.error && this.countriesError;
  }

  public onSubmit(e: Event): void {
    this.$v.$touch();
    if (this.$v.$invalid) {
      return;
    }
    const region: IRegion = {
      name: this.regionName,
      country: this.country as ICountry
    };

    if (this.$route.params.id) {
      // edit
      region.id = Number.parseInt(this.$route.params.id);
      this.saveAction({
        resource: this.resourceName,
        data: region,
        id: region.id,
        descriptionField: "name"
      });
    } else {
      // add
      this.saveAction({
        resource: this.resourceName,
        data: region,
        descriptionField: "name"
      });
    }
  }

  mounted() {
    this.findAllCountriesAction({
      resource: "country",
      params: {
        currentPerPage: 2000,
        currentPage: 1,
        sort: [{ field: "name", type: "asc" }]
      }
    });
  }
}
