
import { Component, Vue, Watch } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import CrudLayout from "@/layouts/CrudLayout.vue";
import Validation from "@/components/Validation.vue";
import Snackbar from "@/components/snackbar/Snackbar.vue";
import i18n from "@/locales";
import Loading from "@/components/Loading.vue";
import { BFormCheckbox } from "bootstrap-vue";
import { Validations } from "vuelidate-property-decorators";
import { required, sameAs } from "vuelidate/lib/validators";
import IExhibitor from "@/models/IExhibitor";

const namespace: string = "exhibitor";
const snackbarNamespace: string = "snackbar";
const breadcrumbNamespace: string = "breadcrumb";

@Component({
  components: {
    CrudLayout,
    Validation,
    Snackbar,
    Loading,
    BFormCheckbox
  }
})
export default class ExhibitorResetPassword extends Vue {
  public name: string = "ExhibitorResetPassword";
  private resourceName = "exhibitor";

  @Getter("getError", { namespace }) error: any;
  @Getter("isLoading", { namespace }) loading: any;
  @Getter("isPasswordReset", { namespace }) reset: any;
  @Getter("getItem", { namespace }) exhibitor: any;

  @Action("resetPassword", { namespace }) resetPasswordAction: any;
  @Action("resetPasswordReset", { namespace }) resetAction: any;
  @Action("setText", { namespace: snackbarNamespace }) setText: any;
  @Action("setActive", { namespace: snackbarNamespace }) setActive: any;
  @Action("findOne", { namespace: namespace }) getExhibitorAction: any;
  @Action("mergeData", { namespace: breadcrumbNamespace })
  breadcrumbSetDataAction: any;

  protected get mail(): string {
    if (this.exhibitor && this.exhibitor.hasOwnProperty("email")) {
      this.breadcrumbSetDataAction({
        [String(this.$router.currentRoute.name)]: {
          email: this.exhibitor.email
        }
      });
      return this.exhibitor.email;
    }
    return "";
  }

  @Validations()
  protected validations() {
    let fields = {};
    fields = Object.assign({}, fields, {
      oldPassword: { required },
      password: { required },
      password_repeat: {
        required,
        password_repeat: sameAs("password")
      }
    });

    return fields;
  }

  public oldPassword: string = "";
  public password: string = "";
  public password_repeat: string = "";

  protected back() {
    this.$router.push({ path: "/" + this.resourceName });
  }

  @Watch("reset", { immediate: true, deep: true })
  protected onUpdated(value: boolean) {
    if (value) {
      this.$router.push("/" + this.resourceName);
    }
  }

  beforeDestroy() {
    this.resetAction();
  }
  mounted() {
    this.getExhibitorAction({
      resource: this.resourceName,
      id: Number.parseInt(this.$route.params.id)
    });
  }
  public onSubmit(e: Event): void {
    this.$v.$touch();
    if (this.$v.$invalid) {
      return;
    }
    const exhibitor: IExhibitor = {
      password: this.password,
      oldPassword: this.oldPassword,
      passwordRepeat: this.password_repeat
    };

    exhibitor.id = Number.parseInt(this.$route.params.id);
    this.resetPasswordAction({
      resource: this.resourceName,
      data: exhibitor,
      id: exhibitor.id
    });
  }
}
