
import { Vue, Component, Prop } from "vue-property-decorator";
import { BaseMixin } from "@/mixins/baseMixin";
import axios from "axios";
import FileSaver from "file-saver";

@Component({
  components: {},
  mixins: [BaseMixin]
})
export default class DownloadPageButton extends Vue {
  public name: string = "DownloadPageButton";
  @Prop()
  public pageId!: number;

  protected download(id: number) {
    axios
      .get(process.env.VUE_APP_BACKEND_HOST + "/api/page-wine-download/" + id, {
        responseType: "arraybuffer"
      })
      .then(response => {
        const file = new Blob([response.data], { type: "application/pdf" });
        FileSaver.saveAs(file, id + ".pdf");
      });
  }
}
