
import { Vue, Component, Prop } from "vue-property-decorator";
import { BaseMixin } from "@/mixins/baseMixin";
import HistoryHelper from "../../utils/history/HistoryHelper";

@Component({
  components: {},
  mixins: [BaseMixin]
})
export default class FormBackButton extends Vue {
  public name: string = "FormBackButton";
  @Prop({ default: false })
  public resetAction: any;

  constructor() {
    super();
  }

  private goBack(): void {
    if (this.resetAction) {
      this.resetAction();
    }
    let hHelper = new HistoryHelper(this.$router);
    hHelper.goBack();
  }
}
