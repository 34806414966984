
import DefaultLayout from "@/layouts/DefaultLayout.vue";
import { Component, Vue, Watch } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import MisTable from "@/components/MisTable.vue";
import { ICrudOptions } from "@/service/interfaces/ICrudOptions";
import ITableColumn from "@/interfaces/table/ITableColumn";
import CrudLayout from "@/layouts/CrudLayout.vue";
import i18n from "@/locales";
import "vue-good-table/dist/vue-good-table.css";
import AppConfig from "@/config/AppConfig";
import ICatalog from "@/models/ICatalog";

const namespace = "page";
const catalogNamespace = "catalog";
const breadcrumbNamespace: string = "breadcrumb";

@Component({
  components: {
    CrudLayout,
    DefaultLayout,
    MisTable
  }
})
export default class PageList extends Vue {
  public name: string = "PageList";
  public resourceName: string = "page";
  private showPageCreateButton: boolean = false;
  public columns: Array<ITableColumn> = [
    {
      label: String(i18n.t("general.position")),
      field: "position",
      sortable: true,
      rendererName: "PagePositionField",
      filterOptions: {
        enabled: true
      }
    },
    {
      label: String(i18n.t("general.page_type")),
      field: "page_type",
      formatFn: (value: any) => {
        return String(i18n.t("general." + AppConfig.PAGE_TYPES[value]));
      },
      sortable: true,
      filterOptions: {
        enabled: true,
        filterDropdownItems: [
          {
            value: 1,
            text: String(i18n.t("general." + AppConfig.PAGE_TYPES[1]))
          },
          {
            value: 2,
            text: String(i18n.t("general." + AppConfig.PAGE_TYPES[2]))
          },
          {
            value: 3,
            text: String(i18n.t("general." + AppConfig.PAGE_TYPES[3]))
          },
          {
            value: 4,
            text: String(i18n.t("general." + AppConfig.PAGE_TYPES[4]))
          },
          {
            value: 5,
            text: String(i18n.t("general." + AppConfig.PAGE_TYPES[5]))
          }
        ]
      }
    },
    {
      label: String(i18n.t("general.description")),
      field: "description",
      sortable: true,
      filterOptions: {
        enabled: true
      }
    },
    {
      label: String(i18n.t("general.company")),
      field: "exhibitor.company_name",
      sortable: true,
      filterOptions: {
        enabled: true
      }
    },
    {
      label: String(i18n.t("general.stand")),
      field: "stand.stand",
      sortable: true,
      filterOptions: {
        enabled: true
      }
    },
    {
      label: String(i18n.t("general.page_title")),
      field: "page_title.name",
      sortable: true,
      filterOptions: {
        enabled: true
      }
    },
    {
      label: String(i18n.t("general.active")),
      field: "active",
      type: "number",
      html: true,
      sortable: true,
      filterOptions: {
        enabled: true,
        filterDropdownItems: [
          { value: 1, text: String(i18n.t("general.active")) },
          { value: 0, text: String(i18n.t("general.inactive")) }
        ]
      }
    },
    {
      label: String(i18n.t("general.released")),
      field: "released",
      type: "number",
      html: true,
      sortable: true,
      filterOptions: {
        enabled: true,
        filterDropdownItems: [
          { value: 1, text: String(i18n.t("general.released")) },
          { value: 0, text: String(i18n.t("general.non_released")) }
        ]
      }
    },
    {
      label: String(i18n.t("general.accepted")),
      field: "release_accepted",
      type: "number",
      html: true,
      sortable: true,
      filterOptions: {
        enabled: true,
        filterDropdownItems: [
          { value: 1, text: String(i18n.t("general.accepted")) },
          { value: 0, text: String(i18n.t("general.non_accepted")) }
        ]
      }
    },
    {
      label: String(i18n.tc("general.action", 2)),
      field: "actions",
      sortable: false,
      hideUpdateBtnFn: (value: any) => {
        return value.page_type == 4 || value.page_type == 5;
      },
      hideDeleteBtnFn: (value: any) => {
        return value.page_type == 4 || value.page_type == 5;
      },
      disableBtnFn: (row: any) => {
        // return Boolean(row.status === 1 && !row.status.ready);
        return false;
      }
    }
  ];
  protected searchFields: Array<string> = ["name"];
  private instantSave: boolean = true;

  public get additionalToolbarButtons(): Array<Object> {
    if (
      this.$router.currentRoute.name === "catalog_page" &&
      this.getActiveCatalog == null
    ) {
      return [];
    }

    return [
      {
        name: "DropDownButton",
        props: {
          class: "dropdown-create-page",
          to: `/${this.resourceName}/send-mail`,
          label: this.$t("general.add_page_dropdown"),
          items: [
            {
              index: 1,
              text: i18n.t("general.wine"),
              callback: () => this.addWine()
            },
            {
              index: 1,
              text: i18n.t("general.advertising"),
              callback: () => this.addAd()
            },
            {
              index: 1,
              text: i18n.t("general.content_page"),
              callback: () => this.addContent()
            }
          ],
          isActive: this.showPageCreateButton
        }
      }
    ];
  }

  @Getter("getList", { namespace })
  public dataList: any;

  @Getter("getTotal", { namespace })
  public totalRows: any;

  @Getter("getError", { namespace })
  public error: any;

  @Getter("isLoading", { namespace })
  public loading: any;

  @Getter("isDeleted", { namespace })
  public deleted: any;

  @Getter("isCreated", { namespace })
  public isCreated?: boolean;

  @Getter("getPosition", { namespace })
  public position?: number | null;

  @Getter("isSwapped", { namespace: namespace })
  isPositionSwapped?: boolean;

  @Getter("getItem", { namespace: catalogNamespace })
  public catalog: any;

  @Getter("getRedirectedCatalogInactive", { namespace })
  public redirectedCatalogInactive?: boolean;

  @Getter("getActiveCatalog", { namespace: catalogNamespace })
  public getActiveCatalog: any;

  @Action("search", { namespace })
  public searchItemsAction: any;

  @Action("del", { namespace })
  public deleteItemAction: any;

  @Action("resetList", { namespace })
  public resetAction: any;

  @Action("changePosition", { namespace })
  public changePosition: any;

  @Action("successMessage", { namespace })
  public successMessageAction: any;

  @Action("errorInactiveCatalog", { namespace })
  public inactiveCatalogMessageAction: any;

  @Action("redirectCatalogInactive", { namespace })
  public redirectCatalogInactiveAction: any;

  @Action("mergeData", { namespace: breadcrumbNamespace })
  public breadcrumbSetDataAction: any;

  @Action("findOne", { namespace: catalogNamespace })
  public findCatalogAction: any;

  @Watch("catalog")
  public catalogWatcher(catalog: any) {
    if (this.$router.currentRoute.name === "ActiveCatalog") {
      this.showPageCreateButton = true;
    } else if (catalog) {
      this.showPageCreateButton = catalog.id == this.catalogId;
      if (catalog.id != this.catalogId && this.redirectedCatalogInactive) {
        this.redirectCatalogInactiveAction(false);

        const message: String = String(
          i18n.t("general.catalog_is_inactive", { catalog: this.catalog.name })
        );

        this.$nextTick(() => {
          this.inactiveCatalogMessageAction(message);
        });
        this.$store.dispatch("catalog/resetCreate");
      }
    }
  }

  @Watch("getActiveCatalog", { immediate: true, deep: true })
  protected isCatalogActive(activeCatalog: ICatalog | null) {
    if (!activeCatalog) {
      this.redirectCatalogInactiveAction(false);
    }
  }

  @Watch("isCreated", { immediate: true, deep: true })
  protected isItemCreated(status: boolean) {
    if (status) {
      this.$nextTick(() => {
        this.successMessageAction(
          String(
            i18n.t("general.page_created_success", {
              position: this.position
            })
          )
        );
      });
    }
  }

  @Watch("isPositionSwapped")
  protected onPositionSwapped(swapped: boolean): void {
    if (swapped && this.$refs.pageTable) {
      (this.$refs.pageTable as MisTable).reloadData();
    }
  }

  public async searchAction(options: ICrudOptions): Promise<any> {
    options.resource = this.resourcePath;
    await this.searchItemsAction(options);

    if (this.$router.currentRoute.name !== "ActiveCatalog") {
      await this.findCatalogAction({
        resource: "catalog",
        id: this.catalogId,
        disabledLoading: true
      });
    }

    const { name } = this.catalog || { name: "" };
    this.breadcrumbSetDataAction({
      [String(this.$router.currentRoute.name)]: {
        pageListTitle: this.$t("general.page_from", {
          catalogName: name ? name : ""
        })
      }
    });
  }

  public deleteAction(options: ICrudOptions): void {
    options.resource = this.resourcePath;
    options.resourceName = this.resourceName;
    this.deleteItemAction(options);
  }

  public onTableRowDrop(e: Event, data: any): void {
    data = {
      ...data,
      moveItem: this.dataList[data["oldIdx"]],
      afterItem: this.dataList[data["newIdx"]]
    };
    if (this.instantSave) {
      this.changePosition({
        resource: this.resourceName,
        resourceName: this.$i18n.t("general.page"),
        params: {
          page_id: this.dataList[data["oldIdx"]].id,
          new_position: this.dataList[data["newIdx"]].position
        }
      });
    }
  }

  public get catalogPath(): string {
    return "catalog/" + this.catalogId + "/page";
  }

  public get resourcePath(): string {
    if (this.$router.currentRoute.name == "ActiveCatalog") {
      return "active_catalog/page";
    }
    return this.catalogPath;
  }

  public get tableResourcePath(): string {
    if (this.$router.currentRoute.name == "ActiveCatalog") {
      return "active-catalog/page";
    }
    return this.catalogPath;
  }

  private addAd(): void {
    if (this.$router.currentRoute.name == "ActiveCatalog") {
      this.$router.push({
        path: "/active-catalog/page/wine/create/2"
      });
    } else {
      this.$router.push({
        path: "/catalog/" + this.catalog.id + "/page/wine/create/2"
      });
    }
  }

  private addContent(): void {
    if (this.$router.currentRoute.name == "ActiveCatalog") {
      this.$router.push({
        path: "/active-catalog/page/content/create"
      });
    } else {
      this.$router.push({
        path: "/catalog/" + this.catalog.id + "/page/content/create"
      });
    }
  }

  private addWine(): void {
    if (this.$router.currentRoute.name == "ActiveCatalog") {
      this.$router.push({
        path: "/active-catalog/page/wine/create/1"
      });
    } else {
      this.$router.push({
        path: "/catalog/" + this.catalog.id + "/page/wine/create/1"
      });
    }
  }

  public beforeDestroy() {
    this.resetAction();
  }

  protected get catalogId(): number {
    if (this.$router.currentRoute.name == "ActiveCatalog") {
      return this.getActiveCatalog.id;
    }
    return Number(this.$route.params.catalog);
  }

  protected get pageListLabel() {
    return this.catalog
      ? this.$t("general.page_overview_from", {
          catalogName: this.catalog.name
        })
      : this.$t("general.page_overview");
  }
}
