import { Component, Vue, Watch } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";

const namespace = "auth";

@Component
export class AuthMixin extends Vue {
  public alertHidden: boolean = true;

  @Getter("getError", { namespace })
  public getError: any;

  @Getter("getSuccess", { namespace })
  public getSuccess: any;

  @Action("setError", { namespace })
  public setError: any;

  @Action("setSuccess", { namespace })
  public setSuccess: any;

  @Getter("getIsLoading", { namespace })
  public getIsLoading!: Boolean;

  public resetMessage() {
    this.setError(null);
    this.setSuccess(null);
  }

  public get error() {
    return this.getError;
  }

  public get success() {
    return this.getSuccess;
  }

  public get alertType(): string {
    return this.error ? "danger" : "success";
  }

  public get alertText(): string {
    return this.error || this.success;
  }

  public get isLoading() {
    return this.getIsLoading;
  }

  @Watch("getError", { immediate: true, deep: true })
  public onError(error: any) {
    if (error) {
      this.alertHidden = false;
    }
  }

  @Watch("getSuccess", { immediate: true, deep: true })
  public onSuccess(success: any) {
    if (success) {
      this.alertHidden = false;
    }
  }

  public handleClose() {
    this.alertHidden = true;
    this.resetMessage();
  }
}
