
import DefaultLayout from "@/layouts/DefaultLayout.vue";
import { Component, Vue } from "vue-property-decorator";
import { BaseMixin } from "@/mixins/baseMixin";
import { Action, Getter } from "vuex-class";
// @ts-ignore
import "vue-good-table/dist/vue-good-table.css";
import MisTable from "@/components/MisTable.vue";
import { ICrudOptions } from "@/service/interfaces/ICrudOptions";
import ITableColumn from "@/interfaces/table/ITableColumn";
import CrudLayout from "@/layouts/CrudLayout.vue";
import i18n from "@/locales";

const namespace = "bottlesize";

@Component({
  components: {
    CrudLayout,
    DefaultLayout,
    MisTable
  },
  mixins: [BaseMixin]
})
export default class BottleSizeList extends Vue {
  public resourceName: string = "bottle-size";
  protected searchFields: Array<string> = ["size"];
  public columns: Array<ITableColumn> = [
    {
      label: String(i18n.t("general.bottle_size")),
      field: "size",
      filterOptions: {
        enabled: true
      }
    },
    {
      label: String(i18n.tc("general.action", 2)),
      field: "actions",
      sortable: false
    }
  ];
  @Getter("isLoading", { namespace }) loading: any;
  @Getter("isDeleted", { namespace }) deleted: any;
  @Action("del", { namespace }) deleteItemAction: any;
  @Action("search", { namespace }) searchItemsAction: any;
  @Getter("getTotal", { namespace }) totalRows: any;
  @Getter("getList", { namespace }) dataList: any;
  @Getter("getError", { namespace }) error: any;

  public deleteAction(options: ICrudOptions): void {
    this.deleteItemAction(options);
  }

  public searchAction(options: ICrudOptions): void {
    this.searchItemsAction(options);
  }
}
