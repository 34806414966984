
import { Component, Prop, Vue } from "vue-property-decorator";
import ActionButton from "./ActionButton.vue";

@Component({
  components: {
    ActionButton
  }
})
export default class CatalogPagesButton extends Vue {
  public name: string = "CatalogPagesButton";

  @Prop({ default: {} })
  public item: any;

  public actionHandle(): void {
    const { id } = this.item;
    this.$router.push({ path: `/catalog/${id}/page` });
  }
}
