
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import CrudLayout from "@/layouts/CrudLayout.vue";
import { Validate } from "vuelidate-property-decorators";
import { maxLength, minLength, required } from "vuelidate/lib/validators";
import Validation from "@/components/Validation.vue";
import IPage from "@/models/IPage";
import Dropdown from "@/components/Dropdown.vue";
import FormBackButton from "@/components/buttons/FormBackButton.vue";
import IRegion from "@/models/IRegion";
import MultiSelect from "@/components/multiselect/MultiSelect.vue";
import IUser from "@/models/IUser";
import ICatalog from "@/models/ICatalog";
import IStand from "@/models/IStand";
import ILogo from "@/models/ILogo";
import IPageTitle from "@/models/IPageTitle";

const namespace: string = "page";

const SUPER_ADMIN: string = "SUPER_ADMIN";
const ADMIN: string = "ADMIN";
const EXHIBITOR: string = "EXHIBITOR";

const CREATE: string = "CREATE";
const UPDATE: string = "UPDATE";

@Component({
  components: {
    MultiSelect,
    FormBackButton,
    Dropdown,
    CrudLayout,
    Validation
  }
})
export default class PageForm extends Vue {
  // component initial values
  public name: string = "PageForm";
  private resourceName = "page";

  // props
  @Prop({ default: "add" })
  public type: any;
  @Prop({ default: false })
  public saved: any;
  @Prop({ default: false })
  public loading: any;
  @Prop({ default: "" })
  public error: any;
  @Prop({
    default: function() {}
  })
  public saveAction: any;
  @Prop({
    default: function() {}
  })
  public back: any;
  @Prop()
  public item: any;

  // model
  public id?: number;
  public catalog_id: number = 0;
  public catalog: ICatalog | null = null;

  public user_id: number = 0;
  public user: IUser | null = null;

  @Validate({ required, minLength: minLength(2), maxLength: maxLength(64) })
  public description?: string;
  public footer?: Text;
  public position?: number;
  public page_type?: number;
  public content_pdf?: string;
  public active: boolean = true;
  public accepted: boolean = true;
  public released: boolean | null = null;
  public region_id?: number;
  public region?: IRegion;
  public stand_id?: number;
  public stand?: IStand;
  public release_accepted: boolean = false;
  public logo_id?: number;
  public logo?: ILogo;
  public page_title_id?: number;
  public page_title?: IPageTitle;

  @Watch("item", { immediate: true, deep: true })
  protected onItemChange(item: any) {
    if (item) {
      this.init(item);
    }
  }

  get mode() {
    return this.$route.name === "page_edit" ? UPDATE : CREATE;
  }

  private init(page: IPage): void {
    this.$nextTick(() => {
      this.id = page.id;
      this.catalog_id = page.catalog_id;
      this.catalog = page.catalog;
      this.user_id = page.user_id;
      this.user = page.user;
      this.description = page.description;
      this.footer = page.footer;
      this.position = page.position;
      this.page_type = page.page_type;
      this.content_pdf = page.content_pdf;
      this.active = page.active;
      this.accepted = page.accepted;
      this.released = page.released;
      this.region_id = page.region_id;
      this.region = page.region;
      this.stand_id = page.stand_id;
      this.stand = page.stand;
      this.release_accepted = page.release_accepted;
      this.logo_id = page.logo_id;
      this.logo = page.logo;
      this.page_title_id = page.page_title_id;
      this.page_title = page.page_title;
    });
  }

  public created() {
    //
  }

  public mounted() {
    //without assigning pageTypes here in mounted(), the dropdown is not updated after Creating new Page
  }

  public onSubmit(e: Object): void {
    this.$v.$touch();
    if (this.$v.$invalid) {
      return;
    }

    const page: IPage = {
      id: this.id,
      catalog_id: this.catalog_id,
      catalog: this.catalog,
      user_id: this.user_id,
      user: this.user,
      description: this.description,
      footer: this.footer,
      position: this.position,
      page_type: this.page_type,
      content_pdf: this.content_pdf,
      active: this.active,
      accepted: this.accepted,
      released: this.released,
      region_id: this.region_id,
      region: this.region,
      stand_id: this.stand_id,
      stand: this.stand,
      release_accepted: this.release_accepted,
      logo_id: this.logo_id,
      logo: this.logo,
      page_title_id: this.page_title_id,
      page_title: this.page_title
    };
    if (this.$route.params.id) {
      // edit
      page.id = Number.parseInt(this.$route.params.id);
      this.saveAction({
        resource: this.resourceName,
        data: page,
        id: page.id
      });
    } else {
      // add
      this.saveAction({ resource: this.resourceName, data: page });
    }
  }
}
