
import { Component, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import CrudLayout from "@/layouts/CrudLayout.vue";
import Validation from "@/components/Validation.vue";
import BottleSizeForm from "@/views/admin/bottle_size/BottleSizeForm.vue";
import FormCreate from "@/views/form/create/FormCreate.vue";

const namespace: string = "bottlesize";

@Component({
  components: {
    CrudLayout,
    Validation,
    BottleSizeForm
  },
  extends: FormCreate
})
export default class BottleSizeCreate extends Vue {
  public name: string = "BottleSizeCreate";
  protected resourceName = "bottle-size";

  @Getter("getError", { namespace }) error: any;
  @Getter("isLoading", { namespace }) loading: any;
  @Getter("isCreated", { namespace }) created: any;
  @Getter("getItem", { namespace }) createdItem: any;

  @Action("create", { namespace }) createItemAction: any;
  @Action("resetCreate", { namespace }) resetAction: any;

  beforeDestroy() {
    this.resetAction();
  }
}
