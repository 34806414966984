
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import AppConfig from "@/config/AppConfig";
import { Action, Getter } from "vuex-class";
import CrudLayout from "@/layouts/CrudLayout.vue";
import Validation from "@/components/Validation.vue";
import PageWineUpdateNotApprovedForm from "@/views/exhibitor/page/wine/notapproved/PageWineUpdateNotApprovedForm.vue";
import PageWineApprovedForm from "@/views/exhibitor/page/wine/approved/PageWineApprovedForm.vue";
import FormBackButton from "@/components/buttons/FormBackButton.vue";
import ReleaseButton from "@/components/buttons/ReleaseButton.vue";
import DownloadPageButton from "@/components/buttons/DownloadPageButton.vue";

const namespace: string = "page";

@Component({
  components: {
    DownloadPageButton,
    ReleaseButton,
    FormBackButton,
    PageWineApprovedForm,
    PageWineUpdateNotApprovedForm,
    CrudLayout,
    Validation
  }
})
export default class PageWineUpdateForm extends Vue {
  public name: string = "PageWineUpdateForm";
  private resourceName = "page";

  @Prop()
  protected userId: any;

  protected pageType: number = 0;
  protected pageTitle: any = "";

  @Getter("getError", { namespace }) error: any;
  @Getter("isLoading", { namespace }) loading: any;
  @Getter("isUpdated", { namespace }) updated: any;
  @Getter("getItem", { namespace }) item: any;

  @Action("update", { namespace }) updateItemAction: any;
  @Action("findOne", { namespace }) getItemAction: any;
  @Action("resetUpdate", { namespace }) resetAction: any;

  protected back() {
    this.$router.push({ path: "/" + this.resourceName });
  }

  @Watch("item", { immediate: true, deep: true })
  protected onPage(page: any) {
    if (page) {
      this.pageType = Number(page.page_type);
      this.pageTitle = this.$i18n.t(
        "general." +
          this.resourceName +
          "_" +
          AppConfig.PAGE_TYPES[this.pageType] +
          "_update"
      );
    }
  }

  beforeMount() {
    if (this.$route.params) {
      this.getItemAction({
        resource: this.resourceName,
        id: Number.parseInt(this.$route.params.id)
      });
    }
  }

  beforeDestroy() {
    this.resetAction();
  }
}
