
import { Component, Vue, Watch } from "vue-property-decorator";
import AppConfig from "@/config/AppConfig";
import { Action, Getter, namespace as moduleNamespace } from "vuex-class";
import CrudLayout from "@/layouts/CrudLayout.vue";
import Validation from "@/components/Validation.vue";
import PageWineUpdateForm from "@/views/exhibitor/page/wine/PageWineUpdateForm.vue";
import PageWineAdForm from "@/views/exhibitor/page/ad/PageWineAdForm.vue";
import AuthService from "@/service/AuthService";

const namespace: string = "page";
const NotificationModule = moduleNamespace("notification");

@Component({
  components: {
    PageWineAdForm,
    PageWineUpdateForm,
    CrudLayout,
    Validation
  }
})
export default class PageUpdate extends Vue {
  public name: string = "PageUpdate";
  private resourceName = "page";

  protected pageType: number = 0;
  protected pageTitle: any = "";
  protected userId: number | null = 0;

  @Getter("getError", { namespace }) error: any;
  @Getter("isLoading", { namespace }) loading: any;
  @Getter("isUpdated", { namespace }) updated: any;
  @Getter("getItem", { namespace }) item: any;

  @Action("update", { namespace }) updateItemAction: any;
  @Action("findOne", { namespace }) getItemAction: any;
  @Action("resetUpdate", { namespace }) resetAction: any;

  @NotificationModule.Action("setText")
  protected setNotification: any;

  @NotificationModule.Action("setActive")
  protected setNotificationActive: any;

  protected back() {
    this.$router.push({ path: "/" + this.resourceName });
  }

  @Watch("item", { immediate: true, deep: true })
  protected onPage(page: any) {
    if (page) {
      this.notification(page.release_accepted);
      this.pageType = Number(page.page_type);
      this.pageTitle = this.$i18n.t(
        "general." +
          this.resourceName +
          "_" +
          AppConfig.PAGE_TYPES[this.pageType] +
          "_update"
      );
    }
  }

  beforeMount() {
    const authService = new AuthService();
    if (authService.getUserId() !== null) {
      this.userId = authService.getUserId();
    } else {
      throw "User id not found!";
    }

    this.getItemAction({
      resource: this.resourceName,
      id: Number.parseInt(this.$route.params.id)
    });
  }

  public notification(state: number): void {
    if (state === 0) {
      this.setNotification({
        text: this.$t("general.exhibitor_page_release_reject"),
        theme: "danger"
      });
    } else if (state === 1) {
      this.setNotification({
        text: this.$t("general.exhibitor_page_release_accepted"),
        theme: "info"
      });
    } else {
      this.setNotificationActive(false);
    }
  }

  beforeDestroy() {
    this.resetAction();
    this.setNotificationActive(false);
  }
}
