import { ActionTree, ActionContext } from "vuex";
import { State } from "./state";

export function setData(store: ActionContext<State, any>, data: any) {
  store.commit("SET_DATA", data);
}

export default <ActionTree<State, any>>{
  setData
};
