
import { Component, Prop, Vue } from "vue-property-decorator";
import { Getter, namespace } from "vuex-class";
import { ICrudOptions } from "@/service/interfaces/ICrudOptions";
import { WINE_TYPE, ADD_TYPE } from "@/config/PageTypes";
import IPageCategory from "@/models/IPageCategory";
import IWine from "@/models/IWine";
import ExpovinaButton from "@/components/buttons/ExpovinaButton.vue";

const PageModule = namespace("page");
const pageCategoryNamespace: string = "pagecategory";

@Component({
  components: { ExpovinaButton }
})
export default class ReleaseButton extends Vue {
  public name: string = "ReleaseButton";

  @PageModule.Action("release")
  public releaseUpdateAction: any;

  @Getter("getList", { namespace: pageCategoryNamespace })
  pageCategories?: Array<IPageCategory>;

  @Getter("getAllAttachedWines", { namespace: pageCategoryNamespace })
  protected getAllAttachedWines!: Array<any>;

  @Prop({ required: true })
  public resource!: string;

  @Prop({ required: true })
  public id!: number;

  @Prop({ required: true })
  public item!: any;

  @Prop({ required: true })
  public additionalUrl!: any;

  @Prop({ type: Boolean, default: false, required: false })
  protected disabled: any;

  public release(forced = false) {
    if (this.winesWithoutGrapeVariety?.length && !forced) {
      this.$bvModal.show("wines-without-grape-variety-warning");

      return;
    }

    const timeout: number = this.winesWithoutGrapeVariety?.length ? 1000 : 0;

    this.$bvModal.hide("wines-without-grape-variety-warning");

    setTimeout(() => {
      this.$bvModal.show("release-modal");
    }, timeout);
  }

  public releaseModal() {
    this.$bvModal.hide("release-modal");
    const options: ICrudOptions = {
      resource: this.resource,
      id: this.id,
      descriptionField: "",
      additional: this.additionalUrl
    };
    this.releaseUpdateAction(options);
  }

  private buttonDisabled() {
    if (this.buttonHidden()) {
      return true;
    }
    return Boolean(this.item && this.item.released);
  }

  private buttonHidden() {
    const { logo_id, page_categories, page_type, content_pdf } = this.item;
    if (page_type == WINE_TYPE) {
      if (
        logo_id == null ||
        !(page_categories instanceof Array) ||
        (page_categories.length === 0 &&
          (!this.pageCategories || this.pageCategories.length === 0))
      ) {
        return true;
      }
    } else if (page_type == ADD_TYPE) {
      if (content_pdf == null) {
        return true;
      }
    }
    return false;
  }

  protected get winesWithoutGrapeVariety(): Array<IWine> {
    return this.getAllAttachedWines.filter((wine: IWine) => {
      return wine.grape_varieties_count === 0;
    });
  }
}
