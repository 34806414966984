
import { Component, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";
import moment from "moment";
import AuthService from "@/service/AuthService";
import { ROLES } from "@/config/Roles";
import { MdParserMixin } from "@/mixins/MdParserMixin";

const authService = new AuthService();
const CatalogModule = namespace("catalog");

@Component
export default class CatalogMessage extends MdParserMixin {
  public name: string = "CatalogMessage";

  protected days: number = 7;
  protected textClass: string | null = null;
  protected textRed: string = "text-red-500";

  @CatalogModule.Getter("getActiveCatalog")
  protected getActiveCatalog: any;

  @CatalogModule.Action("lastActiveCatalog")
  protected lastActiveCatalogAction: any;

  @CatalogModule.Getter("getLastActiveCatalog")
  protected getLastActiveCatalog: any;

  @CatalogModule.Getter("isUpdated")
  protected isUpdated: any;

  @CatalogModule.Action("hasCatalogPage")
  protected hasCatalogPage: any;

  @CatalogModule.Getter("getHasCatalogPage")
  protected getHasCatalogPage: any;

  protected dateFormat = "DD.MM.YYYY";

  protected get classes() {
    return this.textClass;
  }

  protected get message() {
    const catalog = this.getActiveCatalog || this.getLastActiveCatalog;
    if (!catalog) {
      return null;
    }
    const { name, from, to } = catalog;
    if (this.isCatalogActive) {
      this.textClass = null;
      return this.$t("general.active_catalog_message", {
        catalogName: name,
        catalogFrom: moment(from).format(this.dateFormat),
        catalogTo: moment(to).format(this.dateFormat)
      });
    } else if (this.isExhibitor) {
      this.textClass = this.textRed;
      return this.$t("general.exhibitor_active_catalog_expired_message", {
        catalogName: name,
        countDays: this.expireDaysReformat
      });
    }
    this.textClass = this.textRed;
    return this.$t("general.admin_active_catalog_expired_message", {
      catalogName: name,
      countDays: this.expireDaysReformat
    });
  }

  protected get followMessage() {
    this.textClass = this.textRed;
    if (this.isExhibitor) {
      return this.$t("general.exhibitor_active_catalog_expire_message", {
        countDaysLeft: this.expireDaysReformat
      });
    }
    return this.$t("general.admin_active_catalog_expire_message", {
      countDaysLeft: this.expireDaysReformat
    });
  }

  protected get isCatalogActive() {
    return Boolean(this.getActiveCatalog);
  }

  protected get isExhibitor(): boolean {
    return authService.getRole() == ROLES["EXHIBITOR"];
  }

  protected get expireDays() {
    const catalog = this.getActiveCatalog || this.getLastActiveCatalog;
    if (!catalog) {
      return 0;
    }
    return this.calculateExpireDays(catalog.to);
  }

  public get isActiveMessage() {
    if (!this.getHasCatalogPage) return false;
    else if (this.isCatalogActive) return true;
    else return this.expireDays <= this.days;
  }

  protected get isActiveFollowMessage() {
    return this.isCatalogActive && this.expireDays <= this.days;
  }

  protected calculateExpireDays(date: string): number {
    const expiration = moment(date).format("YYYY-MM-DD");
    const currentDate = moment().format("YYYY-MM-DD");
    return Math.abs(moment(expiration).diff(currentDate, "days"));
  }

  @Watch("isUpdated", { immediate: true, deep: true })
  protected isUpdatedWatcher() {
    if (!this.isCatalogActive) {
      this.lastActiveCatalog();
    }
  }

  protected lastActiveCatalog(): void {
    this.lastActiveCatalogAction({
      resource: "catalog",
      descriptionField: "name",
      additional: "last-active-catalog"
    });
  }

  protected get expireDaysReformat() {
    return this.expireDays + 1;
  }

  public created() {
    this.hasCatalogPage({
      resource: "catalog",
      descriptionField: "name",
      additional: "has-catalog-page"
    });
  }
}
