import { ActionContext } from "vuex";
import ErrorHelper from "@/utils/error/ErrorHelper";

/**
 * Dispatches messages to the snackbar store
 */
export default class MessageDispatcher {
  /**
   * dispatch root action to show snackbar message
   * @param store
   * @param error
   */
  protected dispatchMessage(
    store: ActionContext<any, any>,
    message: string,
    type: string
  ): Promise<any> {
    return store.dispatch(
      "snackbar/setText",
      {
        text: message,
        type: type
      },
      { root: true }
    );
  }

  /**
   *
   * @param store
   * @param message
   */
  public dispatchSuccessMessage(
    store: ActionContext<any, any>,
    message: string
  ): Promise<any> {
    return this.dispatchMessage(store, message, "success");
  }

  /**
   *
   * @param store
   * @param message
   */
  public dispatchErrorMessage(
    store: ActionContext<any, any>,
    message: string
  ): Promise<any> {
    return this.dispatchMessage(store, message, "danger");
  }

  /**
   *
   * @param store
   * @param message
   */
  protected dispatchInfoMessage(
    store: ActionContext<any, any>,
    message: string
  ): Promise<any> {
    return this.dispatchMessage(store, message, "info");
  }

  public dispatchLoading(
    store: ActionContext<any, any>,
    loading: boolean
  ): Promise<any> {
    return store.dispatch("snackbar/setLoading", loading, { root: true });
  }
}
