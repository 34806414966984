
import { Component, Vue } from "vue-property-decorator";

const namespace: string = "admin";

@Component({
  components: {}
})
export default class AdminView extends Vue {
  public name: string = "AdminView";
}
