
import DefaultLayout from "@/layouts/DefaultLayout.vue";
import { Component, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
// @ts-ignore
import "vue-good-table/dist/vue-good-table.css";
import MisTable from "@/components/MisTable.vue";
import CrudLayout from "@/layouts/CrudLayout.vue";
import i18n from "@/locales";
import { ICrudOptions } from "@/service/interfaces/ICrudOptions";
import ITableColumn from "@/interfaces/table/ITableColumn";

const namespace = "winetype";

@Component({
  components: {
    CrudLayout,
    DefaultLayout,
    MisTable
  }
})
export default class WineTypeList extends Vue {
  public name: string = "WineTypeList";
  public resourceName: string = "wine-type";
  private resourceLabelName = "wine_type";
  public searchFields: Array<string> = ["wine_type"];
  public columns: Array<ITableColumn> = [
    {
      label: String(i18n.t("general.wine_type")),
      field: "wine_type",
      filterOptions: {
        enabled: true
      }
    },
    {
      label: String(i18n.tc("general.action", 2)),
      field: "actions",
      sortable: false
    }
  ];

  @Getter("getList", { namespace })
  public dataList: any;

  @Getter("getTotal", { namespace })
  public totalRows: any;

  @Getter("getError", { namespace })
  public error: any;

  @Getter("isLoading", { namespace })
  public loading: any;

  @Getter("isDeleted", { namespace })
  public deleted: any;

  @Action("search", { namespace })
  public searchItemsAction: any;

  @Action("del", { namespace })
  public deleteItemAction: any;

  @Action("resetList", { namespace })
  public resetAction: any;

  public searchAction(options: ICrudOptions): void {
    this.searchItemsAction(options);
  }

  public deleteAction(options: ICrudOptions): void {
    this.deleteItemAction(options);
  }

  public beforeDestroy() {
    this.resetAction();
  }
}
