
import { Component, Vue, Watch } from "vue-property-decorator";
import { Action, Getter, namespace } from "vuex-class";
import CrudLayout from "@/layouts/CrudLayout.vue";
import Validation from "@/components/Validation.vue";
import RegionForm from "@/views/admin/region/RegionForm.vue";
import FormCreate from "@/views/form/create/FormCreate.vue";

const region = namespace("region");
const RegionGetter = region.Getter;
const RegionAction = region.Action;

@Component({
  components: {
    CrudLayout,
    Validation,
    RegionForm
  },
  extends: FormCreate
})
export default class RegionCreate extends Vue {
  protected name: string = "RegionCreate";
  protected resourceName = "region";

  @RegionGetter("getError") error: any;
  @RegionGetter("isLoading") loading: any;
  @RegionGetter("isCreated") created: any;
  @RegionGetter("getItem") createdItem: any;

  @RegionAction("create") createItemAction: any;
  @RegionAction("resetCreate") resetAction: any;

  beforeDestroy() {
    this.resetAction();
  }
}
