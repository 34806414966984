import { MutationTree } from "vuex";
import { State } from "./state";

export function TEXT_SNACKBAR(state: State, message: string) {
  state.text = message;
}

export function THEME_SNACKBAR(state: State, theme: string) {
  state.theme = theme;
}

export function ACTIVE_SNACKBAR(state: State, active: boolean) {
  state.active = active;
}

export function ON_CHANGE_SNACKBAR(state: State, unique: any) {
  state.onChange = unique;
}

export function LOADING(state: State, loading: boolean) {
  state.loading = loading;
}

export default <MutationTree<State>>{
  TEXT_SNACKBAR,
  THEME_SNACKBAR,
  ACTIVE_SNACKBAR,
  ON_CHANGE_SNACKBAR,
  LOADING
};
