import { GetterTree } from "vuex";
import { State } from "./state";

export function getText(state: State): string {
  return state.text;
}

export function getActive(state: State): boolean {
  return state.active;
}

export function getTheme(state: State): string {
  return state.theme;
}

export default <GetterTree<State, any>>{
  getText,
  getActive,
  getTheme
};
