import WineView from "@/views/admin/wine/WineView.vue";
import WineList from "@/views/admin/wine/WineList.vue";
import WineCreate from "@/views/admin/wine/WineCreate.vue";
import WineUpdate from "@/views/admin/wine/WineUpdate.vue";
import { ROLES } from "@/config/Roles";
import auth from "@/middlewares/AuthMiddleware";
import i18n from "@/locales";

export default [
  {
    path: "/wine",
    component: WineView,
    meta: {
      visibleInAside: true,
      roles: [ROLES.ADMIN, ROLES.SUPER_ADMIN],
      title: String(i18n.t("menu.wine")),
      middleware: [auth],
      avoidBack: true
    },
    children: [
      {
        path: "",
        name: "wine",
        component: WineList,
        meta: {
          visibleInAside: true,
          title: "",
          roles: [ROLES.ADMIN, ROLES.SUPER_ADMIN],
          middleware: [auth]
        }
      },
      {
        path: "create",
        name: "wine_create",
        component: WineCreate,
        meta: {
          visibleInAside: true,
          roles: [ROLES.ADMIN, ROLES.SUPER_ADMIN],
          title: String(i18n.t("general.wine_add")),
          middleware: [auth],
          avoidBack: true
        }
      },
      {
        path: "edit/:id",
        name: "wine_edit",
        component: WineUpdate,
        meta: {
          roles: [ROLES.ADMIN, ROLES.SUPER_ADMIN],
          title: String(i18n.t("general.edit_wine")),
          middleware: [auth]
        }
      }
    ]
  }
];
