
import { Component, Prop, Vue } from "vue-property-decorator";
import Validation from "@/components/Validation.vue";
import Loading from "@/components/Loading.vue";
import { BFormCheckbox } from "bootstrap-vue";

@Component({
  components: {
    Validation,
    Loading,
    BFormCheckbox
  }
})
export default class ExhibitorFormFields extends Vue {
  @Prop({ default: false })
  public profileUpdate?: boolean;
  @Prop({ default: "" })
  public companyName?: string;
  @Prop({ default: "" })
  public email?: string;
  @Prop({ default: "" })
  public street?: string;
  @Prop({ default: "" })
  public number?: string;
  @Prop({ default: "" })
  public website?: string;
  @Prop({ default: "" })
  public zip_code?: string;
  @Prop({ default: "" })
  public city?: string;
  @Prop({ default: "" })
  public phone?: string;
  @Prop({ default: "" })
  public description?: string;
  @Prop({ default: "" })
  public password?: string;
  @Prop({ default: "" })
  public password_repeat?: string;
  @Prop({ default: "" })
  public active?: string;
  @Prop({ default: "" })
  public type?: string;

  @Prop({})
  public v?: any;

  @Prop({ default: false })
  public passwordRequired?: boolean;

  public set companyNameProperty(value: any) {
    this.$emit("companyName", value);
  }

  public get companyNameProperty(): any {
    return this.companyName;
  }

  public set emailProperty(value: any) {
    this.$emit("email", value);
  }

  public get emailProperty(): any {
    return this.email;
  }

  public set streetProperty(value: any) {
    this.$emit("street", value);
  }

  public get streetProperty(): any {
    return this.street;
  }

  public set numberProperty(value: any) {
    this.$emit("number", value);
  }

  public get numberProperty() {
    return this.number;
  }

  public set websiteProperty(value: any) {
    this.$emit("website", value);
  }

  public get websiteProperty() {
    return this.website;
  }

  public set zipCodeProperty(value: any) {
    this.$emit("zip_code", value);
  }

  public get zipCodeProperty() {
    return this.zip_code;
  }

  public set cityProperty(value: any) {
    this.$emit("city", value);
  }

  public get cityProperty() {
    return this.city;
  }

  public set phoneProperty(value: any) {
    this.$emit("phone", value);
  }

  public get phoneProperty() {
    return this.phone;
  }

  public set descriptionProperty(value: any) {
    this.$emit("description", value);
  }

  public get descriptionProperty() {
    return this.description;
  }

  public set passwordProperty(value: any) {
    this.$emit("password", value);
  }

  public get passwordProperty() {
    return this.password;
  }

  public set passwordRepeatProperty(value: any) {
    this.$emit("password_repeat", value);
  }

  public get passwordRepeatProperty() {
    return this.password_repeat;
  }

  public set activeProperty(value: any) {
    this.$emit("active", value);
  }

  public get activeProperty() {
    return this.active;
  }
}
