import { GetterTree } from "vuex";
import { State } from "./state";

export function getText(state: State): string {
  return state.text;
}

export function getTheme(state: State): string {
  return state.theme;
}

export function getActive(state: State): boolean {
  return state.active;
}

export function getOnChange(state: State): any {
  return state.onChange;
}

export function isLoading(state: State): any {
  return state.loading;
}
export default <GetterTree<State, any>>{
  getText,
  getTheme,
  getActive,
  getOnChange,
  isLoading
};
