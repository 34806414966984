
import { Component } from "vue-property-decorator";
import ResetPasswordForm from "@/components/auth/ResetPasswordForm.vue";
import Alert from "@/components/Alert.vue";
import Loading from "@/components/Loading.vue";
import { Action } from "vuex-class";
import NotLoggedInLayout from "@/layouts/NotLoggedInLayout.vue";
import { AuthMixin } from "@/mixins/AuthMixin";

const namespace: string = "auth";

@Component({
  components: {
    NotLoggedInLayout,
    ResetPasswordForm,
    Alert,
    Loading
  }
})
export default class ResetPassword extends AuthMixin {
  public name: string = "ResetPassword";

  @Action("resetPassword", { namespace })
  private resetPassword: any;

  public onSubmit(credentials: object): void {
    credentials = Object.assign({}, credentials, {
      email: this.$route.query.email,
      token: this.$route.params.token
    });
    this.resetPassword(credentials);
  }
}
