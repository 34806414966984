import AuthService from "@/service/AuthService";
import { Component, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import router from "@/router";

const namespace = "catalog";
const authService = new AuthService();

const asideMenuNamespace = "asideMenu";

@Component
export default class MenuMixin extends Vue {
  @Getter("getActiveCatalog", { namespace })
  public getActiveCatalog: any;

  @Action("updateMobileMenuStatus", { namespace: asideMenuNamespace })
  protected updateMobileMenuStatus: any;

  public getTitle(title: string | Function): string {
    if (typeof title === "function") {
      return title(router.currentRoute);
    }
    return title;
  }

  public isVisible(item: any): boolean {
    if (this.isRouteActiveCatalogDepending(item)) {
      if (this.getActiveCatalog === null) {
        return false;
      }
    }

    if (
      !item.hasOwnProperty("meta") ||
      !item.meta.hasOwnProperty("roles") ||
      !item.meta.hasOwnProperty("visibleInAside") ||
      !Boolean(item.meta.visibleInAside)
    ) {
      return false;
    }

    if (!Boolean(this.getTitle(item.meta.title))) {
      return false;
    }

    const roles: Array<string> = item.meta.roles;
    const role: string = authService.getRole();

    if (!role) {
      return false;
    }
    return roles.indexOf(role) !== -1;
  }

  public isExpandable(item: any) {
    if (item.children) {
      const authService = new AuthService();
      const role: string = authService.getRole();

      let visibleByRole = item.children.filter((childItem: any) => {
        let roles: Array<string> = childItem.meta.roles;

        return role && roles.indexOf(role) >= 0;
      });

      if (visibleByRole.length > 0) {
        let visibleByPath = visibleByRole.filter((childItem: any) => {
          return childItem.path != "";
        });

        if (visibleByPath.length > 0) {
          let visible = visibleByPath.filter((childItem: any) => {
            return childItem.meta.visibleInAside == true;
          });

          return visible.length > 0;
        }
      }
    }

    return false;
  }

  /**
   * Lists the route paths, that are hidden, if there is no active catalog
   * @param item
   */
  public isRouteActiveCatalogDepending(item: any): boolean {
    return (
      item.path === "/admin-request" ||
      item.path === "/active-catalog/page" ||
      item.path === "/request" ||
      item.path === "/exhibitor-page"
    );
  }

  public showIcon(item: any) {
    if (!item) return false;
    if (item.storeAction) {
      return !this.$store.getters[item.storeAction];
    }
    return true;
  }

  public filterMenuItems(items: Array<Object>): Array<Object> {
    return items.filter((item: Object) => this.isVisible(item));
  }

  public resetMobileMenuStatus() {
    this.updateMobileMenuStatus(false);
  }
}
