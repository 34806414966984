const SYMBOLS: Array<any> = [
  { value: "v", name: "❖" },
  { value: "n", name: "■" },
  { value: "l", name: "●" },
  { value: "m", name: "❍" },
  { value: "z", name: "❚" },
  { value: "r", name: "❒" },
  { value: "d", name: "❄" },
  { value: "k", name: "❋" },
  { value: "a", name: "❁" },
  { value: "b", name: "❂" }
];

export function getSymbolByValue(value: string): string {
  let symbol: string = "";

  SYMBOLS.forEach(s => {
    if (s.value === value) {
      symbol = s.name;
    }
  });
  return symbol;
}

export default SYMBOLS;
