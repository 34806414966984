import { GetterTree } from "vuex";
import { State } from "./state";
import {
  getList,
  getTotal,
  getItem,
  getError,
  isLoading,
  isCreated,
  isUpdated,
  isDeleted
} from "@/store/modules/crud/functions/getters";
import CryptService from "@/service/CryptService";

export function getUserCatalogRequest(state: State): any {
  if (state.userCatalogRequest) {
    return state.userCatalogRequest;
  }
  const key = "userCatalogRequest";
  const value: string | null = sessionStorage.getItem(key);
  if (value !== null) {
    state.userCatalogRequest = JSON.parse(
      CryptService.decode(value.toString())
    );
    return state.userCatalogRequest;
  }
  return null;
}

export function getActiveCatalog(state: State): any {
  if (state.activeCatalog) {
    return state.activeCatalog;
  }
  const key = "activeCatalog";
  const value: string | null = sessionStorage.getItem(key);
  if (value != null) {
    state.activeCatalog = JSON.parse(CryptService.decode(value.toString()));
    return state.activeCatalog;
  }
  return null;
}

export function getLastActiveCatalog(state: State): any {
  return state.lastActiveCatalog;
}

export function getHasCatalogPage(state: State): any {
  return state.hasCatalogPage;
}

export default <GetterTree<State, any>>{
  getList,
  getTotal,
  getItem,
  getError,
  isLoading,
  isCreated,
  isUpdated,
  isDeleted,
  getUserCatalogRequest,
  getActiveCatalog,
  getLastActiveCatalog,
  getHasCatalogPage
};
