
import { Component, Prop, Watch } from "vue-property-decorator";
import FormBackButton from "@/components/buttons/FormBackButton.vue";
import AuthService from "@/service/AuthService";
import Permission from "@/service/Permission";
import { ROLES } from "@/config/Roles";
import axios from "axios";
import FileSaver from "file-saver";
import { Validations } from "vuelidate-property-decorators";
import { pdfRequired, requiredIfValue } from "@/validators/validators";
import AuthImage from "@/directives/AuthImage";
import PageContentFormUpload from "@/views/admin/page/content/PageContentFormUpload.vue";
import ReleaseButton from "@/components/buttons/ReleaseButton.vue";
import { Action, namespace as VuexNamespace } from "vuex-class";
import { BaseMixin } from "@/mixins/baseMixin";

const StatusModule = VuexNamespace("status");
const namespace: string = "page";

@Component({
  components: {
    PageContentFormUpload,
    FormBackButton,
    ReleaseButton
  },
  directives: {
    AuthImage: new AuthImage()
  }
})
export default class PageWineAdForm extends BaseMixin {
  public name: string = "PageWineAdForm";
  private resourceName = "page";
  @Action("updateAd", { namespace }) saveAction: any;

  @Prop()
  public item: any;

  @Prop({ default: "edit" })
  public type: any;
  public id: number = 0;
  public imageUrl: string = "";
  public file: any = "";
  public uploadPercentage: number = 0;
  public showProgressBar: boolean = true;
  public disableButton: boolean = false;

  @Validations()
  protected validations() {
    return {
      file: {
        requireIfValue: requiredIfValue("content_pdf", ""),
        pdfRequired: pdfRequired
      }
    };
  }

  @StatusModule.Getter("getList")
  protected statusList: any;

  @Watch("statusList", { immediate: true, deep: true })
  protected onStatusListChange(list: any) {
    if (list) {
      this.imageUrl =
        process.env.VUE_APP_BACKEND_HOST +
        "/api/page-image/" +
        this.id +
        "?t=" +
        Date.now();

      if (this.type == "edit") {
        this.disableButtonDispatch(list);
      }
    }
  }

  @Watch("item", { immediate: true, deep: true })
  protected onItemChange(item: any) {
    if (item) {
      this.init(item);
    }
  }

  private init(page: any): void {
    this.$nextTick(() => {
      this.id = page.id;
      this.imageUrl =
        process.env.VUE_APP_BACKEND_HOST +
        "/api/page-image/" +
        this.id +
        "?t=" +
        Date.now();
    });
  }

  public get preview(): boolean {
    const authService: AuthService = new AuthService();

    if (this.type == "edit") {
      if (
        Permission.hasPermission(
          [ROLES.ADMIN, ROLES.SUPER_ADMIN],
          authService.getRole()
        )
      ) {
        return true;
      }

      if (this.item) {
        if (authService.getUserId() == this.item.exhibitor_id) {
          return true;
        }
      }
    }

    return false;
  }

  public download() {
    const el: HTMLElement | null = document.getElementById("image-preview");

    if (el && el.getAttribute("valid") == "true") {
      if (this.preview) {
        axios
          .get(
            process.env.VUE_APP_BACKEND_HOST + "/api/page-download/" + this.id,
            { responseType: "arraybuffer" }
          )
          .then(response => {
            const file = new Blob([response.data], { type: "application/pdf" });
            FileSaver.saveAs(file, this.id + ".pdf");
          });
      }
    }
  }

  private fileHandler(value: any) {
    this.file = value;
  }

  public get fileExtension(): string {
    if (this.file) {
      return this.file.name.split(".").pop();
    }

    return "";
  }

  public onSubmit(e: Object): void {
    this.$v.$touch();
    if (this.$v.$invalid) {
      return;
    }

    const formData = new FormData();
    formData.append("file", this.file);
    formData.append("active", String(this.item.active));
    formData.append("id", String(this.id));

    this.saveAction({
      resource: "catalog/update-ad",
      data: formData,
      id: this.id,
      disabledLoading: true,
      descriptionField: "content_pdf",
      config: {
        headers: {
          "Content-Type": "multipart/form-data"
        },
        onUploadProgress: (progressEvent: any) => {
          if (formData.get("file") instanceof File) {
            this.uploadPercentage = Number(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }
        }
      }
    })
      .then((response: any) => {
        this.uploadPercentage = 0;
      })
      .catch(() => {
        this.uploadPercentage = 0;
      });
  }

  protected disableButtonDispatch(list: Array<Object>) {
    const status = this.getStatus(list, Number(this.id));
    const statusIsReady = status && status.ready;
    this.disableButton = statusIsReady == false;
  }

  protected get statusOnReady() {
    return this.item && this.item.status && this.item.status.ready;
  }

  protected get buttonDisabled() {
    return this.statusOnReady == 0 || this.disableButton;
  }
}
