
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import MultiSelect from "@/components/multiselect";
import { namespace } from "vuex-class";
import IBottleSizeWineRelation from "@/models/IBottleSizeWineRelation";
import IBottleSize from "@/models/IBottleSize";
import SaveButton from "@/components/buttons/SaveButton.vue";
import DeleteButton from "@/components/buttons/DeleteButton.vue";
import { Validate } from "vuelidate-property-decorators";
import { required } from "vuelidate/lib/validators";
import _ from "lodash";

const BottleSize = namespace("bottlesize");
const resourceName = "bottle-size";

@Component({
  components: {
    DeleteButton,
    SaveButton,
    MultiSelect
  }
})
export default class BottleSizeTable extends Vue {
  public readonly name: string = "BottleSizeTable";
  protected bottleSizes: Array<IBottleSize> = [];
  protected validationIndex: number | null = null;

  @Validate({ $each: { bottleSize: { id: required } } })
  protected newData: Array<IBottleSizeWineRelation> = [];

  @Prop({ required: true, default: [], type: Array })
  public bottleSizeData!: Array<any>;

  @BottleSize.Action("findAll")
  public findAll: any;

  @BottleSize.Action("updateOrCreate")
  public updateOrCreate: any;

  @BottleSize.Action("remove")
  public remove: any;

  @BottleSize.Getter("getList")
  public getAll: any;

  public async created(): Promise<any> {
    await this.findAll({ resource: resourceName, descriptionField: "size" });
    this.bottleSizes = this.getAll;
  }

  public createRow(): void {
    this.newData.push({
      bottleSize: {},
      wine_id: Number(this.$route.params.id) || 0,
      price: 0,
      updated_at: null,
      created_at: null,
      bottle_size_id: 0
    });
  }

  public removeRow(index: number): void {
    this.newData.splice(index, 1);
    this.validationIndex = null;
  }

  @Watch("newData", { deep: true })
  protected watcher(item: any): void {
    this.hideBottleSize(item);
    const filter = item.filter((bottleSize: any) => {
      return !_.isEmpty(bottleSize.bottleSize);
    });

    const mappedFilter = filter.map((i: any) => {
      return {
        wine_id: i.wine_id,
        price: i.price,
        bottle_size_id: i.bottleSize.id,
        old_bottle_size_id: i.bottle_size_id
      };
    });

    if (!_.isEmpty(filter)) {
      this.$emit("added-bottle-size", mappedFilter);
    }
  }

  public hideBottleSize(
    selectedBottleSizes: Array<IBottleSizeWineRelation>
  ): void {
    let opt = this.getAll;

    selectedBottleSizes.forEach(c => {
      opt = opt.filter((i: any) => i.id !== c.bottleSize.id);
    });

    this.bottleSizes = opt;
  }

  public getLastIndex(data: Array<any>): number {
    return data.length - 1;
  }

  @Watch("bottleSizeData", { deep: true, immediate: true })
  public async bottleSizesWatcher(data: Array<any>): Promise<any> {
    this.newData = [];
    if (data.length > 0) {
      await data.forEach((item: any) => {
        const founded = this.getAll.find(
          (x: any) => x.id === item.bottle_size_id
        );
        this.newData.push({ ...item, bottleSize: founded });
      });
      this.createRow();
    } else {
      this.createRow();
    }
  }

  public async submit(
    item: IBottleSizeWineRelation,
    index: number
  ): Promise<any> {
    this.validationIndex = index;
    if (this.validation(this.$v.newData, "bottleSize", index)) {
      return;
    }
    // Update or Create
    /*const bottleSize = {
      wine_id: item.wine_id,
      price: item.price,
      bottle_size_id: item.bottleSize.id,
      old_bottle_size_id: item.bottle_size_id
    };
    const options: ICrudOptions = {
      resource: resourceName,
      additional: "update-or-create",
      data: bottleSize,
      descriptionField: "size"
    };

    await this.updateOrCreate(options);*/

    this.newData = this.newData.map(i => {
      return i.bottle_size_id == item.bottle_size_id
        ? Object.assign({}, i, { bottle_size_id: item.bottleSize.id })
        : i;
    });

    if (this.getLastIndex(this.newData) === index) this.createRow();

    this.$emit("bottle-size-submit");
  }

  public async removeBottleSize(
    item: IBottleSizeWineRelation,
    index: number
  ): Promise<any> {
    const options = {
      resource: resourceName,
      descriptionField: "size",
      wine_id: item.wine_id,
      bottle_size_id: item.bottleSize.id,
      additional: "remove"
    };
    await this.remove(options);
    this.removeRow(index);
  }

  public validation(item: any, param: string, index: number) {
    return item.$each[index][param].$invalid && this.validationIndex === index;
  }
}
