
import { Vue, Component, Prop } from "vue-property-decorator";
import { BaseMixin } from "@/mixins/baseMixin";
import LinkButton from "@/components/buttons/LinkButton.vue";

@Component({
  components: {
    LinkButton
  },
  mixins: [BaseMixin]
})
export default class UpdateButton extends Vue {
  public name: string = "UpdateButton";

  @Prop({ default: "" })
  public to: any;

  @Prop({ default: {} })
  public item: any;

  get currentPath(): string {
    return (
      this.$router.currentRoute.path.replace(/\/$/, "") +
      "/edit/" +
      this.item.id
    );
  }

  get toUrl(): string {
    return this.to ? this.to + this.item.id : this.currentPath;
  }
}
