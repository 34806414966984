import { CrudState } from "@/store/modules/crud/State";

export function SET_DATA_LIST(state: CrudState, data: any) {
  state.dataList = data;
}
export function SET_DATA_TOTAL(state: CrudState, total: any) {
  state.total = total;
}

export function SET_DATA_ITEM(state: CrudState, item: any) {
  state.dataItem = item;
}

export function SET_DATA_ERROR(state: CrudState, error: any) {
  state.error = error;
}

export function SET_DATA_LOADING(state: CrudState, loading: any) {
  state.loading = loading;
}

export function SET_DATA_CREATED(state: CrudState, created: any) {
  state.created = created;
}

export function SET_DATA_UPDATED(state: CrudState, updated: any) {
  state.updated = updated;
}

export function SET_DATA_DELETED(state: CrudState, deleted: any) {
  state.deleted = deleted;
}
