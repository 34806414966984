
import DefaultLayout from "@/layouts/DefaultLayout.vue";
import { Component, Vue } from "vue-property-decorator";
import { BaseMixin } from "@/mixins/baseMixin";
import { Action, Getter, Mutation } from "vuex-class";
// @ts-ignore
import "vue-good-table/dist/vue-good-table.css";
import MisTable from "@/components/MisTable.vue";
import { ICrudOptions } from "@/service/interfaces/ICrudOptions";
import ITableColumn from "@/interfaces/table/ITableColumn";
import CrudLayout from "@/layouts/CrudLayout.vue";
import i18n from "@/locales";
import CatalogExhibitorListButton from "@/components/buttons/CatalogExhibitorListButton.vue";

const namespace = "catalog";

@Component({
  components: {
    CrudLayout,
    DefaultLayout,
    MisTable,
    CatalogExhibitorListButton
  },
  mixins: [BaseMixin]
})
export default class CatalogList extends Vue {
  public resourceName: string = "catalog";

  public additionalActionButtons: Array<any> = [
    { name: "CatalogPagesButton" },
    { name: "CatalogExhibitorListButton" }
  ];
  public columns: Array<ITableColumn> = [
    {
      label: String(i18n.t("general.catalog")),
      field: "name",
      filterOptions: {
        enabled: true
      }
    },
    {
      label: String(i18n.t("general.catalog_type")),
      field: "catalog_type",
      type: "number",
      formatFn: (catalogType: any) =>
        catalogType == "1"
          ? String(i18n.t("general.primavera"))
          : String(i18n.t("general.expovina")),
      filterOptions: {
        enabled: true,
        filterDropdownItems: [
          { value: "1", text: String(i18n.t("general.primavera")) },
          { value: "2", text: String(i18n.t("general.expovina")) }
        ]
      }
    },
    {
      label: String(i18n.t("general.date_from")),
      field: "from",
      type: "date",
      dateInputFormat: "yyyy-MM-dd",
      dateOutputFormat: "dd.MM.yyyy",
      filterOptions: {
        enabled: true,
        isDate: true
      }
    },
    {
      label: String(i18n.t("general.date_to")),
      field: "to",
      type: "date",
      dateInputFormat: "yyyy-MM-dd",
      dateOutputFormat: "dd.MM.yyyy",
      filterOptions: {
        enabled: true,
        isDate: true
      }
    },
    {
      label: String(i18n.tc("general.action", 2)),
      field: "actions",
      sortable: false
    }
  ];
  protected searchFields: Array<string> = ["name"];

  @Getter("getList", { namespace }) dataList: any;
  @Getter("getTotal", { namespace }) totalRows: any;
  @Getter("getError", { namespace }) error: any;
  @Getter("isLoading", { namespace }) loading: any;
  @Getter("isDeleted", { namespace }) deleted: any;

  @Action("search", { namespace }) searchItemsAction: any;
  @Action("del", { namespace }) deleteItemAction: any;
  @Action("resetList", { namespace }) resetAction: any;

  @Mutation("SET_DATA_ITEM", { namespace }) resetItem: any;

  public name: string = "CatalogList";
  public searchAction(options: ICrudOptions): void {
    this.searchItemsAction(options);
  }
  public deleteAction(options: ICrudOptions): void {
    this.deleteItemAction(options);
  }

  beforeDestroy() {
    this.resetAction();
  }

  created() {
    this.resetItem(null);
  }
}
