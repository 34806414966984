import AuthService from "@/service/AuthService";

const authService = new AuthService();

export class State {
  token: any = authService.getToken() || null;
  isLoading: boolean = true;
  error: any = null;
  success: any = null;
  existCatalogRequest: boolean = true;
}
