import { ActionContext, ActionTree } from "vuex";
import { ICrudOptions } from "@/service/interfaces/ICrudOptions";
import axios from "axios";
import i18n from "@/locales";
import AppConfig from "@/config/AppConfig";
import { State } from "./state";
import {
  create,
  del,
  findAll,
  findOne,
  paginate,
  resetCreate,
  resetList,
  resetUpdate,
  search,
  update,
  crudActions
} from "@/store/modules/crud/functions/actions";

const BASE_URL = AppConfig.ENTRYPOINT + "/api/";

export async function updateOrCreate(
  store: ActionContext<State, any>,
  options: ICrudOptions
): Promise<any> {
  await crudActions.dispatchLoading(store, true);
  return axios
    .post(
      `${BASE_URL}${options.resource}/${options.additional}`,
      options.data,
      options.config
    )
    .then(async (response: any) => {
      store.commit("SET_DATA_ERROR", null);
      await crudActions.dispatchLoading(store, false);
      await crudActions.dispatchSuccessMessage(
        store,
        String(
          i18n.t("general.item_saved_success", {
            item: i18n.t("general.bottle_size")
          })
        )
      );
    })
    .catch((error: any) => {
      crudActions.rejectWriteAction(store, error, options);
    });
}

export async function remove(
  store: ActionContext<State, any>,
  options: any
): Promise<any> {
  await crudActions.dispatchLoading(store, true);
  return axios
    .delete(
      `${BASE_URL}${options.resource}/${options.additional}/${options.wine_id}/${options.bottle_size_id}`
    )
    .then(async (response: any) => {
      store.commit("SET_DATA_ERROR", null);
      await crudActions.dispatchLoading(store, false);
      await crudActions.dispatchSuccessMessage(
        store,
        String(
          i18n.t("general.item_deleted_successfully", {
            resourceName: i18n.t("general.bottle_size")
          })
        )
      );
    })
    .catch((error: any) => {
      crudActions.rejectWriteAction(store, error, options);
    });
}

export default <ActionTree<State, any>>{
  findOne,
  findAll,
  search,
  paginate,
  create,
  update,
  del,
  resetList,
  resetCreate,
  resetUpdate,
  updateOrCreate,
  remove
};
