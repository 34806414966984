
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Validate } from "vuelidate-property-decorators";
import { required } from "vuelidate/lib/validators";
import Validation from "@/components/Validation.vue";
import IPage from "@/models/IPage";
import Dropdown from "@/components/Dropdown.vue";
import FormBackButton from "@/components/buttons/FormBackButton.vue";
import IRegion from "@/models/IRegion";
import MultiSelect from "@/components/multiselect/MultiSelect.vue";
import IUser from "@/models/IUser";
import ICatalog from "@/models/ICatalog";
import IStand from "@/models/IStand";
import ILogo from "@/models/ILogo";
import IPageTitle from "@/models/IPageTitle";
import CatalogUtil from "@/utils/catalog/CatalogUtil";
import PageWineLeft from "@/views/exhibitor/page/wine/notapproved/left/PageWineLeft.vue";
import PageWineRight from "@/views/exhibitor/page/wine/notapproved/right/PageWineRight.vue";
import ReleaseButton from "@/components/buttons/ReleaseButton.vue";
import ImportPage from "@/components/import/ImportPage.vue";

const CREATE: string = "CREATE";
const UPDATE: string = "UPDATE";

@Component({
  components: {
    ImportPage,
    ReleaseButton,
    PageWineRight,
    PageWineLeft,
    MultiSelect,
    FormBackButton,
    Dropdown,
    Validation
  }
})
export default class PageWineUpdateNotApprovedForm extends Vue {
  // Component initial values
  public name: string = "PageWineUpdateNotApprovedForm";
  private resourceName = "page";

  // Props
  @Prop({ default: "update" })
  public type: any;
  @Prop({ default: false })
  public saved: any;
  @Prop({ default: false })
  public loading: any;
  @Prop({ default: "" })
  public error: any;
  @Prop({
    default: function() {}
  })
  public saveAction: any;
  @Prop({
    default: function() {}
  })
  public back: any;

  @Prop()
  public item: any;

  @Prop()
  protected userId: any;

  // Models
  public id: number | undefined = 0;
  public catalog_id: number = 0;
  public catalog: ICatalog | null = null;
  public user_id: number = 0;
  public user: IUser | null = null;
  public description?: string;
  public footer?: Text;
  public position?: number;
  public page_type?: number;
  public content_pdf?: string;
  public active: boolean = true;
  public accepted: boolean = true;
  public released: boolean | null = null;
  public region_id?: number;
  public region?: IRegion;
  public stand_id?: number;
  public stand?: IStand;
  public release_accepted: boolean = false;
  public logo_id?: number;
  public logo?: ILogo;
  public page_title_id?: number;
  @Validate({ required })
  public pageTitle?: IPageTitle;

  @Watch("item", { immediate: true, deep: true })
  protected onItemChange(item: any) {
    if (item) {
      this.init(item);
    }
  }

  protected get mode() {
    return this.$route.name === "page_edit" ? UPDATE : CREATE;
  }

  protected get isExpovina(): boolean {
    return this.item && CatalogUtil.isExpovina(this.item.catalog);
  }

  private init(page: IPage): void {
    this.id = page.id;
    this.catalog_id = page.catalog_id;
    this.catalog = page.catalog;
    this.user_id = page.user_id;
    this.user = page.user;
    this.description = page.description;
    this.footer = page.footer;
    this.position = page.position;
    this.page_type = page.page_type;
    this.content_pdf = page.content_pdf;
    this.active = page.active;
    this.accepted = page.accepted;
    this.released = page.released;
    this.region_id = page.region_id;
    this.region = page.region;
    this.stand_id = page.stand_id;
    this.stand = page.stand;
    this.release_accepted = page.release_accepted;
    this.logo_id = page.logo_id;
    this.logo = page.logo;
    this.page_title_id = page.page_title_id;
    this.pageTitle = page.page_title;
  }

  public onSubmit(e: Object): void {
    this.$v.$touch();
    if (this.$v.$invalid) {
      return;
    }

    const page: IPage = {
      id: this.id,
      catalog_id: this.catalog_id,
      catalog: this.catalog,
      user_id: this.user_id,
      user: this.user,
      description: this.description,
      footer: this.footer,
      position: this.position,
      page_type: this.page_type,
      content_pdf: this.content_pdf,
      active: this.active,
      accepted: this.accepted,
      released: this.released,
      region_id: this.region_id,
      region: this.region,
      stand_id: this.stand_id,
      stand: this.stand,
      release_accepted: this.release_accepted,
      logo_id: this.logo_id,
      logo: this.logo,
      page_title_id: this.page_title_id,
      page_title: this.pageTitle
    };
    if (this.$route.params.id) {
      // Edit
      page.id = Number.parseInt(this.$route.params.id);
      this.saveAction({
        resource: this.resourceName,
        data: page,
        id: page.id
      });
    } else {
      // Add
      this.saveAction({ resource: this.resourceName, data: page });
    }
  }

  public get itemReleased() {
    return Boolean(this.item && this.item.released);
  }

  protected get getClass() {
    return this.isExpovina
      ? "wine-page-container--expovina"
      : "wine-page-container--primavera";
  }
}
