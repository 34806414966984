import CatalogCreate from "@/views/admin/catalog/CatalogCreate.vue";
import CatalogUpdate from "@/views/admin/catalog/CatalogUpdate.vue";
import { ROLES } from "@/config/Roles";
import auth from "@/middlewares/AuthMiddleware";
import i18n from "@/locales";
import CatalogView from "@/views/admin/catalog/CatalogView.vue";
import CatalogList from "@/views/admin/catalog/CatalogList.vue";
import PageView from "@/views/admin/page/PageView.vue";
import PageList from "@/views/admin/page/PageList.vue";
import PageCreate from "@/views/admin/page/PageCreate.vue";
import PageUpdate from "@/views/admin/page/PageUpdate.vue";
import PageWineAdCreate from "@/views/admin/page/winead/PageWineAdCreate.vue";
import { WINE_TYPE, WINE_TYPES } from "@/config/PageTypes";
import CheckPageCatalogMiddleware from "@/middlewares/CheckPageCatalogMiddleware";
import PageContentCreate from "@/views/admin/page/content/PageContentCreate.vue";
import ExhibitorView from "@/views/admin/exhibitor/ExhibitorView.vue";
import ExhibitorList from "@/views/admin/exhibitor/ExhibitorList.vue";
import ExhibitorUpdate from "@/views/admin/exhibitor/ExhibitorUpdate.vue";
import ExhibitorSendMails from "@/views/admin/exhibitor/ExhibitorSendMails.vue";
import ExhibitorCreate from "@/views/admin/exhibitor/ExhibitorCreate.vue";
import ExhibitorResetPassword from "@/views/admin/exhibitor/ExhibitorResetPassword.vue";

export default [
  {
    path: "/catalog",
    component: CatalogView,
    meta: {
      visibleInAside: true,
      roles: [ROLES.ADMIN, ROLES.SUPER_ADMIN],
      title: String(i18n.t("menu.catalog")),
      middleware: [auth]
    },
    children: [
      {
        path: "",
        name: "catalog",
        component: CatalogList,
        meta: {
          visibleInAside: true,
          title: "",
          roles: [ROLES.ADMIN, ROLES.SUPER_ADMIN],
          middleware: [auth]
        }
      },
      {
        path: "create",
        name: "catalog_create",
        component: CatalogCreate,
        meta: {
          visibleInAside: true,
          roles: [ROLES.ADMIN, ROLES.SUPER_ADMIN],
          title: String(i18n.t("general.add_catalog")),
          middleware: [auth],
          avoidBack: true
        }
      },
      {
        path: "edit/:id",
        name: "catalog_edit",
        component: CatalogUpdate,
        meta: {
          roles: [ROLES.ADMIN, ROLES.SUPER_ADMIN],
          title: String(i18n.t("general.edit_catalog")),
          middleware: [auth]
        }
      },
      // Page
      {
        path: ":catalog/page",
        component: PageView,
        meta: {
          visibleInAside: false,
          roles: [ROLES.ADMIN, ROLES.SUPER_ADMIN],
          title: (route: any, data: any) => {
            if (data && data.hasOwnProperty("pageListTitle")) {
              return String(data.pageListTitle);
            }
            return String(i18n.t("general.page"));
          },
          middleware: [auth]
        },
        children: [
          {
            path: "",
            name: "catalog_page",
            component: PageList,
            meta: {
              visibleInAside: false,
              title: "",
              roles: [ROLES.ADMIN, ROLES.SUPER_ADMIN],
              middleware: [auth]
            }
          },
          {
            path: "create",
            name: "catalog_page_create",
            component: PageCreate,
            meta: {
              visibleInAside: false,
              roles: [ROLES.ADMIN, ROLES.SUPER_ADMIN],
              title: String(i18n.t("general.add_page")),
              middleware: [auth],
              avoidBack: true
            }
          },
          {
            path: "edit/:id",
            name: "catalog_page_edit",
            component: PageUpdate,
            meta: {
              roles: [ROLES.ADMIN, ROLES.SUPER_ADMIN],
              title: (route: any, data: any) => {
                if (data && data.hasOwnProperty("title")) {
                  return String(data.title);
                }
                return "";
              },
              middleware: [auth]
            }
          },
          {
            path: "wine/create/:id",
            name: "catalog_page_wine",
            component: PageWineAdCreate,
            meta: {
              roles: [ROLES.EXHIBITOR, ROLES.ADMIN, ROLES.SUPER_ADMIN],
              title: (route: any, data: any) => {
                if (data && data.hasOwnProperty("catalog")) {
                  const id: number = parseInt(route.params.id);
                  if (WINE_TYPES.indexOf(id) > -1) {
                    const key =
                      id == WINE_TYPE
                        ? "general.page_wine_add"
                        : "general.page_ad_add";

                    return String(
                      i18n.t(key, {
                        catalog: data.catalog
                      })
                    );
                  }
                }
                return "";
              },
              middleware: [auth, CheckPageCatalogMiddleware]
            }
          },
          {
            path: "content/create",
            name: "catalog_page_content",
            component: PageContentCreate,
            meta: {
              roles: [ROLES.ADMIN, ROLES.SUPER_ADMIN],
              title: (route: any, data: any) => {
                if (data && data.hasOwnProperty("catalog")) {
                  return String(
                    i18n.t("general.page_content_add", {
                      catalog: data.catalog
                    })
                  );
                }
                return "";
              },
              middleware: [auth, CheckPageCatalogMiddleware]
            }
          }
        ]
      },
      // Exhibitor
      {
        path: ":catalog/exhibitor",
        component: ExhibitorView,
        meta: {
          visibleInAside: false,
          roles: [ROLES.ADMIN, ROLES.SUPER_ADMIN],
          title: (route: any, data: any) => {
            if (data && data.hasOwnProperty("catalogExhibitorListTitle")) {
              return String(data.catalogExhibitorListTitle);
            }
            return String(i18n.t("general.exhibitor"));
          },
          middleware: [auth]
        },
        children: [
          {
            path: "",
            name: "catalog_exhibitor_list",
            component: ExhibitorList,
            meta: {
              title: "",
              roles: [ROLES.ADMIN, ROLES.SUPER_ADMIN],
              middleware: [auth]
            }
          },
          {
            path: "edit/:id",
            name: "catalog_exhibitor_edit",
            component: ExhibitorUpdate,
            meta: {
              title: (route: any, data: any) => {
                if (data && data.hasOwnProperty("catalogExhibitorEditTitle")) {
                  return String(data.catalogExhibitorEditTitle);
                }
                return String(i18n.t("general.edit_exhibitor"));
              },
              roles: [ROLES.ADMIN, ROLES.SUPER_ADMIN],
              middleware: [auth]
            }
          },
          {
            path: "send-mail",
            name: "catalog_exhibitor_send_mail",
            component: ExhibitorSendMails,
            meta: {
              title: String(i18n.t("general.send_email_to_all_users")),
              roles: [ROLES.ADMIN, ROLES.SUPER_ADMIN],
              middleware: [auth]
            }
          },
          {
            path: "create",
            name: "catalog_exhibitor_create",
            component: ExhibitorCreate,
            meta: {
              title: String(i18n.t("general.exhibitor_create")),
              visibleInAside: false,
              roles: [ROLES.SUPER_ADMIN],
              middleware: [auth],
              avoidBack: true
            }
          },
          {
            path: "password-reset/:id",
            name: "catalog_exhibitor_password_reset",
            component: ExhibitorResetPassword,
            meta: {
              roles: [ROLES.SUPER_ADMIN],
              title: (route: any, data: any) => {
                if (data && data.hasOwnProperty("email")) {
                  return String(
                    i18n.t("general.exhibitor_password_reset", {
                      mail: data.email
                    })
                  );
                }
                return "";
              },
              middleware: [auth]
            }
          }
        ]
      }
    ]
  }
];
