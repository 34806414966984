
import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";

const PageModule = namespace("page");
@Component({
  components: {}
})
export default class ExhibitorPageMessage extends Vue {
  protected name = "ExhibitorPageMessage";

  @PageModule.Getter("getItem")
  protected pageItem: any;
}
