
import { Component, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import CrudLayout from "@/layouts/CrudLayout.vue";
import Validation from "@/components/Validation.vue";
import AwardForm from "@/views/admin/award/AwardForm.vue";

const namespace: string = "award";

@Component({
  components: {
    CrudLayout,
    Validation,
    AwardForm
  }
})
export default class AwardUpdate extends Vue {
  public name: string = "AwardUpdate";
  private resourceName = "award";

  @Getter("getError", { namespace })
  protected error: any;

  @Getter("isLoading", { namespace })
  protected loading: any;

  @Getter("isUpdated", { namespace })
  protected updated: any;

  @Getter("getItem", { namespace })
  protected item: any;

  @Action("update", { namespace })
  protected updateItemAction: any;

  @Action("findOne", { namespace })
  protected getItemAction: any;

  @Action("resetUpdate", { namespace })
  protected resetAction: any;

  protected back() {
    // TODO remember last table state (session storage)
    this.$router.push({ path: "/" + this.resourceName });
  }

  mounted() {
    this.getItemAction({
      resource: this.resourceName,
      id: Number.parseInt(this.$route.params.id),
      descriptionField: "name"
    });
  }

  beforeDestroy() {
    this.resetAction();
  }
}
