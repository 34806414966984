import PageView from "@/views/admin/page/PageView.vue";
import PageList from "@/views/exhibitor/page/PageList.vue";
import PageUpdate from "@/views/exhibitor/page/PageUpdate.vue";
import { ROLES } from "@/config/Roles";
import auth from "@/middlewares/AuthMiddleware";
import i18n from "@/locales";

export default [
  {
    path: "/exhibitor-page",
    component: PageView,
    meta: {
      visibleInAside: true,
      roles: [ROLES.EXHIBITOR],
      title: String(i18n.t("menu.page")),
      middleware: [auth]
    },
    children: [
      {
        path: "",
        name: "exhibitor_page",
        component: PageList,
        meta: {
          visibleInAside: false,
          title: "",
          roles: [ROLES.EXHIBITOR],
          middleware: [auth]
        }
      },
      {
        path: "edit/:id",
        name: "exhibitor_page_edit",
        component: PageUpdate,
        meta: {
          roles: [ROLES.EXHIBITOR],
          title: String(i18n.t("general.page_winepage_update")),
          middleware: [auth],
          avoidBack: true
        }
      }
    ]
  }
];
