
import { Component, Vue } from "vue-property-decorator";

const namespace: string = "wine";

@Component({
  components: {}
})
export default class WineView extends Vue {
  public name: string = "WineView";
}
