
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class WinesButton extends Vue {
  public name: string = "WinesButton";

  @Prop({ default: {} })
  public item: any;

  protected get title() {
    return this.$t("general.wine");
  }

  protected onClick() {
    this.$router.push({
      name: "wine",
      query: {
        columnFilters: JSON.stringify({
          "user.company_name": this.item.company_name
        })
      }
    });
  }
}
