
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class ServiceTable extends Vue {
  public name: string = "ServiceTable";

  @Prop()
  public serviceList: any;
}
