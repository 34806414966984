import { ActionContext, ActionTree } from "vuex";
import { State } from "./state";
import axios from "axios";
import {
  create,
  del,
  findAll,
  findOne,
  paginate,
  resetCreate,
  resetList,
  resetUpdate,
  search,
  update
} from "@/store/modules/crud/functions/actions";
import AppConfig from "@/config/AppConfig";
import ErrorHelper from "@/utils/error/ErrorHelper";
import ICatalog from "@/models/ICatalog";
import { CrudState } from "@/store/modules/crud/State";
import { ICrudOptions } from "@/service/interfaces/ICrudOptions";

const BASE_URL = `${AppConfig.ENTRYPOINT}/api/`;

export async function activeCatalog(
  store: ActionContext<State, any>,
  url: string
): Promise<any> {
  await axios
    .get(`${BASE_URL}${url}`)
    .then((response: any) => {
      store.commit(
        "SET_DATA_USER_CATALOG_REQUEST",
        response.data.data.user_catalog_request
      );
      store.commit(
        "SET_DATA_ACTIVE_CATALOG",
        response.data.data.active_catalog
      );
    })
    .catch((error: any) => {
      store.commit("SET_DATA_ERROR", ErrorHelper.getError(error));
    });
}

export async function setActiveCatalog(
  store: ActionContext<State, any>,
  activeCatalog: ICatalog
) {
  store.commit("SET_DATA_ACTIVE_CATALOG", activeCatalog);
}

export async function setUserCatalogRequest(
  store: ActionContext<State, any>,
  hasRequest: boolean
) {
  store.commit("SET_DATA_USER_CATALOG_REQUEST", hasRequest);
}

export function setIconStatus(
  store: ActionContext<State, any>,
  state: boolean
) {
  store.commit("SET_DATA_USER_CATALOG_REQUEST", state);
}

export async function lastActiveCatalog(
  store: ActionContext<CrudState, any>,
  options: ICrudOptions
): Promise<any> {
  await axios
    .get(`${BASE_URL}${options.resource}/${options.additional}`)
    .then((response: any) => {
      store.commit("SET_DATA_LAST_ACTIVE_CATALOG", response.data.data);
    })
    .catch((error: any) => {
      store.commit("SET_DATA_ERROR", ErrorHelper.getError(error));
    });
}

export async function hasCatalogPage(
  store: ActionContext<CrudState, any>,
  options: ICrudOptions
): Promise<any> {
  await axios
    .get(`${BASE_URL}${options.resource}/${options.additional}`)
    .then((response: any) => {
      store.commit("SET_DATA_CATALOG_PAGE", response.data.data);
    })
    .catch((error: any) => {
      store.commit("SET_DATA_ERROR", ErrorHelper.getError(error));
    });
}

export default <ActionTree<State, any>>{
  findOne,
  findAll,
  search,
  paginate,
  create,
  update,
  del,
  resetList,
  resetCreate,
  resetUpdate,
  activeCatalog,
  setIconStatus,
  setActiveCatalog,
  setUserCatalogRequest,
  lastActiveCatalog,
  hasCatalogPage
};
