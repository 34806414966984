import { ROLES } from "@/config/Roles";
import auth from "@/middlewares/AuthMiddleware";
import i18n from "@/locales";
import BottleSizeView from "@/views/admin/bottle_size/BottleSizeView.vue";
import BottleSizeList from "@/views/admin/bottle_size/BottleSizeList.vue";
import BottleSizeCreate from "@/views/admin/bottle_size/BottleSizeCreate.vue";
import BottleSizeUpdate from "@/views/admin/bottle_size/BottleSizeUpdate.vue";

export default [
  {
    path: "/bottle-size",
    component: BottleSizeView,
    meta: {
      visibleInAside: true,
      roles: [ROLES.ADMIN, ROLES.SUPER_ADMIN],
      title: String(i18n.t("menu.bottle_size")),
      middleware: [auth]
    },
    children: [
      {
        path: "",
        name: "size_list",
        component: BottleSizeList,
        meta: {
          visibleInAside: true,
          title: "",
          roles: [ROLES.ADMIN, ROLES.SUPER_ADMIN],
          middleware: [auth]
        }
      },
      {
        path: "create",
        name: "size_create",
        component: BottleSizeCreate,
        meta: {
          visibleInAside: true,
          title: String(i18n.t("general.bottle_size_create")),
          roles: [ROLES.ADMIN, ROLES.SUPER_ADMIN],
          middleware: [auth],
          avoidBack: true
        }
      },
      {
        path: "edit/:id",
        name: "size_edit",
        component: BottleSizeUpdate,
        meta: {
          roles: [ROLES.ADMIN, ROLES.SUPER_ADMIN],
          title: String(i18n.t("general.edit_bottle_size")),
          middleware: [auth]
        }
      }
    ]
  }
];
