import { Component, Vue } from "vue-property-decorator";

@Component
export default class ScrollToTopMixin extends Vue {
  public scrollToTop() {
    const step: number = 15;
    const speed: number = 15; // lower = faster
    let intervalId: number;

    intervalId = setInterval(() => {
      if (window.pageYOffset === 0) {
        clearInterval(intervalId);
      }
      window.scroll(0, window.pageYOffset - step);
    }, speed);
  }
}
