
import { Component, Prop, Watch } from "vue-property-decorator";
import CrudLayout from "@/layouts/CrudLayout.vue";
import { Validations } from "vuelidate-property-decorators";
import { numeric, required } from "vuelidate/lib/validators";
import Validation from "@/components/Validation.vue";
import FormBackButton from "@/components/buttons/FormBackButton.vue";
import MultiSelect from "@/components/multiselect/MultiSelect.vue";
import { Action, Getter, namespace as vuexNamespace } from "vuex-class";
import AuthService from "@/service/AuthService";
import Permission from "@/service/Permission";
import { ROLES } from "@/config/Roles";
import axios from "axios";
import FileSaver from "file-saver";
import AuthImage from "@/directives/AuthImage";
import { pdfRequired, requiredIfValue } from "@/validators/validators";
import PageContentFormUpload from "@/views/admin/page/content/PageContentFormUpload.vue";
import ReleaseRadioButton from "@/components/buttons/ReleaseRadioButton.vue";
import { BaseMixin } from "@/mixins/baseMixin";

const namespace: string = "page";
const exhibitorNamespace: string = "exhibitor";
const standNamespace: string = "stand";
const pageTitleNamespace: string = "pagetitle";
const pageTitleResourceName: string = "page-title";
const NotificationModule = vuexNamespace("notification");
const catalogNamespace: string = "catalog";
const StatusModule = vuexNamespace("status");

@Component({
  components: {
    MultiSelect,
    FormBackButton,
    CrudLayout,
    Validation,
    PageContentFormUpload,
    ReleaseRadioButton
  },
  directives: {
    AuthImage: new AuthImage()
  }
})
export default class PageWineAdForm extends BaseMixin {
  public name: string = "PageWineAdForm";
  private resourceName = namespace;
  public uploadPercentage: number = 0;
  public imageUrl: string = "";
  public content_pdf: string = "";
  public showProgressBar: boolean = true;
  private readonly limitResult: number = 10000;
  public pageType: number | null = null;
  public released: any = null;
  public release_accepted: any = null;
  public exhibitorId: any = null;
  public standValue: any = null;
  public pageTitle: any = null;
  public companyName: any = null;
  public active: number = 1;
  public file: any = "";
  public standValueId: number = 0;
  public pageTitleId: number = 0;
  private exhibitorList: any[] = [];
  private standList: any[] = [];
  private pageTitleList: any[] = [];
  public disableButton: boolean = false;
  public typeAdd = "add";
  public typeEdit = "edit";
  protected comment: string = "";

  @Prop({ default: false })
  public loading: any;

  @Prop({ default: false })
  public isExhibitor?: boolean;

  @Prop({ default: "" })
  public title?: string;

  @Prop({ default: "add" })
  public type?: string;

  public id?: number;

  @Prop()
  public item: any;

  @Prop({
    default: function() {}
  })
  public resetAction: any;

  @Prop({
    default: function() {}
  })
  public saveAction: any;

  @Getter("getList", { namespace: exhibitorNamespace }) exhibitors: any;
  @Getter("getList", { namespace: standNamespace }) stands: any;
  @Getter("getList", { namespace: pageTitleNamespace }) pageTitles: any;
  @Getter("getActiveCatalog", { namespace: catalogNamespace }) catalog: any;

  @Action("resetList", { namespace: standNamespace }) resetStandAction: any;
  @Action("search", { namespace: exhibitorNamespace }) exhibitorAction: any;
  @Action("search", { namespace: standNamespace }) standAction: any;
  @Action("search", { namespace: pageTitleNamespace }) pageTitleAction: any;

  @StatusModule.Getter("getList")
  protected statusList: any;

  @NotificationModule.Action("setText")
  protected setNotification: any;

  @NotificationModule.Action("setActive")
  protected setNotificationActive: any;

  @Validations()
  protected validations() {
    if (this.type == this.typeAdd) {
      if (this.isAd) {
        return {
          active: { required, numeric },
          standValue: {},
          pageTitle: { required },
          companyName: { required }
        };
      }
      return {
        active: { required, numeric },
        standValue: {},
        pageTitle: { required },
        companyName: { required }
      };
    }

    // edit
    if (this.isAd) {
      return {
        active: { required, numeric },
        file: {
          pdfRequired: pdfRequired
        },
        standValue: { required },
        pageTitle: { required }
      };
    }

    return {
      active: { required, numeric },
      file: {
        requireIfValue: requiredIfValue("content_pdf", ""),
        pdfRequired: pdfRequired
      },
      standValue: {},
      pageTitle: { required }
    };
  }

  @Watch("companyName", { immediate: true, deep: true })
  protected companyNameWatcher(companyName: any) {
    this.standValue = null;
    if (this.type == this.typeAdd) {
      if (companyName == null) {
        this.standList = [];
      } else {
        this.standAction({
          resource: "stand-exhibitor",
          params: {
            currentPerPage: this.limitResult,
            currentPage: 1,
            sort: [{ field: "stand", type: "asc" }],
            columnFilters: {
              catalog_id: this.getCatalogId,
              user_id: companyName.id
            }
          }
        });
      }
    }
  }

  @Watch("exhibitors", { immediate: true, deep: true })
  protected exhibitorListWatcher(exhibitors: any[]) {
    this.exhibitorList = [];
    if (exhibitors.length > 0) {
      exhibitors.forEach((exhibitor: any) => {
        this.exhibitorList.push({
          id: exhibitor.id,
          label: exhibitor.company_name
        });
      });
    }
  }

  @Watch("pageTitles", { immediate: true, deep: true })
  protected pageTitlesListWatcher(pageTitles: any[]) {
    this.pageTitleList = [];
    if (pageTitles.length > 0) {
      pageTitles.forEach((pageTitle: any) => {
        this.pageTitleList.push({
          id: pageTitle.id,
          label: pageTitle.name
        });
      });

      this.$watch(
        "pageTitleId",
        value => {
          this.pageTitleList.forEach((pageTitle: any) => {
            if (pageTitle.id == value) {
              this.pageTitle = pageTitle;
            }
          });
        },
        { immediate: true, deep: true }
      );
    }
  }

  @Watch("stands", { immediate: true, deep: true })
  protected standListWatcher(stands: any[]) {
    this.standList = [];
    if (stands.length > 0) {
      stands.forEach((stand: any) => {
        this.standList.push({
          id: stand.id,
          label: stand.stand
        });
      });

      this.$watch(
        "standValueId",
        value => {
          this.standList.forEach((stand: any) => {
            if (stand.id == value) {
              this.standValue = stand;
            }
          });
        },
        { immediate: true, deep: true }
      );
    }
  }

  @Watch("item", { immediate: true, deep: true })
  protected onItemChange(item: any) {
    if (item) {
      this.init(item);
      this.notification(item.release_accepted);
    }
  }

  @Watch("statusList", { immediate: true, deep: true })
  protected onStatusListChange(list: any) {
    if (list) {
      this.imageUrl =
        process.env.VUE_APP_BACKEND_HOST +
        "/api/page-image/" +
        this.id +
        "?t=" +
        Date.now();

      if (this.type == this.typeEdit) {
        this.disableButtonDispatch(list);
      }
    }
  }

  protected get isAd(): boolean {
    if (this.type == this.typeAdd) {
      return Number(this.$router.currentRoute.params.id) == 2;
    }
    return this.pageType == 2;
  }

  public get releaseAccepted() {
    return Boolean(this.item && this.item.release_accepted == 1);
  }

  private init(page: any): void {
    this.$nextTick(() => {
      if (page.content_pdf == null || page.content_pdf == "null") {
        this.content_pdf = "";
      } else {
        this.content_pdf = page.content_pdf;
      }

      this.id = page.id;
      this.active = page.active;
      this.pageType = page.page_type;

      this.exhibitorId = page.exhibitor_id;
      this.standValueId = page.stand_id;
      this.pageTitleId = page.page_title_id;

      this.imageUrl =
        process.env.VUE_APP_BACKEND_HOST +
        "/api/page-image/" +
        this.id +
        "?t=" +
        Date.now();
      this.released = page.released;
      this.release_accepted = page.release_accepted;
      this.comment = page.comment;
      this.standAction({
        resource: "stand-exhibitor",
        params: {
          currentPerPage: this.limitResult,
          currentPage: 1,
          sort: [{ field: "stand", type: "asc" }],
          columnFilters: {
            catalog_id: this.getCatalogId,
            user_id: this.exhibitorId
          }
        }
      });
    });
  }

  private searchCompanyName(name: string) {
    name = name.trim();
    if (name) {
      this.exhibitorAction({
        resource: exhibitorNamespace,
        params: {
          currentPerPage: this.limitResult,
          currentPage: 1,
          sort: [{ field: "company_name", type: "asc" }],
          columnFilters: {
            catalog_id: this.getCatalogId,
            company_name: name
          }
        }
      });
    }
  }

  private searchStand(name: string) {
    name = name.trim();
    if (name && this.companyName !== null) {
      this.standAction({
        resource: "stand-exhibitor",
        params: {
          currentPerPage: this.limitResult,
          currentPage: 1,
          sort: [{ field: "stand", type: "asc" }],
          columnFilters: {
            stand: name,
            catalog_id: this.getCatalogId,
            user_id: this.companyName.id
          }
        }
      });
    }
  }

  public download() {
    const el: HTMLElement | null = document.getElementById("image-preview");

    if (el && el.getAttribute("valid") == "true") {
      if (this.preview) {
        axios
          .get(
            process.env.VUE_APP_BACKEND_HOST + "/api/page-download/" + this.id,
            { responseType: "arraybuffer" }
          )
          .then(response => {
            const file = new Blob([response.data], { type: "application/pdf" });
            FileSaver.saveAs(file, this.id + ".pdf");
          });
      }
    }
  }

  public get preview(): boolean {
    const authService: AuthService = new AuthService();

    if (this.type == this.typeEdit) {
      if (
        Permission.hasPermission(
          [ROLES.ADMIN, ROLES.SUPER_ADMIN],
          authService.getRole()
        )
      ) {
        return true;
      }

      if (this.item) {
        if (authService.getUserId() == this.item.exhibitor_id) {
          return true;
        }
      }
    }

    return false;
  }

  private searchPageTitle(name: string) {
    name = name.trim();
    if (name) {
      this.pageTitleAction({
        resourceName: exhibitorNamespace,
        resource: pageTitleResourceName,
        params: {
          currentPerPage: this.limitResult,
          currentPage: 1,
          sort: [{ field: "name", type: "asc" }],
          columnFilters: {
            name: name
          }
        }
      });
    }
  }

  public get getCatalogId(): string {
    if (this.$router.currentRoute.params.hasOwnProperty("catalog")) {
      return this.$router.currentRoute.params.catalog;
    }

    return this.catalog.id;
  }

  public created() {
    this.resetAction();
    this.resetStandAction();
    const catalogId = this.getCatalogId;
    this.exhibitorAction({
      resource: "exhibitor-in-catalog",
      params: {
        currentPerPage: this.limitResult,
        sort: [{ field: "company_name", type: "asc" }],
        currentPage: 1,
        columnFilters: {
          catalog_id: catalogId
        }
      }
    });
    this.pageTitleAction({
      resourceName: exhibitorNamespace,
      resource: pageTitleResourceName,
      params: {
        currentPerPage: this.limitResult,
        currentPage: 1,
        sort: [{ field: "name", type: "asc" }]
      }
    });
  }

  public onSubmit(): void {
    this.$v.$touch();
    if (this.$v.$invalid) {
      return;
    }

    if (this.type == this.typeAdd) {
      this.saveAction({
        catalog_id: this.getCatalogId,
        type: this.$router.currentRoute.params.id,
        stand_id: this.standValue ? this.standValue.id : null,
        user_id: this.companyName.id,
        active: this.active,
        page_title_id: this.pageTitle ? this.pageTitle.id : null
      });
    } else {
      const cleanRelease =
        this.release_accepted == null ? "" : this.release_accepted;
      const formData = new FormData();
      formData.append("file", this.file);
      formData.append("active", String(this.active));
      formData.append("id", String(this.id));
      formData.append(
        "stand_id",
        String(this.standValue ? this.standValue.id : null)
      );
      formData.append(
        "page_title_id",
        String(this.pageTitle ? this.pageTitle.id : null)
      );
      formData.append("release_accepted", String(cleanRelease));
      formData.append("comment", this.comment);

      this.saveAction({
        resource: "catalog/update-ad",
        data: formData,
        id: this.id,
        disabledLoading: true,
        descriptionField: "content_pdf",
        config: {
          headers: {
            "Content-Type": "multipart/form-data"
          },
          onUploadProgress: (progressEvent: any) => {
            if (formData.get("file") instanceof File) {
              this.uploadPercentage = Number(
                Math.round((progressEvent.loaded / progressEvent.total) * 100)
              );
            }
          }
        }
      })
        .then((response: any) => {
          this.uploadPercentage = 0;
        })
        .catch(() => {
          this.uploadPercentage = 0;
        });
    }
  }

  private reset() {
    this.standValue = null;
    this.companyName = null;
    this.pageTitle = null;
    this.resetAction();
  }

  public get fileExtension(): string {
    if (this.file) {
      return this.file.name.split(".").pop();
    }

    return "";
  }

  private fileHandler(value: any) {
    this.file = value;
  }

  beforeDestroy() {
    this.reset();
  }

  public notification(state: number): void {
    if (state == 0) {
      this.setNotification({
        text: this.$t("general.page_release_rejected"),
        theme: "danger"
      });
    } else {
      this.setNotificationActive(false);
    }
  }

  public destroyed() {
    this.setNotificationActive(false);
  }

  protected disableButtonDispatch(list: Array<Object>) {
    const status = this.getStatus(list, Number(this.id));
    const statusIsReady = status && status.ready;
    this.disableButton = statusIsReady == false;
  }

  protected get statusOnReady() {
    return this.item && this.item.status && this.item.status.ready;
  }

  protected get buttonDisabled() {
    return this.statusOnReady == 0 || this.disableButton;
  }

  protected get standIsRequired() {
    return this.type == this.typeEdit && this.isAd;
  }

  protected get isReleased(): boolean {
    return this.released == 1;
  }
}
