
import { Component, Prop, Vue } from "vue-property-decorator";
import CrudLayout from "@/layouts/CrudLayout.vue";
import { Validate } from "vuelidate-property-decorators";
import { required, requiredIf } from "vuelidate/lib/validators";
import Validation from "@/components/Validation.vue";
import Upload from "@/components/Upload.vue";
import Dropdown from "@/components/Dropdown.vue";
import { pdfRequired } from "@/validators/validators";
import MultiSelect from "@/components/multiselect/MultiSelect.vue";

const namespace: string = "logo";

@Component({
  components: {
    Dropdown,
    Upload,
    CrudLayout,
    Validation,
    MultiSelect
  }
})
export default class LogoForm extends Vue {
  // Component initial values
  public name: string = "LogoForm";

  private resourceName = "logo";

  // props
  @Prop({ default: "add" })
  public type: any;

  @Prop({ default: false })
  public saved: any;

  @Prop({ default: false })
  public loading: any;

  @Prop({ default: "" })
  public error: any;

  @Prop({ default: {} })
  public saveAction: any;

  @Prop({ default: {} })
  public back: any;

  @Prop()
  public item: any;

  @Prop({ type: Function, required: false })
  public searchExhibitor: any;

  @Prop({ type: Array, required: false })
  public exhibitors: any;

  @Prop({ type: Boolean, required: false, default: false })
  protected showExhibitors: any;

  // true - with verification, false - direct
  public withVerification: boolean = true;

  public uploadPercentage: number = 0;

  public showProgressBar: boolean = false;

  @Validate({
    required: requiredIf(function(component) {
      return component.showExhibitors == true;
    })
  })
  public exhibitor: any = null;

  @Validate({ required, pdfRequired: pdfRequired })
  public file: string = "";

  protected uploadType: Array<Object> = [
    {
      value: true,
      name: "Mit Überprüfung"
    },
    {
      value: false,
      name: "Direct"
    }
  ];

  public handleFile(file: any) {
    this.file = file;
  }

  private withVerificationHandler(value: boolean) {
    this.withVerification = value;
  }

  public onSubmit(e: Event): void {
    this.$v.$touch();
    if (this.$v.$invalid) {
      return;
    }

    let formData = new FormData();
    formData.append("file", this.file);
    formData.append("withVerification", String(this.withVerification));
    if (this.exhibitor && this.exhibitor.id) {
      formData.append("user_id", String(this.exhibitor.id));
    }

    this.showProgressBar = true;

    // Add
    this.saveAction({
      resource: this.resourceName,
      data: formData,
      disabledLoading: true,
      descriptionField: "name",
      config: {
        headers: {
          "Content-Type": "multipart/form-data"
        },
        onUploadProgress: (progressEvent: any) => {
          this.uploadPercentage = Number(
            Math.round((progressEvent.loaded / progressEvent.total) * 100)
          );
        }
      }
    })
      .then((response: any) => {
        this.showProgressBar = false;
        this.uploadPercentage = 0;
      })
      .catch((error: any) => {
        this.showProgressBar = false;
        this.uploadPercentage = 0;
      });
  }
}
