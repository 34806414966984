import { ROLES } from "@/config/Roles";
import auth from "@/middlewares/AuthMiddleware";
import i18n from "@/locales";
import RequestUpdate from "@/views/admin/request/RequestUpdate.vue";
import RequestList from "@/views/admin/request/RequestList.vue";
import RequestView from "@/views/admin/request/RequestView.vue";

export default [
  {
    path: "/admin-request",
    component: RequestView,
    meta: {
      visibleInAside: true,
      roles: [ROLES.ADMIN, ROLES.SUPER_ADMIN],
      title: String(i18n.t("menu.request")),
      middleware: [auth],
      avoidBack: true
    },
    children: [
      {
        path: "",
        name: "AdminRequest",
        component: RequestList,
        meta: {
          visibleInAside: true,
          title: "",
          roles: [ROLES.ADMIN, ROLES.SUPER_ADMIN],
          middleware: [auth]
        }
      },
      {
        path: "edit/:id",
        name: "AdminRequestEdit",
        component: RequestUpdate,
        meta: {
          roles: [ROLES.ADMIN, ROLES.SUPER_ADMIN],
          title: (route: any, data: any) => {
            if (data && data.hasOwnProperty("requestUpdateTitle")) {
              return String(data.requestUpdateTitle);
            }
            return String(i18n.t("general.request_edit"));
          },
          middleware: [auth]
        }
      }
    ]
  }
];
