
import { Component, Vue, Watch } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import CrudLayout from "@/layouts/CrudLayout.vue";
import Validation from "@/components/Validation.vue";
import Breadcrumb from "@/components/Breadcrumb.vue";
import IBreadcrumbItem from "@/interfaces/IBreadcrumbItem";
import i18n from "@/locales/index";
import StandForm from "@/views/admin/stand/StandForm.vue";
import FormCreate from "@/views/form/create/FormCreate.vue";

const namespace: string = "stand";

@Component({
  components: {
    CrudLayout,
    Validation,
    StandForm
  },
  extends: FormCreate
})
export default class StandCreate extends Vue {
  protected name: string = "StandCreate";
  protected resourceName = "stand";

  @Getter("getError", { namespace }) error: any;
  @Getter("isLoading", { namespace }) loading: any;
  @Getter("isCreated", { namespace }) created: any;
  @Getter("getItem", { namespace }) createdItem: any;

  @Action("create", { namespace }) createItemAction: any;
  @Action("resetCreate", { namespace }) resetAction: any;

  beforeDestroy() {
    this.resetAction();
  }
}
