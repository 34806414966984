
import { Vue, Component, Prop } from "vue-property-decorator";
import { Validations } from "vuelidate-property-decorators";
import { required, sameAs, minLength } from "vuelidate/lib/validators";
import Validation from "@/components/Validation.vue";

@Component({
  components: {
    Validation
  }
})
export default class ResetPasswordForm extends Vue {
  public name: string = "ResetPasswordForm";

  @Prop({ required: false, default: false })
  public reset!: boolean;

  protected showErrors = true;

  protected password: string = "";
  protected passwordConfirmation: string = "";

  @Validations()
  validations() {
    let fields = {};
    fields = Object.assign({}, fields, {
      password: { required, minLength: minLength(6) },
      passwordConfirmation: {
        required,
        password_repeat: sameAs("password")
      }
    });
    return fields;
  }

  public onSubmit(): void {
    this.$v.$touch();
    this.showErrors = true;
    if (this.$v.$invalid) {
      return;
    }

    this.$emit("onSubmit", {
      password: this.password,
      passwordConfirmation: this.passwordConfirmation
    });

    this.showErrors = false;

    if (this.reset) {
      this.password = "";
      this.passwordConfirmation = "";
    }
  }
}
