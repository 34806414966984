import { Module } from "vuex";
import { State } from "@/store/modules/region/state";
import Mutations from "@/store/modules/region/mutations";
import Getters from "@/store/modules/region/getters";
import Actions from "@/store/modules/region/actions";

export class Region implements Module<State, any> {
  namespaced: boolean = true;
  state: State;
  mutations = Mutations;
  getters = Getters;
  actions = Actions;

  // Setup CRUD operations
  constructor() {
    this.state = new State();
  }
}
