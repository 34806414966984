
import { Component, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import CrudLayout from "@/layouts/CrudLayout.vue";
import Validation from "@/components/Validation.vue";
import ExhibitorForm from "./form/ExhibitorForm.vue";
import FormCreate from "@/views/form/create/FormCreate.vue";
import AuthService from "@/service/AuthService";
import Permission from "@/service/Permission";
import { ROLES } from "@/config/Roles";

const namespace: string = "exhibitor";
const breadcrumbNamespace: string = "breadcrumb";
const catalogNamespace: string = "catalog";

@Component({
  components: {
    CrudLayout,
    Validation,
    ExhibitorForm
  },
  extends: FormCreate
})
export default class ExhibitorUpdate extends Vue {
  protected name: string = "ExhibitorUpdate";
  protected resourceName = "exhibitor";
  protected profileUpdate: boolean = false;
  protected isExhibitor: boolean = false;

  @Getter("getError", { namespace }) error: any;
  @Getter("isLoading", { namespace }) loading: any;
  @Getter("isUpdated", { namespace }) updated: any;
  @Getter("getItem", { namespace }) item: any;

  @Action("update", { namespace }) updateItemAction: any;
  @Action("findOne", { namespace }) getItemAction: any;
  @Action("resetUpdate", { namespace }) resetAction: any;

  @Action("mergeData", { namespace: breadcrumbNamespace })
  protected setBreadcrumb: any;
  @Getter("getItem", { namespace: catalogNamespace })
  protected catalog: any;
  @Action("findOne", { namespace: catalogNamespace })
  protected getCatalog: any;

  protected back() {
    this.$router.push({ path: "/" + this.resourceName });
  }

  beforeMount() {
    if (this.$route.params.id == undefined) {
      this.profileUpdate = true;
    }

    const authService = new AuthService();

    this.getItemAction({
      resource: this.resourceName,
      id: this.profileUpdate
        ? authService.getUserId()
        : Number.parseInt(this.$route.params.id)
    });
  }

  beforeDestroy() {
    this.resetAction();
  }

  public async mounted() {
    const { catalog } = this.$route.params;
    if (catalog) {
      await this.getCatalog({
        resource: "catalog",
        id: Number(catalog)
      });
      const catalogObject = this.catalog;
      const catalogExhibitorListTitle =
        String(this.$t("general.exhibitor")) +
        " " +
        String(this.$t("general.of")) +
        " " +
        catalogObject.name;

      this.setBreadcrumb({
        [String(this.$router.currentRoute.name)]: {
          catalogExhibitorListTitle
        }
      });
    }
  }

  public created() {
    const authService: AuthService = new AuthService();
    if (Permission.hasPermission([ROLES.EXHIBITOR], authService.getRole())) {
      this.isExhibitor = true;
    }
  }
}
