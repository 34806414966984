
import { TransitionMixin } from "@/mixins/transitionMixin";
import { Component, Vue } from "vue-property-decorator";

@Component({
  mixins: [TransitionMixin]
})
export default class CollapseTransition extends Vue {
  public name: string = "CollapseTransition";

  public transitionStyle(duration = 300): any {
    let durationInSeconds = duration / 1000;
    return `${durationInSeconds}s height ease-in-out, ${durationInSeconds}s padding-top ease-in-out, ${durationInSeconds}s padding-bottom ease-in-out`;
  }

  public beforeEnter(el: any): void {
    // @ts-ignore
    let enterDuration = this.duration.enter
      ? this.duration.enter
      : this.duration;
    el.style.transition = this.transitionStyle(enterDuration);
    if (!el.dataset) el.dataset = {};
    el.dataset.oldPaddingTop = el.style.paddingTop;
    el.dataset.oldPaddingBottom = el.style.paddingBottom;
    el.style.height = "0";
    el.style.paddingTop = 0;
    el.style.paddingBottom = 0;
    this.setStyles(el);
  }

  public enter(el: any): void {
    el.dataset.oldOverflow = el.style.overflow;
    if (el.scrollHeight !== 0) {
      el.style.height = el.scrollHeight + "px";
      el.style.paddingTop = el.dataset.oldPaddingTop;
      el.style.paddingBottom = el.dataset.oldPaddingBottom;
    } else {
      el.style.height = "";
      el.style.paddingTop = el.dataset.oldPaddingTop;
      el.style.paddingBottom = el.dataset.oldPaddingBottom;
    }
    el.style.overflow = "hidden";
  }

  public afterEnter(el: any): void {
    // For safari: remove class then reset height is necessary
    el.style.transition = "";
    el.style.height = "";
    el.style.overflow = el.dataset.oldOverflow;
  }

  public beforeLeave(el: any): void {
    if (!el.dataset) el.dataset = {};
    el.dataset.oldPaddingTop = el.style.paddingTop;
    el.dataset.oldPaddingBottom = el.style.paddingBottom;
    el.dataset.oldOverflow = el.style.overflow;
    el.style.height = el.scrollHeight + "px";
    el.style.overflow = "hidden";
    this.setStyles(el);
  }

  public leave(el: any): void {
    // @ts-ignore
    let leaveDuration = this.duration.leave
      ? this.duration.leave
      : this.duration;
    if (el.scrollHeight !== 0) {
      // For safari: add class after set height, or it will jump to zero height suddenly, weired
      el.style.transition = this.transitionStyle(leaveDuration);
      el.style.height = 0;
      el.style.paddingTop = 0;
      el.style.paddingBottom = 0;
    }
    // Necessary for transition-group
    this.setAbsolutePosition(el);
  }

  public afterLeave(el: any): void {
    el.style.transition = "";
    el.style.height = "";
    el.style.overflow = el.dataset.oldOverflow;
    el.style.paddingTop = el.dataset.oldPaddingTop;
    el.style.paddingBottom = el.dataset.oldPaddingBottom;
  }

  public leaveCancelled(el: any): void {
    el.style.transition = "";
    el.style.height = "";
    el.style.overflow = el.dataset.oldOverflow;
    el.style.paddingTop = el.dataset.oldPaddingTop;
    el.style.paddingBottom = el.dataset.oldPaddingBottom;
  }

  public enterCancelled(el: any): void {
    // console.log(el);
  }
}
