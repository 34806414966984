import axios from "axios";

if (!axios) {
  throw new Error("[vue-auth-image] cannot locate Axios");
}

function setImgSrc(el: HTMLElement, binding: any, vnode: any) {
  if (binding.oldValue === undefined || binding.value !== binding.oldValue) {
    const imageUrl = binding.value;

    axios({
      method: "get",
      url: imageUrl,
      responseType: "arraybuffer"
    })
      .then(function(response: any) {
        el.setAttribute("valid", "true");
        const mimeType = response.headers["content-type"].toLowerCase();
        // @ts-ignore
        const imgBase64 = new Buffer(response.data, "binary").toString(
          "base64"
        );
        // @ts-ignore
        el.src = "data:" + mimeType + ";base64," + imgBase64;
        if (binding.expression && vnode.context[binding.expression]) {
          const success: string | null = el.getAttribute("success");
          if (success && typeof vnode.context[success] == "function") {
            vnode.context[success]();
          }
        }
      })
      .catch(() => {
        el.setAttribute("valid", "false");
        // @ts-ignore
        el.src = process.env.VUE_APP_BACKEND_HOST + "/img/placeholder.png";

        if (binding.expression && vnode.context[binding.expression]) {
          const fail: string | null = el.getAttribute("fail");
          if (fail && typeof vnode.context[fail] == "function") {
            vnode.context[fail]();
          }
        }
      });
  }
}

export default class AuthImage {
  public bind(el: HTMLElement, binding: any, vnode: any) {
    setImgSrc(el, binding, vnode);
  }

  public componentUpdated(el: HTMLElement, binding: any, vnode: any) {
    setImgSrc(el, binding, vnode);
  }
}
