
import { Component, Prop, Vue } from "vue-property-decorator";
import Validation from "@/components/Validation.vue";
import Upload from "@/components/Upload.vue";

@Component({
  components: {
    Validation,
    Upload
  }
})
export default class WineFormUpload extends Vue {
  @Prop({})
  public v?: any;

  @Prop({})
  public fileExtension?: any;

  @Prop({ default: false })
  public disabled!: any;

  public handleFile(file: any) {
    this.$emit("file", file);
  }
}
