import router from "@/router";

export const onMaintenance = async () => {
  const jsonObject = require("@/storage/maintenance.json");
  const currentRoute = router.currentRoute.name;
  const maintenanceModeRouteName = "MaintenanceMode";

  if (jsonObject.maintenance && !(currentRoute == maintenanceModeRouteName)) {
    await router.push({ name: maintenanceModeRouteName });
  } else if (
    !jsonObject.maintenance &&
    currentRoute == maintenanceModeRouteName
  ) {
    await router.push({ path: "/" });
  }
};
