
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import ImportPageItem from "@/components/import/ImportPageItem.vue";
import ClickOutside from "@/directives/ClickOutside";
import { namespace } from "vuex-class";

const PageModule = namespace("page");

@Component({
  directives: {
    clickOutside: new ClickOutside()
  },
  components: {
    ImportPageItem
  }
})
export default class ImportPage extends Vue {
  public name: string = "ImportPage";

  protected resourceName: string = "pages-import";

  protected modalId: string = "import-page";

  protected active: boolean = false;

  protected selectedPage: any = {};

  @Prop({ default: 0, type: Number, required: true })
  public pageId: any;

  @PageModule.Action("getPages")
  protected getPages: any;

  @PageModule.Getter("getList")
  protected pages: any;

  @PageModule.Getter("isUpdated")
  protected isUpdated: any;

  @PageModule.Action("importPage")
  protected importPage: any;

  @PageModule.Getter("isImported")
  protected isImported: any;

  @PageModule.Mutation("SET_DATA_IMPORTED")
  protected isImportedMutation: any;

  @Watch("isUpdated", { immediate: true, deep: true })
  protected async isUpdatedWatcher() {
    await this.getPagesDispatch();
  }

  protected toggleDropdownMenu(): void {
    this.active = !this.active;
  }

  protected onOutsideClick(): void {
    if (this.active) this.active = false;
  }

  protected get hasPages() {
    return Boolean(this.pages.length > 0);
  }

  protected async dispatchImport(): Promise<any> {
    this.$bvModal.hide(this.modalId);
    await this.importPage({
      resource: this.resourceName,
      descriptionField: "description",
      additional: "import",
      data: {
        replacePageId: this.pageId,
        importPageId: this.selectedPage.id
      }
    });
  }

  protected importPageItem(data: Object) {
    this.selectedPage = data;
    this.$bvModal.show(this.modalId);
  }

  protected async getPagesDispatch() {
    await this.getPages({
      resource: this.resourceName,
      id: this.pageId,
      descriptionField: "description"
    });
    this.isImportedMutation({
      isImported: false,
      importPageId: 0,
      replacePageId: 0
    });
  }

  public async mounted() {
    await this.getPagesDispatch();
  }
}
