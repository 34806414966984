import * as date from "./date";
import * as decimal from "./decimal";
import * as number from "./number";
import * as percentage from "./percentage";
import * as boolean from "./boolean";

export default {
  date,
  decimal,
  number,
  percentage,
  boolean
};
