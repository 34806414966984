
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import CrudLayout from "@/layouts/CrudLayout.vue";
import { Validate } from "vuelidate-property-decorators";
import {
  maxValue,
  minLength,
  minValue,
  required
} from "vuelidate/lib/validators";
import Validation from "@/components/Validation.vue";
import IAward from "@/models/IAward";
import Dropdown from "@/components/Dropdown.vue";
import i18n from "@/locales";
import FormBackButton from "@/components/buttons/FormBackButton.vue";
import SortService from "@/service/SortService";

const namespace: string = "award";

@Component({
  components: {
    FormBackButton,
    Dropdown,
    CrudLayout,
    Validation
  }
})
export default class AwardForm extends Vue {
  // Component initial values
  public name: string = "AwardForm";

  private resourceName = "award";

  // props
  @Prop({ default: "add" })
  public type: any;

  @Prop({ default: false })
  public saved: any;

  @Prop({ default: false })
  public loading: any;

  @Prop({ default: "" })
  public error: any;

  @Prop({ default: {} })
  public saveAction: any;

  @Prop({ default: {} })
  public back: any;

  @Prop()
  public item: any;

  // model
  @Validate({ required, minLength: minLength(4) })
  public award: string = "";

  @Validate({ required })
  public symbol: string = "";

  @Watch("item", { immediate: true, deep: true })
  protected onItemChange(item: any) {
    if (item) {
      this.init(item);
    }
  }

  private symbols: Array<Object> = [
    { value: "v", name: "❖" },
    { value: "n", name: "■" },
    { value: "l", name: "●" },
    { value: "m", name: "❍" },
    { value: "z", name: "❚" },
    { value: "r", name: "❒" },
    { value: "d", name: "❄" },
    { value: "k", name: "❋" },
    { value: "a", name: "❁" },
    { value: "b", name: "❂" }
  ];

  private get getSymbols(): any[] {
    return SortService.arrayAsc(this.symbols, "value");
  }

  private init(award: IAward): void {
    this.$nextTick(() => {
      this.award = award.award;
      this.symbol = award.symbol;
    });
  }

  private selectSymbol(value: any) {
    this.symbol = value;
  }

  public onSubmit(e: Event): void {
    this.$v.$touch();
    if (this.$v.$invalid) {
      return;
    }
    const award: IAward = {
      award: this.award,
      symbol: this.symbol,
      color: "",
      is_active: true
    };

    if (this.$route.params.id) {
      // Edit
      award.id = Number.parseInt(this.$route.params.id);
      this.saveAction({
        resource: this.resourceName,
        data: award,
        id: award.id,
        descriptionField: "award"
      });
    } else {
      // Add
      this.saveAction({
        resource: this.resourceName,
        data: award,
        descriptionField: "award"
      });
    }
  }
}
