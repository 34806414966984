import axios from "axios";
import AppConfig from "@/config/AppConfig";
import { ICrudOptions } from "@/service/interfaces/ICrudOptions";

/**
 *Basic CRUD Service
 */

export default class CrudService {
  public readonly BASE_URL = AppConfig.ENTRYPOINT + "/api/";

  /**
   *
   * @param options
   */
  public async findOne(options: ICrudOptions): Promise<any> {
    return axios.get(this.BASE_URL + options.resource + "/" + options.id);
  }

  /**
   *
   * @param options
   */
  public async findAll(options: ICrudOptions): Promise<any> {
    let url = this.BASE_URL + options.resource;

    if (options.params) {
      url +=
        "/" +
        options.params.currentPerPage +
        "/" +
        options.params.currentPage +
        "?";

      if (options.params.search) {
        url += "&search=" + JSON.stringify(options.params.search);
      }
      if (options.params.columnFilters) {
        url += "&columnFilters=" + JSON.stringify(options.params.columnFilters);
      }
      if (options.params.sort) {
        url += "&sort=" + JSON.stringify(options.params.sort);
      }
      if (options.params.userId) {
        url += "&userId=" + JSON.stringify(options.params.userId);
      }
      console.debug("CrudService.findAll", options);
    }

    console.debug("CrudService.findAll 2", options);

    if (options.query) {
      url += options.params ? "" : "?";

      Object.keys(options.query).forEach((queryProp: any) => {
        url += "&" + queryProp + "=" + options.query[queryProp];
      });
    }

    return axios.get(url);
  }

  /**
   *
   * @param options
   */
  public async search(options: ICrudOptions): Promise<any> {
    let url =
      this.BASE_URL +
      options.resource +
      "/" +
      options.params.currentPerPage +
      "/" +
      options.params.currentPage +
      "?";
    if (options.params.search) {
      url += "&search=" + JSON.stringify(options.params.search);
    }
    if (options.params.columnFilters) {
      url += "&columnFilters=" + JSON.stringify(options.params.columnFilters);
    }
    if (options.params.sort) {
      url += "&sort=" + JSON.stringify(options.params.sort);
    }
    if (options.params.userId) {
      url += "&userId=" + JSON.stringify(options.params.userId);
    }
    if (options.params.custom) {
      url += "&custom=" + JSON.stringify(options.params.custom);
    }

    return axios.get(url);
  }

  /**
   *
   * @param options
   */
  public async paginate(options: ICrudOptions): Promise<any> {
    return axios.get(
      this.BASE_URL +
        options.resource +
        "/" +
        options.params.limit +
        "/" +
        options.params.offset
    );
  }

  /**
   *
   * @param options
   */
  public async create(options: ICrudOptions): Promise<any> {
    return axios.post(
      this.BASE_URL + options.resource,
      options.data,
      options.config
    );
  }

  /**
   *
   * @param options
   */
  public async update(options: ICrudOptions): Promise<any> {
    return axios.put(
      this.BASE_URL + options.resource + "/" + options.id,
      options.data,
      options.config
    );
  }

  public updatePost(options: ICrudOptions): Promise<any> {
    let url: string = "";

    if (options.profileUpdate) {
      url = this.BASE_URL + options.resource + "/update-profile/" + options.id;
    } else {
      url = this.BASE_URL + options.resource + "/" + options.id;
    }

    return axios.post(url, options.data, options.config);
  }

  /**
   *
   * @param options
   */
  public async delete(options: ICrudOptions): Promise<any> {
    return axios.delete(this.BASE_URL + options.resource + "/" + options.id);
  }

  /**
   * Swap the positions of two entities
   * @param options
   */
  public async swapPositions(options: ICrudOptions): Promise<any> {
    return axios.put(
      this.BASE_URL +
        options.resource +
        "/swap-positions/" +
        options.params.id1 +
        "/" +
        options.params.id2,
      options.data,
      options.config
    );
  }
}
