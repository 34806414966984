import { ActionContext, ActionTree } from "vuex";
import { State } from "./state";
import {
  create,
  del,
  findAll,
  findOne,
  paginate,
  resetCreate,
  resetList,
  resetUpdate,
  search,
  update
} from "@/store/modules/crud/functions/actions";
import { swapPositions } from "@/store/modules/crud/functions/positions/actions";
import { ICrudOptions } from "@/service/interfaces/ICrudOptions";
import CrudActions from "@/store/modules/crud/classes/CrudActions";
import axios from "axios";
import AppConfig from "@/config/AppConfig";
import i18n from "@/locales";
import ErrorHelper from "@/utils/error/ErrorHelper";

const crudActions: CrudActions = new CrudActions();
const BASE_URL: string = AppConfig.ENTRYPOINT + "/api/";

export async function findByPage(
  store: ActionContext<any, any>,
  options: ICrudOptions
): Promise<any> {
  store.commit("SET_DATA_LOADING", true);
  crudActions.dispatchLoading(store, true);
  const request: Promise<any> = axios.get(
    BASE_URL + options.resource + "/by-page/" + options.params.page_id
  );
  request
    .then((response: any) => {
      crudActions.resolveReadMultipleAction(store, response, options);
    })
    .catch(error => {
      crudActions.rejectReadAction(store, error, options);
    });

  return request;
}

export async function findApprovedByPage(
  store: ActionContext<any, any>,
  options: ICrudOptions
): Promise<any> {
  store.commit("SET_DATA_LOADING", true);
  crudActions.dispatchLoading(store, true);
  const request: Promise<any> = axios.get(
    BASE_URL + options.resource + "/approved-by-page/" + options.params.page_id
  );
  request
    .then((response: any) => {
      crudActions.resolveReadMultipleAction(store, response, options);
    })
    .catch(error => {
      crudActions.rejectReadAction(store, error, options);
    });

  return request;
}

export async function addWine(
  store: ActionContext<any, any>,
  options: ICrudOptions
): Promise<any> {
  store.commit("SET_DATA_LOADING", true);
  store.commit("SET_DATA_UPDATED", false);
  crudActions.dispatchLoading(store, true);
  const request: Promise<any> = axios.post(
    BASE_URL +
      options.resource +
      "/add-wine/" +
      options.params.category_id +
      "/" +
      options.params.wine_id
  );
  request
    .then((response: any) => {
      store.commit("SET_DATA_UPDATED", true);
      crudActions.resolveWriteAction(
        store,
        response,
        String(
          i18n.t("general.item_created_success", {
            item: options.params.wine_name
          })
        ),
        response.data.data,
        options
      );
    })
    .catch(error => {
      store.commit("SET_DATA_UPDATED", false);
      crudActions.rejectWriteAction(store, error, options);
    });

  return request;
}

export async function removeWine(
  store: ActionContext<any, any>,
  options: ICrudOptions
): Promise<any> {
  store.commit("SET_DATA_LOADING", true);
  store.commit("SET_DATA_UPDATED", false);
  crudActions.dispatchLoading(store, true);
  const request: Promise<any> = axios.post(
    BASE_URL +
      options.resource +
      "/remove-wine/" +
      options.params.category_id +
      "/" +
      options.params.wine_id
  );
  request
    .then((response: any) => {
      store.commit("SET_DATA_UPDATED", true);
      crudActions.resolveWriteAction(
        store,
        response,
        String(
          i18n.t("general.item_deleted_successfully", {
            resourceName: options.params.wine_name
          })
        ),
        response.data.data,
        options
      );
    })
    .catch(error => {
      store.commit("SET_DATA_UPDATED", false);
      crudActions.rejectWriteAction(store, error, options);
    });

  return request;
}

export async function swapWinePositions(
  store: ActionContext<any, any>,
  options: ICrudOptions
): Promise<any> {
  crudActions.dispatchLoading(store, true);
  store.commit("SET_DATA_LOADING", true);
  store.commit("SET_DATA_POSITION_SWAPPED", false);
  const request: Promise<any> = axios.put(
    BASE_URL +
      options.resource +
      "/swap-wine-positions/" +
      options.params.wine_id_1 +
      "/" +
      options.params.wine_id_2 +
      "/" +
      options.params.category_id_1 +
      "/" +
      options.params.category_id_2
  );
  request
    .then((response: any) => {
      crudActions.dispatchSuccessMessage(
        store,
        String(i18n.t("general.positions_updated"))
      );
      store.commit("SET_DATA_ERROR", null);
      store.commit("SET_DATA_LOADING", false);
      store.commit("SET_DATA_POSITION_SWAPPED", true);
      crudActions.dispatchLoading(store, false);
    })
    .catch(error => {
      crudActions.dispatchLoading(store, false);
      crudActions.dispatchErrorMessage(store, ErrorHelper.getError(error));
      store.commit("SET_DATA_ERROR", ErrorHelper.getError(error));
      store.commit("SET_DATA_LOADING", false);
      store.commit("SET_DATA_POSITION_SWAPPED", false);
      crudActions.dispatchLoading(store, false);
    });

  return request;
}

export async function moveWineToPositionOf(
  store: ActionContext<any, any>,
  options: ICrudOptions
): Promise<any> {
  crudActions.dispatchLoading(store, true);
  store.commit("SET_DATA_LOADING", true);
  store.commit("SET_DATA_POSITION_SWAPPED", false);
  const request: Promise<any> = axios.put(
    BASE_URL +
      options.resource +
      "/move-wine-to-position-of/" +
      options.params.wine_id_1 +
      "/" +
      options.params.category_id_1 +
      "/" +
      options.params.wine_id_2 +
      "/" +
      options.params.category_id_2
  );
  request
    .then((response: any) => {
      crudActions.dispatchSuccessMessage(
        store,
        String(i18n.t("general.positions_updated"))
      );
      store.commit("SET_DATA_ERROR", null);
      store.commit("SET_DATA_LOADING", false);
      store.commit("SET_DATA_POSITION_SWAPPED", true);
      crudActions.dispatchLoading(store, false);
    })
    .catch(error => {
      crudActions.dispatchLoading(store, false);
      crudActions.dispatchErrorMessage(store, ErrorHelper.getError(error));
      store.commit("SET_DATA_ERROR", ErrorHelper.getError(error));
      store.commit("SET_DATA_LOADING", false);
      store.commit("SET_DATA_POSITION_SWAPPED", false);
      crudActions.dispatchLoading(store, false);
    });

  return request;
}

export async function swapWineCategory(
  store: ActionContext<any, any>,
  options: ICrudOptions
): Promise<any> {
  crudActions.dispatchLoading(store, true);
  store.commit("SET_DATA_LOADING", true);
  store.commit("SET_DATA_POSITION_SWAPPED", false);
  const request: Promise<any> = axios.put(
    BASE_URL +
      options.resource +
      "/swap-wine-category/" +
      options.params.wine_id_1 +
      "/" +
      options.params.category_id_1 +
      "/" +
      options.params.category_id_2
  );
  request
    .then((response: any) => {
      crudActions.dispatchSuccessMessage(
        store,
        String(i18n.t("general.positions_updated"))
      );
      store.commit("SET_DATA_ERROR", null);
      store.commit("SET_DATA_LOADING", false);
      store.commit("SET_DATA_POSITION_SWAPPED", true);
      crudActions.dispatchLoading(store, false);
    })
    .catch(error => {
      crudActions.dispatchLoading(store, false);
      crudActions.dispatchErrorMessage(store, ErrorHelper.getError(error));
      store.commit("SET_DATA_ERROR", ErrorHelper.getError(error));
      store.commit("SET_DATA_LOADING", false);
      store.commit("SET_DATA_POSITION_SWAPPED", false);
      crudActions.dispatchLoading(store, false);
    });

  return request;
}

export async function removeWines(
  store: ActionContext<any, any>,
  options: ICrudOptions
): Promise<any> {
  axios
    .post(`${BASE_URL}${options.resource}/${options.additional}`, options.data)
    .then((response: any) => {
      store.commit("SET_DATA_ERROR", null);
      store.commit("SET_DATA_UPDATED", true);
    })
    .catch(error => {
      crudActions.rejectWriteAction(store, error, options);
    });
}

export default <ActionTree<State, any>>{
  findOne,
  findAll,
  search,
  paginate,
  create,
  update,
  del,
  resetList,
  resetCreate,
  resetUpdate,
  findByPage,
  findApprovedByPage,
  swapPositions,
  addWine,
  removeWine,
  swapWinePositions,
  moveWineToPositionOf,
  swapWineCategory,
  removeWines
};
