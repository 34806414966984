import Vue from "vue";
import VueRouter from "vue-router";
import auth from "@/router/auth";
import AuthenticatedMiddleware from "@/middlewares/VueRouterMiddleware";
import admin from "@/router/admin/index";
import superadmin from "@/router/superadmin/index";
import exhibitor from "@/router/exhibitor/index";
import others from "@/router/others";
import notfound from "@/router/notfound";

Vue.use(VueRouter);

export const routes = [
  ...others,
  ...auth,
  ...admin,
  ...superadmin,
  ...exhibitor,
  ...notfound
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: function(to, from, savedPosition) {
    if (to.hash) {
      return { selector: to.hash };
    } else {
      return { x: 0, y: 0 };
    }
  }
});

new AuthenticatedMiddleware(router);

export default router;
