// TODO use/update models
import { CrudState } from "@/store/modules/crud/State";
import IExhibitor from "@/models/IExhibitor";

export class State extends CrudState {
  dataList: Array<IExhibitor> = [];
  dataItem: IExhibitor | null = null;
  sendedMail: boolean = false;
  catalogs: any[] = [];
  isPasswordReset: boolean = false;
  setCatalogs: boolean = false;
  exhibitor: any = {};
  selectedCatalogs: any[] = [];
  countries: any[] = [];
  regions: any[] = [];
  selectedRegions: any[] = [];
}
