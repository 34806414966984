
import { template, templateSettings } from "lodash";
import { Vue, Component, Prop } from "vue-property-decorator";
import i18n from "@/locales/index";

// Curly brace syntax
templateSettings.interpolate = /{{([\s\S]+?)}}/g;

@Component
export default class Validation extends Vue {
  public name: string = "Validation";

  @Prop({ required: true })
  public validations!: any;

  @Prop({ required: false })
  public label: any;

  get errors() {
    if (!this.invalid) {
      return [];
    }
    return Object.keys(this.validations.$params).reduce((errors, validator) => {
      if (!this.validations[validator]) {
        let attribute = this.label != undefined ? this.label : "Attr";
        let params = this.validations.$params[validator];
        if (!params) {
          params = { attribute: attribute };
        } else {
          params["attribute"] = attribute;
        }
        const compiled: any = template(
          String(i18n.t("validation." + validator, params))
        );
        const error: any = compiled(this.validations.$params[validator]);
        // @ts-ignore
        errors.push(error);
      }
      return errors;
    }, []);
  }

  get invalid() {
    return this.validations.$dirty && this.validations.$invalid;
  }

  render() {
    // @ts-ignore
    return this.$scopedSlots.default({
      errors: this.errors,
      invalid: this.invalid
    });
  }
}
