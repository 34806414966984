
import { Component, Prop, Vue } from "vue-property-decorator";
import { Types, Status } from "@/config/StatusTypes";

@Component
export default class BadgeItem extends Vue {
  public name: string = "BadgeItem";

  @Prop({ required: true })
  protected data: any;

  @Prop({ required: true })
  protected index: any;

  protected remove(): void {
    this.$emit("remove", { item: this.data, index: this.index });
  }

  protected get type(): String {
    return Types[this.data.type];
  }

  protected get status(): String {
    return Status[this.data.status];
  }

  protected get ready(): String {
    return this.data.status;
  }

  protected get filename(): string {
    return this.data.name || null;
  }

  protected get getClass(): string {
    if (this.ready == "0") return "text-blue-500";
    else if (this.ready == "1") return "text-green-500";
    else return "text-red-500";
  }
}
