
import ClickOutside from "@/directives/ClickOutside";
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import i18n from "@/locales";

@Component({
  directives: {
    ClickOutside: new ClickOutside()
  }
})
export default class Dropdown extends Vue {
  public name: string = "Dropdown";

  @Prop({ required: true })
  public items!: Array<any>;

  @Prop({ default: false })
  public defaultFirst!: Boolean;

  @Prop({
    required: false,
    default: function() {
      return i18n.t("general.none_selected_option");
    }
  })
  public noneSelectedOption!: String;

  @Prop({ default: null })
  public value!: Number | String | null;

  // TODO take care of code repetition in onItemsChange and onChange
  @Watch("items")
  protected onItemsChange(items: any) {
    this.items.forEach(item => {
      if (item.value == this.value) {
        this.selectedName = item.name;
        this.selectedClass = item.value;
        this.selectedValue = item.value;
      }
    });
  }

  @Watch("value")
  protected onChange(value: any) {
    this.items.forEach(item => {
      if (item.value == value) {
        this.selectedName = item.name;
        this.selectedClass = item.value;
        this.selectedValue = item.value;
      }
    });
    this.$emit("change", { value: value });
  }

  public expanded: Boolean = false;
  public selectedValue: any = null;
  public selectedClass: string = "";
  public selectedName: string = "";

  toggle() {
    this.expanded = !this.expanded;
  }

  selectValue(item: any) {
    this.selectedName = item.name;
    this.selectedClass = item.value;
    this.selectedValue = item.value;
    this.$emit("selectedValue", this.selectedValue);
  }

  hideDropdown() {
    this.expanded = false;
  }

  created() {
    if (this.defaultFirst) {
      this.selectedName = this.items[0].name;
      this.selectedClass = this.items[0].value;
      this.selectedValue = this.items[0].value;
      this.$emit("selectedValue", this.selectedValue);
    }
  }
}
