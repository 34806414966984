
import { Component, Prop, Vue } from "vue-property-decorator";
import DefaultLayout from "@/layouts/DefaultLayout.vue";
import Breadcrumb from "@/components/Breadcrumb.vue";
import Notification from "@/components/Notification.vue";
import CatalogMessage from "@/views/admin/catalog/CatalogMessage.vue";
import ExhibitorPageMessage from "@/views/exhibitor/page/ExhibitorPageMessage.vue";

@Component({
  components: {
    ExhibitorPageMessage,
    CatalogMessage,
    Notification,
    DefaultLayout,
    Breadcrumb
  }
})
export default class CrudLayout extends Vue {
  public name: string = "CrudLayout";
  @Prop({ default: "" })
  public title: any;

  @Prop({ default: "" })
  public resourceName: any;

  private crudForm: any = null;

  private initSubmitListener(): void {
    this.crudForm = document.getElementById(
      "form_" + this.resourceName + "_create"
    );

    if (!this.crudForm) {
      this.crudForm = document.getElementById(
        "form_" + this.resourceName + "_edit"
      );
    }

    if (this.crudForm) {
      this.crudForm.addEventListener("submit", () => {
        // Do After Submit stuff ...
        this.scrollBehaviour();
      });
    }
  }

  private scrollBehaviour(): void {
    // let it be a bit more smooth by waiting short time ...
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 500);
  }

  mounted() {
    this.initSubmitListener();
  }
}
