
import { Component, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import CrudLayout from "@/layouts/CrudLayout.vue";
import Validation from "@/components/Validation.vue";
import WineTypeForm from "@/views/admin/wine_type/WineTypeForm.vue";

const namespace: string = "winetype";

@Component({
  components: {
    CrudLayout,
    Validation,
    WineTypeForm
  }
})
export default class WineTypeUpdate extends Vue {
  public name: string = "WineTypeUpdate";
  public resourceName = "wine-type";

  @Getter("getError", { namespace })
  public error: any;

  @Getter("isLoading", { namespace })
  public loading: any;

  @Getter("isUpdated", { namespace })
  public updated: any;

  @Getter("getItem", { namespace })
  public item: any;

  @Action("update", { namespace })
  public updateItemAction: any;

  @Action("findOne", { namespace })
  public getItemAction: any;

  @Action("resetUpdate", { namespace })
  public resetAction: any;

  protected back() {
    // TODO remember last table state (session storage)
    this.$router.push({ path: "/" + this.resourceName });
  }

  public mounted() {
    this.getItemAction({
      resource: this.resourceName,
      id: Number.parseInt(this.$route.params.id)
    });
  }

  public beforeDestroy() {
    this.resetAction();
  }
}
