
import { Vue, Component, Prop } from "vue-property-decorator";
import { BaseMixin } from "@/mixins/baseMixin";

@Component({
  components: {},
  mixins: [BaseMixin]
})
export default class RejectButton extends Vue {
  public name: string = "RejectButton";

  @Prop({ default: "" })
  public to: any;

  private reject(): void {
    this.$emit("click");
  }
}
