import CheckCatalogService from "@/service/CheckCatalogService";

export default async function checkCatalog(mainRouter: any) {
  const next: Function = mainRouter.next;
  const checkService = new CheckCatalogService();
  checkService.isActive().then(response => {
    if (!response) {
      return next({ name: "exhibitor_profile" });
    }
    return next();
  });
}
