import auth from "@/middlewares/AuthMiddleware";
import { ROLES } from "@/config/Roles";
import i18n from "@/locales";
import ExhibitorUpdate from "@/views/admin/exhibitor/ExhibitorUpdate.vue";

export default [
  {
    path: "/profile/edit",
    name: "exhibitor_profile",
    component: ExhibitorUpdate,
    meta: {
      title: String(i18n.t("menu.profile")),
      visibleInAside: true,
      roles: [ROLES.EXHIBITOR],
      middleware: [auth]
    }
  }
];
