import catalog from "./catalog";
import pageTitle from "./pageTitle";
import region from "./region";
import stand from "./stand";
import bottleSize from "./bottleSize";
import exhibitor from "./exhibitor";
import award from "@/router/admin/award";
import service from "@/router/admin/service";
import request from "@/router/admin/request";
import page from "@/router/admin/page";
import wine from "@/router/admin/wine";
import websockets from "@/router/admin/websockets";
import logo from "@/router/admin/logo";
import wineType from "@/router/admin/wineType";

export default [
  ...catalog,
  ...page,
  ...exhibitor,
  ...wine,
  ...stand,
  ...request,
  ...region,
  ...pageTitle,
  ...bottleSize,
  ...award,
  ...service,
  ...websockets,
  ...logo,
  ...wineType
];
