
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import CrudLayout from "@/layouts/CrudLayout.vue";
import { Validate } from "vuelidate-property-decorators";
import {
  maxValue,
  minLength,
  minValue,
  required,
  numeric,
  decimal,
  between
} from "vuelidate/lib/validators";
import Validation from "@/components/Validation.vue";
import IService from "@/models/IService";
import FormBackButton from "@/components/buttons/FormBackButton.vue";

const namespace: string = "service";

@Component({
  components: {
    FormBackButton,
    CrudLayout,
    Validation
  }
})
export default class ServiceForm extends Vue {
  // Component initial values
  public name: string = "ServiceForm";

  private resourceName = "service";

  // props
  @Prop({ default: "add" })
  public type: any;

  @Prop({ default: false })
  public saved: any;
  @Prop({ default: false })
  public resetAction: any;

  @Prop({ default: false })
  public loading: any;

  @Prop({ default: "" })
  public error: any;

  @Prop({ default: {} })
  public saveAction: any;

  @Prop({ default: {} })
  public back: any;

  @Prop()
  public item: any;

  // model
  @Validate({ required, minLength: minLength(4) })
  public serviceName: string = "";

  @Watch("item", { immediate: true, deep: true })
  protected onItemChange(item: any) {
    if (item) {
      this.init(item);
    }
  }

  private init(service: IService): void {
    this.serviceName = service.name;
  }

  public onSubmit(e: Event): void {
    this.$v.$touch();
    if (this.$v.$invalid) {
      return;
    }
    const service: IService = {
      name: this.serviceName
    };

    if (this.$route.params.id) {
      // Edit
      service.id = Number.parseInt(this.$route.params.id);
      this.saveAction({
        resource: this.resourceName,
        data: service,
        id: service.id,
        descriptionField: "name"
      });
    } else {
      // Add
      this.saveAction({
        resource: this.resourceName,
        data: service,
        descriptionField: "name"
      });
    }
  }
}
