
import { Component, Prop, Watch } from "vue-property-decorator";
import AsideMenuItem from "@/components/menu/AsideMenuItem.vue";
import { Getter } from "vuex-class";
import MenuMixin from "@/mixins/MenuMixin";
import AsideMenuCloseButton from "@/components/buttons/menu/AsideMenuCloseButton.vue";

@Component({
  components: {
    AsideMenuCloseButton,
    AsideMenuItem
  }
})
export default class AsideMenu extends MenuMixin {
  public name: string = "AsideMenu";

  protected windowWidth = 0;

  @Prop({ required: true, default: [] })
  public headerMenu!: Array<Object>;

  @Getter("getStatus", { namespace: "asideMenu" })
  protected isOpen: any;

  @Watch("getActiveCatalog")
  protected catalogWatcher() {
    this.headerMenu.map((item: any) => {
      if (this.isRouteActiveCatalogDepending(item)) {
        item.meta.visibleInAside = this.getActiveCatalog !== null;
      }
    });
  }

  protected get isSmallScreen(): any {
    return this.windowWidth <= 1450;
  }

  public mounted() {
    this.windowWidth = window.innerWidth;
    window.addEventListener("resize", () => {
      this.windowWidth = window.innerWidth;
    });
  }
}
