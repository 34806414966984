
import "vue-good-table/dist/vue-good-table.css";
import { Component, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import DefaultLayout from "@/layouts/DefaultLayout.vue";
import MisTable from "@/components/MisTable.vue";
import CrudLayout from "@/layouts/CrudLayout.vue";
import { ICrudOptions } from "@/service/interfaces/ICrudOptions";
import ITableColumn from "@/interfaces/table/ITableColumn";
import i18n from "@/locales";

const namespace = "adminRequest";

@Component({
  components: {
    CrudLayout,
    DefaultLayout,
    MisTable
  }
})
export default class RequestList extends Vue {
  public resourceName: string = "admin-request";
  private resourceLabelName = this.resourceName.replace("admin-", "");
  public name: string = "RequestList";

  public columns: Array<ITableColumn> = [
    {
      label: String(i18n.t("general.firm_name")),
      field: "user.company_name",
      filterOptions: {
        enabled: true
      }
    },
    {
      label: String(i18n.t("general.request_stand_count")),
      field: "count_stands",
      type: "number",
      filterOptions: {
        enabled: true
      }
    },
    {
      label: String(i18n.t("general.request_pages_wine_count")),
      field: "count_pages_wine",
      type: "number",
      filterOptions: {
        enabled: true
      }
    },
    {
      label: String(i18n.t("general.accepted")),
      field: "accepted",
      type: "number",
      html: true,
      filterOptions: {
        enabled: true,
        filterDropdownItems: [
          { value: "active", text: String(i18n.t("general.accepted")) },
          { value: "inactive", text: String(i18n.t("general.non_accepted")) }
        ]
      }
    },
    {
      label: String(i18n.t("general.service")),
      field: "exhibitor_catalog_request_service",
      type: "number",
      sortable: false,
      array: true,
      arrayOptions: {
        printField: "service.name",
        afterColonField: "amount",
        separate: ",",
        colon: ":"
      },
      filterOptions: {
        enabled: false
      }
    },
    {
      label: String(i18n.tc("general.action", 2)),
      field: "actions",
      sortable: false
    }
  ];
  protected searchFields: Array<string> = ["name"];

  @Getter("getList", { namespace })
  public dataList: any;

  @Getter("getTotal", { namespace })
  public totalRows: any;

  @Getter("getError", { namespace })
  public error: any;

  @Getter("isLoading", { namespace })
  public loading: any;

  @Getter("isDeleted", { namespace })
  public deleted: any;

  @Action("search", { namespace })
  public searchItemsAction: any;

  @Action("del", { namespace })
  public deleteItemAction: any;

  @Action("resetList", { namespace })
  public resetAction: any;

  public searchAction(options: ICrudOptions): void {
    this.searchItemsAction(options);
  }

  public deleteAction(options: ICrudOptions): void {
    this.deleteItemAction(options);
  }

  public beforeDestroy() {
    this.resetAction();
  }
}
