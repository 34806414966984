import store from "@/store";

export default class CheckCatalogService {
  public async isActive() {
    return await this.asyncStore();
  }

  public asyncStore() {
    return new Promise(function(resolve, reject) {
      store.watch(
        (state: any) => state.catalog.activeCatalog,
        () => {
          resolve(store.getters["catalog/getActiveCatalog"]);
        }
      );
    });
  }
}
