
import { Component, Prop, Vue } from "vue-property-decorator";
import AuthImage from "@/directives/AuthImage";

const namespace: string = "page";
const logoNamespace: string = "logo";

@Component({
  components: {},
  directives: { AuthImage: new AuthImage() }
})
export default class PageWineApprovedLeft extends Vue {
  public name: string = "PageWineApprovedLeft";
  protected host = process.env.VUE_APP_BACKEND_HOST;
  @Prop()
  public item: any;
}
