
import { Component } from "vue-property-decorator";
import ForgotPasswordForm from "@/components/auth/ForgotPasswordForm.vue";
import Alert from "@/components/Alert.vue";
import Loading from "@/components/Loading.vue";
import { Action } from "vuex-class";
import NotLoggedInLayout from "@/layouts/NotLoggedInLayout.vue";
import { AuthMixin } from "@/mixins/AuthMixin";

const namespace: string = "auth";

@Component({
  components: {
    NotLoggedInLayout,
    ForgotPasswordForm,
    Alert,
    Loading
  }
})
export default class ForgotPassword extends AuthMixin {
  public name: string = "ForgotPassword";

  @Action("forgotPassword", { namespace })
  private forgotPassword: any;

  public onSubmit(credentials: object): void {
    this.forgotPassword(credentials);
  }
}
