import { ActionContext } from "vuex";
import CrudService from "@/service/CrudService";
import { ICrudOptions } from "@/service/interfaces/ICrudOptions";
import ErrorHelper from "@/utils/error/ErrorHelper";
import CrudActions from "@/store/modules/crud/classes/CrudActions";
import i18n from "@/locales";

/**
 * Basic CRUD actions
 */
export default class PositionActions extends CrudActions {
  protected crudService: CrudService = new CrudService();

  constructor() {
    super();
  }

  public async swapPositions(
    store: ActionContext<any, any>,
    options: ICrudOptions
  ): Promise<any> {
    this.dispatchLoading(store, true);
    store.commit("SET_DATA_LOADING", true);
    store.commit("SET_DATA_POSITION_SWAPPED", false);
    const request: Promise<any> = this.crudService.swapPositions(options);
    request
      .then((response: any) => {
        this.dispatchSuccessMessage(
          store,
          String(i18n.t("general.positions_updated"))
        );
        store.commit("SET_DATA_ERROR", null);
        store.commit("SET_DATA_LOADING", false);
        store.commit("SET_DATA_POSITION_SWAPPED", true);
        this.dispatchLoading(store, false);
      })
      .catch(error => {
        this.dispatchErrorMessage(store, ErrorHelper.getError(error));
        store.commit("SET_DATA_ERROR", ErrorHelper.getError(error));
        store.commit("SET_DATA_LOADING", false);
        store.commit("SET_DATA_POSITION_SWAPPED", false);
        this.dispatchLoading(store, false);
      });

    return request;
  }
}
