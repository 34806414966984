
import { Vue, Component, Prop } from "vue-property-decorator";
import { BaseMixin } from "@/mixins/baseMixin";

@Component({
  components: {},
  mixins: [BaseMixin]
})
export default class SendMailMailClientButton extends Vue {
  public name: string = "SendMailMailClientButton";

  @Prop({ default: {} })
  public item: any;
}
