
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import CrudLayout from "@/layouts/CrudLayout.vue";
import { Validate } from "vuelidate-property-decorators";
import {
  maxLength,
  minLength,
  numeric,
  required
} from "vuelidate/lib/validators";
import Validation from "@/components/Validation.vue";
import Loading from "@/components/Loading.vue";
import ISize from "@/models/ISize";
import FormBackButton from "@/components/buttons/FormBackButton.vue";

const namespace: string = "bottlesize";

@Component({
  components: {
    FormBackButton,
    CrudLayout,
    Validation,
    Loading
  }
})
export default class BottleSizeForm extends Vue {
  // component initial values
  public name: string = "BottleSizeForm";
  private resourceName = "bottle-size";

  // props
  @Prop({ default: "add" })
  public type: any;
  @Prop({ default: false })
  public saved: any;
  @Prop({ default: false })
  public resetAction: any;
  @Prop({ default: false })
  public loading: any;
  @Prop({ default: "" })
  public error: any;
  @Prop({
    default: function() {}
  })
  public saveAction: any;
  @Prop({
    default: function() {}
  })
  public back: any;
  @Prop()
  public item: any;

  @Validate({ required, minLength: minLength(2), maxLength: maxLength(50) })
  public size: string = "";

  @Validate({ numeric })
  protected priority_size: number = 0;

  @Watch("item", { immediate: true, deep: true })
  protected onItemChange(item: any) {
    if (item) {
      this.init(item);
    }
  }

  private init(bottleSize: ISize): void {
    this.size = bottleSize.size;
    this.priority_size = bottleSize.priority_size;
  }

  public onSubmit(e: Event): void {
    this.$v.$touch();
    if (this.$v.$invalid) {
      return;
    }
    const bottleSize: ISize = {
      size: this.size,
      priority_size: this.priority_size
    };
    if (this.$route.params.id) {
      // edit
      bottleSize.id = Number.parseInt(this.$route.params.id);
      this.saveAction({
        resource: this.resourceName,
        data: bottleSize,
        id: bottleSize.id,
        descriptionField: "size"
      });
    } else {
      // add
      this.saveAction({
        resource: this.resourceName,
        data: bottleSize,
        descriptionField: "size"
      });
    }
  }
}
