import WebsocketsView from "@/views/admin/websockets/WebsocketsView.vue";
import WebsocketsDashboard from "@/views/admin/websockets/WebsocketsDashboard.vue";
import { ROLES } from "@/config/Roles";
import auth from "@/middlewares/AuthMiddleware";

export default [
  {
    path: "/websockets",
    component: WebsocketsView,
    meta: {
      visibleInAside: false,
      roles: [ROLES.ADMIN, ROLES.SUPER_ADMIN],
      title: "websockets",
      middleware: [auth],
      avoidBack: true
    },
    children: [
      {
        path: "",
        name: "websockets",
        component: WebsocketsDashboard,
        meta: {
          visibleInAside: false,
          title: "",
          roles: [ROLES.ADMIN, ROLES.SUPER_ADMIN],
          middleware: [auth]
        }
      }
    ]
  }
];
