
import MobileMenuItem from "@/components/menu/MobileMenuItem.vue";
import { Component, Prop } from "vue-property-decorator";
import MenuMixin from "@/mixins/MenuMixin";

@Component({
  components: {
    MobileMenuItem
  }
})
export default class MobileMenu extends MenuMixin {
  public name: string = "MobileMenu";

  protected isActive: Boolean = false;

  @Prop({ required: true, default: [] })
  public headerMenu!: Array<Object>;

  public toggleMenu() {
    this.isActive = !this.isActive;
    this.updateMobileMenuStatus(this.isActive);
  }
}
