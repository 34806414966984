
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component
export default class CountCatalogPagesButton extends Vue {
  public name: string = "CountCatalogPagesButton";
  protected modalId = "count-catalog-pages-modal";
  protected cover: number = 4;
  protected pages: number = 0;
  protected total: number = 0;

  @Prop({ default: { count: 0 } })
  protected countCatalogPagesData!: any;

  @Prop({ required: false, default: false, type: Boolean })
  protected disabled!: boolean;

  protected countCatalogPages() {
    this.$emit("count-catalog-pages-action");
  }

  protected get divide() {
    return (
      this.countCatalogPagesData.count % 4 === 0 &&
      this.countCatalogPagesData.count !== 0
    );
  }

  @Watch("countCatalogPagesData")
  protected countCatalogPagesWatcher() {
    this.pages = Number(this.countCatalogPagesData.count);
    this.total = Number(this.countCatalogPagesData.count + this.cover);
    this.$bvModal.show(this.modalId);
  }
}
