
import { Component, Vue } from "vue-property-decorator";

const namespace: string = "bottlesize";

@Component({
  components: {}
})
export default class BottleSizeView extends Vue {
  public name: string = "BottleSizeView";
}
