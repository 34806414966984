import AwardSymbols from "@/config/data/AwardSymbols";
import CatalogTypes from "@/config/data/CatalogTypes";
import Decks from "@/config/data/Decks";
import Ships from "@/config/data/Ships";

/**
 *
 * App Configuration
 */
export default class AppConfig {
  public static ENTRYPOINT: string = `${process.env.VUE_APP_BACKEND_HOST}`;
  public static HTTPS: string = `${process.env.VUE_APP_HTTPS}`;
  public static BROADCAST_AUTH_ENDPOINT: string = `${process.env.VUE_APP_BACKEND_HOST}${process.env.VUE_APP_BACKEND_BROADCAST_AUTH}`;
  public static WS_PORT: string = `${process.env.VUE_APP_WS_PORT}`;
  public static WS_HOST: string = `${process.env.VUE_APP_WS_HOST}`;
  public static WS_KEY: string = `${process.env.VUE_APP_WS_KEY}`;

  public static DEFAULT_TABLE_RESULTS_PER_PAGE: number = 20;
  public static DEFAULT_TABLE_PER_PAGE_OPTIONS: number[] = [
    1,
    5,
    10,
    20,
    50,
    100
  ];

  public static PAGE_TYPES: any = {
    0: "",
    1: "winepage",
    2: "ad",
    3: "content",
    4: "toc_exhibitor",
    5: "toc_region"
  };

  public static SECRET_KEY: string = `${process.env.VUE_APP_SECRET_KEY}`;

  public static APP_VERSION = require("../../package.json").version || "0.1.0";

  public static DATE_FORMAT_FRONTEND = "DD.MM.YYYY";
  public static DATE_FORMAT_BACKEND = "YYYY-MM-DD";

  public static AWARD_SYMBOLS = AwardSymbols;
  public static CATALOG_TYPES: any[] = CatalogTypes;
  public static DECKS: any[] = Decks;
  public static SHIPS: any[] = Ships;
}
