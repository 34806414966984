
import { Component, Prop, Watch, Vue, Mixins } from "vue-property-decorator";
import Validation from "@/components/Validation.vue";
import Upload from "@/components/Upload.vue";
import AppConfig from "@/config/AppConfig";
import AuthImage from "@/directives/AuthImage";
import AuthService from "@/service/AuthService";

import { BaseMixin } from "@/mixins/baseMixin";

@Component({
  components: {
    Validation,
    Upload
  },
  directives: {
    AuthImage: new AuthImage()
  }
})
export default class ExhibitorFormUpload extends Mixins(BaseMixin) {
  @Prop({})
  public v?: any;

  @Prop({})
  public webLogoId?: number | null;

  @Prop({})
  public uploadPercentage?: any;

  @Prop({})
  public showProgressBar?: any;

  @Prop({})
  public webLogo: any;

  private logoUrl: string = "";
  public fileExtension: any = "";

  @Watch("webLogoId", { immediate: true, deep: true })
  protected onChangeLogo(id: number) {
    if (id != null) {
      this.logoUrl = `${AppConfig.ENTRYPOINT}/api/exhibitor/thumbnail/${id}`;
    } else {
      this.logoUrl = "";
    }
  }

  public fileExtensionFormat(file: any): void {
    if (file) {
      this.fileExtension = file.name.split(".").pop();
    } else {
      this.fileExtension = "";
    }
  }

  public handleFile(file: any) {
    this.fileExtensionFormat(file);
    this.$emit("file", file);
  }

  public downloadWebLogo(): void {
    const { extension, name, mime_type, id } = this.webLogo;
    const url = `${AppConfig.ENTRYPOINT}/api/exhibitor/download/${id}`;
    if (this.webLogo) {
      this.download(url, `${name}.${extension}`, mime_type);
    }
  }
}
