// TODO use/update models
import { CrudState } from "@/store/modules/crud/State";
import ICatalog from "@/models/ICatalog";

export class State extends CrudState {
  dataList: Array<ICatalog> = [];
  dataItem: ICatalog | null = null;
  userCatalogRequest: boolean | null = null;
  activeCatalog: ICatalog | null = null;
  lastActiveCatalog: ICatalog | null = null;
  hasCatalogPage: boolean | null = null;
}
