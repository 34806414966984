
import { Component, Vue, Watch } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import CrudLayout from "@/layouts/CrudLayout.vue";
import Validation from "@/components/Validation.vue";
import RequestForm from "@/views/exhibitor/request/RequestForm.vue";
import AuthService from "@/service/AuthService";

const namespace: string = "request";

@Component({
  components: {
    CrudLayout,
    Validation,
    RequestForm
  }
})
export default class RequestCreateUpdate extends Vue {
  protected name: string = "RequestCreateUpdate";
  protected resourceName = "request";

  @Getter("getError", { namespace })
  protected error: any;

  @Getter("isLoading", { namespace })
  protected loading: any;

  @Getter("isCreated", { namespace })
  protected created: any;

  @Getter("getItem", { namespace })
  protected createdItem: any;

  @Action("create", { namespace })
  protected createItemAction: any;

  @Action("resetCreate", { namespace })
  protected resetAction: any;

  @Action("findAll", { namespace: "service" })
  protected findAllServices: any;

  @Getter("getList", { namespace: "service" })
  protected getServices: any;

  @Action("findAll", { namespace })
  protected findRequest: any;

  @Action("update", { namespace })
  protected updateRequest: any;

  public authService: AuthService = new AuthService();

  protected back() {
    this.$router.go(-1);
  }
}
