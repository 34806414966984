
import { Vue, Component, Prop } from "vue-property-decorator";
import { BaseMixin } from "@/mixins/baseMixin";
import LinkButton from "@/components/buttons/LinkButton.vue";

@Component({
  components: { LinkButton },
  mixins: [BaseMixin]
})
export default class AddButton extends Vue {
  public name: string = "AddButton";

  @Prop({ default: "" })
  public to: any;

  get toUrl(): string {
    return this.to ? this.to : this.$router.currentRoute.path + "/create";
  }
}
