import store from "@/store";

export default class CheckPageCatalogService {
  public async isActive() {
    return await this.asyncStore();
  }

  public asyncStore() {
    return new Promise(function(resolve, reject) {
      store.watch(
        state => {
          return state.page.isActiveCatalog;
        },
        () => {
          resolve(store.getters["page/isActiveCatalog"]);
        }
      );
    });
  }
}
