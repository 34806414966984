
import { Vue, Component, Prop } from "vue-property-decorator";
import { BaseMixin } from "@/mixins/baseMixin";

@Component({
  components: {},
  mixins: [BaseMixin]
})
export default class ActionButton extends Vue {
  public name: string = "ActionButton";

  @Prop({ default: "" })
  public title: any;

  @Prop({ default: "" })
  public clickAction: any;

  @Prop({ default: false, type: Boolean, required: false })
  protected disabled: any;

  protected click(): void {
    if (!this.disabled) this.clickAction();
  }
}
