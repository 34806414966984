
import { Component, Vue } from "vue-property-decorator";

@Component
export default class SaveButton extends Vue {
  public name: string = "SaveButton";

  public click() {
    this.$emit("click");
  }
}
