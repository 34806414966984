
import { Component, Vue } from "vue-property-decorator";

const namespace: string = "adminRequest";

@Component({
  components: {}
})
export default class RequestView extends Vue {
  public name: string = "RequestView";
}
