import { ActionContext } from "vuex";
import { CrudState } from "@/store/modules/crud/State";
import { ICrudOptions } from "@/service/interfaces/ICrudOptions";
import CrudActions from "@/store/modules/crud/classes/CrudActions";
import CrudResetActions from "@/store/modules/crud/classes/CrudResetActions";

export const crudActions: CrudActions = new CrudActions();
export const crudResetActions: CrudResetActions = new CrudResetActions();

export function findOne(
  store: ActionContext<CrudState, any>,
  options: ICrudOptions
): Promise<any> {
  return crudActions.findOne(store, options);
}

export async function findAll(
  store: ActionContext<CrudState, any>,
  options: ICrudOptions
): Promise<any> {
  await crudActions.findAll(store, options);
}

export async function search(
  store: ActionContext<CrudState, any>,
  options: ICrudOptions
): Promise<any> {
  await crudActions.search(store, options);
}

export async function paginate(
  store: ActionContext<CrudState, any>,
  options: ICrudOptions
): Promise<any> {
  await crudActions.paginate(store, options);
}

export async function create(
  store: ActionContext<CrudState, any>,
  options: ICrudOptions
): Promise<any> {
  await crudActions.create(store, options);
}

export async function update(
  store: ActionContext<CrudState, any>,
  options: ICrudOptions
): Promise<any> {
  await crudActions.update(store, options);
}

export async function del(
  store: ActionContext<CrudState, any>,
  options: ICrudOptions
): Promise<any> {
  await crudActions.delete(store, options);
}

export function resetList(
  store: ActionContext<CrudState, any>,
  options: ICrudOptions
): void {
  crudResetActions.resetList(store, options);
}

export function resetCreate(
  store: ActionContext<CrudState, any>,
  options: ICrudOptions
): void {
  crudResetActions.resetCreate(store, options);
}

export function resetUpdate(
  store: ActionContext<CrudState, any>,
  options: ICrudOptions
): void {
  crudResetActions.resetUpdate(store, options);
}
