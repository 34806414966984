
import { Component, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import CrudLayout from "@/layouts/CrudLayout.vue";
import Validation from "@/components/Validation.vue";
import RequestForm from "@/views/admin/request/RequestForm.vue";

const namespace: string = "adminRequest";
const namespaceStand: string = "stand";
const namespaceService: string = "service";
const breadcrumbNamespace: string = "breadcrumb";

@Component({
  components: {
    CrudLayout,
    Validation,
    RequestForm
  }
})
export default class RequestCreateUpdate extends Vue {
  protected name: string = "RequestCreateUpdate";
  protected resourceName = "admin-request";

  @Getter("getError", { namespace })
  protected error: any;

  @Getter("isLoading", { namespace })
  protected loading: any;

  @Getter("isCreated", { namespace })
  protected saved: any;

  @Getter("getItem", { namespace })
  public getRequestItem: any;

  @Getter("getList", { namespace })
  protected getRequestList: any;

  @Getter("getList", { namespace: namespaceService })
  protected getServiceList: any;

  @Getter("getList", { namespace: namespaceStand })
  protected getStandList: any;

  @Action("resetCreate", { namespace })
  protected resetAction: any;

  @Action("findAll", { namespace: namespaceStand })
  protected findAllStands: any;

  @Action("findAll", { namespace: namespaceService })
  protected findAllServices: any;

  @Action("findOne", { namespace })
  protected findRequestAction: any;

  @Action("update", { namespace })
  protected updateRequest: any;

  @Action("mergeData", { namespace: breadcrumbNamespace })
  protected breadcrumbSetDataAction: any;

  public mounted() {
    this.findAllServices({ resource: "service" });
    this.findAllStands({
      resource: "stand",
      query: {
        active_catalog: 1
      }
    });
    this.findRequestAction({
      resource: this.resourceName,
      id: Number.parseInt(this.$route.params.id)
    });
  }

  protected back() {
    this.$router.go(-1);
  }
}
