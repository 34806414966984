
// @ts-ignore
import Treeselect from "@riophae/vue-treeselect";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import CrudLayout from "@/layouts/CrudLayout.vue";
import { Validate } from "vuelidate-property-decorators";
import Validation from "@/components/Validation.vue";
import Dropdown from "@/components/Dropdown.vue";
import IExhibitorCatalogRequest from "@/models/IExhibitorCatalogRequest";
import "@/assets/sass/transparent-table.scss";
import ServiceTable from "@/components/ServiceTable.vue";
import { countStand } from "@/validators/validators";
import { required } from "vuelidate/lib/validators";
import { namespace } from "vuex-class";
import MultiSelect from "@/components/multiselect/MultiSelect.vue";
import "@/assets/sass/dropdown-table.scss";
import SortService from "@/service/SortService";
import IRequestPage from "@/models/IRequestPage";
import uid from "uid";

const NotificationModule = namespace("notification");
const PageTitleModule = namespace("pagetitle");

@Component({
  components: {
    ServiceTable,
    Dropdown,
    CrudLayout,
    Validation,
    Treeselect,
    MultiSelect
  }
})
export default class RequestForm extends Vue {
  public name: string = "RequestForm";
  private resourceName = "admin-request";
  private resourceLabelName = this.resourceName.replace("admin-", "");

  protected requestId: number = 0;
  protected pageServices: any = {};
  protected countStands: number = 0;
  protected comment: string = "";
  protected isAccepted: any = null;
  protected modalId: string = "accepted-modal";
  protected modalAcceptedInfo: string = "";
  protected modalAccepted: boolean = false;
  protected notificationState: any = null;
  protected validate = false;
  protected relationStands: any;
  protected exhibitorCatalogServices: Array<Object> = [];
  protected pages: any = null;

  @Validate({
    $each: { pageStand: { id: required }, pageTitle: { id: required } }
  })
  protected winePages: Array<IRequestPage> = [];

  @Validate({
    $each: { pageStand: { id: required }, pageTitle: { id: required } }
  })
  protected adPages: Array<IRequestPage> = [];

  @Validate({ countStand: countStand })
  public stands: any[] = [];

  @Validate({ required })
  public accepted: string = "";

  @Prop({ default: "add" })
  public type: any;

  @Prop({ default: false })
  public saved: any;

  @Prop({ default: false })
  public loading: any;

  @Prop({ default: "" })
  public error: any;

  @Prop({ default: {} })
  public back: any;

  @Prop({ required: true })
  public getServiceList: any;

  @Prop({ required: true })
  public getStandList: any;

  @Prop({ required: true })
  public getRequestItem: any;

  @Prop({ required: false })
  public updateRequest: any;

  @Prop({ required: false })
  public breadcrumbAction: any;

  @NotificationModule.Action("setText")
  protected setNotification: any;

  @NotificationModule.Action("setActive")
  protected setNotificationActive: any;

  @PageTitleModule.Getter("getList")
  public pageTileList: any;

  @PageTitleModule.Action("findAll")
  public getPageTitles: any;

  @Watch("getRequestItem")
  public async requestItemWatcher(item: any) {
    const {
      id,
      count_stands,
      count_pages_wine,
      count_pages_advertising,
      comment,
      accepted,
      user,
      exhibitor_catalog_request_service
    } = item.exhibitor_catalog_request;

    this.exhibitorCatalogServices =
      item.exhibitor_catalog_request_service ||
      exhibitor_catalog_request_service;

    this.relationStands = item.rel_stand_user_catalog;
    this.requestId = id;
    this.countStands = count_stands;
    this.comment = comment;
    this.accepted = accepted;
    this.notificationState = String(accepted);
    this.isAccepted = accepted;
    this.pages = await Promise.resolve(user.onCreatedPages || user.pages);
    this.notification();

    const emptyPage = {
      id: null,
      stand: null,
      page_title: null
    };

    // Wine pages generate
    const mapWinePages = this.pages.filter((x: any) => x.page_type === 1);
    const winePagesCount = count_pages_wine || 1;
    this.winePages = [];
    for (let i = 0; i < winePagesCount; i++) {
      const { id, stand, page_title } = mapWinePages[i] || emptyPage;
      this.winePages.push({
        count: i + 1,
        uid: uid(),
        pageStand: stand ? { id: stand.id, label: stand.stand } : null,
        pageTitle: page_title
          ? { id: page_title.id, label: page_title.name }
          : null,
        pageId: id
      });
    }

    // Ad pages generate
    const mapAdPages = this.pages.filter((x: any) => x.page_type === 2);
    const adPagesCount = count_pages_advertising || 0;
    this.adPages = [];
    for (let i = 0; i < adPagesCount; i++) {
      const { id, stand, page_title } = mapAdPages[i] || emptyPage;
      this.adPages.push({
        count: i + 1,
        uid: uid(),
        pageStand: stand ? { id: stand.id, label: stand.stand } : null,
        pageTitle: page_title
          ? { id: page_title.id, label: page_title.name }
          : null,
        pageId: id
      });
    }

    this.stands = [];
    await this.relationStands.forEach((item: any) => {
      this.stands.push(item.stand_id);
    });

    // Set breadcrumb title
    this.breadcrumbAction({
      [String(this.$router.currentRoute.name)]: {
        requestUpdateTitle: this.getTitle
      }
    });
  }

  @Watch("accepted")
  public acceptedWatcher(accepted: string | null) {
    if (accepted == "0") this.validate = false;
    else if (accepted == "1") this.validate = true;
  }

  public destroyed() {
    this.setNotificationActive(false);
  }

  public async created() {
    await this.getPageTitles({
      resource: "page-title",
      descriptionField: "name"
    });
  }

  public filterService(requestCatalogServices: Array<any>, item: any) {
    if (requestCatalogServices.length < 1) {
      return 0;
    }
    const filteredItem = requestCatalogServices.filter((row: any) => {
      return row.service_id == item.id;
    });
    if (filteredItem.length < 1) {
      return 0;
    }
    this.setService(item, filteredItem[0].amount);
    return filteredItem[0].amount;
  }

  public setService(item: any, count: number) {
    this.pageServices[item.id] = {
      service_id: item.id,
      amount: count
    };
  }

  public acceptedModal() {
    this.$bvModal.hide(this.modalId);
    this.modalAccepted = true;
    this.onSubmit();
  }

  public notification(): void {
    if (this.notificationState == 0) {
      this.setNotification({
        text: this.$t("general.request_rejected"),
        theme: "danger"
      });
    }
  }

  public invalid(value: any, index: number = 0, param: string = "") {
    if (!this.validate) {
      return;
    }
    if (value.$each && value.$each[index]) {
      const invalid = value.$each[index][param].$invalid;
      if (invalid) {
        this.scrollToTop(390);
      }
      return invalid;
    }
    const invalid = value.$invalid;
    if (invalid) {
      this.scrollToTop(390);
    }
    return invalid;
  }

  public async onSubmit() {
    if (this.accepted == "1" || this.accepted == null) {
      this.$v.$touch();
      this.validate = true;

      if (this.$v.$invalid) {
        return;
      }
    } else {
      this.validate = false;
    }

    if (this.isAccepted != null && !this.modalAccepted) {
      this.$bvModal.show(this.modalId);
      this.modalAcceptedInfo = String(
        this.$t("general.question_stand_accepted")
      );
      return;
    }

    // Mapping page stand and page title
    const winePagesData = this.winePages.map((page: any) => {
      return {
        pageId: page.pageId,
        pageTitle: page.pageTitle ? page.pageTitle.label : null,
        pageTitleId: page.pageTitle ? page.pageTitle.id : null,
        stand: page.pageStand ? page.pageStand.label : null,
        standId: page.pageStand ? page.pageStand.id : null
      };
    });

    const adPagesData = this.adPages.map((page: any) => {
      return {
        pageId: page.pageId,
        pageTitle: page.pageTitle ? page.pageTitle.label : null,
        pageTitleId: page.pageTitle ? page.pageTitle.id : null,
        stand: page.pageStand ? page.pageStand.label : null,
        standId: page.pageStand ? page.pageStand.id : null
      };
    });

    const request: IExhibitorCatalogRequest = {
      service: this.pageServices,
      accepted: this.accepted,
      comment: this.comment,
      id: this.requestId,
      stand: this.stands,
      winePages: winePagesData,
      adPages: adPagesData
    };

    let options = {};

    if (this.pages == undefined || this.pages.length < 1) {
      options = { message: this.$t("general.request_sent_success") };
    }

    // Edit
    this.updateRequest({
      resource: this.resourceName,
      data: request,
      id: this.requestId,
      descriptionField: "name",
      ...options
    });

    this.modalAccepted = false;
  }

  public get filteredStands() {
    let stands: Array<any> | { id: any; label: any }[] = [];
    this.stands.forEach(item => {
      const founded = this.mappingStands.find(stand => stand.id == item);
      if (founded) {
        stands.push(founded);
      }
    });
    return SortService.arrayAsc(stands, "label");
  }

  protected get mappingStands(): any[] {
    return SortService.arrayAsc(
      this.getStandList.map((stand: any) => {
        return {
          id: stand.id,
          label: stand.stand
        };
      }),
      "label"
    );
  }

  public get mappingPageTitles() {
    return SortService.arrayAsc(
      this.pageTileList.map((item: any) => {
        return {
          id: item.id,
          label: item.name
        };
      }),
      "label"
    );
  }

  protected scrollToTop(yOffset: number) {
    if (window.pageYOffset < yOffset) {
      return;
    }
    const intervalId = setInterval(() => {
      if (window.pageYOffset < yOffset) {
        clearInterval(intervalId);
      }
      window.scroll(0, window.pageYOffset - 10);
    }, 1);
  }

  protected get getTitle() {
    const { exhibitor_catalog_request } = this.getRequestItem || {
      exhibitor_catalog_request: null
    };
    if (exhibitor_catalog_request) {
      return this.$t("general.request_edit_by_company_name", {
        companyName: exhibitor_catalog_request.user.company_name
      });
    }
    return null;
  }

  protected deselectStands(item: any) {
    this.reduceStands(this.winePages, item);
    this.reduceStands(this.adPages, item);
  }

  protected reduceStands(pages: any, item: any) {
    pages.reduce((a: Array<IRequestPage>, requestPage: IRequestPage) => {
      if (requestPage.pageStand && requestPage.pageStand.id === item.id) {
        requestPage.pageStand = null;
        a.push(requestPage);
      }
      return a;
    }, []);
  }
}
