
import { Component, Vue } from "vue-property-decorator";

const namespace: string = "exhibitor";

@Component({
  components: {}
})
export default class ExhibitorView extends Vue {
  public name: string = "ExhibitorView";
}
