
import { Component, Prop } from "vue-property-decorator";
import AuthImage from "@/directives/AuthImage";
import { BaseMixin } from "@/mixins/baseMixin";
import AppConfig from "@/config/AppConfig";

@Component({
  directives: {
    AuthImage: new AuthImage()
  }
})
export default class ImportPageItem extends BaseMixin {
  public name: string = "ImportPageItem";

  @Prop({ required: true, type: Object })
  protected data: any;

  @Prop({ required: true })
  protected isImported: any;

  protected get position(): Number {
    return this.data.position;
  }

  protected get source() {
    const { id } = this.data;
    return `${AppConfig.ENTRYPOINT}/api/page-image/${id}`;
  }

  protected get alt() {
    return this.$t("general.preview");
  }

  protected get disablePageItem() {
    const { id } = this.$route.params;
    const founded = this.data.copy_page.find((x: any) => x.id == id);
    return this.data.copy_page.length > 0 && !founded;
  }

  protected get getDisabledClass() {
    return this.disablePageItem
      ? "opacity-25 !cursor-default !hover:text-unset"
      : null;
  }

  protected importPage(event: any): void {
    if (this.disablePageItem || event.target.id == "preview") {
      return;
    }
    this.$emit("import", this.data);
  }

  protected downloadFile() {
    const { id } = this.data;
    const url = `${AppConfig.ENTRYPOINT}/api/page-wine-download/${id}`;
    this.download(url, `${id}.pdf`);
  }
}
