import { ActionTree, ActionContext } from "vuex";
import { State } from "./state";

export function updateStatus(store: ActionContext<State, any>, data: any) {
  store.commit("CHANGE_STATUS", data);
}

export function updateMobileMenuStatus(
  store: ActionContext<State, any>,
  status: boolean
) {
  store.commit("SET_MOBILE_MENU_STATUS", status);
}

export default <ActionTree<State, any>>{
  updateStatus,
  updateMobileMenuStatus
};
