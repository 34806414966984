import { ROLES } from "@/config/Roles";
import i18n from "@/locales";
import auth from "@/middlewares/AuthMiddleware";
import AdminView from "@/views/superadmin/admin/AdminView.vue";
import AdminList from "@/views/superadmin/admin/AdminList.vue";
import AdminCreate from "@/views/superadmin/admin/AdminCreate.vue";
import AdminUpdate from "@/views/superadmin/admin/AdminUpdate.vue";

export default [
  {
    path: "/admin",
    component: AdminView,
    meta: {
      visibleInAside: true,
      roles: [ROLES.SUPER_ADMIN],
      title: String(i18n.t("menu.admin")),
      middleware: [auth]
    },
    children: [
      {
        path: "",
        name: "admin_list",
        component: AdminList,
        meta: {
          title: "",
          roles: [ROLES.SUPER_ADMIN],
          middleware: [auth]
        }
      },
      {
        path: "create",
        name: "admin_create",
        component: AdminCreate,
        meta: {
          title: String(i18n.t("general.admin_create")),
          visibleInAside: true,
          roles: [ROLES.SUPER_ADMIN],
          middleware: [auth],
          avoidBack: true
        }
      },
      {
        path: "edit/:id",
        name: "admin_edit",
        component: AdminUpdate,
        meta: {
          roles: [ROLES.SUPER_ADMIN],
          title: String(i18n.t("general.edit_catalog")),
          middleware: [auth]
        }
      }
    ]
  }
];
