import { Component, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";

const exhibitorNamespace: string = "exhibitor";

@Component
export class LogoMixin extends Vue {
  protected showExhibitors: boolean = false;
  private readonly limitResult: number = 100;

  @Action("search", { namespace: exhibitorNamespace })
  protected exhibitorAction: any;

  @Getter("getList", { namespace: exhibitorNamespace })
  protected exhibitors: any;

  protected searchExhibitorAction(name: string = "") {
    this.exhibitorAction({
      resource: exhibitorNamespace,
      params: {
        currentPerPage: this.limitResult,
        currentPage: 1,
        sort: [
          {
            field: "company_name",
            type: "asc"
          }
        ],
        columnFilters: {
          company_name: name
        }
      }
    });
  }

  protected get mapExhibitors() {
    return this.exhibitors.map((exhibitor: any) => {
      return {
        id: exhibitor.id,
        label: exhibitor.company_name
      };
    });
  }

  protected searchExhibitor(name: string) {
    name = name.trim();
    if (name) {
      this.searchExhibitorAction(name);
    }
  }
}
