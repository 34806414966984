/**
 *   History Helper
 *   --------------
 *
 *   - stores/finds routes to/in history
 *   - finds routes in history and possible next route
 *
 *   use goBack() to go back to next possible page
 */
import { VueRouter } from "vue-router/types/router";
import CryptService from "@/service/CryptService";
const HISTORY = "HISTORY";

export default class HistoryHelper {
  private router: VueRouter;
  private readonly routes: any;

  constructor(router: VueRouter) {
    this.router = router;
    // @ts-ignore
    this.routes = router.options.routes;
  }

  public goBack(): void {
    const history: any | null = HistoryHelper.getHistoryItem(0);

    if (history == null) {
      return;
    }

    const previousRoute = this.getRouteByName(history.name);

    if (
      previousRoute &&
      previousRoute.meta &&
      "avoidBack" in previousRoute.meta &&
      previousRoute.meta.avoidBack
    ) {
      const nextRoutePath = this.findNextRoutePath(previousRoute);

      if (nextRoutePath !== "") {
        this.router.push({ path: nextRoutePath });
        return;
      }
    }

    this.router.go(-1);
  }

  public getRouteByName(name: any, routes: any = null) {
    return this.router.resolve({ name: name }).route;
  }

  private findNextRoutePath(previousRoute: any) {
    let currentRouteName = this.router.currentRoute.name;
    let nextRoutePath = "";
    let history = HistoryHelper.getHistory();
    if (history) {
      for (let i = 0; i < history.length; i++) {
        let historyItem = history[i];
        let tmpRoute = this.getRouteByName(JSON.parse(historyItem).name);
        if (tmpRoute && tmpRoute.meta && tmpRoute.name !== currentRouteName) {
          if (!("avoidBack" in tmpRoute.meta)) {
            nextRoutePath = JSON.parse(historyItem).path;
            break;
          }
        }
      }
    }
    return nextRoutePath;
  }

  public initListener() {
    window.onpopstate = (event: { state: any }) => {
      if (event.state) {
        this.goBack();
      }
    };
  }

  // Static methods
  public static pushToHistory(route: any) {
    let history = this.getHistory();

    let routeData = {
      name: route.name,
      params: route.params,
      path: route.path
    };
    let historyString = JSON.stringify(routeData);
    if (history) {
      history.unshift(JSON.stringify(routeData));
      historyString = history.join(";");
    }

    this.setHistory(historyString);
  }

  public static setHistory(history: string): void {
    const value: string = CryptService.encode(history);

    sessionStorage.setItem(HISTORY, value);
  }

  public static getHistory(): string[] | null {
    const historyString: string | null = sessionStorage.getItem(HISTORY);

    if (historyString == null) {
      return null;
    }
    const value: string = CryptService.decode(historyString);

    return value.split(";");
  }

  public static getHistoryItem(idx: number): any | null {
    const history: string[] | null = HistoryHelper.getHistory();

    if (history == null) {
      return null;
    }

    if (history && history.length > idx) {
      return JSON.parse(history[idx]);
    }
  }
}
