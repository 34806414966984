
import { Component, Vue, Watch } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import CrudLayout from "@/layouts/CrudLayout.vue";
import Validation from "@/components/Validation.vue";
import PageContentForm from "@/views/admin/page/content/PageContentForm.vue";

const namespace: string = "page";
const breadcrumbNamespace: string = "breadcrumb";
const catalogNamespace: string = "catalog";

@Component({
  components: {
    CrudLayout,
    Validation,
    PageContentForm
  }
})
export default class PageContentCreate extends Vue {
  protected name: string = "PageContentCreate";
  protected title: string = "";
  private resourceName: string = namespace;

  @Getter("getItem", { namespace: catalogNamespace }) catalog: any;
  @Getter("getActiveCatalog", { namespace: catalogNamespace })
  activeCatalog: any;
  @Getter("isLoading", { namespace }) loading: any;
  @Getter("isCreated", { namespace }) isCreated?: boolean;

  @Action("mergeData", { namespace: breadcrumbNamespace })
  breadcrumbSetDataAction: any;
  @Action("findOne", { namespace: catalogNamespace }) findCatalogAction: any;
  @Action("resetCreate", { namespace }) resetAction: any;
  @Action("createContent", { namespace }) createItemAction: any;
  @Action("redirectCatalogInactive", { namespace })
  redirectCatalogInactiveAction: any;

  @Watch("isCreated", { immediate: true, deep: true })
  protected isItemCreated(status: boolean) {
    if (status) {
      this.redirect();
    }
  }

  get getCatalogId(): string {
    if (this.$router.currentRoute.params.hasOwnProperty("catalog")) {
      return this.$router.currentRoute.params.catalog;
    }

    return this.activeCatalog.id;
  }

  protected redirect() {
    this.$router.go(-1);
  }

  @Watch("catalog", { immediate: true, deep: true })
  protected onCatalog(catalog: any) {
    if (catalog) {
      const { name } = catalog;
      this.breadcrumbSetDataAction({
        [String(this.$router.currentRoute.name)]: {
          pageListTitle: this.$t("general.page_from", {
            catalogName: name ? name : ""
          }),
          catalog: name
        }
      });
      if (catalog.currently_active) {
        this.title = String(
          this.$i18n.t("general.page_content_add", { catalog: catalog.name })
        );
      } else {
        this.redirectCatalogInactiveAction(true);
        this.redirect();
      }
    }
  }

  public created() {
    if (this.getCatalogId !== "false") {
      this.findCatalogAction({
        resource: catalogNamespace,
        id: this.getCatalogId
      });
    }
  }

  beforeDestroy() {
    this.resetAction();
  }
}
