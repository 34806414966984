
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import "@/assets/sass/snackbar.scss";
import { Action, Getter } from "vuex-class";

const namespace = "snackbar";

@Component
export default class Snackbar extends Vue {
  public name: string = "Snackbar";

  public config: any = {
    time: 7 * 1000,
    position: "bottom",
    close: true
  };

  @Getter("getActive", { namespace })
  public getActive: any;

  @Getter("getText", { namespace })
  public getText: any;

  @Getter("getTheme", { namespace })
  public getTheme: any;

  @Getter("getOnChange", { namespace })
  public getOnChange: any;

  @Getter("getSnack", { namespace })
  public getSnack: any;

  @Action("setActive", { namespace })
  public setActive: any;

  @Prop({ default: "OK" })
  public button: any;

  public theme: string = "info";

  public active: boolean = false;

  public timeout: any;

  public sleep(time: number) {
    return new Promise<void>((resolve, reject) =>
      setTimeout((_: any) => resolve(), time)
    );
  }

  public close() {
    this.setActive(false);
    this.active = this.getActive;
    this.$emit("close");
    clearTimeout(this.timeout);
  }

  public get styles() {
    return `${this.config.position} ${this.theme}`;
  }

  public get text() {
    this.theme = this.getTheme;
    return this.getText;
  }

  public async action() {
    if (this.active) {
      this.close();
      await this.sleep(400);
      this.setActive(true);
    } else {
      this.setActive(true);
    }
    this.active = this.getActive;
    this.timeout = setTimeout(this.close, this.config.time);
  }

  @Watch("getOnChange")
  public onChange() {
    this.action();
  }
}
