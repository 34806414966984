
import { Component, Prop, Vue } from "vue-property-decorator";
import CheckCatalogService from "@/service/CheckCatalogService";

const catalogService = new CheckCatalogService();

@Component
export default class PagesButton extends Vue {
  public name: string = "PagesButton";

  @Prop({ default: {} })
  public item: any;

  protected get title() {
    return this.$t("general.pages");
  }

  protected onClick() {
    this.$router.push({
      name: "ActiveCatalog",
      query: {
        columnFilters: JSON.stringify({
          "exhibitor.company_name": this.item.company_name
        })
      }
    });
  }

  protected get isCatalogActive() {
    return catalogService.isActive();
  }
}
