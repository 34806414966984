<template>
  <div class="vgt-wrap__footer vgt-clearfix">
    <!-- Left -->
    <div class="footer__row-count vgt-pull-left">
      <span class="footer__row-count__label">{{ rowsPerPageText }}</span>
      <select
        autocomplete="off"
        name="perPageSelect"
        class=""
        v-model="currentPerPage"
        @change="perPageChanged"
      >
        <option
          v-for="(option, idx) in rowsPerPageOptions"
          :key="'rows-dropdown-option-' + idx"
          :value="option"
        >
          {{ option }}
        </option>
        <option v-if="paginateDropdownAllowAll" :value="total">
          {{ allText }}
        </option>
      </select>
    </div>

    <!-- Right -->
    <div class="footer__navigation vgt-pull-right">
      <a
        href="javascript:void(0)"
        class="footer__navigation__page-btn footer__navigation__page_back"
        @click.prevent.stop="previousPage"
        tabindex="0"
        v-if="prevIsPossible"
      >
      </a>
      <ul class="footer__navigation__list">
        <li
          v-for="(page, index) in pageRange"
          :key="index"
          :class="{
            footer__navigation__list__active: page === currentPage
          }"
        >
          <a href="javascript:void(0);" v-on:click="changePage(page)">
            {{ page }}
          </a>
        </li>
      </ul>
      <a
        href="javascript:void(0)"
        class="footer__navigation__page-btn footer__navigation__page_next"
        @click.prevent.stop="nextPage"
        tabindex="0"
        v-if="nextIsPossible"
      >
      </a>
    </div>
  </div>
</template>

<script>
import cloneDeep from "lodash.clonedeep";

const DEFAULT_ROWS_PER_PAGE_DROPDOWN = [10, 20, 30, 40, 50];

export default {
  name: "VgtNewPagination",

  props: {
    styleClass: { default: "table table-bordered" },
    total: { default: null },
    perPage: {},
    rtl: { default: false },
    customRowsPerPageDropdown: {
      default() {
        return [];
      }
    },
    paginateDropdownAllowAll: { default: true },
    mode: { default: "records" },

    // text options
    nextText: { default: "Next" },
    prevText: { default: "Prev" },
    rowsPerPageText: { default: "Rows per page:" },
    ofText: { default: "of" },
    pageText: { default: "page" },
    allText: { default: "All" }
  },

  data: () => ({
    currentPage: 1,
    prevPage: 0,
    currentPerPage: 10,
    rowsPerPageOptions: [],
    limit: 2
  }),

  watch: {
    perPage: {
      handler(newValue, oldValue) {
        this.handlePerPage();
        this.perPageChanged(oldValue);
      },
      immediate: true
    },

    customRowsPerPageDropdown() {
      this.handlePerPage();
    },

    total: {
      handler(newValue, oldValue) {
        if (this.rowsPerPageOptions.indexOf(this.currentPerPage) === -1) {
          this.currentPerPage = newValue;
        }
      }
    }
  },

  computed: {
    // Number of pages
    pagesCount() {
      const quotient = Math.floor(this.total / this.currentPerPage);
      const remainder = this.total % this.currentPerPage;

      return remainder === 0 ? quotient : quotient + 1;
    },

    // Current displayed items
    paginatedInfo() {
      let first = (this.currentPage - 1) * this.currentPerPage + 1;
      const last = Math.min(this.total, this.currentPage * this.currentPerPage);

      if (last === 0) {
        first = 0;
      }

      return `${first} - ${last} ${this.ofText} ${this.total}`;
    },

    // Can go to next page
    nextIsPossible() {
      return this.currentPage < this.pagesCount;
    },

    // Can go to previous page
    prevIsPossible() {
      return this.currentPage > 1;
    },

    pageRange() {
      if (this.limit === -1) {
        return 0;
      }
      if (this.limit === 0) {
        return this.pagesCount;
      }
      const current = this.currentPage;
      const last = this.pagesCount;
      const delta = this.limit;
      const left = current - delta;
      const right = current + delta + 1;
      const range = [];
      const pages = [];
      let l;
      for (let i = 1; i <= last; i++) {
        if (i === 1 || i === last || (i >= left && i < right)) {
          range.push(i);
        }
      }
      range.forEach(function(i) {
        if (l) {
          if (i - l === 2) {
            pages.push(l + 1);
          } else if (i - l !== 1) {
            pages.push("...");
          }
        }
        pages.push(i);
        l = i;
      });
      return pages;
    }
  },

  methods: {
    // Change current page
    changePage(pageNumber, emit = true) {
      if (
        pageNumber > 0 &&
        this.total > this.currentPerPage * (pageNumber - 1)
      ) {
        this.prevPage = this.currentPage;
        this.currentPage = pageNumber;
        if (emit) this.pageChanged();
      }
    },

    // Go to next page
    nextPage() {
      if (this.nextIsPossible) {
        this.prevPage = this.currentPage;
        ++this.currentPage;
        this.pageChanged();
      }
    },

    // Go to previous page
    previousPage() {
      if (this.prevIsPossible) {
        this.prevPage = this.currentPage;
        --this.currentPage;
        this.pageChanged();
      }
    },

    // Indicate page changing
    pageChanged() {
      this.$emit("page-changed", {
        currentPage: this.currentPage,
        prevPage: this.prevPage
      });
    },

    // Indicate per page changing
    perPageChanged(oldValue) {
      // go back to first page
      if (oldValue) {
        //* only emit if this isn't first initialization
        this.$emit("per-page-changed", { currentPerPage: this.currentPerPage });
      }
      this.changePage(1, false);
    },

    // Handle per page changing
    handlePerPage() {
      //* if there's a custom dropdown then we use that
      if (
        this.customRowsPerPageDropdown !== null &&
        Array.isArray(this.customRowsPerPageDropdown) &&
        this.customRowsPerPageDropdown.length !== 0
      ) {
        this.rowsPerPageOptions = cloneDeep(this.customRowsPerPageDropdown);
      } else {
        //* otherwise we use the default rows per page dropdown
        this.rowsPerPageOptions = cloneDeep(DEFAULT_ROWS_PER_PAGE_DROPDOWN);
      }

      if (this.perPage) {
        this.currentPerPage = this.perPage;
        // if perPage doesn't already exist, we add it
        let found = false;
        for (let i = 0; i < this.rowsPerPageOptions.length; i++) {
          if (this.rowsPerPageOptions[i] === this.perPage) {
            found = true;
          }
        }
        if (!found && this.perPage !== -1) {
          this.rowsPerPageOptions.unshift(this.perPage);
        }
      } else {
        // reset to default
        this.currentPerPage = 10;
      }
    }
  },

  mounted() {
    const pageFromQuery = Number.parseInt(
      String(this.$route.query.currentPage)
    );
    const perPageFromQuery = Number.parseInt(
      String(this.$route.query.currentPerPage)
    );
    if (pageFromQuery) {
      this.currentPage = pageFromQuery;
    } else {
      this.currentPage = 1;
    }
    if (perPageFromQuery) {
      this.currentPerPage = perPageFromQuery;
    } else {
      this.currentPerPage = this.perPage ? this.perPage : 10;
    }
  }
};
</script>
