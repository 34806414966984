import { GetterTree } from "vuex";
import { CrudState } from "@/store/modules/crud/State";

export function getList(state: CrudState): any {
  return state.dataList;
}

export function getTotal(state: CrudState): any {
  return state.total;
}

export function getItem(state: CrudState): any {
  return state.dataItem;
}

export function getError(state: CrudState): any {
  return state.error;
}

export function isLoading(state: CrudState): any {
  return state.loading;
}

export function isCreated(state: CrudState): any {
  return state.created;
}

export function isUpdated(state: CrudState): any {
  return state.updated;
}

export function isDeleted(state: CrudState): any {
  return state.deleted;
}

export default <GetterTree<CrudState, any>>{
  getList,
  getTotal,
  getItem,
  getError,
  isLoading,
  isCreated,
  isUpdated,
  isDeleted
};
