
import { Component, Vue } from "vue-property-decorator";

const namespace: string = "logo";

@Component({
  components: {}
})
export default class LogoView extends Vue {
  public name: string = "LogoView";
}
