
import { Component, Vue } from "vue-property-decorator";

const namespace: string = "region";

@Component({
  components: {}
})
export default class RegionView extends Vue {
  public name: string = "RegionView";
}
