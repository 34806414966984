import { GetterTree } from "vuex";
import { State } from "./state";
import { isSwapped } from "@/store/modules/crud/functions/positions/getters";
export function getList(state: State): any {
  return state.dataList;
}
export function getRedirectedCatalogInactive(state: State): boolean {
  return state.redirectCatalogInactive;
}
export function getTotal(state: State): any {
  return state.total;
}
export function getPosition(state: State) {
  return state.position;
}

export function getItem(state: State): any {
  return state.dataItem;
}

export function getError(state: State): any {
  return state.error;
}

export function isLoading(state: State): any {
  return state.loading;
}

export function isCreated(state: State): any {
  return state.created;
}

export function isUpdated(state: State): any {
  return state.updated;
}

export function isDeleted(state: State): any {
  return state.deleted;
}

export function isImported(state: State): Object {
  return state.imported;
}

export default <GetterTree<State, any>>{
  getList,
  getTotal,
  getItem,
  getError,
  isLoading,
  isCreated,
  isUpdated,
  isDeleted,
  getPosition,
  getRedirectedCatalogInactive,
  isSwapped,
  isImported
};
