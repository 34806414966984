import { GetterTree } from "vuex";
import { State } from "./state";

export function getList(state: State): any {
  return state.dataList;
}

export function getTotal(state: State): any {
  return state.total;
}
export function getSelectedCatalogs(state: State): any[] {
  return state.selectedCatalogs;
}
export function getCatalogs(state: State): any {
  return state.catalogs;
}
export function isPasswordReset(state: State) {
  return state.isPasswordReset;
}
export function isCatalogSet(state: State): any {
  return state.setCatalogs;
}
export function getItem(state: State): any {
  return state.dataItem;
}
export function getError(state: State): any {
  return state.error;
}
export function isMailSent(state: State): any {
  return state.sendedMail;
}
export function isLoading(state: State): any {
  return state.loading;
}
export function isCreated(state: State): any {
  return state.created;
}
export function isUpdated(state: State): any {
  return state.updated;
}
export function getRegions(state: State): any[] {
  return state.regions;
}
export function getCountries(state: State): any[] {
  return state.countries;
}
export function isDeleted(state: State): any {
  return state.deleted;
}
export function getExhibitor(state: State): any {
  return state.exhibitor;
}
export function getSelectedRegions(state: State): any[] {
  return state.selectedRegions;
}
export default <GetterTree<State, any>>{
  getList,
  getTotal,
  getItem,
  getError,
  isLoading,
  isCreated,
  isUpdated,
  isDeleted,
  getCatalogs,
  isCatalogSet,
  isMailSent,
  isPasswordReset,
  getRegions,
  getCountries,
  getSelectedRegions,
  getSelectedCatalogs
};
