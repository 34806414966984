import Vue from "vue";
import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import App from "@/App.vue";
import "@/registerServiceWorker";
import router from "@/router";
import store from "@/store";
import i18n from "@/locales/index";
import Vuelidate from "vuelidate";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import AuthService from "@/service/AuthService";
import SaveIcon from "@/icons/SaveIcon.vue";
import { onMaintenance } from "@/utils/maintenance";
import CrudService from "@/service/CrudService";

Vue.prototype.$crudService = new CrudService();

axios.interceptors.request.use((config: AxiosRequestConfig) => {
  const authService: AuthService = new AuthService();
  const token: string = authService.getToken();
  if (token) {
    config.headers["Authorization"] = "Bearer " + token;
  }
  return config;
});

axios.interceptors.response.use(
  (response: AxiosResponse) => {
    return response;
  },
  function(error: any) {
    if (error.response.status === 503) {
      return router.push({ name: "MaintenanceMode" });
    }
    if (error.response && 401 === error.response.status) {
      const authService: AuthService = new AuthService();
      authService.clear();
      window.location.href = "/login";
    }
    return Promise.reject(error);
  }
);

Vue.use(require("vue-moment"));
Vue.use(Vuelidate);
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);

Vue.component("save-icon", SaveIcon);

const appInit: any = async () => {
  const authService: AuthService = new AuthService();
  const token: string = authService.getToken();
  const activeCatalog: string = store.getters["catalog/getActiveCatalog"];

  if (activeCatalog == null && token) {
    await store.dispatch("catalog/activeCatalog", "catalog/active-catalog");
  }

  new Vue({
    router,
    store,
    i18n,
    render: (h: any) => h(App)
  }).$mount("#app");
};

appInit();

onMaintenance();
