
import { Component, Vue, Watch } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import CrudLayout from "@/layouts/CrudLayout.vue";
import Validation from "@/components/Validation.vue";
import PageForm from "@/views/admin/page/PageForm.vue";
import FormCreate from "@/views/form/create/FormCreate.vue";

const namespace: string = "page";

@Component({
  components: {
    CrudLayout,
    Validation,
    PageForm
  },
  extends: FormCreate
})
export default class PageCreate extends Vue {
  protected name: string = "PageCreate";
  protected resourceName = "page";

  @Getter("getError", { namespace }) error: any;
  @Getter("isLoading", { namespace }) loading: any;
  @Getter("isCreated", { namespace }) created: any;
  @Getter("getItem", { namespace }) createdItem: any;

  @Action("create", { namespace }) createItemAction: any;
  @Action("resetCreate", { namespace }) resetAction: any;
}
