
import { Component, Vue, Watch } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import CrudLayout from "@/layouts/CrudLayout.vue";
import Validation from "@/components/Validation.vue";
import AwardForm from "@/views/admin/award/AwardForm.vue";
import FormCreate from "@/views/form/create/FormCreate.vue";

const namespace: string = "award";

@Component({
  components: {
    CrudLayout,
    Validation,
    AwardForm
  },
  extends: FormCreate
})
export default class AwardCreate extends Vue {
  protected name: string = "AwardCreate";
  protected resourceName = "award";

  @Getter("getError", { namespace })
  protected error: any;

  @Getter("isLoading", { namespace })
  protected loading: any;

  @Getter("isCreated", { namespace })
  protected created: any;

  @Getter("getItem", { namespace })
  protected createdItem: any;

  @Action("create", { namespace })
  protected createItemAction: any;

  @Action("resetCreate", { namespace })
  protected resetAction: any;

  beforeDestroy() {
    this.resetAction();
  }
}
