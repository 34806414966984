import AuthService from "@/service/AuthService";

export const ROLES: any = {
  SUPER_ADMIN: "SUPER_ADMIN",
  ADMIN: "ADMIN",
  EXHIBITOR: "EXHIBITOR",
  NOBODY: "NOBODY"
};

export const API_ROLES: any = {
  API: "API"
};

let ROLE_HOME_PAGE: any = {
  [ROLES.SUPER_ADMIN]: "catalog",
  [ROLES.ADMIN]: "catalog",
  [ROLES.EXHIBITOR]: "exhibitor_wine"
};

export function getRoleHomeComponent(
  role: string | null,
  redirectRouteName: string | null = null
): string {
  if (role === null) {
    return "";
  }
  redirectRouteName ? (ROLE_HOME_PAGE[role] = redirectRouteName) : "";
  return ROLE_HOME_PAGE[role];
}

export function getRoleHomeComponentFromStorage(): string {
  const authService = new AuthService();
  const role: string = authService.getRole();
  return getRoleHomeComponent(role);
}
