
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class GenerateCatalogButton extends Vue {
  public name: string = "GenerateCatalogButton";
  protected modalId = "generate-catalog-modal";

  @Prop({ required: false, default: false, type: Boolean })
  protected disabled!: boolean;

  protected showModal() {
    this.$bvModal.show(this.modalId);
  }

  protected generateCatalog() {
    this.$bvModal.hide(this.modalId);
    this.$emit("generate-action");
  }
}
