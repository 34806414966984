import { Module } from "vuex";
import { State } from "@/store/modules/service/state";
import Mutations from "@/store/modules/service/mutations";
import Getters from "@/store/modules/service/getters";
import Actions from "@/store/modules/service/actions";

export class Service implements Module<State, any> {
  namespaced: boolean = true;
  state: State;
  mutations = Mutations;
  getters = Getters;
  actions = Actions;

  // Setup CRUD operations
  constructor() {
    this.state = new State();
  }
}
