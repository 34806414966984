
import { Component, Watch } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import CrudLayout from "@/layouts/CrudLayout.vue";
import Validation from "@/components/Validation.vue";
import LogoForm from "@/views/exhibitor/logo/LogoForm.vue";
import { LogoMixin } from "@/mixins/logoMixin";

const namespace: string = "logo";

@Component({
  components: {
    CrudLayout,
    Validation,
    LogoForm
  }
})
export default class LogoCreate extends LogoMixin {
  public name: string = "LogoCreate";
  private resourceName = "logo";

  @Getter("getError", { namespace })
  protected error: any;

  @Getter("isLoading", { namespace })
  protected loading: any;

  @Getter("isCreated", { namespace })
  protected saved: any;

  @Getter("getItem", { namespace })
  protected createdItem: any;

  @Action("create", { namespace })
  protected createItemAction: any;

  @Action("resetCreate", { namespace })
  protected resetAction: any;

  @Watch("createdItem", { immediate: true, deep: true })
  protected onCreated(createdItem: any) {
    if (createdItem && createdItem.id) {
      const { name } = this.$router.currentRoute;
      let route = "/" + this.resourceName;
      if (name == "AdminLogoCreate") {
        route = "/admin-logo";
      }
      setTimeout(() => {
        this.$router.push(route);
      }, 1000);
    }
  }

  protected back() {
    this.$router.go(-1);
  }

  protected beforeDestroy() {
    this.resetAction();
  }

  public created() {
    if (this.$router.currentRoute.name == "AdminLogoCreate") {
      this.searchExhibitorAction();
      this.showExhibitors = true;
    }
  }
}
