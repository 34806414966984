
import Slick from "vue-slick";
import DefaultLayout from "@/layouts/DefaultLayout.vue";
import { Component, Watch } from "vue-property-decorator";
import { BaseMixin } from "@/mixins/baseMixin";
import { Action, Getter, namespace as VuexNamespace } from "vuex-class";
import MisTable from "@/components/MisTable.vue";
import { ICrudOptions } from "@/service/interfaces/ICrudOptions";
import ITableColumn from "@/interfaces/table/ITableColumn";
import CrudLayout from "@/layouts/CrudLayout.vue";
import i18n from "@/locales";
import "vue-good-table/dist/vue-good-table.css";

const namespace = "logo";
const StatusModule = VuexNamespace("status");

@Component({
  components: {
    CrudLayout,
    Slick,
    DefaultLayout,
    MisTable
  }
})
export default class LogoList extends BaseMixin {
  public name: string = "LogoList";
  public additionalActionButtons: Array<any> = [{ name: "ApproveLogoButton" }];

  public resourceName: string = "logo";

  public columns: Array<ITableColumn> = [
    {
      label: String(i18n.t("general.preview")),
      field: "preview",
      sortable: false,
      image: {
        enable: true,
        maxWidth: "70px",
        maxHeight: "70px",
        thumbnailField:
          process.env.VUE_APP_BACKEND_HOST + "/api/logo/thumbnail/",
        thumbnailExtension: "",
        downloadableField:
          process.env.VUE_APP_BACKEND_HOST + "/api/logo/download/"
      },
      filterOptions: {
        enabled: false
      },
      downloadFn: (props: any) => {
        this.download(
          `${props.column.image.downloadableField}${props.row.id}`,
          props.formattedRow.name
        );
      }
    },
    {
      label: String(i18n.t("general.exhibitor")),
      field: "user.company_name",
      filterOptions: {
        enabled: true
      }
    },
    {
      label: String(i18n.t("general.logo")),
      field: "name",
      filterOptions: {
        enabled: true
      }
    },
    {
      label: String(i18n.t("general.logo_ready")),
      field: "ready",
      type: "number",
      html: true,
      filterOptions: {
        enabled: true,
        filterDropdownItems: [
          { value: "active", text: String(i18n.t("general.active")) },
          { value: "inactive", text: String(i18n.t("general.inactive")) }
        ]
      }
    },
    {
      label: String(i18n.t("general.logo_approved")),
      field: "approved",
      type: "number",
      html: true,
      filterOptions: {
        enabled: true,
        filterDropdownItems: [
          { value: "active", text: String(i18n.t("general.active")) },
          { value: "inactive", text: String(i18n.t("general.inactive")) }
        ]
      }
    },
    {
      label: String(i18n.tc("general.action", 2)),
      field: "actions",
      sortable: false,
      disableBtnFn: function(item: any) {
        return item.status && item.status.status === "0";
      }
    }
  ];

  protected searchFields: Array<string> = ["name"];

  @Getter("getList", { namespace })
  protected dataList: any;

  @Getter("getTotal", { namespace })
  protected totalRows: any;

  @Getter("getError", { namespace })
  protected error: any;

  @Getter("isLoading", { namespace })
  protected loading: any;

  @Getter("isDeleted", { namespace })
  protected deleted: any;

  @Getter("getSelectedForApproval", { namespace })
  protected selectedForApproval: any;

  @Action("search", { namespace })
  protected searchItemsAction: any;

  @Action("del", { namespace })
  protected deleteItemAction: any;

  @Action("resetList", { namespace })
  protected resetAction: any;

  @Action("update", { namespace })
  protected updateItemsAction: any;

  @Action("selectForApproval", { namespace })
  protected selectForApprovalAction: any;

  @StatusModule.Getter("getList")
  protected statusList: any;

  protected onCreated = false;

  protected get modalApprovalMessage(): string {
    if (!this.selectedForApproval) {
      return "";
    }
    return String(
      i18n.t("general.question_approve_item", {
        resourceName: this.selectedForApproval.name,
        type: this.selectedForApproval.approved
          ? String(this.$t("general.reject")).toLowerCase()
          : String(this.$t("general.approve")).toLowerCase()
      })
    );
  }

  @Watch("statusList", { immediate: true, deep: true })
  protected onStatusListChange(newValue: any, oldValue: any) {
    if (newValue.length > 0 && this.$refs.logoTable && !this.onCreated) {
      (this.$refs.logoTable as MisTable).reloadData();
    }

    // Fixing duplicate request for status list watcher
    if (oldValue && oldValue.length > 0) {
      this.onCreated = false;
    }
  }

  @Watch("selectedForApproval")
  protected onSelectedForApprovalChange(item: any) {
    if (item) {
      this.$bvModal.show("modal-approve-confirm");
    }
  }

  public approve() {
    this.selectedForApproval.approved = !this.selectedForApproval.approved;
    this.updateItemsAction({
      resource: "logo",
      id: this.selectedForApproval.id,
      data: this.selectedForApproval,
      descriptionField: "name"
    });
    this.$bvModal.hide("modal-approve-confirm");
  }

  protected closeApprovalModal() {
    this.selectForApprovalAction(null);
    this.$bvModal.hide("modal-approve-confirm");
  }

  public searchAction(options: ICrudOptions): void {
    this.searchItemsAction(options);
  }

  public deleteAction(options: ICrudOptions): void {
    this.deleteItemAction(options);
  }

  public beforeDestroy() {
    this.resetAction();
  }

  public get resource() {
    if (this.$router.currentRoute.name == "AdminLogo") {
      return "admin-logo";
    }
    return this.resourceName;
  }

  public created() {
    this.onCreated = true;
  }
}
