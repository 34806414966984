import { GetterTree } from "vuex";
import { PositionState } from "@/store/modules/crud/PositoinState";

export function isSwapped(state: PositionState): any {
  return state.swapped;
}

export default <GetterTree<PositionState, any>>{
  isSwapped
};
