import Vue from "vue";
import Vuex from "vuex";
import Table from "@/store/modules/table";
import Auth from "@/store/modules/auth";
import { Catalog } from "@/store/modules/catalog";
import { PageTitle } from "@/store/modules/page_title";
import Snackbar from "@/store/modules/snackbar";
import AsideMenu from "@/store/modules/aside_menu";
import { Country } from "@/store/modules/country";
import { Region } from "@/store/modules/region";
import { BottleSize } from "@/store/modules/bottle_size";
import { Admin } from "@/store/modules/admin";
import { Award } from "@/store/modules/award";
import { Exhibitor } from "@/store/modules/exhibitor";
import { Service } from "@/store/modules/service";
import { Breadcrumb } from "@/store/modules/breadcrumb";
import { Stand } from "@/store/modules/stand";
import { Wine } from "@/store/modules/wine";
import { Request } from "@/store/modules/request";
import { Logo } from "@/store/modules/logo";
import { AdminRequest } from "@/store/modules/admin_request";
import Notification from "@/store/modules/notification";
import { Page } from "@/store/modules/page";
import { PageCategory } from "@/store/modules/page_category";
import { Print } from "@/store/modules/print";
import { Status } from "@/store/modules/status";
import { WineType } from "@/store/modules/wine_type";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    table: new Table(),
    auth: new Auth(),
    catalog: new Catalog(),
    snackbar: new Snackbar(),
    asideMenu: new AsideMenu(),
    pagetitle: new PageTitle(),
    region: new Region(),
    country: new Country(),
    bottlesize: new BottleSize(),
    admin: new Admin(),
    award: new Award(),
    exhibitor: new Exhibitor(),
    service: new Service(),
    logo: new Logo(),
    breadcrumb: new Breadcrumb(),
    stand: new Stand(),
    request: new Request(),
    adminRequest: new AdminRequest(),
    wine: new Wine(),
    notification: new Notification(),
    page: new Page(),
    pagecategory: new PageCategory(),
    print: new Print(),
    status: new Status(),
    winetype: new WineType()
  }
});
