
import { Component, Prop, Vue } from "vue-property-decorator";
import CollapseTransition from "@/transition/CollapseTransition.vue";

@Component({
  components: {
    CollapseTransition
  }
})
export default class AsideMenuItem extends Vue {
  public name: string = "AsideMenuItem";

  protected isExpand: boolean = false;

  @Prop({ required: false, default: false })
  public expandable!: boolean;

  public toggle(): void {
    this.isExpand = !this.isExpand;
  }
}
