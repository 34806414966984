
import { Vue, Prop, Component, Watch } from "vue-property-decorator";
import { routes } from "@/router";
import { Route } from "vue-router";
import { Getter } from "vuex-class";

const namespace: string = "breadcrumb";

class BreadCrumbElement {
  public url: string = "";
  public name: string = "";

  constructor(url: string, name: string) {
    this.name = name;
    this.url = url;
  }
}
@Component
export default class Breadcrumb extends Vue {
  public name: string = "Breadcrumb";

  @Getter("getData", { namespace }) data: any;

  public breadcrumbs: BreadCrumbElement[] = [];

  @Watch("data", { immediate: false, deep: true })
  private onDataChange(newVal: any) {
    this.watchBreadcrumb();
  }

  @Watch("$route", { immediate: false, deep: true })
  private onUrlChange(newVal: Route) {
    this.watchBreadcrumb();
  }

  created() {
    this.watchBreadcrumb();
  }

  private watchBreadcrumb() {
    const result: BreadCrumbElement[] | BreadCrumbElement | null = this.getMenu(
      routes
    );
    if (result instanceof BreadCrumbElement) {
      this.breadcrumbs = [result];
    } else if (result !== null) {
      this.breadcrumbs = result;
    }

    if (Array.isArray(this.breadcrumbs)) {
      this.breadcrumbs = this.parseUri(this.breadcrumbs);
    }
  }

  private getTitle(router: any): string {
    if (!router.meta || !router.meta.title) {
      return "";
    }
    if (typeof router.meta.title === "string") {
      return router.meta.title;
    }

    if (this.data && this.data.hasOwnProperty(this.$router.currentRoute.name)) {
      return router.meta.title(
        this.$router.currentRoute,
        this.data[String(this.$router.currentRoute.name)]
      );
    }

    return router.meta.title(this.$router.currentRoute);
  }

  private getMenu(
    routesInput: any
  ): BreadCrumbElement[] | BreadCrumbElement | null {
    for (const key in routesInput) {
      const title: string = this.getTitle(routesInput[key]);
      if (routesInput[key].name === this.$router.currentRoute.name) {
        return new BreadCrumbElement(this.$router.currentRoute.path, title);
      } else if (routesInput[key].hasOwnProperty("children")) {
        let temp: BreadCrumbElement[] = [
          new BreadCrumbElement(routesInput[key].path, title)
        ];

        const result:
          | BreadCrumbElement[]
          | BreadCrumbElement
          | null = this.getMenu(routesInput[key].children);

        if (result instanceof BreadCrumbElement) {
          if (result && result.name) {
            temp.push(result);
          }
          return temp;
          break;
        } else if (result !== null) {
          temp = temp.concat(result);
          return temp;
          break;
        }
      }
    }

    return null;
  }

  private parseUri(data: Array<BreadCrumbElement>): Array<BreadCrumbElement> {
    let parsedUrl: Array<BreadCrumbElement> = [];
    data.forEach((item: any, index: number) => {
      if (
        this.$router.currentRoute.matched[index] !== null &&
        this.$router.currentRoute.matched[index] !== undefined
      ) {
        const path = this.$router.currentRoute.matched[index].path;
        const params: any = this.$router.currentRoute.params;
        if (typeof params == "object") {
          let parsePath = path;
          for (let key in params) {
            // @ts-ignore
            parsePath = parsePath.replace(
              ":" + String(key),
              String(params[key])
            );
          }
          item.url = parsePath;
          parsedUrl.push(item);
        }
      }
    });
    return parsedUrl;
  }
}
