
import { Component, Prop, Vue } from "vue-property-decorator";
import Alert from "@/components/Alert.vue";
import Loading from "@/components/Loading.vue";

@Component({
  components: {
    Alert,
    Loading
  }
})
export default class NotLoggedInLayout extends Vue {
  public name: string = "NotLoggedInLayout";

  protected currentYear = new Date().getFullYear();

  @Prop({ default: true })
  public isLoading!: Boolean;

  @Prop({ default: "" })
  public headerTitle!: string;

  @Prop({ default: "max-w-100" })
  public widthClass!: string;

  @Prop({ default: "" })
  public wrapperClass!: string;

  @Prop({ default: "" })
  public customAuthContainerClass!: string;
}
