import { MutationTree } from "vuex";
import { State } from "./state";

export function SET_DATA_LIST(state: State, data: any) {
  state.dataList = data;
}

export function SET_DATA_TOTAL(state: State, total: any) {
  state.total = total;
}

export function SET_DATA_ITEM(state: State, item: any) {
  state.dataItem = item;
}

export function SET_DATA_ERROR(state: State, error: any) {
  state.error = error;
}

export function SET_DATA_LOADING(state: State, loading: any) {
  state.loading = loading;
}

export function SET_DATA_CREATED(state: State, created: any) {
  state.created = created;
}

export function SET_DATA_UPDATED(state: State, updated: any) {
  state.updated = updated;
}

export function SET_DATA_DELETED(state: State, deleted: any) {
  state.deleted = deleted;
}
export default <MutationTree<State>>{
  SET_DATA_LIST,
  SET_DATA_ITEM,
  SET_DATA_TOTAL,
  SET_DATA_ERROR,
  SET_DATA_LOADING,
  SET_DATA_CREATED,
  SET_DATA_UPDATED,
  SET_DATA_DELETED
};
