import { ROLES } from "@/config/Roles";
import auth from "@/middlewares/AuthMiddleware";
import i18n from "@/locales";
import Help from "@/views/exhibitor/Help.vue";

export default [
  {
    path: "/help",
    component: Help,
    name: "Help",
    meta: {
      visibleInAside: true,
      roles: [ROLES.EXHIBITOR],
      title: String(i18n.t("menu.help")),
      middleware: [auth]
    }
  }
];
