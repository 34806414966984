
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import CrudLayout from "@/layouts/CrudLayout.vue";
import Validation from "@/components/Validation.vue";
import i18n from "@/locales";
import PageContentForm from "@/views/admin/page/content/PageContentForm.vue";

const namespace: string = "page";
const breadcrumbNamespace: string = "breadcrumb";
const catalogNamespace: string = "catalog";

@Component({
  components: {
    CrudLayout,
    Validation,
    PageContentForm
  }
})
export default class PageContentUpdate extends Vue {
  protected name: string = "PageContentUpdate";
  protected title: string = "";

  @Prop()
  public page: any;

  @Prop({ default: false })
  public isExhibitor?: boolean;

  @Prop()
  public catalog: any;

  @Getter("isLoading", { namespace }) loading: any;

  @Action("resetUpdate", { namespace }) resetAction: any;
  @Action("updateContent", { namespace }) updateItemAction: any;

  @Watch("catalog", { immediate: true, deep: true })
  protected onCatalog(catalog: any) {
    if (catalog) {
      let title: String = "";

      if (this.isExhibitor) {
        title = String(i18n.t("general.update_page_exhibitor"));
      } else {
        title = String(
          i18n.t("general.update_page_content", {
            catalog: catalog.name
          })
        );
      }

      this.$emit("title", title);
    }
  }

  beforeDestroy() {
    this.resetAction();
  }
}
