import { ActionContext, ActionTree } from "vuex";
import { State } from "./state";
import {
  create,
  del,
  findAll,
  findOne,
  paginate,
  resetCreate,
  resetList,
  resetUpdate,
  search,
  update
} from "@/store/modules/crud/functions/actions";
import Echo from "laravel-echo";
// @ts-ignore
window.Pusher = require("pusher-js");
import AuthService from "@/service/AuthService";
import AppConfig from "@/config/AppConfig";
import { ICrudOptions } from "@/service/interfaces/ICrudOptions";

export async function websocket(
  store: ActionContext<any, any>,
  options: ICrudOptions
) {
  const authService: AuthService = new AuthService();
  const token: string = authService.getToken();
  const { id } = authService.getUser();

  // @ts-ignore
  window.Echo = new Echo({
    broadcaster: "pusher",
    key: AppConfig.WS_KEY,
    wsHost: AppConfig.WS_HOST,
    wsPort: AppConfig.WS_PORT,
    disableStats: true,
    encrypted: false,
    enabledTransports: ["ws"],
    forceTLS: false,
    authEndpoint: AppConfig.BROADCAST_AUTH_ENDPOINT,
    auth: {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json"
      }
    }
  });

  // @ts-ignore
  window.Echo.private(`status.${id}`).listen("StatusEvent", (event: any) => {
    store.commit("SET_DATA_LIST", event.data);
  });
}

export default <ActionTree<State, any>>{
  findOne,
  findAll,
  search,
  paginate,
  create,
  update,
  del,
  resetList,
  resetCreate,
  resetUpdate,
  websocket
};
