
import { Vue, Component } from "vue-property-decorator";
import { namespace } from "vuex-class";
import "@/assets/sass/notification.scss";

const NotificationModule = namespace("notification");

@Component
export default class Notification extends Vue {
  public name: string = "Notification";

  @NotificationModule.Getter("getActive")
  public getActive: any;

  @NotificationModule.Getter("getText")
  public getText: any;

  @NotificationModule.Getter("getTheme")
  public getTheme: any;

  public get styles() {
    return `${this.getTheme}`;
  }
}
