
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import CrudLayout from "@/layouts/CrudLayout.vue";
import Validation from "@/components/Validation.vue";
import PageWineUpdate from "@/views/admin/page/winead/update/PageWineUpdate.vue";
import PageAdUpdate from "@/views/admin/page/winead/update/PageAdUpdate.vue";
import PageContentUpdate from "@/views/admin/page/content/PageContentUpdate.vue";

const namespace: string = "page";
const catalogNamespace: string = "catalog";
const breadcrumbNamespace: string = "breadcrumb";

@Component({
  components: {
    PageWineUpdate,
    PageAdUpdate,
    PageContentUpdate,
    CrudLayout,
    Validation
  }
})
export default class PageUpdate extends Vue {
  public name: string = "PageUpdate";
  private resourceName = "pageUpdate";

  protected pageType: number = 0;
  protected pageTitle: any = "";

  @Prop({ default: false })
  public isExhibitor?: boolean;

  @Getter("getError", { namespace }) error: any;
  @Getter("isLoading", { namespace }) loading: any;
  @Getter("isUpdated", { namespace }) updated: any;
  @Getter("getItem", { namespace }) page: any;
  @Getter("getItem", { namespace: catalogNamespace }) catalog: any;

  @Action("update", { namespace }) updateItemAction: any;
  @Action("findOne", { namespace }) getItemAction: any;
  @Action("findOne", { namespace: catalogNamespace }) findCatalogAction: any;
  @Action("mergeData", { namespace: breadcrumbNamespace })
  breadcrumbSetDataAction: any;

  protected setTitle(title: string) {
    this.breadcrumbSetDataAction({
      [String(this.$router.currentRoute.name)]: {
        title: title,
        pageListTitle: this.$t("general.page_from", {
          catalogName: this.catalog.name
        })
      }
    });

    this.pageTitle = title;
  }

  protected title(title: string) {
    if (!this.catalog) {
      const intervalHandler = setInterval(() => {
        if (this.catalog) {
          this.setTitle(title);
          clearInterval(intervalHandler);
        }
      }, 300);
      return;
    }

    this.setTitle(title);
  }

  @Watch("page", { immediate: true, deep: true })
  protected onPage(page: any) {
    if (page) {
      this.pageType = Number(page.page_type);

      this.findCatalogAction({
        resource: catalogNamespace,
        id: page.catalog_id
      });
    }
  }

  beforeMount() {
    const id: number = Number.parseInt(this.$route.params.id);

    this.getItemAction({
      resource: namespace,
      id: id,
      descriptionField: "id"
    });
  }
}
