
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class Upload extends Vue {
  public name: string = "Upload";

  @Prop()
  public validationClass!: any;

  @Prop({ default: false })
  public disabled!: any;

  /*
   Handles a change on the file upload
  */
  public handleFile(): void {
    // @ts-ignore
    this.$emit("file", this.$refs.file.files[0]);
  }
}
