import AppConfig from "@/config/AppConfig";

import moment from "moment";

/**
 *
 */
export default class DateUtil {
  /**
   * Prepare a date received from the server for frontend use
   * @param serverDate
   * @return string
   */
  public static parseServerDate(serverDate: string | Date): string {
    if (typeof serverDate === "string" || serverDate instanceof String) {
      return moment(serverDate, AppConfig.DATE_FORMAT_BACKEND).format(
        AppConfig.DATE_FORMAT_FRONTEND
      );
    } else {
      return moment(serverDate).format(AppConfig.DATE_FORMAT_FRONTEND);
    }
  }

  /**
   * Format the frontend date, so it can be saved in the backend in a proper format
   * @param frontendDate
   * @return string
   */
  public static formatFrontendDate(frontendDate: string | Date): string {
    if (typeof frontendDate === "string" || frontendDate instanceof String) {
      return moment(frontendDate, AppConfig.DATE_FORMAT_FRONTEND).format(
        AppConfig.DATE_FORMAT_BACKEND
      );
    } else {
      return moment(frontendDate).format(AppConfig.DATE_FORMAT_BACKEND);
    }
  }
}
