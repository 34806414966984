
import { Vue, Component } from "vue-property-decorator";
import { BaseMixin } from "@/mixins/baseMixin";
import { Action } from "vuex-class";

const namespace: string = "auth";
@Component({
  components: {},
  mixins: [BaseMixin]
})
export default class LogoutButton extends Vue {
  public name: string = "LogoutButton";

  @Action("logout", { namespace })
  private logout: any;

  public handleLogOut() {
    this.logout();
    document.body.classList.remove("active-header");
  }
}
