import { Route } from "vue-router";
import { getRoleHomeComponentFromStorage } from "@/config/Roles";

export const redirectUrl = (from: Route, next: Function) => {
  if (from.query.redirectUrl) {
    const path = from.query.redirectUrl;
    delete from.query.redirectUrl;
    return next({ path: path });
  }
};

export const hasMetaRoles = (to: Route) => {
  return (
    to.hasOwnProperty("meta") &&
    to.meta &&
    to.meta.hasOwnProperty("roles") &&
    Array.isArray(to.meta.roles)
  );
};

export const redirectToHomePage = (next: Function, to: Route) => {
  const homePage: string = getRoleHomeComponentFromStorage();
  if (to.name === homePage) {
    return next();
  }
  return next({ name: homePage });
};
