
import { Component, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import CrudLayout from "@/layouts/CrudLayout.vue";
import Validation from "@/components/Validation.vue";
import WineForm from "@/views/admin/wine/WineForm.vue";

const namespace: string = "wine";

@Component({
  components: {
    CrudLayout,
    Validation,
    WineForm
  }
})
export default class WineUpdate extends Vue {
  public name: string = "WineUpdate";
  private resourceName = "wine";

  @Getter("getError", { namespace }) error: any;
  @Getter("isLoading", { namespace }) loading: any;
  @Getter("isUpdated", { namespace }) updated: any;
  @Getter("getItem", { namespace }) item: any;

  @Action("updateWine", { namespace }) updateItemAction: any;
  @Action("findOne", { namespace }) getItemAction: any;
  @Action("resetUpdate", { namespace }) resetAction: any;

  protected back() {
    // TODO remember last table state (session storage)
    this.$router.push({ path: "/" + this.resourceName });
  }

  mounted() {
    this.getItemAction({
      resource: this.resourceName,
      id: Number.parseInt(this.$route.params.id),
      descriptionField: "wine"
    });
  }

  beforeDestroy() {
    this.resetAction();
  }
}
