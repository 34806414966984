import { MutationTree } from "vuex";
import { State } from "./state";
import { SET_DATA_POSITION_SWAPPED } from "@/store/modules/crud/functions/positions/mutations";

export function SET_DATA_LIST(state: State, data: any) {
  state.dataList = data;
}

export function SET_DATA_TOTAL(state: State, total: any) {
  state.total = total;
}

export function SET_DATA_POSITION(state: State, position: number) {
  state.position = position;
}

export function SET_DATA_ITEM(state: State, item: any) {
  state.dataItem = item;
}

export function SET_DATA_ERROR(state: State, error: any) {
  state.error = error;
}

export function SET_DATA_LOADING(state: State, loading: any) {
  state.loading = loading;
}

export function SET_DATA_CREATED(state: State, created: any) {
  state.created = created;
}

export function SET_DATA_UPDATED(state: State, updated: any) {
  state.updated = updated;
}

export function SET_DATA_DELETED(state: State, deleted: any) {
  state.deleted = deleted;
}

export function SET_ORDER_SAVING(state: State, saving: boolean) {
  state.savingOrder = saving;
}

export function SET_REDIRECT_CATALOG(state: State, status: boolean) {
  state.redirectCatalogInactive = status;
}

export function SET_DATA_IMPORTED(state: State, imported: Object) {
  state.imported = imported;
}

export default <MutationTree<State>>{
  SET_DATA_LIST,
  SET_DATA_ITEM,
  SET_DATA_TOTAL,
  SET_DATA_ERROR,
  SET_DATA_LOADING,
  SET_DATA_CREATED,
  SET_DATA_UPDATED,
  SET_DATA_DELETED,
  SET_ORDER_SAVING,
  SET_DATA_POSITION,
  SET_REDIRECT_CATALOG,
  SET_DATA_POSITION_SWAPPED,
  SET_DATA_IMPORTED
};
