
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { BaseMixin } from "@/mixins/baseMixin";
import { Action, Getter } from "vuex-class";

const namespace: string = "page";
@Component({
  components: {},
  mixins: [BaseMixin]
})
export default class PagePositionField extends Vue {
  public name: string = "PagePositionField";
  private resourceName = namespace;
  private selectedPosition: number = 0;

  @Getter("getError", { namespace }) error: any;
  @Getter("isLoading", { namespace }) loading: any;
  @Getter("isUpdated", { namespace }) updated: any;

  @Action("changePosition", { namespace }) changePositionAction: any;

  @Prop({ required: true })
  public item!: any;

  protected get position(): number {
    if (this.selectedPosition === 0) {
      this.selectedPosition = this.item.position;
    }
    return this.selectedPosition;
  }

  protected set position(pos: number) {
    if (pos) {
      this.selectedPosition = pos;
    }
  }

  protected updatePosition() {
    this.changePositionAction({
      resource: this.resourceName,
      resourceName: this.$i18n.t("general.page"),
      params: {
        page_id: this.item.id,
        new_position: this.selectedPosition
      }
    });
  }
}
