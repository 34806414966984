export default class ClickOutside {
  public bind(el: HTMLElement, binding: any, vNode: any): void {
    // Provided expression must evaluate to a function.
    if (typeof binding.value !== "function") {
      const compName = vNode.context.name;
      let warn = `[Vue-click-outside:] provided expression '${binding.expression}' is not a function, but has to be`;
      if (compName) {
        warn += `Found in component '${compName}'`;
      }
      console.warn(warn);
    }

    // Define Handler and cache it on the element
    const bubble = binding.modifiers.bubble;
    const handler = (e: any) => {
      if (bubble || (!el.contains(e.target) && el !== e.target)) {
        binding.value(e);
      }
    };
    // @ts-ignore
    el.__vueClickOutside__ = handler;

    // Add Event Listeners
    document.addEventListener("click", handler);
  }

  public unbind(el: HTMLElement, binding: any): void {
    // Remove Event Listeners
    // @ts-ignore
    document.removeEventListener("click", el.__vueClickOutside__);
    // @ts-ignore
    el.__vueClickOutside__ = null;
  }
}
