<template>
  <div class="container">
    <div class="card col-xs-12 mt-4">
      <div class="card-header">
        <form id="connect" class="form-inline !max-w-full" role="form">
          <label class="my-1 mr-2 min-w-auto w-auto" for="app">App:</label>
          <select
            class="form-control form-control-sm mr-2 bg-image-none"
            name="app"
            v-model="app"
            id="app"
          >
            <option v-for="(app, index) in apps" :value="app" :key="index">
              {{ app.name }}
            </option>
          </select>
          <label class="my-1 mr-2 min-w-auto w-auto" for="app">Port:</label>
          <input
            class="form-control form-control-sm mr-2"
            v-model="port"
            placeholder="Port"
          />
          <button
            v-if="!connected"
            type="submit"
            @click.prevent="connect"
            class="mr-2 btn btn-sm btn-primary mt-0"
          >
            Connect
          </button>
          <button
            v-if="connected"
            type="submit"
            @click.prevent="disconnect"
            class="btn btn-sm btn-danger mt-0"
          >
            Disconnect
          </button>
        </form>
        <div id="status">{{ status }}</div>
      </div>
      <div class="card-body">
        <div v-if="connected && app.statisticsEnabled">
          <h4>Realtime Statistics</h4>
          <div ref="plotly" style="width: 100%; height: 250px;"></div>
        </div>
        <div v-if="connected">
          <h4>Event Creator</h4>
          <form class="!max-w-full">
            <div class="row">
              <div class="col">
                <input
                  type="text"
                  class="form-control border"
                  v-model="form.channel"
                  placeholder="Channel"
                />
              </div>
              <div class="col">
                <input
                  type="text"
                  class="form-control border"
                  v-model="form.event"
                  placeholder="Event"
                />
              </div>
            </div>
            <div class="row mt-3">
              <div class="col">
                <div class="form-group">
                  <textarea
                    placeholder="Data"
                    v-model="form.data"
                    class="form-control"
                    id="data"
                    rows="3"
                  ></textarea>
                </div>
              </div>
            </div>
            <div class="row text-right">
              <div class="col">
                <button
                  type="submit"
                  @click.prevent="sendEvent"
                  class="btn btn-sm btn-primary"
                >
                  Send event
                </button>
              </div>
            </div>
          </form>
        </div>
        <h4>Events</h4>
        <table id="events" class="table table-striped table-hover">
          <thead>
            <tr>
              <th>Type</th>
              <th>Socket</th>
              <th>Details</th>
              <th>Time</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(log, index) in logs.slice().reverse()" :key="index">
              <td>
                <span class="badge" :class="getBadgeClass(log)">
                  {{ log.type }}
                </span>
              </td>
              <td>{{ log.socketId }}</td>
              <td>{{ log.details }}</td>
              <td>{{ log.time }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import Plotly from "plotly.js-dist";
import AppConfig from "@/config/AppConfig";
import AuthService from "@/service/AuthService";
import axios from "axios";

const LOG_CHANNEL_PREFIX = "private-websockets-dashboard-";

export default {
  name: "WebsocketsDashboard",

  data: () => {
    return {
      connected: false,
      chart: null,
      pusher: null,
      app: null,
      port: AppConfig.WS_PORT,
      apps: [
        {
          id: process.env.VUE_APP_WS_APP_ID,
          key: process.env.VUE_APP_WS_KEY,
          secret: process.env.VUE_APP_WS_SECRET_KEY,
          name: process.env.VUE_APP_WS_APP_NAME,
          host: null,
          path: null,
          capacity: null,
          clientMessagesEnabled: false,
          statisticsEnabled: true
        }
      ],
      form: {
        channel: null,
        event: null,
        data: null
      },
      logs: [],
      routePath: AppConfig.ENTRYPOINT + "/websockets",
      token: null,
      status: ""
    };
  },

  mounted() {
    this.app = this.apps[0] || null;
    const authService = new AuthService();
    this.token = authService.getToken();
  },

  methods: {
    connect() {
      this.pusher = new Pusher(this.app.key, {
        wsHost:
          this.app.host === null ? window.location.hostname : this.app.host,
        wsPort: this.port === null ? AppConfig.WS_PORT : this.port,
        wssPort: this.port === null ? AppConfig.WS_PORT : this.port,
        wsPath: this.app.path === null ? "" : this.app.path,
        disableStats: true,
        authEndpoint: this.routePath + "/auth",
        forceTLS: false,
        auth: {
          headers: {
            Authorization: `Bearer ${this.token}`,
            Accept: "application/json",
            "X-App-ID": this.app.id
          }
        },
        enabledTransports: ["ws", "flash"]
      });

      this.pusher.connection.bind("state_change", states => {
        this.status = "Channels current state is " + states.current;
      });

      this.pusher.connection.bind("connected", () => {
        this.connected = true;

        this.loadChart();
      });

      this.pusher.connection.bind("disconnected", () => {
        this.connected = false;
        this.logs = [];
      });

      this.pusher.connection.bind("error", event => {
        if (event.error.data.code === 4100) {
          this.status = "Maximum connection limit exceeded!";
          this.connected = false;
          this.logs = [];
          throw new Error("Over capacity");
        }
      });

      this.subscribeToAllChannels();

      this.subscribeToStatistics();
    },

    disconnect() {
      this.pusher.disconnect();
    },

    loadChart() {
      axios
        .get(this.routePath + "/api/" + this.app.id + "/statistics")
        .then(data => {
          let chartData = [
            {
              x: data.data.peak_connections.x,
              y: data.data.peak_connections.y,
              type: "lines",
              name: "# Peak Connections"
            },
            {
              x: data.data.websocket_message_count.x,
              y: data.data.websocket_message_count.y,
              type: "bar",
              name: "# Websocket Messages"
            },
            {
              x: data.data.api_message_count.x,
              y: data.data.api_message_count.y,
              type: "bar",
              name: "# API Messages"
            }
          ];
          let layout = {
            margin: {
              l: 50,
              r: 0,
              b: 50,
              t: 50,
              pad: 4
            }
          };
          const plotlyRef = this.$refs.plotly;
          this.chart = Plotly.newPlot(plotlyRef, chartData, layout);
        })
        .catch(err => {
          console.error(err);
        });
    },

    subscribeToAllChannels() {
      [
        "disconnection",
        "connection",
        "vacated",
        "occupied",
        "subscribed",
        "client-message",
        "api-message"
      ].forEach(channelName => this.subscribeToChannel(channelName));
    },

    subscribeToChannel(channel) {
      this.pusher
        .subscribe(LOG_CHANNEL_PREFIX + channel)
        .bind("log-message", data => {
          this.logs.push(data);
        });
    },

    subscribeToStatistics() {
      this.pusher
        .subscribe(`${LOG_CHANNEL_PREFIX}statistics`)
        .bind("statistics-updated", data => {
          const update = {
            x: [[data.time], [data.time], [data.time]],
            y: [
              [data.peak_connection_count],
              [data.websocket_message_count],
              [data.api_message_count]
            ]
          };
          const plotlyRef = this.$refs.plotly;
          Plotly.extendTraces(plotlyRef, update, [0, 1, 2]);
        });
    },

    getBadgeClass(log) {
      if (log.type === "occupied" || log.type === "connection") {
        return "badge-primary";
      }
      if (log.type === "vacated") {
        return "badge-warning";
      }
      if (log.type === "disconnection") {
        return "badge-error";
      }
      if (log.type === "api_message") {
        return "badge-info";
      }
      return "badge-secondary";
    },

    sendEvent() {
      axios
        .post(this.routePath + "/event", {
          headers: {
            Authorization: "Bearer " + this.token
          },
          key: this.app.key,
          secret: this.app.secret,
          appId: this.app.id,
          channel: this.form.channel,
          event: this.form.event,
          data: this.form.data
        })
        .catch(err => {
          console.error("Error sending event.");
        });
    }
  }
};
</script>
