import ServiceCreate from "@/views/admin/service/ServiceCreate.vue";
import ServiceUpdate from "@/views/admin/service/ServiceUpdate.vue";
import { ROLES } from "@/config/Roles";
import auth from "@/middlewares/AuthMiddleware";
import i18n from "@/locales";
import ServiceView from "@/views/admin/service/ServiceView.vue";
import ServiceList from "@/views/admin/service/ServiceList.vue";

export default [
  {
    path: "/service",
    component: ServiceView,
    meta: {
      visibleInAside: true,
      roles: [ROLES.ADMIN, ROLES.SUPER_ADMIN],
      title: String(i18n.t("menu.service")),
      middleware: [auth],
      avoidBack: true
    },
    children: [
      {
        path: "",
        name: "service",
        component: ServiceList,
        meta: {
          visibleInAside: true,
          title: "",
          roles: [ROLES.ADMIN, ROLES.SUPER_ADMIN],
          middleware: [auth]
        }
      },
      {
        path: "create",
        name: "service_create",
        component: ServiceCreate,
        meta: {
          visibleInAside: true,
          roles: [ROLES.ADMIN, ROLES.SUPER_ADMIN],
          title: String(i18n.t("general.service_add")),
          middleware: [auth],
          avoidBack: true
        }
      },
      {
        path: "edit/:id",
        name: "service_edit",
        component: ServiceUpdate,
        meta: {
          roles: [ROLES.ADMIN, ROLES.SUPER_ADMIN],
          title: String(i18n.t("general.service_edit")),
          middleware: [auth]
        }
      }
    ]
  }
];
