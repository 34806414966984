
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import Dropdown from "@/components/Dropdown.vue";
import { getSymbolByValue } from "@/config/data/AwardSymbols";
import { Action, Getter } from "vuex-class";
import IBottleSize from "@/models/IBottleSize";
import IPageCategory from "@/models/IPageCategory";
import IWine from "@/models/IWine";
import AppConfig from "@/config/AppConfig";
import MultiSelect from "@/components/multiselect/MultiSelect.vue";
import i18n from "@/locales";
import { BModal } from "bootstrap-vue";
// @ts-ignore
import VueLazyInput from "vue-lazy-input";
Vue.use(VueLazyInput);

const namespace: string = "page";
const bottleSizeNamespace: string = "bottlesize";
const pageCategoryNamespace: string = "pagecategory";
const wineNamespace: string = "wine";

@Component({
  components: { Dropdown, MultiSelect, BModal }
})
export default class PageWineApprovedRight extends Vue {
  public name: string = "PageWineApprovedRight";
  private resourceName = "page";
  private awardToSymbol = getSymbolByValue;

  @Getter("getList", { namespace: bottleSizeNamespace })
  bottleSizes?: Array<IBottleSize>;
  @Action("search", { namespace: bottleSizeNamespace })
  bottleSizesGetAction: any;
  @Getter("getList", { namespace: pageCategoryNamespace })
  pageCategories?: Array<IPageCategory>;
  @Getter("isCreated", { namespace: pageCategoryNamespace })
  isPageCategoryCreated?: boolean;
  @Getter("isUpdated", { namespace: pageCategoryNamespace })
  isPageCategoryUpdated?: boolean;
  @Getter("isDeleted", { namespace: pageCategoryNamespace })
  isPageCategoryDeleted?: boolean;
  @Getter("isSwapped", { namespace: pageCategoryNamespace })
  isPageCategorySwapped?: boolean;
  @Action("findApprovedByPage", { namespace: pageCategoryNamespace })
  pageCategoriesGetAction: any;
  @Action("create", { namespace: pageCategoryNamespace })
  pageCategoriesCreateAction: any;
  @Action("update", { namespace: pageCategoryNamespace })
  pageCategoriesUpdateAction: any;
  @Action("del", { namespace: pageCategoryNamespace })
  pageCategoriesDeleteAction: any;
  @Action("swapPositions", { namespace: pageCategoryNamespace })
  pageCategoriesSwapPositionsAction: any;
  @Action("swapWinePositions", { namespace: pageCategoryNamespace })
  pageCategoriesWineSwapPositionsAction: any;
  @Action("swapWineCategory", { namespace: pageCategoryNamespace })
  pageCategoriesWineSwapCategoryAction: any;
  @Action("addWine", { namespace: pageCategoryNamespace })
  pageCategoriesAddWineAction: any;
  @Action("removeWine", { namespace: pageCategoryNamespace })
  pageCategoriesRemoveWineAction: any;
  @Getter("getList", { namespace: wineNamespace })
  wines?: Array<IWine>;
  @Action("search", { namespace: wineNamespace })
  winesGetAction: any;
  @Action("addPrice", { namespace: wineNamespace })
  addPriceAction: any;

  private readonly MAX_ROW_COUNT = 27;
  private selectedForRemovalCategory: IPageCategory | null = null;
  private deleteCategoryMessage: string = "";
  private filteredWines: Array<IWine> = [];
  public newBottleSize1: IBottleSize | null = null;
  public newBottleSize2: IBottleSize | null = null;
  protected newCategoryRow: IPageCategory = {
    id: 0,
    category: "",
    position: 100,
    bottle_size1: this.newBottleSize1,
    bottle_size2: this.newBottleSize2
  };
  public newWineRows: Array<IWine | null> = [];
  @Prop()
  public item: any;
  @Prop({
    default: function() {}
  })
  public saveAction: any;

  protected host = process.env.VUE_APP_BACKEND_HOST;

  @Watch("isPageCategoryCreated")
  protected onPageCategoryCreated(created: boolean) {
    if (created) {
      this.initNewPageCategory();
      this.loadPageCategories();
    }
  }

  @Watch("isPageCategoryUpdated")
  protected onPageCategoryUpdated(updated: boolean): void {
    this.loadPageCategories();
  }

  @Watch("isPageCategoryDeleted")
  protected onPageCategoryDeleted(deleted: boolean): void {
    if (deleted) {
      this.loadPageCategories();
    }
  }

  @Watch("isPageCategorySwapped")
  protected onPageCategoriesSwapped(swapped: boolean): void {
    if (swapped) {
      this.loadPageCategories();
    }
  }

  @Watch("pageCategories")
  protected onPageCategoriesLoaded(pageCategories: Array<IPageCategory>) {
    if (pageCategories) {
      this.filterWines();
    }
  }

  @Watch("wines")
  protected onWinesLoaded(wines: Array<IWine>) {
    if (wines) {
      this.filterWines();
    }
  }

  @Watch("pageCategories")
  protected onPageCategoriesLoad(categories: Array<IPageCategory>): void {
    let rowCount: number = 0;
    this.newWineRows = [];
    for (const category of categories) {
      rowCount++;
      // filter the bottle_sizes
      if (category.wines) {
        for (const wine of category.wines) {
          rowCount++;
          const price1: any = wine.bottle_sizes.find(
            (bottleSize: { bottle_size_id: number | undefined }) =>
              category.bottle_size1 &&
              bottleSize.bottle_size_id == category.bottle_size1.id
          );
          if (price1) {
            wine.price1 = price1.price;
          }
          const price2: any = wine.bottle_sizes.find(
            (bottleSize: { bottle_size_id: number | undefined }) =>
              category.bottle_size2 &&
              bottleSize.bottle_size_id == category.bottle_size2.id
          );
          if (price2) {
            wine.price2 = price2.price;
          }
        }
      }
      // add a add wine row
      const newWineRow: IWine | null = null;
      this.newWineRows.push(newWineRow);
    }
    if (this.item && this.item.footer) {
      rowCount += this.item.footer.split(/\r\n|\r|\n/).length;
    }
    if (rowCount >= this.MAX_ROW_COUNT) {
      this.$bvModal.show("modal-page-row-count-warning");
    }
  }

  protected getWinePrice(
    item: any,
    category: IPageCategory | null,
    type: number = 1
  ) {
    if (!category) {
      return "";
    }
    const categoryBottleSize: IBottleSize | null | undefined =
      type === 1 ? category.bottle_size1 : category.bottle_size2;
    if (!categoryBottleSize || !categoryBottleSize.size) {
      return "";
    }
    const wineBottleSize: any =
      type === 1 ? item.bottle_size1 : item.bottle_size2;
    if (categoryBottleSize.size != wineBottleSize.size) {
      return "";
    }
    return wineBottleSize.price;
  }

  protected categoryDragStartHandler(
    $event: DragEvent,
    category: IPageCategory
  ): void {
    const dataTransfer: any = $event.dataTransfer;
    if (!dataTransfer) {
      throw "Data transfer not defined!";
    }
    dataTransfer.setData("category", String(category.id));
  }

  protected categoryDragOverHandler($event: DragEvent): void {
    $event.preventDefault();
  }

  protected categoryDropHandler(
    $event: DragEvent,
    category: IPageCategory
  ): void {
    $event.preventDefault();

    const dataTransfer: any = $event.dataTransfer;
    if (!dataTransfer) {
      throw "Data transfer not defined!";
    }
    const categoryId = category.id;
    if (!categoryId) {
      throw "Missing category id!";
    }

    // drop category into category
    const droppedCategoryId = dataTransfer.getData("category");
    if (droppedCategoryId) {
      this.swapCategoryPositions(
        Number.parseInt(droppedCategoryId),
        categoryId
      );
    }

    // drop wine into category
    const droppedWineId = dataTransfer.getData("wine");
    const droppedWineCategoryId = dataTransfer.getData("wineCategory");
    if (droppedWineId && droppedWineCategoryId) {
      this.swapWineCategory(
        Number.parseInt(droppedWineId),
        Number.parseInt(droppedWineCategoryId),
        categoryId
      );
    }
  }

  protected wineDragStartHandler(
    $event: DragEvent,
    wine: IWine,
    category: IPageCategory
  ): void {
    const dataTransfer: any = $event.dataTransfer;
    if (!dataTransfer) {
      throw "Data transfer not defined!";
    }
    dataTransfer.setData("wine", String(wine.id));
    dataTransfer.setData("wineCategory", String(category.id));
  }

  protected wineDragOverHandler($event: DragEvent): void {
    $event.preventDefault();
  }

  protected wineDropHandler(
    $event: DragEvent,
    wine: IWine,
    category: IPageCategory
  ): void {
    $event.preventDefault();
    const dataTransfer: any = $event.dataTransfer;
    if (!dataTransfer) {
      throw "Data transfer not defined!";
    }
    const wineId = wine.id;
    if (!wineId) {
      throw "Missing wine id!";
    }
    const categoryId = category.id;
    if (!categoryId) {
      throw "Missing category id!";
    }
    const droppedWineId = dataTransfer.getData("wine");
    const droppedCategoryId = dataTransfer.getData("wineCategory");
    if (droppedWineId && droppedCategoryId) {
      this.swapWinePositions(
        Number.parseInt(droppedWineId),
        wineId,
        Number.parseInt(droppedCategoryId),
        categoryId
      );
    }
  }

  private initNewPageCategory(): void {
    this.newCategoryRow = {
      id: 0,
      category: "",
      page_id: this.item.id,
      bottle_size1: this.newBottleSize1,
      bottle_size2: this.newBottleSize2
    };
  }

  private loadPageCategories(): void {
    this.pageCategoriesGetAction({
      resource: "page-category",
      params: {
        page_id: this.$route.params.id
      }
    });
  }

  protected addCategory(): void {
    this.newCategoryRow.page_id = this.item.id;
    this.pageCategoriesCreateAction({
      resource: "page-category",
      data: this.newCategoryRow,
      descriptionField: "category"
    });
  }

  protected updateCategory(category: IPageCategory): void {
    this.pageCategoriesUpdateAction({
      resource: "page-category",
      data: category,
      id: category.id,
      descriptionField: "category"
    });
  }

  protected swapCategoryPositions(
    categoryId1: number,
    categoryId2: number
  ): void {
    this.pageCategoriesSwapPositionsAction({
      resource: "page-category",
      params: {
        id1: categoryId1,
        id2: categoryId2
      },
      descriptionField: "category"
    });
  }

  protected showRemoveCategoryModal(category: IPageCategory): void {
    this.selectedForRemovalCategory = category;
    this.$bvModal.show("modal-delete-page-category-confirm");
    this.deleteCategoryMessage = String(
      i18n.t("general.question_sure_that_you_want_to_delete_category", {
        category: this.selectedForRemovalCategory.category
      })
    );
  }

  protected removeSelectedCategory(): void {
    this.$bvModal.hide("modal-delete-page-category-confirm");
    if (this.selectedForRemovalCategory) {
      this.removeCategory(this.selectedForRemovalCategory);
    } else {
      throw "No category selected";
    }
  }

  /**
   *
   * @param category
   */
  protected removeCategory(category: IPageCategory): void {
    this.newCategoryRow.page_id = this.item.id;
    this.pageCategoriesDeleteAction({
      resource: "page-category",
      resourceName: "category",
      id: category.id,
      descriptionField: category.category
    });
  }

  protected addWine(wine: IWine, category: IPageCategory): void {
    if (!wine) {
      return;
    }
    this.newCategoryRow.page_id = this.item.id;
    this.pageCategoriesAddWineAction({
      resource: "page-category",
      resourceName: "category",
      params: {
        category_id: category.id,
        wine_id: wine.id,
        wine_name: wine.wine
      },
      descriptionField: "category"
    });
  }

  protected removeWine(wine: IWine, category: IPageCategory): void {
    if (!wine) {
      return;
    }
    this.newCategoryRow.page_id = this.item.id;
    this.pageCategoriesRemoveWineAction({
      resource: "page-category",
      resourceName: "category",
      params: {
        category_id: category.id,
        wine_id: wine.id,
        wine_name: wine.wine
      },
      descriptionField: "category"
    });
  }

  protected swapWinePositions(
    wineId1: number,
    wineId2: number,
    categoryId1: number,
    categoryId2: number
  ): void {
    this.pageCategoriesWineSwapPositionsAction({
      resource: "page-category",
      params: {
        wine_id_1: wineId1,
        wine_id_2: wineId2,
        category_id_1: categoryId1,
        category_id_2: categoryId2
      },
      descriptionField: "category"
    });
  }

  protected swapWineCategory(
    wineId1: number,
    categoryId1: number,
    categoryId2: number
  ): void {
    this.pageCategoriesWineSwapCategoryAction({
      resource: "page-category",
      params: {
        wine_id_1: wineId1,
        category_id_1: categoryId1,
        category_id_2: categoryId2
      },
      descriptionField: "category"
    });
  }

  protected filterWines(): void {
    if (!this.wines) {
      return;
    }
    this.filteredWines = this.wines.filter((wine: IWine) => {
      if (!this.pageCategories) {
        return true;
      }
      for (const category of this.pageCategories) {
        if (!category.wines) {
          return true;
        }
        for (const categoryWine of category.wines) {
          if (wine.id === categoryWine.id) {
            return false;
          }
        }
      }
      return true;
    });
  }

  protected updatePrice(wine: IWine, category: IPageCategory, type: number) {
    let price: any = type === 1 ? wine.price1 : wine.price2;
    if (!price) {
      price = 0;
    }
    let bottleSizeId: any = 0;
    const bottleSize1 = category.bottle_size1;
    if (type === 1) {
      if (!bottleSize1) {
        throw "Bottle size undefined!";
      } else {
        bottleSizeId = bottleSize1.id;
      }
    }
    const bottleSize2 = category.bottle_size2;
    if (type === 2) {
      if (!bottleSize2) {
        throw "Bottle size undefined!";
      } else {
        bottleSizeId = bottleSize2.id;
      }
    }

    this.addPriceAction({
      resource: "wine",
      resourceName: "wine",
      params: {
        wine_id: wine.id,
        wine_name: wine.wine,
        bottle_size_id: bottleSizeId,
        price: price
      },
      descriptionField: "wine"
    });
  }

  public created() {
    this.bottleSizesGetAction({
      resource: "bottle-size",
      params: {
        currentPerPage: AppConfig.DEFAULT_TABLE_RESULTS_PER_PAGE,
        currentPage: 1
      }
    });
    this.winesGetAction({
      resource: "wine",
      params: {
        currentPerPage: AppConfig.DEFAULT_TABLE_RESULTS_PER_PAGE,
        currentPage: 1
      }
    });
    this.loadPageCategories();
  }

  protected save(): void {
    this.saveAction({
      resource: "page",
      resourceName: String(i18n.t("general.page")),
      data: this.item,
      id: this.item.id
    });
  }
}
