import { ROLES } from "@/config/Roles";
import auth from "@/middlewares/AuthMiddleware";
import i18n from "@/locales";
import RequestCreateUpdate from "@/views/exhibitor/request/RequestCreateUpdate.vue";
import checkCatalog from "@/middlewares/CheckCatalogMiddleware";

export default [
  {
    path: "/request",
    component: RequestCreateUpdate,
    name: "Request",
    meta: {
      visibleInAside: true,
      roles: [ROLES.EXHIBITOR],
      title: String(i18n.t("menu.request")),
      middleware: [auth, checkCatalog],
      // Work witch Bootstrap icon
      icon: {
        name: "bell-fill",
        variant: "danger",
        storeAction: "catalog/getUserCatalogRequest"
      }
    }
  }
];
