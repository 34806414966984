
// @ts-ignore
import VueSlickCarousel from "vue-slick-carousel";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import AppConfig from "@/config/AppConfig";
import ILogo from "@/models/ILogo";
import AuthImage from "@/directives/AuthImage";
import IPage from "@/models/IPage";
import i18n from "@/locales";
import AuthService from "@/service/AuthService";

const namespace: string = "page";
const logoNamespace: string = "logo";

@Component({
  components: { VueSlickCarousel },
  directives: { AuthImage: new AuthImage() }
})
export default class PageWineLeft extends Vue {
  public name: string = "PageWineLeft";

  @Getter("getList", { namespace: logoNamespace })
  protected logos: any;

  @Action("findByExhibitor", { namespace: logoNamespace })
  protected findByExhibitorAction: any;

  @Action("search", { namespace: logoNamespace })
  protected searchAction: any;

  @Action("resetList", { namespace: logoNamespace })
  protected resetLogoListAction: any;

  @Prop()
  public item: any;

  @Prop({ default: () => {} })
  public saveAction: any;

  @Prop()
  protected userId: any;

  protected host = process.env.VUE_APP_BACKEND_HOST;
  private selectedLogo: ILogo | null = null;

  @Watch("userId", { immediate: true, deep: true })
  protected onUserIdChange(uId?: number) {
    this.$nextTick(function() {
      this.loadLogos();
    });
  }

  @Watch("logos", { immediate: true, deep: true })
  protected onLogosLoaded(logos?: Array<ILogo>) {
    this.$nextTick(function() {
      this.gotoItemLogo();
    });
  }

  @Watch("item", { immediate: true, deep: true })
  protected onPageLoaded(item?: IPage) {
    this.$nextTick(function() {
      this.gotoItemLogo();
    });
  }

  private loadLogos() {
    const authService = new AuthService();
    const isSelf: boolean = authService.getUserId() == this.userId;
    if (isSelf) {
      this.searchAction({
        resource: logoNamespace,
        params: {
          currentPerPage: AppConfig.DEFAULT_TABLE_RESULTS_PER_PAGE,
          currentPage: 1
        }
      });
    } else {
      this.findByExhibitorAction({
        resource: logoNamespace,
        params: {
          exhibitor_id: this.userId,
          currentPerPage: AppConfig.DEFAULT_TABLE_RESULTS_PER_PAGE,
          currentPage: 1
        }
      });
    }
  }

  /**
   * Goto page logo slide, if any
   * Execute when logos and page are both loaded
   **/
  private gotoItemLogo(): void {
    if (
      this.$refs.carousel &&
      this.item &&
      this.item.logo_id &&
      this.logos &&
      this.logos.length > 0
    ) {
      const selectedLogoIndex = this.logos.findIndex(
        (logo: { id: number }) => logo.id === this.item.logo_id
      );
      if (selectedLogoIndex > -1) {
        this.selectedLogo = this.logos[selectedLogoIndex];
        (this.$refs.carousel as VueSlickCarousel).goTo(
          selectedLogoIndex,
          false
        );
      }
    }
  }

  /**
   *
   * @param slideIndex
   */
  protected carouselAfterChangeHandler(slideIndex: number): void {
    this.selectedLogo = this.logos[slideIndex];
  }

  protected saveLogo(): void {
    if (!this.selectedLogo || !this.selectedLogo.id) {
      if (this.logos && this.logos.length > 0 && this.logos[0]) {
        this.selectedLogo = this.logos[0];
        this.item.logo_id = this.logos[0];
      } else {
        return;
      }
    }
    if (this.selectedLogo) {
      this.item.logo_id = this.selectedLogo.id;
    }
    this.saveAction({
      resource: "page",
      resourceName: "Die " + String(i18n.t("general.logo")),
      data: this.item,
      id: this.item.id
    });
  }
  beforeDestroy() {
    this.resetLogoListAction();
    this.selectedLogo = null;
  }
}
