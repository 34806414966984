import { MutationTree } from "vuex";
import { State } from "./state";
import {
  SET_DATA_LIST,
  SET_DATA_ITEM,
  SET_DATA_TOTAL,
  SET_DATA_ERROR,
  SET_DATA_LOADING,
  SET_DATA_CREATED,
  SET_DATA_UPDATED,
  SET_DATA_DELETED
} from "@/store/modules/crud/functions/mutations";

import { SET_DATA_POSITION_SWAPPED } from "@/store/modules/crud/functions/positions/mutations";
export default <MutationTree<State>>{
  SET_DATA_LIST,
  SET_DATA_ITEM,
  SET_DATA_TOTAL,
  SET_DATA_ERROR,
  SET_DATA_LOADING,
  SET_DATA_CREATED,
  SET_DATA_UPDATED,
  SET_DATA_DELETED,
  SET_DATA_POSITION_SWAPPED
};
