import { ActionContext } from "vuex";
import CrudService from "@/service/CrudService";
import { ICrudOptions } from "@/service/interfaces/ICrudOptions";
import ErrorHelper from "@/utils/error/ErrorHelper";

/**
 *Basic CRUD actions
 */
export default class CrudResetActions {
  public resourceName: String;

  constructor(resourceName: string = "DATA") {
    this.resourceName = resourceName;
  }

  /**
   *
   * @param store
   * @param options
   */
  public resetList(
    store: ActionContext<any, any>,
    options: ICrudOptions
  ): void {
    store.commit("SET_" + this.resourceName + "_LIST", []);
    store.commit("SET_" + this.resourceName + "_TOTAL", 0);
    store.commit("SET_" + this.resourceName + "_ERROR", null);
    store.commit("SET_" + this.resourceName + "_LOADING", false);
  }

  /**
   *
   * @param store
   * @param options
   */
  public resetCreate(
    store: ActionContext<any, any>,
    options: ICrudOptions
  ): void {
    store.commit("SET_" + this.resourceName + "_CREATED", false);
    store.commit("SET_" + this.resourceName + "_ITEM", null);
    store.commit("SET_" + this.resourceName + "_ERROR", null);
    store.commit("SET_" + this.resourceName + "_LOADING", false);
  }

  /**
   *
   * @param store
   * @param options
   */
  public resetUpdate(
    store: ActionContext<any, any>,
    options: ICrudOptions
  ): void {
    store.commit("SET_" + this.resourceName + "_UPDATED", false);
    store.commit("SET_" + this.resourceName + "_ITEM", null);
    store.commit("SET_" + this.resourceName + "_ERROR", null);
    store.commit("SET_" + this.resourceName + "_LOADING", false);
  }
}
