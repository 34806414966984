import { GetterTree } from "vuex";
import { State } from "./state";
import {
  getList,
  getTotal,
  getItem,
  getError,
  isLoading,
  isCreated,
  isUpdated,
  isDeleted
} from "@/store/modules/crud/functions/getters";

import { isSwapped } from "@/store/modules/crud/functions/positions/getters";
import { CrudState } from "@/store/modules/crud/State";
import IWine from "@/models/IWine";
import IPageCategory from "@/models/IPageCategory";

export function getAllAttachedWines(state: CrudState): Array<IWine> {
  let wines: Array<IWine> = [];
  if (state.dataList && state.dataList.length) {
    state.dataList.forEach((pageCategory: IPageCategory) => {
      pageCategory.wines?.forEach((wine: IWine) => {
        wines.push(wine);
      });
    });
    return wines;
  }

  return [];
}

export default <GetterTree<State, any>>{
  getList,
  getTotal,
  getItem,
  getError,
  isLoading,
  isCreated,
  isUpdated,
  isDeleted,
  isSwapped,
  getAllAttachedWines
};
