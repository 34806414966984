import { ActionContext, ActionTree } from "vuex";
import { State } from "./state";
import { ICrudOptions } from "@/service/interfaces/ICrudOptions";
import axios from "axios";
import ErrorHelper from "@/utils/error/ErrorHelper";
import AppConfig from "@/config/AppConfig";
import i18n from "@/locales";
import { crudActions } from "@/store/modules/crud/functions/actions";

const BASE_URL: string = AppConfig.ENTRYPOINT + "/api/";

export async function generateCatalog(
  store: ActionContext<State, any>,
  options: ICrudOptions
): Promise<any> {
  // await crudActions.dispatchLoading(store, true);
  return axios
    .post(BASE_URL + options.resource + "/" + options.id)
    .then((response: any) => {
      store.dispatch(
        "snackbar/setText",
        {
          text: String(i18n.t("general.generate_catalog_message_success")),
          type: "success"
        },
        { root: true }
      );
      store.commit("SET_DATA_GENERATED_CATALOG", response.data);
      store.commit("SET_DATA_ERROR", null);
      // crudActions.dispatchLoading(store, false);
    })
    .catch((error: any) => {
      const errorMessage =
        typeof error.response == "undefined"
          ? "general.unknown_error"
          : error.response.data.message;
      //crudActions.dispatchLoading(store, false);
      store.commit("SET_DATA_ERROR", ErrorHelper.getError(error));
      crudActions.rejectWriteAction(
        store,
        String(i18n.t(errorMessage)),
        options
      );
    });
}

export async function countCatalogPages(
  store: ActionContext<State, any>,
  options: ICrudOptions
): Promise<any> {
  await crudActions.dispatchLoading(store, true);
  return axios
    .post(BASE_URL + options.resource + "/" + options.id)
    .then((response: any) => {
      store.commit("SET_DATA_COUNT_CATALOG_PAGES", response.data);
      store.commit("SET_DATA_ERROR", null);
      crudActions.dispatchLoading(store, false);
    })
    .catch((error: any) => {
      const errorMessage =
        typeof error.response == "undefined"
          ? "general.unknown_error"
          : error.response.data.message;
      crudActions.dispatchLoading(store, false);
      store.commit("SET_DATA_ERROR", ErrorHelper.getError(error));
      crudActions.rejectWriteAction(
        store,
        String(i18n.t(errorMessage)),
        options
      );
    });
}

export default <ActionTree<State, any>>{
  generateCatalog,
  countCatalogPages
};
