import PageView from "@/views/admin/page/PageView.vue";
import PageList from "@/views/admin/page/PageList.vue";
import PageCreate from "@/views/admin/page/PageCreate.vue";
import PageUpdate from "@/views/admin/page/PageUpdate.vue";
import { ROLES } from "@/config/Roles";
import auth from "@/middlewares/AuthMiddleware";
import i18n from "@/locales";
import PageContentCreate from "@/views/admin/page/content/PageContentCreate.vue";
import CheckPageCatalogMiddleware from "@/middlewares/CheckPageCatalogMiddleware";
import PageWineAdCreate from "@/views/admin/page/winead/PageWineAdCreate.vue";
import { WINE_TYPE, WINE_TYPES } from "@/config/PageTypes";
// TODO rename routes
export default [
  {
    path: "/active-catalog/page",
    component: PageView,
    meta: {
      visibleInAside: true,
      roles: [ROLES.ADMIN, ROLES.SUPER_ADMIN],
      title: String(i18n.t("menu.page")),
      middleware: [auth]
    },
    children: [
      {
        path: "",
        name: "ActiveCatalog",
        component: PageList,
        meta: {
          visibleInAside: false,
          title: "",
          roles: [ROLES.ADMIN, ROLES.SUPER_ADMIN],
          middleware: [auth]
        }
      },
      {
        path: "create",
        name: "page_create",
        component: PageCreate,
        meta: {
          visibleInAside: false,
          roles: [ROLES.ADMIN, ROLES.SUPER_ADMIN],
          title: String(i18n.t("general.add_page")),
          middleware: [auth],
          avoidBack: true
        }
      },
      {
        path: "edit/:id",
        name: "active_page_edit",
        component: PageUpdate,
        meta: {
          roles: [ROLES.ADMIN, ROLES.SUPER_ADMIN],
          title: (route: any, data: any) => {
            if (data && data.hasOwnProperty("title")) {
              return String(data.title);
            }
            return "";
          },
          middleware: [auth]
        }
      },
      {
        path: "wine/create/:id",
        name: "active_page_wine_create",
        component: PageWineAdCreate,
        meta: {
          roles: [ROLES.EXHIBITOR, ROLES.ADMIN, ROLES.SUPER_ADMIN],
          title: (route: any, data: any) => {
            if (data && data.hasOwnProperty("catalog")) {
              const id: number = parseInt(route.params.id);
              if (WINE_TYPES.indexOf(id) > -1) {
                const key =
                  id == WINE_TYPE
                    ? "general.page_wine_add"
                    : "general.page_ad_add";

                return String(
                  i18n.t(key, {
                    catalog: data.catalog
                  })
                );
              }
            }
            return "";
          },
          middleware: [auth, CheckPageCatalogMiddleware]
        }
      },
      {
        path: "content/create",
        name: "active_page_content_create",
        component: PageContentCreate,
        meta: {
          roles: [ROLES.ADMIN, ROLES.SUPER_ADMIN],
          title: (route: any, data: any) => {
            if (data && data.hasOwnProperty("catalog")) {
              return String(
                i18n.t("general.page_content_add", {
                  catalog: data.catalog
                })
              );
            }
            return "";
          },
          middleware: [auth, CheckPageCatalogMiddleware]
        }
      },
      {
        path: "wine/create/1",
        component: PageWineAdCreate,
        name: "page_wine_create",
        meta: {
          visibleInAside: true,
          title: String(i18n.t("general.add_page_wine")),
          roles: [ROLES.ADMIN, ROLES.SUPER_ADMIN],
          middleware: [auth]
        }
      },
      {
        path: "wine/create/2",
        component: PageWineAdCreate,
        name: "page_ad_create",
        meta: {
          visibleInAside: true,
          title: String(i18n.t("general.add_page_ad")),
          roles: [ROLES.ADMIN, ROLES.SUPER_ADMIN],
          middleware: [auth]
        }
      },
      {
        path: "content/create",
        component: PageContentCreate,
        name: "page_content_create",
        meta: {
          visibleInAside: true,
          title: String(i18n.t("general.add_page_content")),
          roles: [ROLES.ADMIN, ROLES.SUPER_ADMIN],
          middleware: [auth]
        }
      }
    ]
  }
];
