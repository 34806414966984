import { MutationTree } from "vuex";
import { State } from "./state";

export function SET_DATA(state: State, data: any) {
  state.tableData = data;
}

export default <MutationTree<State>>{
  SET_DATA
};
