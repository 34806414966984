
import { Vue, Component, Prop } from "vue-property-decorator";
import { Validations } from "vuelidate-property-decorators";
import { required, email, sameAs, minLength } from "vuelidate/lib/validators";
import Validation from "@/components/Validation.vue";

@Component({
  components: {
    Validation
  }
})
export default class LoginForm extends Vue {
  public name: string = "LoginForm";

  @Prop({ required: false, default: false })
  public reset!: boolean;

  protected showErrors = true;

  protected email: string = "";
  protected password: string = "";

  @Validations()
  validations() {
    let fields = {};
    fields = Object.assign({}, fields, {
      email: { required, email },
      password: { required, minLength: minLength(6) }
    });
    return fields;
  }

  public onSubmit(): void {
    this.$v.$touch();
    this.showErrors = true;
    if (this.$v.$invalid) {
      return;
    }

    this.$emit("onSubmit", {
      email: this.email,
      password: this.password
    });

    this.showErrors = false;

    if (this.reset) {
      this.email = "";
      this.password = "";
    }
  }
}
