// TODO find a way out of state repetition
import { ActionContext, ActionTree } from "vuex";
import { State } from "./state";
import { ICrudOptions } from "@/service/interfaces/ICrudOptions";
import CrudActions from "@/store/modules/crud/classes/CrudActions";

const crudActions: CrudActions = new CrudActions();

export function mergeData(
  store: ActionContext<State, any>,
  options: ICrudOptions
): void {
  store.commit("SET_DATA_UPDATE", options);
}

export default <ActionTree<State, any>>{
  mergeData
};
