
import { Vue, Component } from "vue-property-decorator";
import { BaseMixin } from "@/mixins/baseMixin";

@Component({
  components: {},
  mixins: [BaseMixin]
})
export default class ResetFiltersButton extends Vue {
  public name: string = "ResetFiltersButton";

  public resetFilters(): void {
    this.$emit("resetFilters", true);
  }
}
