
import { Vue, Component, Prop } from "vue-property-decorator";
import { BaseMixin } from "@/mixins/baseMixin";
import LinkButton from "@/components/buttons/LinkButton.vue";

@Component({
  components: { LinkButton },
  mixins: [BaseMixin]
})
export default class PasswordResetButton extends Vue {
  public name: string = "PasswordResetButton";

  @Prop({ default: "" })
  public to: any;

  @Prop({ default: {} })
  public item: any;

  get toUrl(): string {
    return this.to
      ? this.to
      : this.$router.currentRoute.path + "/password-reset/" + this.item.id;
  }
}
