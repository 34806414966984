
import { Component, Vue, Watch } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import CrudLayout from "@/layouts/CrudLayout.vue";
import Validation from "@/components/Validation.vue";
import i18n from "@/locales/index";
import Snackbar from "@/components/snackbar/Snackbar.vue";
import Loading from "@/components/Loading.vue";
import { Validate } from "vuelidate-property-decorators";
import { maxLength, minLength, required } from "vuelidate/lib/validators";
import Dropdown from "@/components/Dropdown.vue";
import IExhibitorMail from "@/models/IExhibitorMails";

const namespace: string = "exhibitor";
const snackbarNamespace: string = "snackbar";

@Component({
  components: {
    CrudLayout,
    Validation,
    Loading,
    Snackbar,
    Dropdown
  }
})
export default class ExhibitorSendMails extends Vue {
  public name: string = "ExhibitorSendMails";
  private resourceName = "exhibitor";

  @Action("setText", { namespace: snackbarNamespace })
  protected setText: any;

  @Action("setActive", { namespace: snackbarNamespace })
  protected setActive: any;

  @Validate({ required, minLength: minLength(3), maxLength: maxLength(50) })
  public subject: string = "";

  @Validate({ required, minLength: minLength(10) })
  public message: string = "";

  @Getter("getError", { namespace }) error: any;
  @Getter("isLoading", { namespace }) loading: any;
  @Getter("getItem", { namespace }) createdItem: any;
  @Getter("getCatalogs", { namespace }) catalogItems!: any[];
  @Getter("isMailSent", { namespace }) isMailSent!: boolean;
  @Getter("isCatalogSet", { namespace }) isCatalogSet!: boolean;

  @Action("sendMail", { namespace }) sendMailAction: any;
  @Action("loadCatalogs", { namespace }) loadCatalogs: any;
  @Action("resetCreate", { namespace }) resetAction: any;

  protected get getCatalogName(): string {
    if (this.catalogItems.length > 0) {
      return this.catalogItems[0].name;
    } else {
      return String(i18n.t("general.no_active_catalog"));
    }
  }

  protected get getCatalogId(): number {
    if (this.catalogItems.length > 0) {
      return this.catalogItems[0].value;
    }
    return 0;
  }

  @Watch("isMailSent", { immediate: true, deep: true })
  protected isMailSentWatcher(mailSended: boolean) {
    if (mailSended) {
      this.subject = "";
      this.message = "";
      this.setText({
        text: String(i18n.t("general.catalog_mail_send_messages")),
        type: "success"
      });
      this.setActive(true);
    }
  }

  @Watch("isCatalogSet", { immediate: true, deep: true })
  protected isCatalogSetWatcher(status: boolean) {
    if (status == true && this.catalogItems.length == 0) {
      this.missingCatalogMessage();
    }
  }

  private missingCatalogMessage(): void {
    this.setText({
      text: String(i18n.t("general.exhibitor_catalog_missing")),
      type: "danger"
    });
    this.setActive(true);
  }

  public created(): void {
    this.loadCatalogs({ resource: this.resourceName });
  }

  public onSubmit(): void {
    this.$v.$touch();
    if (this.$v.$invalid) {
      return;
    }

    if (this.getCatalogId == 0) {
      this.missingCatalogMessage();
      return;
    }

    const mail: IExhibitorMail = {
      catalog_id: this.getCatalogId,
      subject: this.subject,
      text: this.message
    };

    this.sendMailAction({ resource: this.resourceName, data: mail }).then(
      () => {
        this.$v.$reset();
      }
    );
  }

  protected back() {
    this.$router.go(-1);
  }

  beforeDestroy() {
    this.resetAction();
  }
}
