
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import CrudLayout from "@/layouts/CrudLayout.vue";
import { Validate } from "vuelidate-property-decorators";
import { minLength, required } from "vuelidate/lib/validators";
import Validation from "@/components/Validation.vue";
import IPageTitle from "@/models/IPageTitle";
import FormBackButton from "@/components/buttons/FormBackButton.vue";

const namespace: string = "page_title";

@Component({
  components: {
    FormBackButton,
    CrudLayout,
    Validation
  }
})
export default class PageTitleForm extends Vue {
  // component initial values
  public name: string = "PageTitleForm";
  private resourceName = "page-title";
  private resourceLabelName = this.resourceName.replace("-", "_");

  // props
  @Prop({ default: "add" })
  public type: any;
  @Prop({ default: false })
  public saved: any;
  @Prop({ default: false })
  public resetAction: any;
  @Prop({ default: false })
  public loading: any;
  @Prop({ default: "" })
  public error: any;
  @Prop({
    default: function() {}
  })
  public saveAction: any;
  @Prop({
    default: function() {}
  })
  public back: any;
  @Prop()
  public item: any;

  // model
  // private pageTitleId: number = 0;
  @Validate({ required, minLength: minLength(4) })
  public pageTitleName: string = "";

  @Watch("item", { immediate: true, deep: true })
  protected onItemChange(item: any) {
    if (item) {
      this.init(item);
    }
  }

  private init(pageTitle: IPageTitle): void {
    this.pageTitleName = pageTitle.name;
  }

  public onSubmit(e: Event): void {
    this.$v.$touch();
    if (this.$v.$invalid) {
      return;
    }
    const pageTitle: IPageTitle = {
      name: this.pageTitleName
    };
    if (this.$route.params.id) {
      // edit
      pageTitle.id = Number.parseInt(this.$route.params.id);
      this.saveAction({
        resource: this.resourceName,
        data: pageTitle,
        id: pageTitle.id,
        descriptionField: "name"
      });
    } else {
      // add
      this.saveAction({
        resource: this.resourceName,
        data: pageTitle,
        descriptionField: "name"
      });
    }
  }
}
