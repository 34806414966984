import StandView from "@/views/admin/stand/StandView.vue";
import StandList from "@/views/admin/stand/StandList.vue";
import StandCreate from "@/views/admin/stand/StandCreate.vue";
import StandUpdate from "@/views/admin/stand/StandUpdate.vue";
import { ROLES } from "@/config/Roles";
import auth from "@/middlewares/AuthMiddleware";
import i18n from "@/locales";

export default [
  {
    path: "/stand",
    component: StandView,
    meta: {
      visibleInAside: true,
      roles: [ROLES.ADMIN, ROLES.SUPER_ADMIN],
      title: String(i18n.t("menu.stand")),
      middleware: [auth],
      avoidBack: true
    },
    children: [
      {
        path: "",
        name: "stand",
        component: StandList,
        meta: {
          visibleInAside: true,
          title: "",
          roles: [ROLES.ADMIN, ROLES.SUPER_ADMIN],
          middleware: [auth]
        }
      },
      {
        path: "create",
        name: "stand_create",
        component: StandCreate,
        meta: {
          visibleInAside: true,
          roles: [ROLES.ADMIN, ROLES.SUPER_ADMIN],
          title: String(i18n.t("general.add_stand")),
          middleware: [auth],
          avoidBack: true
        }
      },
      {
        path: "edit/:id",
        name: "stand_edit",
        component: StandUpdate,
        meta: {
          roles: [ROLES.ADMIN, ROLES.SUPER_ADMIN],
          title: String(i18n.t("general.edit_stand")),
          middleware: [auth]
        }
      }
    ]
  }
];
