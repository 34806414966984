
import { Vue, Component, Prop } from "vue-property-decorator";
import CollapseTransition from "@/transition/CollapseTransition.vue";

@Component({
  components: {
    CollapseTransition
  }
})
export default class MobileMenuItem extends Vue {
  public name: string = "MobileMenuItem";

  @Prop({ default: false })
  public emptyContent!: Boolean;

  @Prop({ default: true })
  public menuExpandable!: Boolean;

  protected isExpand: Boolean = false;

  protected expandMenu() {
    this.isExpand = !this.isExpand;
  }
}
