
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Validate } from "vuelidate-property-decorators";
import { minLength, required } from "vuelidate/lib/validators";
import CrudLayout from "@/layouts/CrudLayout.vue";
import Validation from "@/components/Validation.vue";
import FormBackButton from "@/components/buttons/FormBackButton.vue";
import IWineType from "@/models/IWineType";

const namespace: string = "winetype";

@Component({
  components: {
    FormBackButton,
    CrudLayout,
    Validation
  }
})
export default class WineTypeForm extends Vue {
  public name: string = "WineTypeForm";
  public resourceName = "wine-type";
  public resourceLabelName = this.resourceName.replace("-", "_");

  @Prop({ default: "add" })
  public type: any;

  @Prop({ default: false })
  public saved: any;

  @Prop({ default: false })
  public resetAction: any;

  @Prop({ default: false })
  public loading: any;

  @Prop({ default: "" })
  public error: any;

  @Prop({ default: () => {} })
  public saveAction: any;

  @Prop({ default: () => {} })
  public back: any;

  @Prop()
  public item: any;

  @Validate({ required, minLength: minLength(4) })
  public wineType: string = "";

  @Watch("item", { immediate: true, deep: true })
  protected onItemChange(item: any) {
    if (item) {
      this.init(item);
    }
  }

  private init(wineType: IWineType): void {
    this.wineType = wineType.wine_type;
  }

  public onSubmit(): void {
    this.$v.$touch();

    if (this.$v.$invalid) {
      return;
    }

    const wineType: IWineType = {
      wine_type: this.wineType
    };

    if (this.$route.params.id) {
      // edit
      wineType.id = Number.parseInt(this.$route.params.id);
      this.saveAction({
        resource: this.resourceName,
        data: wineType,
        id: wineType.id,
        descriptionField: "wine_type"
      });
    } else {
      // add
      this.saveAction({
        resource: this.resourceName,
        data: wineType,
        descriptionField: "wine_type"
      });
    }
  }
}
