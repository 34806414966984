
import DefaultLayout from "@/layouts/DefaultLayout.vue";
import { Component, Vue } from "vue-property-decorator";
import { BaseMixin } from "@/mixins/baseMixin";
import { Action, Getter } from "vuex-class";
import MisTable from "@/components/MisTable.vue";
import { ICrudOptions } from "@/service/interfaces/ICrudOptions";
import ITableColumn from "@/interfaces/table/ITableColumn";
import CrudLayout from "@/layouts/CrudLayout.vue";
import i18n from "@/locales";
import "vue-good-table/dist/vue-good-table.css";

const namespace = "service";

@Component({
  components: {
    CrudLayout,
    DefaultLayout,
    MisTable
  },
  mixins: [BaseMixin]
})
export default class ServiceList extends Vue {
  public name: string = "ServiceList";

  public resourceName: string = "service";

  public columns: Array<ITableColumn> = [
    {
      label: String(i18n.t("general.service")),
      field: "name",
      filterOptions: {
        enabled: true
      }
    },
    {
      label: String(i18n.tc("general.action", 2)),
      field: "actions",
      sortable: false
    }
  ];

  protected searchFields: Array<string> = ["name"];

  @Getter("getList", { namespace })
  protected dataList: any;

  @Getter("getTotal", { namespace })
  protected totalRows: any;

  @Getter("getError", { namespace })
  protected error: any;

  @Getter("isLoading", { namespace })
  protected loading: any;

  @Getter("isDeleted", { namespace })
  protected deleted: any;

  @Action("search", { namespace })
  protected searchItemsAction: any;

  @Action("del", { namespace })
  protected deleteItemAction: any;

  @Action("resetList", { namespace })
  protected resetAction: any;

  public searchAction(options: ICrudOptions): void {
    this.searchItemsAction(options);
  }

  public deleteAction(options: ICrudOptions): void {
    this.deleteItemAction(options);
  }

  public beforeDestroy() {
    this.resetAction();
  }
}
