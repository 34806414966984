import { MutationTree } from "vuex";
import { State } from "./state";

export function RETRIEVE_TOKEN(state: State, token: any) {
  state.token = token;
}

export function IS_LOADING(state: State, status: boolean) {
  state.isLoading = status;
}

export function ERROR(state: State, error: any) {
  state.error = error;
}

export function SUCCESS(state: State, success: any) {
  state.success = success;
}

export function EXIST_CATALOG_REQUEST(state: State, exist: boolean) {
  state.existCatalogRequest = exist;
}

export default <MutationTree<State>>{
  RETRIEVE_TOKEN,
  IS_LOADING,
  ERROR,
  SUCCESS,
  EXIST_CATALOG_REQUEST
};
