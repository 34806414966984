
import { Vue, Component, Prop } from "vue-property-decorator";
import { BaseMixin } from "@/mixins/baseMixin";
import ActionButton from "@/components/buttons/ActionButton.vue";

@Component({
  components: {
    ActionButton
  },
  mixins: [BaseMixin]
})
export default class DeleteButton extends Vue {
  public name: string = "DeleteButton";

  @Prop({ default: "" })
  public to: any;

  private deleteItem(): void {
    this.$emit("click");
  }

  @Prop({ default: false, type: Boolean, required: false })
  protected disabled: any;
}
