
import DefaultLayout from "@/layouts/DefaultLayout.vue";
import { Component, Vue, Watch } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import "vue-good-table/dist/vue-good-table.css";
import MisTable from "@/components/MisTable.vue";
import { ICrudOptions } from "@/service/interfaces/ICrudOptions";
import ITableColumn from "@/interfaces/table/ITableColumn";
import CrudLayout from "@/layouts/CrudLayout.vue";
import i18n from "@/locales";
import Snackbar from "@/components/snackbar/Snackbar.vue";
import { Route } from "vue-router";
import LoginAsExhibitorButton from "@/components/buttons/LoginAsExhibitorButton.vue";

const namespace: string = "exhibitor";
const snackbarNamespace: string = "snackbar";
const catalogNamespace: string = "catalog";
const breadcrumbNamespace: string = "breadcrumb";

@Component({
  components: {
    CrudLayout,
    DefaultLayout,
    MisTable,
    Snackbar
  }
})
export default class ExhibitorList extends Vue {
  public resourceName: string = "exhibitor";

  public additionalActionButtons: Array<any> = [
    { name: "WinesButton" },
    { name: "PagesButton" },
    { name: "SendMailMailClientButton" },
    { name: "LoginAsExhibitorButton" }
  ];

  public additionalToolbarButtons: Array<Object> = [
    {
      name: "ReminderButton"
    },
    {
      name: "SendMailButton",
      props: {
        to: `/${this.resourceName}/send-mail`
      }
    }
  ];

  public columns: Array<ITableColumn> = [
    {
      label: String(i18n.t("general.email")),
      field: "email",
      filterOptions: {
        enabled: true
      }
    },
    {
      label: String(i18n.t("general.company_name")),
      field: "company_name",
      filterOptions: {
        enabled: true
      }
    },
    {
      label: String(i18n.t("general.active")),
      field: "active",
      type: "number",
      html: true,
      filterOptions: {
        enabled: true,
        filterDropdownItems: [
          { value: "active", text: String(i18n.t("general.active")) },
          { value: "inactive", text: String(i18n.t("general.inactive")) }
        ]
      }
    },
    {
      label: String(i18n.tc("general.action", 2)),
      field: "actions",
      sortable: false
    }
  ];
  protected searchFields: Array<string> = [
    "email",
    "company_name",
    "active",
    "catalog_name"
  ];
  protected pageUpdateUrl = "/exhibitor/edit/";

  @Getter("getItem", { namespace: catalogNamespace }) catalog: any;
  @Getter("getActiveCatalog", { namespace: catalogNamespace })
  activeCatalog: any;

  @Getter("getList", { namespace }) exhibitorList: any;
  @Getter("getTotal", { namespace }) totalRows: any;
  @Getter("getError", { namespace }) error: any;
  @Getter("isLoading", { namespace }) loading: any;
  @Getter("isDeleted", { namespace }) deleted: any;
  @Getter("isPasswordReset", { namespace }) reset: any;
  @Getter("isCreated", { namespace }) created: any;

  @Getter("getData", { namespace: breadcrumbNamespace }) breadcrumbs: any;

  @Action("setText", { namespace: snackbarNamespace }) setText: any;
  @Action("setActive", { namespace: snackbarNamespace }) setActive: any;
  @Action("findOne", { namespace: catalogNamespace }) getCatalog: any;
  @Action("mergeData", { namespace: breadcrumbNamespace }) setBreadcrumb: any;
  @Action("search", { namespace }) searchItemsAction: any;

  @Action("del", { namespace }) deleteItemAction: any;
  @Action("resetList", { namespace }) resetAction: any;

  private passwordUpdated: boolean = false;

  public showSendMail: boolean = true;
  public showReminder: boolean = false;
  public showCreate: boolean = true;
  public name: string = "ExhibitorList";

  protected pageTitle: string = "";
  protected dataList: Array<any> = [];
  protected byCatalog: boolean = false;
  protected catalogId: number | boolean = false;

  public searchAction(options: ICrudOptions): void {
    if (this.byCatalog) {
      this.resourceName = "catalog/" + this.catalogId + "/exhibitor";
    } else {
      this.resourceName = "exhibitor";
    }
    options.resource = this.resourceName;
    this.searchItemsAction(options);
  }

  public deleteAction(options: ICrudOptions): void {
    this.deleteItemAction(options);
  }

  @Watch("$route", { immediate: true, deep: true })
  protected routeChanged(router: Route) {
    this.byCatalog = router.name === "catalog_exhibitor_list";
    this.catalogId = this.byCatalog ? Number(router.params["catalog"]) : false;
  }

  @Watch("exhibitorList", { immediate: false, deep: true })
  protected dataChanged(list: any) {
    this.dataList = list;
    if (!this.byCatalog) {
      this.pageTitle = String(this.$t("general.exhibitor_overview"));
    }
  }

  @Watch("catalog", { immediate: false, deep: true })
  protected catalogLoaded(catalog: any) {
    this.pageTitle =
      String(this.$t("general.exhibitor_overview")) +
      " " +
      String(this.$t("general.of")) +
      " " +
      catalog.name;
    this.showSendMail = this.showCreate = this.showReminder =
      catalog.currently_active;

    const catalogExhibitorListTitle =
      String(this.$t("general.exhibitor")) +
      " " +
      String(this.$t("general.of")) +
      " " +
      catalog.name;

    this.pageUpdateUrl = "/catalog/" + catalog.id + "/exhibitor/edit/";

    this.setBreadcrumb({
      [String(this.$router.currentRoute.name)]: {
        catalogExhibitorListTitle
      }
    });
  }

  @Watch("activeCatalog", { immediate: true })
  protected activeCatalogDetected(activeCatalog: any) {
    this.showReminder = Boolean(activeCatalog != null);
  }

  mounted() {
    if (this.byCatalog) {
      this.getCatalog({
        resource: "catalog",
        id: this.catalogId
      });
    }
    if (this.passwordUpdated) {
      this.$nextTick(() => {
        this.setText({
          text: String(i18n.t("general.password_reset_success")),
          type: "success"
        });
        this.setActive(true);
      });
    }
  }

  @Watch("created", { immediate: true, deep: true })
  protected onCreated(value: boolean) {
    if (value) {
      this.$nextTick(() => {
        this.setText({
          text: String(
            i18n.t("general.item_created_success", {
              item: String(i18n.t("general.exhibitor"))
            })
          ),
          type: "success"
        });
        this.setActive(true);
      });
    }
  }

  @Watch("reset", { immediate: true, deep: true })
  protected onUpdated(value: boolean) {
    this.passwordUpdated = value;
  }

  beforeDestroy() {
    this.resetAction();
  }
}
