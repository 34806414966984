import { ActionContext } from "vuex";
import CrudService from "@/service/CrudService";
import { ICrudOptions } from "@/service/interfaces/ICrudOptions";
import ErrorHelper from "@/utils/error/ErrorHelper";
import i18n from "@/locales";
import MessageDispatcher from "@/store/modules/crud/classes/MessageDispatcher";

/**
 * Basic CRUD actions
 */
export default class CrudActions extends MessageDispatcher {
  protected crudService: CrudService = new CrudService();

  constructor() {
    super();
  }

  /**
   * @param store
   * @param options
   */
  public async findOne(
    store: ActionContext<any, any>,
    options: ICrudOptions
  ): Promise<any> {
    store.commit("SET_DATA_LOADING", !options.disabledLoading);
    await this.dispatchLoading(store, !options.disabledLoading);
    const request: Promise<any> = this.crudService.findOne(options);

    request
      .then((response: any) => {
        this.resolveSingleAction(store, response, options);
      })
      .catch(error => {
        this.rejectReadAction(
          store,
          String(i18n.t(error.response.data.error_message)),
          options
        );
      });

    return request;
  }

  /**
   * @param store
   * @param options
   */
  public async findAll(
    store: ActionContext<any, any>,
    options: ICrudOptions
  ): Promise<any> {
    store.commit("SET_DATA_LOADING", true);
    await this.dispatchLoading(store, true);
    return this.crudService
      .findAll(options)
      .then((response: any) => {
        this.resolveReadMultipleAction(store, response, options);
      })
      .catch(error => {
        this.rejectReadAction(store, error, options);
      });
  }

  /**
   * @param store
   * @param options
   */
  public async search(
    store: ActionContext<any, any>,
    options: ICrudOptions
  ): Promise<any> {
    await this.dispatchLoading(store, true);
    store.commit("SET_DATA_LOADING", true);
    return this.crudService
      .search(options)
      .then((response: any) => {
        this.resolveReadMultipleAction(store, response, options);
        this.dispatchLoading(store, false);
      })
      .catch(error => {
        this.rejectReadAction(store, error, options);
      });
  }

  /**
   * @param store
   * @param options
   */
  public async paginate(
    store: ActionContext<any, any>,
    options: ICrudOptions
  ): Promise<any> {
    await this.dispatchLoading(store, true);
    return this.crudService
      .paginate(options)
      .then((response: any) => {
        this.dispatchLoading(store, false);
        this.resolveReadMultipleAction(store, response, options);
      })
      .catch(error => {
        this.rejectReadAction(store, error, options);
      });
  }

  /**
   * @param store
   * @param options
   */
  public async create(
    store: ActionContext<any, any>,
    options: ICrudOptions
  ): Promise<any> {
    await this.dispatchLoading(store, !options.disabledLoading);
    store.commit("SET_DATA_LOADING", !options.disabledLoading);
    store.commit("SET_DATA_CREATED", false);
    return this.crudService
      .create(options)
      .then((response: any) => {
        this.resolveWriteAction(
          store,
          response,
          String(
            i18n.t("general.item_created_success", {
              item: response.data.data[options.descriptionField]
            })
          ),
          response.data.data,
          options
        );
        store.commit("SET_DATA_CREATED", true);
      })
      .catch(error => {
        this.rejectWriteAction(store, error, options);
        store.commit("SET_DATA_CREATED", false);
      })
      .finally(() => {
        store.commit("SET_DATA_LOADING", false);
      });
  }

  /**
   * @param store
   * @param options
   */
  public async update(
    store: ActionContext<any, any>,
    options: ICrudOptions
  ): Promise<any> {
    await this.dispatchLoading(store, true);
    store.commit("SET_DATA_LOADING", true);
    store.commit("SET_DATA_UPDATED", false);
    const request: Promise<any> = this.crudService.update(options);
    request
      .then((response: any) => {
        const itemLabel: string = options.descriptionField
          ? response.data.data[options.descriptionField]
          : options.resourceName;
        this.resolveWriteAction(
          store,
          response,
          String(
            i18n.t("general.item_updated_success", {
              item: itemLabel
            })
          ),
          response.data.data,
          options
        );
        store.commit("SET_DATA_UPDATED", true);
      })
      .catch(error => {
        this.rejectWriteAction(store, error, options);
        store.commit("SET_DATA_UPDATED", false);
      })
      .finally(() => {
        store.commit("SET_DATA_LOADING", false);
      });

    return request;
  }

  public async updatePost(
    store: ActionContext<any, any>,
    options: ICrudOptions
  ): Promise<any> {
    const isFileUpload: boolean =
      options.data.get("web_logo") instanceof File ? true : false;

    if (!isFileUpload) {
      await this.dispatchLoading(store, true);
    } else {
      await this.dispatchLoading(store, !options.disabledLoading);
    }

    store.commit("SET_DATA_UPDATED", false);
    const request: Promise<any> = this.crudService.updatePost(options);
    request
      .then((response: any) => {
        this.resolveWriteAction(
          store,
          response,
          String(
            i18n.t("general.item_updated_success", {
              item: response.data.data[options.descriptionField]
            })
          ),
          response.data.data,
          options
        );
        store.commit("SET_DATA_UPDATED", true);
      })
      .catch(error => {
        this.rejectWriteAction(store, error, options);
        store.commit("SET_DATA_UPDATED", false);
      });

    return request;
  }

  /**
   * @param store
   * @param options
   */
  public async delete(
    store: ActionContext<any, any>,
    options: ICrudOptions
  ): Promise<any> {
    await this.dispatchLoading(store, true);
    store.commit("SET_DATA_LOADING", true);
    store.commit("SET_DATA_DELETED", false);
    return this.crudService
      .delete(options)
      .then((response: any) => {
        store.commit("SET_DATA_DELETED", true);
        const resourceName = options.resourceName
          ? options.resourceName
          : options.resource;
        const itemLabel: string = options.descriptionField
          ? options.descriptionField
          : String(i18n.t("general." + resourceName));
        this.resolveWriteAction(
          store,
          response,
          String(
            i18n.t("general.item_deleted_successfully", {
              resourceName: itemLabel
            })
          ),
          null,
          options
        );
        store.commit("SET_DATA_DELETED", true);
      })
      .catch(error => {
        const { message, error_message } = error.response.data;
        this.rejectWriteAction(
          store,
          String(i18n.t(message ? message : error_message)),
          options
        );
        store.commit("SET_DATA_DELETED", false);
      });
  }

  public async swapPositions(
    store: ActionContext<any, any>,
    options: ICrudOptions
  ): Promise<any> {
    await this.dispatchLoading(store, true);
    store.commit("SET_DATA_LOADING", true);
    store.commit("SET_DATA_POSITION_SWAPPED", false);
    const request: Promise<any> = this.crudService.swapPositions(options);
    request
      .then((response: any) => {
        store.commit("SET_DATA_ERROR", null);
        store.commit("SET_DATA_LOADING", false);
        store.commit("SET_DATA_POSITION_SWAPPED", true);
      })
      .catch(error => {
        this.dispatchLoading(store, false);
        this.dispatchErrorMessage(store, ErrorHelper.getError(error));
        store.commit("SET_DATA_ERROR", ErrorHelper.getError(error));
        store.commit("SET_DATA_LOADING", false);
        store.commit("SET_DATA_POSITION_SWAPPED", false);
      });

    return request;
  }

  public resolveWriteAction(
    store: ActionContext<any, any>,
    response: any,
    successMassage: string,
    item: any,
    options: ICrudOptions
  ): void {
    this.dispatchSuccessMessage(store, successMassage);
    this.dispatchLoading(store, false);
    store.commit("SET_DATA_ITEM", item);
    store.commit("SET_DATA_ERROR", null);
    store.commit("SET_DATA_LOADING", false);
  }

  /**
   * Actions to perform on write action promise reject
   * @param store
   * @param error
   */
  public rejectWriteAction(
    store: ActionContext<any, any>,
    error: any,
    options: ICrudOptions
  ): void {
    this.dispatchLoading(store, false);
    this.dispatchErrorMessage(store, ErrorHelper.getError(error));
    store.commit("SET_DATA_ERROR", ErrorHelper.getError(error));
    store.commit("SET_DATA_LOADING", false);
  }

  /**
   * Actions to perform on read multiple action promise resolve
   * @param store
   * @param response
   */
  public resolveReadMultipleAction(
    store: ActionContext<any, any>,
    response: any,
    options: ICrudOptions
  ): void {
    this.dispatchLoading(store, false);
    store.commit("SET_DATA_LIST", response.data.data);
    store.commit("SET_DATA_TOTAL", response.data.total);
    store.commit("SET_DATA_ERROR", null);
    store.commit("SET_DATA_LOADING", false);
  }

  /**
   * Actions to perform on read single action promise resolve
   * @param store
   * @param response
   */
  protected resolveSingleAction(
    store: ActionContext<any, any>,
    response: any,
    options: ICrudOptions
  ): void {
    this.dispatchLoading(store, false);
    store.commit("SET_DATA_ITEM", response.data.data);
    store.commit("SET_DATA_ERROR", null);
    store.commit("SET_DATA_LOADING", false);
  }
  /**
   * Actions to perform on rad action promise reject
   * @param store
   * @param error
   */
  public rejectReadAction(
    store: ActionContext<any, any>,
    error: any,
    options: ICrudOptions
  ) {
    this.dispatchLoading(store, false);
    this.dispatchErrorMessage(store, ErrorHelper.getError(error));
    store.commit("SET_DATA_ERROR", ErrorHelper.getError(error));
    store.commit("SET_DATA_LOADING", false);
  }
}
