import PageTitleCreate from "@/views/admin/page_title/PageTitleCreate.vue";
import PageTitleUpdate from "@/views/admin/page_title/PageTitleUpdate.vue";
import { ROLES } from "@/config/Roles";
import auth from "@/middlewares/AuthMiddleware";
import i18n from "@/locales";
import PageTitleView from "@/views/admin/page_title/PageTitleView.vue";
import PageTitleList from "@/views/admin/page_title/PageTitleList.vue";

export default [
  {
    path: "/page-title",
    component: PageTitleView,
    meta: {
      visibleInAside: true,
      roles: [ROLES.ADMIN, ROLES.SUPER_ADMIN],
      title: String(i18n.t("menu.page_title")),
      middleware: [auth]
    },
    children: [
      {
        path: "",
        name: "page_title",
        component: PageTitleList,
        meta: {
          visibleInAside: true,
          title: "",
          roles: [ROLES.ADMIN, ROLES.SUPER_ADMIN],
          middleware: [auth]
        }
      },
      {
        path: "create",
        name: "page_title_create",
        component: PageTitleCreate,
        meta: {
          visibleInAside: true,
          roles: [ROLES.ADMIN, ROLES.SUPER_ADMIN],
          title: String(i18n.t("general.add_page_title")),
          middleware: [auth],
          avoidBack: true
        }
      },
      {
        path: "edit/:id",
        name: "page_title_edit",
        component: PageTitleUpdate,
        meta: {
          roles: [ROLES.ADMIN, ROLES.SUPER_ADMIN],
          title: String(i18n.t("general.page_title_edit")),
          middleware: [auth]
        }
      }
    ]
  }
];
