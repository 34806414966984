
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class Loading extends Vue {
  public name: string = "Loading";

  @Prop({ default: true })
  public loading!: Boolean;
}
