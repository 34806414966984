
import { Vue, Component, Prop } from "vue-property-decorator";
import { BaseMixin } from "@/mixins/baseMixin";

@Component({
  components: {},
  mixins: [BaseMixin]
})
export default class LinkButton extends Vue {
  public name: string = "LinkButton";

  @Prop({ default: "" })
  public title: any;

  @Prop({ default: "" })
  public to: any;
}
