
import { Component, Vue, Watch } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import PageWineUpdateNotApprovedForm from "@/views/exhibitor/page/wine/notapproved/PageWineUpdateNotApprovedForm.vue";
import PageWineApprovedForm from "@/views/exhibitor/page/wine/approved/PageWineApprovedForm.vue";
import FormBackButton from "@/components/buttons/FormBackButton.vue";
import ReleaseButton from "@/components/buttons/ReleaseButton.vue";
import { Validations } from "vuelidate-property-decorators";
import { numeric, required } from "vuelidate/lib/validators";
import Validation from "@/components/Validation.vue";
import MultiSelect from "@/components/multiselect/MultiSelect.vue";
import IStand from "@/models/IStand";
import IPageTitle from "@/models/IPageTitle";
import i18n from "@/locales";
import ReleaseRadioButton from "@/components/buttons/ReleaseRadioButton.vue";
import DownloadPageButton from "@/components/buttons/DownloadPageButton.vue";
import DropDownButton from "@/components/DropDownButton.vue";
import axios from "axios";
import FileSaver from "file-saver";
import { BaseMixin } from "@/mixins/baseMixin";

const namespace: string = "page";
const exhibitorNamespace: string = "exhibitor";
const standNamespace: string = "stand";
const pageTitleNamespace: string = "pagetitle";
const pageTitleResourceName: string = "page-title";
const exhibitorStandsResource = "stand-exhibitor";

@Component({
  components: {
    DropDownButton,
    DownloadPageButton,
    ReleaseRadioButton,
    MultiSelect,
    Validation,
    ReleaseButton,
    FormBackButton,
    PageWineApprovedForm,
    PageWineUpdateNotApprovedForm
  }
})
export default class PageWineUpdate extends Vue {
  public name: string = "PageWineUpdate";
  private resourceName = namespace;

  @Getter("getError", { namespace }) error: any;
  @Getter("isLoading", { namespace }) loading: any;
  @Getter("isUpdated", { namespace }) updated: any;
  @Getter("getItem", { namespace }) item: any;

  @Action("update", { namespace }) updateItemAction: any;
  @Action("findOne", { namespace }) getItemAction: any;
  @Action("resetUpdate", { namespace }) resetAction: any;

  @Getter("getList", { namespace: exhibitorNamespace }) exhibitors: any;
  @Getter("getList", { namespace: standNamespace }) stands: any;
  @Getter("getList", { namespace: pageTitleNamespace }) pageTitles: any;

  @Action("search", { namespace: standNamespace }) standAction: any;
  @Action("search", { namespace: pageTitleNamespace }) pageTitleAction: any;

  private readonly limitResult: number = 10000;

  protected page_id: number = 0;
  protected get pageId(): number {
    return this.page_id;
  }
  protected set pageId(id: number) {
    this.page_id = id;
  }
  protected active: number = 0;
  protected stand: IStand | null = null;
  protected itemPageTitle: IPageTitle | null = null;
  protected releaseAccepted: number = 0;
  protected userId: number = 0;
  private printRequestPageItems: any[] = [];
  protected setPrintRequestPageItems(): void {
    this.printRequestPageItems = [
      {
        index: 0,
        text: i18n.t("general.without_articlenumber"),
        callback: () => {
          this.downloadRequestPage(this.pageId, 0);
        }
      },
      {
        index: 1,
        text: i18n.t("general.with_articlenumber"),
        callback: () => {
          this.downloadRequestPage(this.pageId, 1);
        }
      }
    ];
  }

  protected downloadRequestPage(id: number, showArticleNumber: number) {
    axios
      .get(
        process.env.VUE_APP_BACKEND_HOST +
          "/api/page-request-download/" +
          id +
          "/" +
          showArticleNumber,
        {
          responseType: "arraybuffer"
        }
      )
      .then(response => {
        const file = new Blob([response.data], { type: "application/pdf" });
        FileSaver.saveAs(file, id + ".pdf");
      });
  }
  protected comment: String = "";

  @Validations()
  protected validations() {
    if (!this.item.released) {
      return {
        active: { required, numeric },
        stand: { required },
        itemPageTitle: { required }
      };
    } else {
      return {
        releaseAccepted: { required }
      };
    }
  }

  public created(): void {
    this.init();
  }

  @Watch("item", { immediate: true, deep: true })
  protected onPage(page: any) {
    if (page) {
      this.page_id = page.id;
      this.active = page.active;
      this.stand = page.stand;
      this.itemPageTitle = page.page_title;
      this.releaseAccepted = page.release_accepted;
      this.setPrintRequestPageItems();
      let title = String(i18n.t("general.page_winepage_update"));
      this.comment = page.comment;
      if (page.catalog && page.exhibitor) {
        title = String(
          i18n.t("general.update_page_wine", {
            catalog: page.catalog.name,
            company_name: page.exhibitor.company_name
          })
        );
      }
      if (page.exhibitor) {
        this.userId = page.exhibitor.id;
      }
      this.$emit("title", title);
    }
  }

  private init(): void {
    this.searchPageTitle();
    this.searchStand();
  }

  private searchPageTitle(name: string = "") {
    name = name.trim();
    if (name) {
      this.pageTitleAction({
        resourceName: exhibitorNamespace,
        resource: pageTitleResourceName,
        params: {
          currentPerPage: this.limitResult,
          currentPage: 1,
          columnFilters: {
            name: name
          }
        }
      });
    } else {
      this.pageTitleAction({
        resourceName: exhibitorNamespace,
        resource: pageTitleResourceName,
        params: {
          currentPerPage: this.limitResult,
          currentPage: 1
        }
      });
    }
  }

  protected get getCatalogId() {
    return this.item.catalog.id;
  }

  protected get getUserId() {
    return this.item.exhibitor.id;
  }

  private searchStand(name: string = "") {
    name = name.trim();
    if (name) {
      this.standAction({
        resource: exhibitorStandsResource,
        params: {
          currentPerPage: this.limitResult,
          currentPage: 1,
          sort: [{ field: "stand", type: "asc" }],
          columnFilters: {
            stand: name,
            catalog_id: this.getCatalogId,
            user_id: this.getUserId
          }
        }
      });
    } else {
      this.standAction({
        resource: exhibitorStandsResource,
        sort: [{ field: "stand", type: "asc" }],
        params: {
          currentPerPage: this.limitResult,
          currentPage: 1,
          columnFilters: {
            catalog_id: this.getCatalogId,
            user_id: this.getUserId
          }
        }
      });
    }
  }

  public beforeMount() {
    this.getItemAction({
      resource: this.resourceName,
      id: Number.parseInt(this.$route.params.id)
    });
  }

  public beforeDestroy() {
    this.resetAction();
  }

  protected back() {
    this.$router.push({ path: "/" + this.resourceName });
  }

  public onSubmit(): void {
    this.$v.$touch();

    if (this.$v.$invalid) {
      return;
    }

    this.item.active = this.active;
    if (this.stand) {
      this.item.stand = this.stand;
      this.item.stand_id = this.stand.id;
    }

    if (this.itemPageTitle) {
      this.item.page_title = this.itemPageTitle;
      this.item.page_title_id = this.itemPageTitle.id;
    }

    this.item.active = this.active;
    this.item.release_accepted = this.releaseAccepted;
    this.item.comment = this.comment;
    this.updateItemAction({
      resource: this.resourceName,
      resourceName: this.$i18n.t("general.page"),
      data: this.item,
      id: this.item.id
    });
  }
}
