import { MutationTree } from "vuex";
import { State } from "./state";

export function SET_DATA_LIST(state: State, data: any) {
  state.dataList = data;
}

export function SET_DATA_TOTAL(state: State, total: any) {
  state.total = total;
}

export function SET_DATA_IS_PASSWORD_RESET(state: State, status: boolean) {
  state.isPasswordReset = status;
}
export function SET_DATA_ITEM(state: State, item: any) {
  state.dataItem = item;
}
export function SET_DATA_SET_CATALOGS(state: State, status: boolean) {
  state.setCatalogs = status;
}
export function SET_DATA_COUNTRIES(state: State, countries: any[]) {
  state.countries = countries;
}
export function SET_DATA_REGIONS(state: State, regions: any[]) {
  state.regions = regions;
}
export function SET_DATA_CATALOGS(state: State, catalogs: any[]) {
  state.catalogs = catalogs;
}
export function SET_DATA_USER_CATALOGS(state: State, catalogs: any[]) {
  state.catalogs = catalogs;
}
export function SET_DATA_SELECTED_CATALOGS(
  state: State,
  selectedCatalogs: any[]
) {
  state.selectedCatalogs = selectedCatalogs;
}
export function SET_DATA_ERROR(state: State, error: any) {
  state.error = error;
}
export function SET_DATA_SEND_MAIL(state: State, status: boolean) {
  state.sendedMail = status;
}
export function SET_DATA_LOADING(state: State, loading: any) {
  state.loading = loading;
}

export function SET_DATA_CREATED(state: State, created: any) {
  state.created = created;
}
export function SET_DATA_SET_EXHIBITOR(state: State, exhibitor: any) {
  state.exhibitor = exhibitor;
}
export function SET_DATA_UPDATED(state: State, updated: any) {
  state.updated = updated;
}

export function SET_DATA_DELETED(state: State, deleted: any) {
  state.deleted = deleted;
}
export function SET_DATA_SELECTED_REGIONS(
  state: State,
  selectedRegions: any[]
) {
  state.selectedRegions = selectedRegions;
}
export default <MutationTree<State>>{
  SET_DATA_LIST,
  SET_DATA_ITEM,
  SET_DATA_TOTAL,
  SET_DATA_ERROR,
  SET_DATA_LOADING,
  SET_DATA_CREATED,
  SET_DATA_UPDATED,
  SET_DATA_DELETED,
  SET_DATA_SEND_MAIL,
  SET_DATA_CATALOGS,
  SET_DATA_SET_CATALOGS,
  SET_DATA_IS_PASSWORD_RESET,
  SET_DATA_SET_EXHIBITOR,
  SET_DATA_USER_CATALOGS,
  SET_DATA_SELECTED_CATALOGS,
  SET_DATA_COUNTRIES,
  SET_DATA_REGIONS,
  SET_DATA_SELECTED_REGIONS
};
