import AuthService from "@/service/AuthService";
import { redirectToHomePage } from "@/middlewares/helper";

const authService = new AuthService();

export default function IfNotExistTokenMiddleware(route: any) {
  const { next, to } = route;
  const token: string = authService.getToken();

  if (!token) {
    return next();
  }
  return redirectToHomePage(next, to);
}
