// TODO use/update models
import IPage from "@/models/IPage";
import { PositionState } from "@/store/modules/crud/PositoinState";

export class State extends PositionState {
  dataList: Array<IPage> = [];
  dataItem: IPage | null = null;
  position: number | null = null;
  redirectCatalogInactive: boolean = false;
  imported: Object = { isImported: false, importPageId: 0, replacePageId: 0 };
}
