import { GetterTree } from "vuex";
import { State } from "./state";
import CryptService from "@/service/CryptService";

export function getStatus(state: State): boolean {
  const userData = JSON.parse(
    CryptService.decode(localStorage.getItem("user") || "")
  );
  if (userData["aside-menu"] != null) {
    state.isOpen = userData["aside-menu"];
  }
  return state.isOpen;
}

export function isSetInLS(state: State): boolean {
  const userData = JSON.parse(
    CryptService.decode(localStorage.getItem("user") || "")
  );
  return Boolean(userData["aside-menu"] != null);
}

export function getMobileMenuStatus(state: State): boolean {
  return state.isMobileMenuOpen;
}

export default <GetterTree<State, any>>{
  getStatus,
  isSetInLS,
  getMobileMenuStatus
};
