
import { Component, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";
import AuthService from "@/service/AuthService";
import AppConfig from "@/config/AppConfig";
import LogoutButton from "@/components/buttons/LogoutButton.vue";
import Badge from "@/components/badges/Badge.vue";
import AsideMenuOpenButton from "@/components/buttons/menu/AsideMenuOpenButton.vue";

const namespace: string = "auth";
@Component({
  components: {
    AsideMenuOpenButton,
    Badge,
    LogoutButton
  }
})
export default class LoggedInHeader extends Vue {
  public name: string = "LoggedInHeader";

  @Getter("getToken", { namespace })
  private getToken: any;

  protected body: any = document.body;

  private appVersion: string = AppConfig.APP_VERSION;

  protected get checkToken() {
    return !!this.getToken;
  }

  public get authUser(): any {
    const authService = new AuthService();
    return authService.getUser();
  }
}
