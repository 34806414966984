
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import CrudLayout from "@/layouts/CrudLayout.vue";
import { Validate } from "vuelidate-property-decorators";
import { minValue, numeric, required } from "vuelidate/lib/validators";
import Validation from "@/components/Validation.vue";
import Dropdown from "@/components/Dropdown.vue";
import i18n from "@/locales";
import { Getter, namespace } from "vuex-class";
import IExhibitorCatalogRequest from "@/models/IExhibitorCatalogRequest";
import IExhibitorCatalogRequestService from "@/models/IExhibitorCatalogRequestService";
import "@/assets/sass/transparent-table.scss";
import ServiceTable from "@/components/ServiceTable.vue";
import catalog from "@/router/admin/catalog";
import moment from "moment";

const NotificationModule = namespace("notification");
const namespaceRequest: string = "request";
const CatalogModule = namespace("catalog");

@Component({
  components: {
    ServiceTable,
    Dropdown,
    CrudLayout,
    Validation
  }
})
export default class RequestForm extends Vue {
  // Component initial values
  public name: string = "RequestForm";

  private resourceName = "request";

  public editable: boolean = false;

  public requestId: number = 0;

  protected notificationState: number | null = null;

  protected updateInfo: string = "";

  protected modalConfirmed: boolean = false;

  // props
  @Prop({ default: "add" })
  public type: any;

  @Prop({ default: false })
  public saved: any;

  @Prop({ default: false })
  public loading: any;

  @Prop({ default: "" })
  public error: any;

  @Prop({ default: {} })
  public saveAction: any;

  @Prop({ default: {} })
  public back: any;

  @Prop()
  public findAllServices: any;

  @Prop()
  public getServices: any;

  @Prop()
  public findRequest: any;

  @Prop()
  public updateRequest: any;

  @Prop()
  public authService: any;

  public inputData: any = {};

  @Getter("getList", { namespace: namespaceRequest })
  protected getRequest: any;

  @Getter("getItem", { namespace: namespaceRequest })
  protected getRequestItem: any;

  @CatalogModule.Getter("getActiveCatalog")
  public getActiveCatalog: any;

  @NotificationModule.Action("setText")
  protected setNotification: any;

  @NotificationModule.Action("setActive")
  protected setNotificationActive: any;

  // model
  @Validate({ required, numeric, minValue: minValue(1) })
  public standCount: number = 1;

  @Validate({ required, numeric, minValue: minValue(1) })
  public pagesWineCount: number = 1;

  @Validate({ required, numeric })
  public adPagesCount: number = 0;

  public onSubmit(): void {
    this.$v.$touch();
    if (this.$v.$invalid) {
      return;
    }
    const request: IExhibitorCatalogRequest = {
      count_stands: this.standCount,
      count_pages_wine: this.pagesWineCount,
      count_pages_advertising: this.adPagesCount,
      service: this.inputData,
      id: this.requestId
    };

    if (this.editable) {
      if (!this.modalConfirmed) {
        this.updateInfo = String(i18n.t("general.request_question_resent"));
        this.$bvModal.show("modal-update");
        return;
      }
      // Edit
      this.updateRequest({
        resource: this.resourceName,
        data: request,
        id: this.requestId,
        descriptionField: "name"
      });
      this.modalConfirmed = false;
    } else {
      // Add
      this.saveAction({
        resource: this.resourceName,
        data: request,
        descriptionField: "name"
      });
    }
  }

  public created() {
    this.findAllServices({ resource: "service" });
    this.findRequest({ resource: "request" });
  }

  @Watch("getRequest")
  public requestData() {
    const reqData = this.getRequest;
    if (reqData) {
      this.editable = true;
      this.requestId = reqData.id;
      this.standCount = reqData.count_stands;
      this.pagesWineCount = reqData.count_pages_wine;
      this.adPagesCount = reqData.count_pages_advertising;
      this.notificationState = reqData;
    }
    this.notification();
  }

  @Watch("getRequestItem")
  public requestItem() {
    const reqData = this.getRequestItem;
    this.editable = true;
    this.requestId = reqData.exhibitor_catalog_request.id;
    this.standCount = reqData.exhibitor_catalog_request.count_stands;
    this.pagesWineCount = reqData.exhibitor_catalog_request.count_pages_wine;
    this.adPagesCount =
      reqData.exhibitor_catalog_request.count_pages_advertising;

    this.notificationState = reqData.exhibitor_catalog_request;
    this.notification();
    this.getServices.forEach((item: any) => {
      this.filterById(reqData, item);
    });
  }

  public filterById(requestData: any, item: any) {
    if (!requestData) {
      return 0;
    }
    if (requestData.exhibitor_catalog_request_service == undefined) {
      return 0;
    }
    let filteredItem = requestData.exhibitor_catalog_request_service.filter(
      (row: any) => {
        return row.service_id == item.id;
      }
    );
    if (filteredItem.length < 1) {
      return 0;
    }
    this.inputSet(item, filteredItem[0].amount);
    return filteredItem[0].amount;
  }

  public inputSet(item: any, count: number) {
    const exhibitorCatalogRequestService: IExhibitorCatalogRequestService = {
      service_id: item.id,
      amount: count
    };
    this.inputData[item.id] = exhibitorCatalogRequestService;
  }

  public confirmDialog() {
    this.$bvModal.hide("modal-update");
    this.modalConfirmed = true;
    this.onSubmit();
  }

  protected welcomeInfo: string = "";
  public async notification(): Promise<any> {
    if (this.notificationState == 0) {
      this.setNotification({
        text: this.$t("general.request_rejected"),
        theme: "danger"
      });
    } else if (this.notificationState == null) {
      const { name } = await this.authService.getUser();
      const activeCatalog = await this.getActiveCatalog;
      const message = this.$t("general.exhibitor_request_welcome_message", {
        companyName: name,
        catalogName: activeCatalog.name,
        catalogTo: moment(activeCatalog.to).format("DD.MM.YYYY")
      });
      this.welcomeInfo = message.toString().replace("\n", "</br>");
      this.$bvModal.show("modal-welcome");
    }
  }

  public destroyed() {
    this.setNotificationActive(false);
  }
}
