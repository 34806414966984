/**
 *Basic server error handling
 */
export default class ErrorHelper {
  public static getError(error: any) {
    if (!error.response || !error.response.data) {
      return error;
    }
    const errorData: any = error.response.data;

    const errors = errorData.errors;
    if (errors) {
      return errors;
    }
    return errorData.message ? errorData.message : error;
  }
}
