import LogoCreate from "@/views/exhibitor/logo/LogoCreate.vue";
import LogoView from "@/views/exhibitor/logo/LogoView.vue";
import LogoList from "@/views/exhibitor/logo/LogoList.vue";
import { ROLES } from "@/config/Roles";
import auth from "@/middlewares/AuthMiddleware";
import i18n from "@/locales";

export default [
  {
    path: "/admin-logo",
    component: LogoView,
    meta: {
      visibleInAside: true,
      roles: [ROLES.ADMIN, ROLES.SUPER_ADMIN],
      title: String(i18n.t("menu.logo")),
      middleware: [auth]
    },
    children: [
      {
        path: "",
        name: "AdminLogo",
        component: LogoList,
        meta: {
          visibleInAside: true,
          title: "",
          roles: [ROLES.ADMIN, ROLES.SUPER_ADMIN],
          middleware: [auth]
        }
      },
      {
        path: "create",
        name: "AdminLogoCreate",
        component: LogoCreate,
        meta: {
          visibleInAside: true,
          roles: [ROLES.ADMIN, ROLES.SUPER_ADMIN],
          title: String(i18n.t("general.logo_add")),
          middleware: [auth]
        }
      }
    ]
  }
];
