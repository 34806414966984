import { ActionTree, ActionContext } from "vuex";
import { State } from "./state";

export function setText(store: ActionContext<State, any>, data: any) {
  store.commit("TEXT_SNACKBAR", data.text);
  store.commit("THEME_SNACKBAR", data.type);
  store.commit("ON_CHANGE_SNACKBAR", new Date());
  store.commit("ACTIVE_SNACKBAR", true);
}

export function setActive(store: ActionContext<State, any>, active: boolean) {
  store.commit("ACTIVE_SNACKBAR", active);
}

export function setLoading(store: ActionContext<State, any>, loading: boolean) {
  store.commit("LOADING", loading);
}

export default <ActionTree<State, any>>{
  setText,
  setActive,
  setLoading
};
