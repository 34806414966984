import { ActionContext, ActionTree } from "vuex";
import { State } from "./state";
import { ICrudOptions } from "@/service/interfaces/ICrudOptions";
import axios from "axios";
import ErrorHelper from "@/utils/error/ErrorHelper";
import AppConfig from "@/config/AppConfig";
import {
  create,
  del,
  findAll,
  findOne,
  paginate,
  resetCreate,
  resetList,
  resetUpdate,
  search,
  update,
  crudActions
} from "@/store/modules/crud/functions/actions";
import CrudService from "@/service/CrudService";
import i18n from "@/locales";

const BASE_URL: string = AppConfig.ENTRYPOINT + "/api/";

const crudService: CrudService = new CrudService();

export async function persistDataListOrder(
  store: ActionContext<State, any>,
  options?: any
): Promise<any> {
  store.commit("SET_ORDER_SAVING", true);
  let data: any = store.state.dataList;
  console.log(BASE_URL + options.resource + "/persist-order");
  return axios
    .patch(BASE_URL + options.resource + "/persist-order", data)
    .then((response: any) => {
      console.log("persist-order response", response.data);
      store.commit("SET_DATA_LIST", response.data.data);
    })
    .catch((error: any) => {
      console.log("persist-order response error", error);
    })
    .then((response: any) => {
      console.log("Finally ...");
      store.commit("SET_ORDER_SAVING", false);
    });
}

export async function persistMoveItemAfter(
  store: ActionContext<State, any>,
  options: any
): Promise<any> {
  let data: any = store.state.dataList;
  options = {
    ...options,
    orderColumn: "position"
  };
  store.commit("SET_DATA_LOADING", true);
  store.commit("SET_DATA_POSITION_SWAPPED", false);
  crudActions.dispatchLoading(store, true);
  return axios
    .patch(BASE_URL + "page/persist-order", options)
    .then((response: any) => {
      store.commit("SET_DATA_ERROR", null);
      store.commit("SET_DATA_LOADING", false);
      crudActions.dispatchLoading(store, false);
      store.commit("SET_DATA_POSITION_SWAPPED", true);
    })
    .catch((error: any) => {
      store.commit("SET_DATA_POSITION_SWAPPED", false);
      crudActions.rejectWriteAction(store, error, options);
    });
}

export async function changePosition(
  store: ActionContext<State, any>,
  options: ICrudOptions
): Promise<any> {
  store.commit("SET_DATA_LOADING", true);
  store.commit("SET_DATA_POSITION_SWAPPED", false);
  crudActions.dispatchLoading(store, true);
  return axios
    .put(
      BASE_URL +
        "page/change-position/" +
        options.params.page_id +
        "/" +
        options.params.new_position
    )
    .then((response: any) => {
      store.commit("SET_DATA_ERROR", null);
      store.commit("SET_DATA_LOADING", false);
      crudActions.dispatchLoading(store, false);
      store.commit("SET_DATA_POSITION_SWAPPED", true);
    })
    .catch((error: any) => {
      store.commit("SET_DATA_POSITION_SWAPPED", false);
      crudActions.rejectWriteAction(store, error, options);
    });
}

export function createPage(
  store: ActionContext<State, any>,
  options?: any
): Promise<any> {
  crudActions.dispatchLoading(store, true);
  store.commit("SET_DATA_CREATED", false);
  store.commit("SET_DATA_POSITION", null);
  const request: Promise<any> = axios.put(
    BASE_URL + "catalog/add-wine",
    options
  );
  request
    .then((response: any) => {
      crudActions.dispatchLoading(store, false);
      store.commit("SET_DATA_CREATED", true);
      store.commit("SET_DATA_POSITION", response.data.data.position);
    })
    .catch((error: any) => {
      store.commit("SET_DATA_CREATED", false);
      crudActions.rejectWriteAction(store, error, options);
    });

  return request;
}
export async function successMessage(
  store: ActionContext<State, any>,
  message: string
): Promise<any> {
  store.commit("SET_DATA_POSITION", null);
  await crudActions.dispatchSuccessMessage(store, message);
}

export async function errorInactiveCatalog(
  store: ActionContext<State, any>,
  message: string
): Promise<any> {
  await crudActions.dispatchErrorMessage(store, message);
}
export function redirectCatalogInactive(
  store: ActionContext<State, any>,
  status: boolean
): void {
  store.commit("SET_REDIRECT_CATALOG", status);
}
export async function createContent(
  store: ActionContext<State, any>,
  options: ICrudOptions
): Promise<any> {
  const isFileUpload: boolean =
    options.data.get("file") instanceof File ? true : false;

  await crudActions.dispatchLoading(store, !isFileUpload);

  store.commit("SET_DATA_CREATED", false);
  store.commit("SET_DATA_POSITION", null);
  await crudService
    .create(options)
    .then((response: any) => {
      store.commit("SET_DATA_ERROR", null);
      store.commit("SET_DATA_CREATED", true);
      store.commit("SET_DATA_POSITION", response.data.data.position);
    })
    .catch((error: any) => {
      store.dispatch(
        "snackbar/setText",
        {
          text: ErrorHelper.getError(error),
          type: "danger"
        },
        { root: true }
      );
      store.commit("SET_DATA_ERROR", ErrorHelper.getError(error));
      store.commit("SET_DATA_CREATED", false);
    });
}

export function updateAd(
  store: ActionContext<State, any>,
  options: ICrudOptions
): Promise<any> {
  const isFileUpload: boolean =
    options.data.get("file") instanceof File ? true : false;

  crudActions.dispatchLoading(store, !isFileUpload);

  store.commit("SET_DATA_UPDATED", false);
  const request = crudService.updatePost(options);
  request.then((response: any) => {
    store.commit("SET_DATA_ERROR", null);
    store.commit("SET_DATA_UPDATED", true);
    crudActions.resolveWriteAction(
      store,
      response,
      String(
        i18n.t("general.item_updated_success", {
          item: response.data.data[options.descriptionField]
        })
      ),
      response.data.data,
      options
    );
  });
  request.catch((error: any) => {
    store.dispatch(
      "snackbar/setText",
      {
        text: ErrorHelper.getError(error),
        type: "danger"
      },
      { root: true }
    );
    store.commit("SET_DATA_ERROR", ErrorHelper.getError(error));
    store.commit("SET_DATA_UPDATED", false);
  });

  return request;
}
export function updateContent(
  store: ActionContext<State, any>,
  options: ICrudOptions
): Promise<any> {
  const isFileUpload: boolean =
    options.data.get("file") instanceof File ? true : false;

  crudActions.dispatchLoading(store, !isFileUpload);

  store.commit("SET_DATA_UPDATED", false);
  const request = crudService.updatePost(options);
  request.then((response: any) => {
    store.commit("SET_DATA_ERROR", null);
    store.commit("SET_DATA_UPDATED", true);
    crudActions.resolveWriteAction(
      store,
      response,
      String(
        i18n.t("general.item_updated_success", {
          item: response.data.data[options.descriptionField]
        })
      ),
      response.data.data,
      options
    );
  });
  request.catch((error: any) => {
    store.dispatch(
      "snackbar/setText",
      {
        text: ErrorHelper.getError(error),
        type: "danger"
      },
      { root: true }
    );
    store.commit("SET_DATA_ERROR", ErrorHelper.getError(error));
    store.commit("SET_DATA_UPDATED", false);
  });

  return request;
}

export function release(
  store: ActionContext<any, any>,
  options: ICrudOptions
): Promise<any> {
  store.dispatch("snackbar/setLoading", !options.disabledLoading, {
    root: true
  });
  store.commit("SET_DATA_LOADING", !options.disabledLoading);
  store.commit("SET_DATA_CREATED", false);
  return axios
    .post(
      BASE_URL + options.resource + "/" + options.additional + "/" + options.id,
      options.data,
      options.config
    )
    .then((response: any) => {
      store.dispatch(
        "snackbar/setText",
        {
          text: String(
            i18n.t("general.your_request_for_release_was_sent_successfully", {
              item: response.data.data[options.descriptionField]
            })
          ),
          type: "success"
        },
        { root: true }
      );
      store.dispatch("snackbar/setLoading", false, { root: true });
      store.commit("SET_DATA_ITEM", response.data.data);
      store.commit("SET_DATA_ERROR", null);
      store.commit("SET_DATA_LOADING", false);
      store.commit("SET_DATA_UPDATED", true);
    })
    .catch((error: any) => {
      store.dispatch(
        "snackbar/setText",
        {
          text: ErrorHelper.getError(error),
          type: "danger"
        },
        { root: true }
      );
      store.dispatch("snackbar/setLoading", false, { root: true });
      store.commit("SET_DATA_ERROR", ErrorHelper.getError(error));
      store.commit("SET_DATA_UPDATED", false);
      store.commit("SET_DATA_LOADING", false);
    });
}

export function getPages(
  store: ActionContext<any, any>,
  options: ICrudOptions
): Promise<any> {
  return axios
    .get(`${BASE_URL}${options.resource}/${options.id}`, options.config)
    .then((response: any) => {
      store.commit("SET_DATA_LIST", response.data.data);
      store.commit("SET_DATA_ERROR", null);
    })
    .catch((error: any) => {
      store.commit("SET_DATA_ERROR", ErrorHelper.getError(error));
    });
}

export function importPage(
  store: ActionContext<any, any>,
  options: ICrudOptions
): Promise<any> {
  store.dispatch("snackbar/setLoading", !options.disabledLoading, {
    root: true
  });

  const defaultImportedData = {
    isImported: false,
    importPageId: 0,
    replacePageId: 0
  };

  store.commit("SET_DATA_IMPORTED", defaultImportedData);
  return axios
    .post(
      `${BASE_URL}${options.resource}/${options.additional}`,
      options.data,
      options.config
    )
    .then((response: any) => {
      store.commit("SET_DATA_ITEM", response.data.data);
      store.commit("SET_DATA_ERROR", null);
      store.commit("SET_DATA_IMPORTED", {
        isImported: true,
        importPageId: options.data.importPageId,
        replacePageId: options.data.replacePageId
      });
      store.dispatch(
        "snackbar/setText",
        {
          text: String(i18n.t("general.import_page_successful")),
          type: "success"
        },
        { root: true }
      );
      store.dispatch("snackbar/setLoading", false, { root: true });
    })
    .catch((error: any) => {
      store.commit("SET_DATA_ERROR", ErrorHelper.getError(error));
      store.commit("SET_DATA_IMPORTED", defaultImportedData);
      store.dispatch(
        "snackbar/setText",
        {
          text: ErrorHelper.getError(error),
          type: "danger"
        },
        { root: true }
      );
      store.dispatch("snackbar/setLoading", false, { root: true });
    });
}

export default <ActionTree<State, any>>{
  findOne,
  findAll,
  search,
  paginate,
  create,
  update,
  del,
  resetList,
  resetCreate,
  resetUpdate,
  persistDataListOrder,
  persistMoveItemAfter,
  createPage,
  successMessage,
  createContent,
  errorInactiveCatalog,
  redirectCatalogInactive,
  updateContent,
  updateAd,
  release,
  changePosition,
  getPages,
  importPage
};
