
import { Component, Vue } from "vue-property-decorator";

const namespace: string = "pagetitle";

@Component({
  components: {}
})
export default class CatalogView extends Vue {
  public name: string = "PageTitleView";
}
