
import { Component, Prop, Watch } from "vue-property-decorator";
import CrudLayout from "@/layouts/CrudLayout.vue";
import { Validate } from "vuelidate-property-decorators";
import {
  maxValue,
  minLength,
  minValue,
  required
} from "vuelidate/lib/validators";
import { dateGreater } from "@/validators/validators";
import Validation from "@/components/Validation.vue";
import ICatalog from "@/models/ICatalog";
import Datepicker from "@/components/Datepicker.vue";
import DateUtil from "@/utils/date/DateUtil";
import Dropdown from "@/components/Dropdown.vue";
import i18n from "@/locales/index";
import FormBackButton from "@/components/buttons/FormBackButton.vue";
import CatalogUtil from "@/utils/catalog/CatalogUtil";
import {
  Action,
  Getter,
  Mutation,
  namespace as VuexNamespace
} from "vuex-class";
import SortService from "@/service/SortService";
import GenerateCatalogButton from "@/components/buttons/GenerateCatalogButton.vue";
import CountCatalogPagesButton from "@/components/buttons/CountCatalogPagesButton.vue";
import { BaseMixin } from "@/mixins/baseMixin";

const namespace: string = "catalog";
const printNamespace: string = "print";
const StatusModule = VuexNamespace("status");

@Component({
  components: {
    CountCatalogPagesButton,
    FormBackButton,
    Dropdown,
    CrudLayout,
    Validation,
    Datepicker,
    GenerateCatalogButton
  }
})
export default class CatalogForm extends BaseMixin {
  // component initial values
  public name: string = "CatalogForm";
  private resourceName = "catalog";

  public dateFromDisabled: boolean = false;
  // public disableButton: boolean = false;

  @Mutation("SET_DATA_ACTIVE_CATALOG", { namespace: "catalog" })
  protected setActiveCatalog: any;
  @Getter("isUpdated", { namespace })
  protected isUpdated?: boolean;
  @Getter("getActiveCatalog", { namespace })
  protected activeCatalog?: ICatalog | null;

  @Action("generateCatalog", { namespace: printNamespace })
  protected actionGenerateCatalog: any;

  @Getter("getGeneratedCatalog", { namespace: printNamespace })
  protected getGeneratedCatalog: any;

  @Action("countCatalogPages", { namespace: printNamespace })
  protected actionCountCatalogPages: any;

  @Getter("getCountCatalogPages", { namespace: printNamespace })
  protected getCountCatalogPages: any;

  @StatusModule.Getter("getList")
  protected statusList: any;

  // props
  @Prop({ default: "add" })
  public type: any;
  @Prop({ default: false })
  public saved: any;
  @Prop({ default: false })
  public resetAction: any;
  @Prop({ default: false })
  public loading: any;
  @Prop({ default: "" })
  public error: any;
  @Prop({
    default: function() {}
  })
  public saveAction: any;
  @Prop({
    default: function() {}
  })
  public back: any;
  @Prop()
  public item: any;

  // model
  // private catalogId: number = 0;
  @Validate({ required, minLength: minLength(4) })
  public catalogName: string = "";

  @Validate({ required })
  public dateFrom: string = "";

  @Validate({ required, dateGreater })
  public dateTo: string = "";

  @Validate({
    required,
    minValue: minValue(1),
    maxValue: maxValue(2)
  })
  public catalogType: number | null = null;

  @Watch("item", { immediate: true, deep: true })
  protected onItemChange(item: any) {
    if (item) {
      this.init(item);
    }
  }

  @Watch("isUpdated", { immediate: true, deep: true })
  protected onItemUpdated(updated: boolean) {
    if (updated) {
      // remove active catalog if the current catalog is no longer active after update
      if (this.activeCatalog && this.item.id == this.activeCatalog.id) {
        if (!CatalogUtil.isActive(this.item)) {
          this.setActiveCatalog(null);
        }
      } else {
        // set active catalog, if the current catalog has become active
        if (CatalogUtil.isActive(this.item)) {
          this.setActiveCatalog(this.item);
        }
      }
    }
  }

  private catalogTypes: Array<Object> = [
    { value: 1, name: String(i18n.t("general.primavera")) },
    { value: 2, name: String(i18n.t("general.expovina")) }
  ];

  private get catalogTypesSorted(): any[] {
    return SortService.arrayAsc(this.catalogTypes, "name");
  }

  private init(catalog: ICatalog): void {
    this.$nextTick(() => {
      this.catalogName = catalog.name;
      this.dateFrom = DateUtil.parseServerDate(catalog.from);
      this.dateTo = DateUtil.parseServerDate(catalog.to);
      this.catalogType = catalog.catalog_type;
      this.dateFromDisabled = CatalogUtil.isActive(catalog);
    });
  }

  private selectCatalogType(value: any) {
    this.catalogType = value;
  }

  public async onSubmit(e: Event): Promise<any> {
    this.$v.$touch();
    if (this.$v.$invalid) {
      return;
    }
    const fromDate: string = DateUtil.formatFrontendDate(this.dateFrom);
    const toDate: string = DateUtil.formatFrontendDate(this.dateTo);
    const catalog: ICatalog = {
      name: this.catalogName,
      from: fromDate,
      to: toDate,
      catalog_type: this.catalogType as number
    };
    if (this.$route.params.id) {
      // edit
      catalog.id = Number.parseInt(this.$route.params.id);
      await this.saveAction({
        resource: this.resourceName,
        data: catalog,
        id: catalog.id,
        descriptionField: "name"
      });
    } else {
      // add
      await this.saveAction({
        resource: this.resourceName,
        data: catalog,
        descriptionField: "name"
      });
    }
    await this.$store.dispatch(
      "catalog/activeCatalog",
      "catalog/active-catalog"
    );
  }

  protected generateCatalogAction() {
    this.actionGenerateCatalog({
      resource: "print/catalog",
      id: this.$route.params.id
    });
  }

  protected countCatalogPagesAction() {
    this.actionCountCatalogPages({
      resource: "print/count-pages",
      id: this.$route.params.id
    });
  }

  protected get disableButton() {
    const status = this.getStatus(
      this.statusList,
      Number(this.$route.params.id)
    );

    return (
      this.isEdit && Boolean(status !== undefined && status.status === "0")
    );
  }

  protected get isEdit() {
    return this.type === "edit";
  }
}
