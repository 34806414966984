import { GetterTree } from "vuex";
import { State } from "./state";

export function getGeneratedCatalog(state: State): any {
  return state.generatedCatalog;
}

export function getCountCatalogPages(state: State): any {
  return state.countCatalogPages;
}
export default <GetterTree<State, any>>{
  getGeneratedCatalog,
  getCountCatalogPages
};
