
import { Component, Prop, Watch } from "vue-property-decorator";
import CrudLayout from "@/layouts/CrudLayout.vue";
import { Validations } from "vuelidate-property-decorators";
import { numeric, required } from "vuelidate/lib/validators";
import Validation from "@/components/Validation.vue";
import FormBackButton from "@/components/buttons/FormBackButton.vue";
import MultiSelect from "@/components/multiselect/MultiSelect.vue";
import {
  pdfRequired,
  requiredIfValue,
  linkValidator
} from "@/validators/validators";
import { CONTENT_TYPE } from "@/config/PageTypes";
import AuthService from "@/service/AuthService";
import { ROLES } from "@/config/Roles";
import AuthImage from "@/directives/AuthImage";
import axios from "axios";
import FileSaver from "file-saver";
import Permission from "@/service/Permission";
import PageContentFormUpload from "@/views/admin/page/content/PageContentFormUpload.vue";
import { Getter, namespace as VuexNamespace } from "vuex-class";
import { BaseMixin } from "@/mixins/baseMixin";

const namespace: string = "page";
const catalogNamespace: string = "catalog";
const StatusModule = VuexNamespace("status");

@Component({
  components: {
    MultiSelect,
    FormBackButton,
    CrudLayout,
    Validation,
    PageContentFormUpload
  },
  directives: {
    AuthImage: new AuthImage()
  }
})
export default class PageContentForm extends BaseMixin {
  public name: string = "PageContentForm";
  private resourceName = namespace;

  @Prop({ default: false })
  public loading: any;

  @Prop({ default: "" })
  public title?: string;

  @Prop({ default: "add" })
  public type?: string;

  @Prop({ default: function() {} })
  public resetAction: any;

  @Prop()
  public item: any;

  @Prop({ default: function() {} })
  public saveAction: any;

  @Prop({ default: false })
  public isExhibitor?: boolean;

  public uploadPercentage: number = 0;
  public showProgressBar: boolean = false;
  public active: number = 1;
  public file: any = "";
  public description: string = "";
  public link: string = "";
  public id?: number;
  public catalog_id?: number;
  public imageUrl: string = "";
  public content_pdf: string = "";
  public disableButton: boolean = false;

  @Getter("getActiveCatalog", { namespace: catalogNamespace })
  public catalog: any;

  @Validations()
  protected validators() {
    if (this.type == "edit") {
      return {
        active: { required, numeric },
        file: {
          requireIfValue: requiredIfValue("content_pdf", null),
          pdfRequired: pdfRequired
        },
        description: {},
        link: { linkValidator: linkValidator }
      };
    }

    return {
      active: { required, numeric },
      file: { required, pdfRequired: pdfRequired },
      description: {},
      link: { linkValidator }
    };
  }

  public get preview(): boolean {
    const authService: AuthService = new AuthService();

    if (this.type == "edit") {
      if (
        Permission.hasPermission(
          [ROLES.ADMIN, ROLES.SUPER_ADMIN],
          authService.getRole()
        )
      ) {
        return true;
      }

      if (this.item) {
        if (authService.getUserId() == this.item.exhibitor_id) {
          return true;
        }
      }
    }

    return false;
  }

  public download() {
    const el: HTMLElement | null = document.getElementById("image-preview");

    if (el && el.getAttribute("valid") == "true") {
      if (this.preview) {
        axios
          .get(
            process.env.VUE_APP_BACKEND_HOST + "/api/page-download/" + this.id,
            { responseType: "arraybuffer" }
          )
          .then(response => {
            const file = new Blob([response.data], { type: "application/pdf" });
            FileSaver.saveAs(file, this.id + ".pdf");
          });
      }
    }
  }

  private fileHandler(value: any) {
    this.file = value;
  }

  public get fileExtension(): string {
    if (this.file) {
      return this.file.name.split(".").pop();
    }

    return "";
  }

  public beforeDestroy() {
    this.resetAction();
  }

  @Watch("item", { immediate: true, deep: true })
  protected onItemChange(item: any) {
    if (item) {
      this.init(item);
    }
  }

  public get getCatalogId(): string {
    if (this.$router.currentRoute.params.hasOwnProperty("catalog")) {
      return this.$router.currentRoute.params.catalog;
    }
    return this.catalog.id;
  }

  private init(page: any): void {
    this.$nextTick(() => {
      this.description = page.description;
      this.link = page.link;
      this.content_pdf = page.content_pdf;
      this.id = page.id;
      this.catalog_id = page.catalog_id;
      this.active = page.active;
      this.imageUrl =
        process.env.VUE_APP_BACKEND_HOST +
        "/api/page-image/" +
        this.id +
        "?t=" +
        Date.now();
    });
  }

  public onSubmit(): void {
    this.$v.$touch();
    if (this.$v.$invalid) {
      return;
    }

    const formData = new FormData();
    formData.append("file", this.file);
    formData.append("active", String(this.active));
    formData.append(
      "description",
      String(this.description) == "null" ? "" : String(this.description)
    );
    formData.append(
      "link",
      String(this.link) == "null" ? "" : String(this.link)
    );

    if (this.type == "add") {
      formData.append("catalog_id", String(this.getCatalogId));
      formData.append("type", String(CONTENT_TYPE));

      this.saveAction({
        resource: "catalog/add-content",
        data: formData,
        disabledLoading: true,
        config: {
          headers: {
            "Content-Type": "multipart/form-data"
          },
          onUploadProgress: (progressEvent: any) => {
            if (formData.get("file") instanceof File) {
              this.uploadPercentage = Number(
                Math.round((progressEvent.loaded / progressEvent.total) * 100)
              );
            }
          }
        }
      });
    } else {
      formData.append("id", String(this.id));
      this.showProgressBar = true;
      this.saveAction({
        resource: "catalog/update-content",
        data: formData,
        id: this.id,
        disabledLoading: true,
        descriptionField: "content_pdf",
        config: {
          headers: {
            "Content-Type": "multipart/form-data"
          },
          onUploadProgress: (progressEvent: any) => {
            if (formData.get("file") instanceof File) {
              this.uploadPercentage = Number(
                Math.round((progressEvent.loaded / progressEvent.total) * 100)
              );
            }
          }
        }
      })
        .then(() => {
          this.showProgressBar = false;
          this.uploadPercentage = 0;
        })
        .catch(() => {
          this.showProgressBar = false;
          this.uploadPercentage = 0;
        });
    }
  }

  @StatusModule.Getter("getList")
  protected statusList: any;

  @Watch("statusList", { immediate: true, deep: true })
  protected onStatusListChange(list: any) {
    if (list) {
      this.imageUrl =
        process.env.VUE_APP_BACKEND_HOST +
        "/api/page-image/" +
        this.id +
        "?t=" +
        Date.now();

      if (this.type == "edit") {
        this.disableButtonDispatch(list);
      }
    }
  }

  protected disableButtonDispatch(list: Array<Object>) {
    const status = this.getStatus(list, Number(this.id));
    const statusIsReady = status && status.ready;
    this.disableButton = statusIsReady == false;
  }

  protected get statusOnReady() {
    return this.item && this.item.status && this.item.status.ready;
  }

  protected get buttonDisabled() {
    return this.statusOnReady == 0 || this.disableButton;
  }
}
