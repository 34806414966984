
import { Component, Vue, Watch } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import CrudLayout from "@/layouts/CrudLayout.vue";
import Validation from "@/components/Validation.vue";
import WineForm from "@/views/admin/wine/WineForm.vue";
import FormCreate from "@/views/form/create/FormCreate.vue";
import AuthService from "@/service/AuthService";
import { ROLES } from "@/config/Roles";

const namespace: string = "wine";

@Component({
  components: {
    CrudLayout,
    Validation,
    WineForm
  },
  extends: FormCreate
})
export default class WineCreate extends Vue {
  protected name: string = "WineCreate";
  protected resourceName = "wine";

  protected get redirectRouteName(): string | null {
    const authService: AuthService = new AuthService();
    if (authService.getRole() == ROLES.EXHIBITOR) {
      return "exhibitor_wine_edit";
    }

    return null;
  }

  @Getter("getError", { namespace }) error: any;
  @Getter("isLoading", { namespace }) loading: any;
  @Getter("isCreated", { namespace }) created: any;
  @Getter("getItem", { namespace }) createdItem: any;

  @Action("create", { namespace }) createItemAction: any;
  @Action("resetCreate", { namespace }) resetAction: any;

  beforeDestroy() {
    this.resetAction();
  }
}
