import AuthService from "@/service/AuthService";

export default class Permission {
  public static hasPermission(roles: Array<string>, role: string): boolean {
    if (roles.indexOf(role) == -1) {
      return false;
    }

    return true;
  }

  public static ownerCheck(user_id: number): boolean {
    const authService = new AuthService();
    const user: any = authService.getUser();

    if (user.hasOwnProperty("id")) {
      if (user.id == user_id) {
        return true;
      }
    }

    return false;
  }

  public static hasPermissionOwnerCheck(
    roles: Array<string>,
    role: string,
    user_id: number
  ): boolean {
    if (Permission.hasPermission(roles, role)) {
      return Permission.ownerCheck(user_id);
    }

    return false;
  }
}
