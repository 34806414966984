
import DefaultLayout from "@/layouts/DefaultLayout.vue";
import { Component, Vue, Watch } from "vue-property-decorator";
import { BaseMixin } from "@/mixins/baseMixin";
import { Action, Getter } from "vuex-class";
// @ts-ignore
import "vue-good-table/dist/vue-good-table.css";
import MisTable from "@/components/MisTable.vue";
import { ICrudOptions } from "@/service/interfaces/ICrudOptions";
import ITableColumn from "@/interfaces/table/ITableColumn";
import CrudLayout from "@/layouts/CrudLayout.vue";
import i18n from "@/locales";
import PagePositionField from "@/components/better_table/components/PagePositionField.vue";
import AppConfig from "@/config/AppConfig";

const namespace = "page";
const catalogNamespace = "catalog";

@Component({
  components: {
    PagePositionField,
    CrudLayout,
    DefaultLayout,
    MisTable
  },
  mixins: [BaseMixin]
})
export default class PageList extends Vue {
  public name: string = "PageList";
  public resourceName: string = "page";
  public columns: Array<ITableColumn> = [
    {
      label: String(i18n.t("general.position")),
      field: "position",
      filterOptions: {
        enabled: true
      }
    },
    {
      label: String(i18n.t("general.page_type")),
      field: "page_type",
      formatFn: (value: any) => {
        return String(i18n.t("general." + AppConfig.PAGE_TYPES[value]));
      },
      filterOptions: {
        enabled: true,
        filterDropdownItems: [
          {
            value: 1,
            text: String(i18n.t("general." + AppConfig.PAGE_TYPES[1]))
          },
          {
            value: 2,
            text: String(i18n.t("general." + AppConfig.PAGE_TYPES[2]))
          },
          {
            value: 3,
            text: String(i18n.t("general." + AppConfig.PAGE_TYPES[3]))
          },
          {
            value: 4,
            text: String(i18n.t("general." + AppConfig.PAGE_TYPES[4]))
          },
          {
            value: 5,
            text: String(i18n.t("general." + AppConfig.PAGE_TYPES[5]))
          }
        ]
      }
    },
    {
      label: String(i18n.t("general.stand")),
      field: "stand.stand",
      filterOptions: {
        enabled: true
      }
    },
    {
      label: String(i18n.t("general.page_title")),
      field: "page_title.name",
      filterOptions: {
        enabled: true
      }
    },
    {
      label: String(i18n.t("general.active")),
      field: "active",
      type: "number",
      html: true,
      filterOptions: {
        enabled: true,
        filterDropdownItems: [
          { value: 1, text: String(i18n.t("general.active")) },
          { value: 0, text: String(i18n.t("general.inactive")) }
        ]
      }
    },
    {
      label: String(i18n.t("general.released")),
      field: "released",
      type: "number",
      html: true,
      filterOptions: {
        enabled: true,
        filterDropdownItems: [
          { value: 1, text: String(i18n.t("general.released")) },
          { value: 0, text: String(i18n.t("general.non_released")) }
        ]
      }
    },
    {
      label: String(i18n.t("general.accepted")),
      field: "release_accepted",
      type: "number",
      html: true,
      filterOptions: {
        enabled: true,
        filterDropdownItems: [
          { value: 1, text: String(i18n.t("general.accepted")) },
          { value: 0, text: String(i18n.t("general.non_accepted")) }
        ]
      }
    },
    {
      label: String(i18n.tc("general.action", 2)),
      field: "actions",
      sortable: false
    }
  ];
  protected searchFields: Array<string> = ["name"];

  @Getter("getList", { namespace }) dataList: any;
  @Getter("getTotal", { namespace }) totalRows: any;
  @Getter("getError", { namespace }) error: any;
  @Getter("isLoading", { namespace }) loading: any;
  @Getter("getPosition", { namespace }) position?: number | null;
  @Getter("getItem", { namespace: catalogNamespace }) catalog?: any;
  @Getter("isSwapped", { namespace: namespace })
  isPositionSwapped?: boolean;

  @Action("search", { namespace }) searchItemsAction: any;
  @Action("resetList", { namespace }) resetAction: any;
  @Action("changePosition", { namespace }) changePosition: any;
  @Action("successMessage", { namespace }) successMessageAction: any;
  @Action("errorInactiveCatalog", { namespace })
  inactiveCatalogMessageAction: any;

  private instantSave: boolean = true;

  @Watch("catalog", { immediate: true, deep: true })
  protected catalogWatcher(catalog: any) {
    if (catalog && catalog.currently_active == false) {
      const message: String = String(
        i18n.t("general.catalog_is_inactive", { catalog: this.catalog.name })
      );
      this.$nextTick(() => {
        this.inactiveCatalogMessageAction(message);
      });

      this.$store.dispatch("catalog/resetCreate");
    }
  }

  @Watch("isPositionSwapped")
  protected onPositionSwapped(swapped: boolean): void {
    if (swapped && this.$refs.pageTable) {
      (this.$refs.pageTable as MisTable).reloadData();
    }
  }

  public searchAction(options: ICrudOptions): void {
    options.resource = "active_catalog_and_user/page";
    this.searchItemsAction(options);
  }

  public deleteAction(options: ICrudOptions): void {}

  public onTableRowDrop(e: Event, data: any): void {
    data = {
      ...data,
      moveItem: this.dataList[data["oldIdx"]],
      afterItem: this.dataList[data["newIdx"]]
    };
    if (this.instantSave) {
      this.changePosition({
        resource: this.resourceName,
        resourceName: this.$i18n.t("general.page"),
        params: {
          page_id: this.dataList[data["oldIdx"]].id,
          new_position: this.dataList[data["newIdx"]].position
        }
      });
    }
  }

  get resourcePath(): string {
    return "catalog/" + this.$route.params.catalog + "/page";
  }

  beforeDestroy() {
    this.resetAction();
  }
}
