
import { Component, Vue } from "vue-property-decorator";
import { Action, Getter, namespace } from "vuex-class";
import CrudLayout from "@/layouts/CrudLayout.vue";
import Validation from "@/components/Validation.vue";
import RegionForm from "@/views/admin/region/RegionForm.vue";

const region = namespace("region");
const RegionGetter = region.Getter;
const RegionAction = region.Action;

@Component({
  components: {
    CrudLayout,
    Validation,
    RegionForm
  }
})
export default class RegionUpdate extends Vue {
  public name: string = "RegionUpdate";
  private resourceName = "region";

  @RegionGetter("getError") error: any;
  @RegionGetter("isLoading") loading: any;
  @RegionGetter("isUpdated") updated: any;
  @RegionGetter("getItem") item: any;

  @RegionAction("update") updateItemAction: any;
  @RegionAction("findOne") getItemAction: any;
  @RegionAction("resetUpdate") resetAction: any;

  protected back() {
    this.$router.push({ path: "/" + this.resourceName });
  }

  mounted() {
    this.getItemAction({
      resource: this.resourceName,
      id: Number.parseInt(this.$route.params.id),
      descriptionField: "name"
    });
  }

  beforeDestroy() {
    this.resetAction();
  }
}
