
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import "@/assets/sass/alert.scss";
import ScrollToTopMixin from "@/mixins/ScrollToTopMixin";

@Component({
  mixins: [ScrollToTopMixin]
})
export default class Alert extends Vue {
  public name: string = "Alert";

  @Prop({ required: true })
  public message!: Object;

  @Prop({ required: false, default: "danger" })
  public alertType!: String;

  @Watch("message", { immediate: true, deep: false })
  protected rerender(message: any) {
    // The watcher acts as a rerenderer, so the alert component to scroll
    // everytime not depending on the message itself
    // @ts-ignore
    this.scrollToTop();
  }

  public hideAlert(): void {
    this.$emit("handleClose", true);
  }
}
