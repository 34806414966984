import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export class TransitionMixin extends Vue {
  public inheritAttrs: boolean = false;

  /**
   * Transition duration. Number for specifying the same duration for enter/leave transitions
   * Object style {enter: 300, leave: 300} for specifying explicit durations for enter/leave
   */
  @Prop({ default: 300 })
  public duration!: any | Object;

  /**
   * Transition delay. Number for specifying the same delay for enter/leave transitions
   * Object style {enter: 300, leave: 300} for specifying explicit durations for enter/leave
   */
  @Prop({ default: 0 })
  public delay!: Number | Object;

  /**
   * Whether the component should be a `transition-group` component.
   */
  @Prop()
  public group!: Boolean;
  /**
   * Transition tag, in case the component is a `transition-group`
   */
  @Prop({ default: "span" })
  public tag!: String;
  /**
   *  Transform origin property https://tympanus.net/codrops/css_reference/transform-origin/.
   *  Can be specified with styles as well but it's shorter with this prop
   */
  @Prop({ default: "" })
  public origin!: String;
  /**
   * Element styles that are applied during transition. These styles are applied on @beforeEnter and @beforeLeave hooks
   */
  @Prop({
    default: () => {
      return {
        animationFillMode: "both",
        animationTimingFunction: "ease-out"
      };
    }
  })
  public styles!: Object;

  get componentType() {
    return this.group ? "transition-group" : "transition";
  }

  get hooks() {
    return {
      ...this.$listeners,
      beforeEnter: this.beforeEnter,
      afterEnter: (el: any) => {
        this.cleanUpStyles(el);
        this.$emit("after-enter", el);
      },
      beforeLeave: this.beforeLeave,
      leave: this.leave,
      afterLeave: (el: any) => {
        this.cleanUpStyles(el);
        this.$emit("after-leave", el);
      }
    };
  }

  public beforeEnter(el: any) {
    // @ts-ignore
    let enterDuration = this.duration.enter
      ? this.duration.enter
      : this.duration;
    el.style.animationDuration = `${enterDuration}ms`;

    // @ts-ignore
    let enterDelay = this.delay.enter ? this.delay.enter : this.delay;
    el.style.animationDelay = `${enterDelay}ms`;

    this.setStyles(el);
    this.$emit("before-enter", el);
  }

  public cleanUpStyles(el: any) {
    Object.keys(this.styles).forEach(key => {
      // @ts-ignore
      const styleValue = this.styles[key];
      if (styleValue) {
        el.style[key] = "";
      }
    });
    el.style.animationDuration = "";
    el.style.animationDelay = "";
  }

  public beforeLeave(el: any) {
    // @ts-ignore
    let leaveDuration = this.duration.leave
      ? this.duration.leave
      : this.duration;
    el.style.animationDuration = `${leaveDuration}ms`;

    // @ts-ignore
    let leaveDelay = this.delay.leave ? this.delay.leave : this.delay;
    el.style.animationDelay = `${leaveDelay}ms`;

    this.setStyles(el);
    this.$emit("before-leave", el);
  }

  public leave(el: any, done: any) {
    this.setAbsolutePosition(el);
    this.$emit("leave", el, done);
  }

  public setStyles(el: any) {
    this.setTransformOrigin(el);
    Object.keys(this.styles).forEach(key => {
      // @ts-ignore
      const styleValue = this.styles[key];
      if (styleValue) {
        el.style[key] = styleValue;
      }
    });
  }

  public setAbsolutePosition(el: any) {
    if (this.group) {
      el.style.position = "absolute";
    }
    return this;
  }

  public setTransformOrigin(el: any) {
    if (this.origin) {
      el.style.transformOrigin = this.origin;
    }
    return this;
  }
}
