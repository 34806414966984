
import { Component, Vue } from "vue-property-decorator";
import { getRoleHomeComponentFromStorage } from "@/config/Roles";

@Component
export default class PageNotFound extends Vue {
  public beforeMount() {
    const homePage: string = getRoleHomeComponentFromStorage();
    const loginRouteName = "Login";
    if (
      homePage == undefined &&
      this.$router.currentRoute.name != loginRouteName
    ) {
      this.$router.push({ name: loginRouteName });
    } else {
      this.$router.push({ name: homePage });
    }
  }
  public render() {
    return null;
  }
}
