
import { Vue, Component } from "vue-property-decorator";
import ActionButton from "@/components/buttons/ActionButton.vue";
import i18n from "@/locales";
import { Action } from "vuex-class";

const namespace: string = "exhibitor";

@Component({
  components: {
    ActionButton
  }
})
export default class ReminderButton extends Vue {
  public name: string = "ReminderButton";
  protected remindInfo: string = "";
  protected modalId: string = "modal-remind-confirm";

  @Action("remindExhibitors", { namespace: namespace })
  protected remindExhibitorsAction: any;

  protected okDialogRemind() {
    this.$bvModal.hide(this.modalId);
    this.remindAction();
  }

  protected showRemindDialog(): void {
    this.remindInfo = String(
      i18n.t("general.question_remind_exhibitors", {
        resourceName: ""
      })
    );
    this.$bvModal.show(this.modalId);
  }

  protected remindAction() {
    this.remindExhibitorsAction("active-catalog");
  }
}
