import { ActionTree, ActionContext } from "vuex";
import { State } from "./state";
import axios from "axios";
import AppConfig from "@/config/AppConfig";
import router from "@/router";
import i18n from "@/locales";
import { getRoleHomeComponent } from "@/config/Roles";
import AuthService from "@/service/AuthService";
import { ROLES, API_ROLES } from "@/config/Roles";

export function login(store: ActionContext<State, any>, credentials: any) {
  store.commit("IS_LOADING", false);
  return new Promise((resolve, reject) => {
    axios
      .post(AppConfig.ENTRYPOINT + "/api/login", {
        email: credentials.email,
        password: credentials.password
      })
      .then(async (response: any) => {
        resolve(response);
        store.commit("IS_LOADING", true);
        if (response.data.error) {
          const trans =
            response.data.error == "invalid_grant"
              ? i18n.t("validation.invalid_grant")
              : i18n.t(response.data.message);
          store.commit("ERROR", trans);
          store.commit("SUCCESS", null);
        } else if (response.data.access_token) {
          const token: string = response.data.access_token;
          const authService = new AuthService();
          if (ROLES[response.data.role]) {
            authService.setToken(token);
            authService.setUser(response.data.user);
            authService.setRole(response.data.role);
            await store.dispatch(
              "catalog/setActiveCatalog",
              response.data.active_catalog,
              { root: true }
            );
            await store.dispatch(
              "catalog/setUserCatalogRequest",
              response.data.user_catalog_request,
              { root: true }
            );
          } else if (API_ROLES[response.data.role]) {
            store.commit("ERROR", i18n.t("auth.login.invalid_email_password"));
          }
          store.commit("RETRIEVE_TOKEN", token);
          store.commit("IS_LOADING", true);

          let redirectRouteName = null;
          if (response.data.role == "EXHIBITOR") {
            if (response.data.active_catalog) {
              if (!response.data.user_catalog_request) {
                redirectRouteName = "Request";
              } else {
                redirectRouteName = "exhibitor_wine";
              }
            }
          }
          const homePage: string = getRoleHomeComponent(
            response.data.role,
            redirectRouteName
          );
          if (ROLES[response.data.role]) {
            await router.push({ name: homePage });
          }
        }
      })
      .catch((error: any) => {
        store.commit("IS_LOADING", true);
        store.commit("ERROR", error);
        store.commit("SUCCESS", null);
        reject(error);
      });
  });
}

export function loginAsExhibitor(
  store: ActionContext<State, any>,
  credentials: any
) {
  store.commit("IS_LOADING", false);
  return new Promise((resolve, reject) => {
    axios
      .post(AppConfig.ENTRYPOINT + "/api/login-as-exhibitor", {
        id: credentials.id,
        email: credentials.email
      })
      .then(async (response: any) => {
        resolve(response);
        store.commit("IS_LOADING", true);

        if (response.data.error) {
          const trans =
            response.data.error == "invalid_grant"
              ? i18n.t("validation.invalid_grant")
              : i18n.t(response.data.message);
          store.commit("ERROR", trans);
          store.commit("SUCCESS", null);
        } else if (response.data.access_token) {
          const token: string = response.data.access_token;
          const authService = new AuthService();
          if (ROLES[response.data.role]) {
            //authService.clear();
            authService.setToken(token);
            authService.setUser(response.data.user);
            authService.setRole(response.data.role);
            await store.dispatch(
              "catalog/setActiveCatalog",
              response.data.active_catalog,
              { root: true }
            );
            await store.dispatch(
              "catalog/setUserCatalogRequest",
              response.data.user_catalog_request,
              { root: true }
            );
          } else if (API_ROLES[response.data.role]) {
            store.commit("ERROR", i18n.t("auth.login.invalid_email_password"));
          }
          store.commit("RETRIEVE_TOKEN", token);
          store.commit("IS_LOADING", true);

          let redirectRouteName = null;
          if (response.data.role == "EXHIBITOR") {
            if (response.data.active_catalog) {
              if (!response.data.user_catalog_request) {
                redirectRouteName = "Request";
              } else {
                redirectRouteName = "exhibitor_wine";
              }
            }
          }
          const homePage: string = getRoleHomeComponent(
            response.data.role,
            redirectRouteName
          );
          if (ROLES[response.data.role]) {
            await router.push({ name: homePage });
          }
        }
      })
      .catch((error: any) => {
        store.commit("ERROR", error);
        store.commit("SUCCESS", null);
        reject(error);
      })
      .finally(() => {
        store.commit("IS_LOADING", true);
      });
  });
}

export function logout(store: ActionContext<State, any>) {
  const authService = new AuthService();
  const token = authService.getToken();

  let config = {
    headers: {
      Authorization: "Bearer " + token
    }
  };

  return new Promise((resolve, reject) => {
    axios
      .get(AppConfig.ENTRYPOINT + "/api/logout", config)
      .then((response: any) => {
        if (response.status) {
          authService.clear();
          store.commit("RETRIEVE_TOKEN", null);
        }
      })
      .catch((error: any) => {
        reject(error);
      });
  });
}

export function register(
  store: ActionContext<State, any>,
  credentials: any
): Promise<any> {
  store.commit("IS_LOADING", false);
  return new Promise((resolve, reject) => {
    axios
      .post(AppConfig.ENTRYPOINT + "/api/register", {
        email: credentials.email,
        password: credentials.password,
        password_confirmation: credentials.passwordConfirmation,
        company_name: credentials.companyName,
        street: credentials.street,
        number: credentials.number,
        website: credentials.website,
        zip_code: credentials.zipCode,
        city: credentials.city,
        phone: credentials.phone,
        description: credentials.description
      })
      .then((response: any) => {
        resolve(response);
        store.commit("IS_LOADING", true);
        if (response.data.error) {
          store.commit("ERROR", i18n.t(response.data.message));
          store.commit("SUCCESS", null);
        } else {
          store.commit("SUCCESS", i18n.t(response.data.message));
          store.commit("ERROR", null);
          router.push({ name: "Login" });
        }
      })
      .catch((error: any) => {
        store.commit("IS_LOADING", true);
        store.commit("ERROR", error);
        store.commit("SUCCESS", null);
        reject(error);
      });
  });
}

export function forgotPassword(
  store: ActionContext<State, any>,
  credentials: any
) {
  store.commit("IS_LOADING", false);
  return new Promise((resolve, reject) => {
    axios
      .post(AppConfig.ENTRYPOINT + "/api/forgot-password", {
        email: credentials.email
      })
      .then((response: any) => {
        store.commit("IS_LOADING", true);
        if (response.data.error) {
          store.commit("ERROR", i18n.t(response.data.message));
          store.commit("SUCCESS", null);
        } else {
          store.commit(
            "SUCCESS",
            i18n.t(
              "general.you_have_received_an_email_with_password_change_instructions"
            )
          );
          store.commit("ERROR", null);
        }
        resolve(response);
        router.push({ name: "Login" });
      })
      .catch((error: any) => {
        store.commit("IS_LOADING", true);
        store.commit("ERROR", i18n.t(error.response.data.message));
        store.commit("SUCCESS", null);
        reject(error);
      });
  });
}

export function resetPassword(
  store: ActionContext<State, any>,
  credentials: any
) {
  store.commit("IS_LOADING", false);
  return new Promise((resolve, reject) => {
    axios
      .post(AppConfig.ENTRYPOINT + "/api/reset-password", {
        email: credentials.email,
        password: credentials.password,
        password_confirmation: credentials.passwordConfirmation,
        token: credentials.token
      })
      .then((response: any) => {
        resolve(response);
        store.commit("IS_LOADING", true);
        if (response.data.error) {
          store.commit("ERROR", i18n.t(response.data.message));
          store.commit("SUCCESS", null);
        } else {
          store.commit(
            "SUCCESS",
            i18n.t("general.you_have_successfully_changed_your_password")
          );
          store.commit("ERROR", null);
          router.push({ name: "Login" });
        }
      })
      .catch((error: any) => {
        store.commit("IS_LOADING", true);
        store.commit("ERROR", error);
        store.commit("SUCCESS", null);
        reject(error);
      });
  });
}

export function setError(store: ActionContext<State, any>, data: any) {
  store.commit("ERROR", data);
}

export function setSuccess(store: ActionContext<State, any>, data: any) {
  store.commit("SUCCESS", data);
}

export default <ActionTree<State, any>>{
  login,
  loginAsExhibitor,
  logout,
  register,
  forgotPassword,
  resetPassword,
  setError,
  setSuccess
};
