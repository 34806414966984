
import { Component, Vue, Watch } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import CrudLayout from "@/layouts/CrudLayout.vue";
import Validation from "@/components/Validation.vue";
import AdminForm from "./AdminForm.vue";
import i18n from "@/locales";

const namespace: string = "admin";
const snackbarNamespace: string = "snackbar";

@Component({
  components: {
    CrudLayout,
    Validation,
    AdminForm
  }
})
export default class AdminUpdate extends Vue {
  public name: string = "AdminUpdate";
  private resourceName = "admin";

  @Getter("getError", { namespace }) error: any;
  @Getter("isLoading", { namespace }) loading: any;
  @Getter("isUpdated", { namespace }) updated: any;
  @Getter("getItem", { namespace }) item: any;

  @Action("update", { namespace }) updateItemAction: any;
  @Action("findOne", { namespace }) getItemAction: any;
  @Action("resetUpdate", { namespace }) resetAction: any;

  protected back() {
    // TODO remember last table state (session storage)
    this.$router.push({ path: "/" + this.resourceName });
  }

  mounted() {
    this.getItemAction({
      resource: this.resourceName,
      id: Number.parseInt(this.$route.params.id),
      descriptionField: "email"
    });
  }

  beforeDestroy() {
    this.resetAction();
  }
}
