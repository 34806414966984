import { Component, Vue } from "vue-property-decorator";
import axios from "axios";
import FileSaver from "file-saver";

@Component
export class BaseMixin extends Vue {
  public loadAsset(src: string): any {
    return require("@/assets/img/" + src);
  }

  public download(
    url: any,
    filename: string,
    type: string = "application/pdf"
  ) {
    axios
      .get(url, { responseType: "arraybuffer" })
      .then(response => {
        const file = new Blob([response.data], { type });
        FileSaver.saveAs(file, filename);
      })
      .catch(err => {
        console.error(err);
      });
  }

  public getStatus(list: Array<any>, id: number) {
    return list.find(x => x.statusable_id == id);
  }
}
