
import { Component, Vue, Watch } from "vue-property-decorator";
import AsideMenu from "@/components/menu/AsideMenu.vue";
import MobileMenu from "@/components/menu/MobileMenu.vue";
import Footer from "@/components/footer/Footer.vue";
import Cookie from "@/components/Cookie.vue";
import Loading from "@/components/Loading.vue";
import { routes } from "@/router";
import LoggedInHeader from "@/components/LoggedInHeader.vue";
import Snackbar from "@/components/snackbar/Snackbar.vue";
import { Action, Getter } from "vuex-class";

const snackbarNamespace: string = "snackbar";
const asideMenuNamespace: string = "asideMenu";

@Component({
  components: {
    Snackbar,
    LoggedInHeader,
    Cookie,
    Footer,
    MobileMenu,
    Loading,
    AsideMenu
  }
})
export default class DefaultLayout extends Vue {
  public name: string = "DefaultLayout";

  @Getter("isSetInLS", { namespace: asideMenuNamespace })
  protected isSetInLS: any;

  @Getter("getStatus", { namespace: asideMenuNamespace })
  protected isOpen: any;

  @Action("updateStatus", { namespace: asideMenuNamespace })
  protected updateAsideStatus: any;

  @Action("setText", { namespace: snackbarNamespace })
  protected setText: any;

  @Action("setActive", { namespace: snackbarNamespace })
  protected setActive: any;

  @Getter("isLoading", { namespace: snackbarNamespace })
  protected loading: any;

  @Getter("getMobileMenuStatus", { namespace: asideMenuNamespace })
  protected onMobileMenuOpen: any;

  protected windowWidth = 0;
  protected body: any = document.body;
  protected prevScroll: number = 0;

  protected onScrollClass: string | null = null;

  protected headerMenu: Array<Object> = routes;

  public get isMobile(): any {
    return this.windowWidth <= 1024;
  }

  @Watch("windowWidth", { immediate: true })
  protected updateAsideMenu(screenWidth: any) {
    if (!this.isMobile) {
      if (!this.isSetInLS) {
        if (screenWidth <= 1366) {
          // 1366x768 as 13" screen resolution
          this.updateAsideStatus(false);
        }
      }
    }
  }

  public onNavPositionChange() {
    if (window.scrollY <= 53) {
      this.onScrollClass = null;
    } else if (this.prevScroll > window.scrollY) {
      this.onScrollClass = "lg:scroll:up";
    } else {
      this.onScrollClass = "scroll:down";
    }
    this.prevScroll = window.scrollY;
  }

  public onScroll() {
    this.onNavPositionChange();
  }

  public mounted() {
    this.windowWidth = this.body.clientWidth;
    window.addEventListener("resize", () => {
      this.windowWidth = this.body.clientWidth;
    });
    window.addEventListener("scroll", this.onScroll);
  }

  public destroyed() {
    window.removeEventListener("scroll", this.onScroll);
  }
}
