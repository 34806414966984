
import { Component, Vue } from "vue-property-decorator";

const namespace: string = "award";

@Component({
  components: {}
})
export default class AwardView extends Vue {
  public name: string = "AwardView";
}
