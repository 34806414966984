import { GetterTree } from "vuex";
import { State } from "./state";
import {
  getList,
  getTotal,
  getItem,
  getError,
  isLoading,
  isCreated,
  isUpdated,
  isDeleted
} from "@/store/modules/crud/functions/getters";

export function getExhibitorsList(state: State): any {
  return state.exhibitors ? state.exhibitors : [];
}
export function getHasReleased(state: State): boolean {
  return state.hasReleased;
}
export default <GetterTree<State, any>>{
  getList,
  getTotal,
  getItem,
  getError,
  isLoading,
  isCreated,
  isUpdated,
  isDeleted,
  getExhibitorsList,
  getHasReleased
};
