
// @ts-ignore
import Treeselect from "@riophae/vue-treeselect";
import { Component, Mixins, Prop, Watch } from "vue-property-decorator";
import CrudLayout from "@/layouts/CrudLayout.vue";
import { Validate, Validations } from "vuelidate-property-decorators";
import { email, required, sameAs } from "vuelidate/lib/validators";
import Validation from "@/components/Validation.vue";
import Loading from "@/components/Loading.vue";
import i18n from "@/locales";
import IExhibitor from "@/models/IExhibitor";
import FormBackButton from "@/components/buttons/FormBackButton.vue";
import { Action, Getter } from "vuex-class";
import { isMedia } from "@/validators/validators";
import AuthService from "@/service/AuthService";
import Snackbar from "@/components/snackbar/Snackbar.vue";
import ExhibitorFormFields from "./ExhibitorFormFields.vue";
import ExhibitorFormUpload from "./ExhibitorFormUpload.vue";
import { BaseMixin } from "@/mixins/baseMixin";
import SortService from "@/service/SortService";

const namespace: string = "exhibitor";
const snackbarNamespace: string = "snackbar";
@Component({
  components: {
    FormBackButton,
    CrudLayout,
    Validation,
    Loading,
    Treeselect,
    Snackbar,
    ExhibitorFormFields,
    ExhibitorFormUpload
  }
})
export default class ExhibitorForm extends Mixins(BaseMixin) {
  // component initial values
  public name: string = "ExhibitorForm";
  private resourceName = "exhibitor";

  // props
  @Prop({ default: "add" })
  public type: any;
  @Prop({ default: false })
  public saved: any;
  @Prop({ default: false })
  public resetAction: any;

  @Prop({ default: false })
  public profileUpdate?: boolean;

  @Prop({ default: false })
  public loading: any;
  @Prop({ default: "" })
  public error: any;
  @Prop({
    default: function() {}
  })
  public saveAction: any;
  @Prop({
    default: function() {}
  })
  public back: any;
  @Prop()
  public item: any;

  @Prop({ default: false })
  public isExhibitor!: boolean;

  @Validate({ required, email })
  public email: string = "";

  @Validate({ required })
  public companyName: string = "";

  public file: any = "";

  public oldPassword: string = "";

  public password: string = "";

  public password_repeat: string = "";

  public active: number = 0;

  public street: string = "";

  public number: string = "";

  public website: string = "";

  public zip_code: string = "";

  public city: string = "";

  public phone: string = "";

  public description: string = "";

  public webLogoId: number | null = null;
  public webLogo: object | null = null;
  public uploadPercentage: number = 0;
  public showProgressBar: boolean = true;

  @Getter("getCatalogs", { namespace }) catalogs!: any[];
  @Getter("getSelectedCatalogs", { namespace }) selectedCatalogs!: any[];
  @Getter("getRegions", { namespace }) regions!: any[];
  @Getter("getCountries", { namespace }) countries!: any[];
  @Getter("getSelectedRegions", { namespace }) selectedRegions!: any[];

  @Action("getSelectedCatalogs", { namespace }) catalogAction: any;
  @Action("getRegions", { namespace }) regionAction: any;
  @Action("setText", { namespace: snackbarNamespace }) setText: any;
  @Action("setActive", { namespace: snackbarNamespace }) setActive: any;

  public ready: number = 0;

  public approved: number = 0;

  private selectedRegion: any = null;
  private selectedCatalog: any = null;

  protected get getCatalogs(): any[] {
    const catalogs: any[] = [];

    this.catalogs.forEach(catalog => {
      catalogs.push({
        id: catalog.id,
        label: catalog.name
      });
    });

    return catalogs;
  }

  protected get getRegions(): any[] {
    const countries: any[] = [];

    this.countries.forEach(country => {
      const regions: any[] = this.regions.filter(region => {
        return region.country_id == country.id;
      });
      const regionsTransformed: any[] = [];
      for (let i = 0; i < regions.length; i++) {
        regionsTransformed.push({
          id: regions[i].id,
          label: regions[i].name
        });
      }
      countries.push({
        id: "c_" + country.id,
        label: country.name,
        children: SortService.arrayAsc(regionsTransformed, "label")
      });
    });

    return countries;
  }

  @Watch("selectedCatalogs", { immediate: true, deep: true })
  protected onSelectedCatalogs(value: any[]) {
    const temp: any[] = [];
    value.forEach(catalog => {
      temp.push(catalog.id);
    });
    this.selectedCatalog = temp;
  }

  @Watch("selectedRegions", { immediate: true, deep: true })
  protected onSelectedRegions(value: any[]) {
    const temp: any[] = [];
    value.forEach(region => {
      temp.push(region.region_id);
    });
    this.selectedRegion = temp;
  }

  @Watch("saved", { immediate: true, deep: true })
  protected onUpdated(value: boolean) {
    if (value && this.type != "edit") {
      this.$router.push({ path: "/" + this.resourceName });
    }
  }

  @Validations()
  protected validations() {
    let fields = {};
    if (this.type == "add") {
      fields = Object.assign({}, fields, {
        street: {},
        number: {},
        website: {},
        zip_code: {},
        city: {},
        phone: {},
        description: {},
        file: { incorrectMediaType: isMedia },
        password: { required },
        password_repeat: {
          required,
          password_repeat: sameAs("password")
        }
      });
    } else {
      const validators: any = {
        street: {},
        number: {},
        website: {},
        zip_code: {},
        city: {},
        phone: {},
        description: {},
        file: { incorrectMediaType: isMedia },
        password: {},
        password_repeat: {
          password_repeat: sameAs("password")
        }
      };

      fields = Object.assign({}, fields, validators);
    }

    return fields;
  }

  public columns: any[] = [
    {
      label: "Name",
      field: "name"
    }
  ];

  public mounted(): void {
    const authService = new AuthService();
    const id: any = this.profileUpdate
      ? authService.getUserId()
      : this.$router.currentRoute.params.id;

    this.catalogAction({
      resource: this.resourceName,
      type: this.type,
      id: id
    });

    if (this.profileUpdate) {
      this.regionAction({
        resource: this.resourceName,
        id: id
      });
    } else {
      if (this.$route.params.id) {
        this.regionAction({
          resource: this.resourceName,
          id: id
        });
      } else {
        this.regionAction({ resource: this.resourceName });
      }
    }
  }

  @Watch("item", { immediate: true, deep: true })
  protected onItemChange(item: any) {
    if (item) {
      this.init(item);
    }
  }

  public get fileExtension(): string {
    if (this.file) {
      return this.file.name.split(".").pop();
    }

    return "";
  }

  private init(exhibitor: IExhibitor): void {
    this.$nextTick(() => {
      this.email = String(exhibitor.email);
      this.active = Number(exhibitor.active);

      if (exhibitor.company_name !== "null" && exhibitor.company_name != null) {
        this.companyName = String(exhibitor.company_name);
      }
      if (exhibitor.street !== "null" && exhibitor.street != null) {
        this.street = String(exhibitor.street);
      }
      if (exhibitor.number !== "null" && exhibitor.number != null) {
        this.number = String(exhibitor.number);
      }
      if (exhibitor.website !== "null" && exhibitor.website != null) {
        this.website = String(exhibitor.website);
      }
      if (exhibitor.zip_code !== "null" && exhibitor.zip_code != null) {
        this.zip_code = String(exhibitor.zip_code);
      }
      if (exhibitor.city !== "null" && exhibitor.city != null) {
        this.city = String(exhibitor.city);
      }
      if (exhibitor.phone !== "null" && exhibitor.phone != null) {
        this.phone = String(exhibitor.phone);
      }
      if (exhibitor.description !== "null" && exhibitor.description != null) {
        this.description = String(exhibitor.description);
      }

      if (exhibitor.web_logo != null) {
        const { web_logo } = exhibitor;
        this.webLogoId = web_logo ? Number(web_logo.id) : null;
        this.webLogo = web_logo ? web_logo : null;
      }
      this.file = "";
    });
  }

  private add(formData: FormData): void {
    this.saveAction({
      resource: this.resourceName,
      data: formData,
      disabledLoading: true,
      config: {
        headers: {
          "Content-Type": "multipart/form-data"
        },
        onUploadProgress: (progressEvent: any) => {
          if (formData.get("web_logo") instanceof File) {
            this.uploadPercentage = Number(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }
        }
      }
    });
  }

  private edit(formData: FormData, exhibitorId: number): void {
    formData.append("id", String(exhibitorId));
    this.saveAction({
      resource: this.resourceName,
      data: formData,
      id: exhibitorId,
      profileUpdate: this.profileUpdate,
      disabledLoading: true,
      config: {
        headers: {
          "Content-Type": "multipart/form-data"
        },
        onUploadProgress: (progressEvent: any) => {
          if (formData.get("web_logo") instanceof File) {
            this.uploadPercentage = Number(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }
        }
      }
    })
      .then((response: any) => {
        this.webLogoId = null;
        setTimeout(() => {
          const { web_logo } = response.data.data;
          this.webLogoId = Number(web_logo.id);
        }, 10);
        this.reset();
        this.setText({
          text: String(i18n.t("general.admin_updated_success")),
          type: "success"
        });
        this.setActive(true);
      })
      .catch(() => {
        this.reset();
      });
  }

  private reset() {
    this.password_repeat = "";
    this.password = "";
    this.uploadPercentage = 0;
    this.file = "";
  }

  private companyNameHandler(value: any) {
    this.companyName = value;
  }

  private emailHandler(value: any) {
    this.email = value;
  }

  private streetHandler(value: any) {
    this.street = value;
  }

  private numberHandler(value: any) {
    this.number = value;
  }

  private websiteHandler(value: any) {
    this.website = value;
  }

  private zipCodeHandler(value: any) {
    this.zip_code = value;
  }

  private cityHandler(value: any) {
    this.city = value;
  }

  private phoneHandler(value: any) {
    this.phone = value;
  }

  private descriptionHandler(value: any) {
    this.description = value;
  }

  private passwordHandler(value: any) {
    this.password = value;
  }

  private passwordRepeatHandler(value: any) {
    this.password_repeat = value;
  }

  private fileHandler(value: any) {
    this.file = value;
  }

  public onSubmit(e: Event): void {
    this.$v.$touch();
    if (this.$v.$invalid) {
      return;
    }

    let formData = new FormData();
    formData.append("web_logo", this.file);
    formData.append("approved", String(this.approved));
    formData.append("ready", String(this.ready));
    formData.append("email", String(this.email));
    formData.append("password", String(this.password));
    formData.append("passwordRepeat", String(this.password_repeat));
    formData.append("active", String(this.active));
    formData.append("company_name", String(this.companyName));
    formData.append("street", String(this.street));
    formData.append("number", String(this.number));
    formData.append("website", String(this.website));
    formData.append("zip_code", String(this.zip_code));
    formData.append("city", String(this.city));
    formData.append("phone", String(this.phone));
    formData.append("description", String(this.description));
    formData.append("catalogs", String(JSON.stringify(this.selectedCatalog)));
    formData.append("regions", String(JSON.stringify(this.selectedRegion)));

    if (!this.profileUpdate) {
      if (this.$route.params.id) {
        const exhibitorId = Number.parseInt(this.$route.params.id);
        this.edit(formData, exhibitorId);
      } else {
        this.add(formData);
      }
    } else {
      const authService = new AuthService();
      const userId: number | null = authService.getUserId();
      if (userId) {
        this.edit(formData, userId);
      }
    }
  }
}
